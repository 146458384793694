/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import { UserPermissionEnum } from 'common/enums/enum-user-permissions';
import { PackingListDropDownListItem } from 'common/models/bins';
import { IAutoCompleteIdName } from 'common/props';
import Button from 'components/button';
import Card from 'components/card';
import DatePickerInput from 'components/datepicker';
import Header from 'components/header';
import { snackActions } from 'config/snackbar.js';
import useQuery from 'helpers/useQuery/useQuery';
import _ from 'lodash';
import moment from 'moment';
import PackingListNotesModal from 'pages/receiving/purchaseordersv2/packing-list-notes-modal';
import ActualQuantityModal from 'pages/receiving/receipts/actual-quantity-modal';
import QtyAndExpectedCheckModal from 'pages/receiving/receipts/qty-and-expected-check-modal';
import { ModalReturnLineItem } from 'pages/sales/return-line-item-modal';
import ConfirmationDialog from 'pages/shared/confirmdeletemodal';
import ConfirmLinkedPoModal from 'pages/shared/confirmlinkedpomodal';
import ConfirmReceiptModal from 'pages/shared/confirmreceiptmodal';
import EditConfirmedReceiptmodal from 'pages/shared/editconfirmedreceiptmodal';
import EditReceiptModal from 'pages/shared/editreceiptmodal';
import MixedPalletModal from 'pages/shared/mixedpalletmodal';
import { PrintTypeEnum } from 'pages/shared/print/models/PrintTypeEnum';
import {
  OtherUOMPrint,
  OtherUOMPrintModel,
} from 'pages/shared/print/otheruomprint';
import PrintQtyModal from 'pages/shared/print/printqtymodal';
import { SKUPrint, SKUPrintModel } from 'pages/shared/print/skuprint';
import Printformtemplate, {
  FormTemplateTypeEnum,
} from 'pages/shared/printformtemplate';
import { getCompletedOrdersForReturn } from 'services/api/orders/orders.api';
import {
  getReceiptLineItemToPrint,
  updateReceiptNonBillable,
} from 'services/api/receipts/receipts-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { DatagridPremium, DatagridStandard } from 'styles';

import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import NoteIcon from '@mui/icons-material/Note';
import PrintIcon from '@mui/icons-material/Print';
import {
  Typography,
  TextField,
  Grid as MUIGrid,
  Box,
  Autocomplete,
  IconButton,
  Tooltip,
  Divider,
  Chip,
  Button as ButtonBlue,
  Link,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import { GridEditRowsModel } from '@mui/x-data-grid';
import {
  GridValidRowModel,
  GridColumns,
  GridActionsCellItem,
  GridColDef,
  GridEnrichedColDef,
} from '@mui/x-data-grid-premium';

import { MainContainer, ContentContainer } from '../../styles';
import {
  SubpageReceiptsContextProvider,
  useSubpageReceiptsContext,
} from './context';
import DocumentsModal from './documentsModal';
import { SinglePrintLineItemV2, PrintAllLineItemV2 } from './lp-print-v2';

export const numberWithCommas = (x) =>
  x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

type AutocompleteBase = {
  label?: string;
  value?: number;
};

type LicensePlateType = AutocompleteBase;
type StatusOnArrivalType = AutocompleteBase;

type LocationCrossDockType = AutocompleteBase;

type ExtendLotNoType = {
  lotId?: number;
  lotCreationDate?: string;
  lotExpirationDate?: string;
};
export type LotNoType = AutocompleteBase & ExtendLotNoType;

type ExtendItemUnitOfMeasureType = {
  uomAbbr: string;
  isPrimaryUOM: boolean;
  qty?: number;
  primaryUOM?: number;
  primaryUOMStr?: string;
  primaryUOMAbbr?: string;
  uomType: number;
  textInPerCase?: string;
  primaryTextInPerCase?: string;
  textInTotal?: string;
  primaryLength?: string;
  primaryWidth?: string;
  primaryHeight?: string;
  primaryWeight?: string;
};
export type ItemUnitOfMeasureType = AutocompleteBase &
  ExtendItemUnitOfMeasureType;

export enum ReceiptStatus {
  Open = 1,
  Confirmed = 2,
  ReadyforPutAway = 3,
}

export enum FormQueryStringStatus {
  New = 'new',
  Edit = 'edit',
}

export enum LicensePlateTypeStatus {
  No = 'No',
  Pallet = 'Pallet',
}

export enum ModalTitleStatus {
  Add = 'Add',
  Edit = 'Edit',
  Delete = 'Delete',
  AddHold = 'Add Hold',
}

export enum ReceiptLineItemStatusOnArrivalEnum {
  Available = 1,
  Hold = 2,
  Damaged = 3,
  Quarantine = 4,
  CrossDock = 5,
}

export type FileType = {
  name: string;
  content: string;
  size: number;
};
export type ReceiptLineItemImageType = {
  receiptLineItemImageId?: number;
  receiptLineItemId?: number;
  fileName?: string;
  fileExtension?: string;
  file?: FileType;
  base64Data: string;
  showRemove: boolean; // UI
};

const ReceiptStatusProgressBar = [
  'Arrived',
  'Open',
  'Ready for Put Away',
  'Confirmed',
  'Put Away Completed',
];

const ReceiptProgressBar = () => {
  const { pageViewerReceipt, receiptStatus, formValues } =
    useSubpageReceiptsContext();

  const [receiptStatusValue, setReceiptStatusValue] = useState<number>(0);
  useEffect(() => {
    if (!pageViewerReceipt.isNew()) {
      if (receiptStatus === ReceiptStatus.Confirmed) {
        setReceiptStatusValue(2);
      } else {
        setReceiptStatusValue(3);
      }
      if (formValues?.isPutAwayCompleted) {
        setReceiptStatusValue(4);
      }
    }
  }, [pageViewerReceipt]);

  return (
    <Box sx={{ width: '100%', padding: '35px 70px' }}>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'space-evenly' }}
        >
          {Object.keys(ReceiptStatusProgressBar).map((key, index) => (
            <Typography variant="body1" fontWeight="bold">
              {ReceiptStatusProgressBar[index]}
            </Typography>
          ))}
        </MUIGrid>
        <MUIGrid item xs={12}>
          <LinearProgress
            variant="determinate"
            value={
              receiptStatusValue === 4 ? 100 : formValues?.statusProgressValue
            }
            sx={{
              width: '100%',
              height: '10px',
              borderRadius: 2,
              backgroundColor: 'rgb(211 211 211)',
              [`& .${linearProgressClasses.bar1Determinate}`]: {
                borderRadius: 2,
                backgroundColor: receiptStatusValue === 4 && '#00cf00',
              },
            }}
          />
        </MUIGrid>
      </MUIGrid>
    </Box>
  );
};

const Receipt = () => {
  const { currentLocationAndFacility } = useContext(AuthContext);

  // const options = ['Option 1', 'Option 2'];
  const {
    queryStringItemReceipts,
    options,
    optionFacility,
    optionLocation,
    customerValue,
    setCustomerValue,
    formValues,
    setFormValues,
    formErrors,
    onLoadPurchaseOrderNumberByWarehouseCustomer,
    loadBillingChargesDetailByModule,
    purchaseOrderOptions,
    handleOnBlurPurchaseOrderIdOrNumber,
    setPurchaseOrderIdOrNumber,
    purchaseOrderIdOrNumber,
    setFacilityValue,
    facilityValue,
    locationValue,
    setLocationValue,
    receiptStatus,
    onLoadGetLocation,
    onLoadGetLocationCrossDock,
    poIdLinkedLineitemValue,
    pageViewerReceipt,
    viewLoadData,
    optionOrdersForReturn,
    orderReturnValue,
    setOrderReturnValue,
    setOrderDetails,
    onLoadOrderDetailsForReturn,
  } = useSubpageReceiptsContext();

  const onChangeDatePickerValue = async (e: any, isFuture) => {
    if (isFuture) {
      const val = new Date(e);
      const fut = new Date();
      if (val > fut) {
        setFormValues((prevState) => ({
          ...prevState,
          arrivalDate: fut,
        }));
        return;
      }
    }
    setFormValues((prevState) => ({
      ...prevState,
      arrivalDate: e,
    }));
  };

  useEffect(() => {
    if (pageViewerReceipt.isViewOrEdit()) {
      setFacilityValue(
        optionFacility.find(
          (item) => item.value === formValues.customerFacilityId,
        ),
      );
      setLocationValue(
        optionLocation.find((item) => item.value === formValues.location),
      );
    }

    const currClient: IAutoCompleteIdName = JSON.parse(
      localStorage.getItem('storeWarehouseCustomer'),
    );

    if (pageViewerReceipt.isNew() && options.length > 0 && currClient) {
      const clientIndex = options.findIndex(
        (r: any) => Number(r.warehouseCustomerId) === Number(currClient.id),
      );
      setCustomerValue((e: any) => ({
        ...e,
        name: options[clientIndex].name,
        warehouseCustomerId: options[clientIndex].warehouseCustomerId,
      }));
      setFormValues((e: any) => ({
        ...e,
        customer: options[clientIndex].name,
        customerId: options[clientIndex].warehouseCustomerId,
      }));
      onLoadPurchaseOrderNumberByWarehouseCustomer(currClient.id);
    }

    if (pageViewerReceipt.isNew() && queryStringItemReceipts.lineItem === 0) {
      if (optionFacility.length === 1) {
        setFormValues((e) => ({
          ...e,
          customerFacilityId: optionFacility[0].value,
        }));
        setFacilityValue(optionFacility[0]);
      }
    }
  }, [options, optionLocation]);

  useEffect(() => {
    if (
      pageViewerReceipt.isNew() &&
      pageViewerReceipt.isReturn() &&
      optionFacility.length > 0
    ) {
      setFormValues((e) => ({
        ...e,
        customerFacilityId: currentLocationAndFacility.customerFacilityId,
      }));
      setFacilityValue(
        optionFacility.find(
          (r) => r.value === currentLocationAndFacility.customerFacilityId,
        ),
      );
    }
  }, [optionFacility]);

  const handleFilterOption = (optionsValue: any[], filter) => {
    const result = optionsValue.filter(
      (r) =>
        r.label.toLowerCase().includes(filter.inputValue.toLowerCase()) ||
        r.purchaseOrderNo
          .toLowerCase()
          .includes(filter.inputValue.toLowerCase()),
    );
    return result;
  };

  const hanldeUpdateNonBillable = async (e: any, value: boolean) => {
    e.preventDefault();

    setFormValues((prev) => ({
      ...prev,
      isNonBillable: value,
    }));

    if (pageViewerReceipt.isView()) {
      try {
        const payload = {
          receiptId: queryStringItemReceipts.id,
          isNonBillable: value,
        };
        await updateReceiptNonBillable(payload).then(() => {
          viewLoadData().then(() => {
            snackActions.success('Non-billing has been successfully updated.');
          });
        });
      } catch (err) {
        snackActions.error('Failed to update');
      }
    }
  };

  return (
    <>
      <ConfirmLinkedPoModal
        poId={poIdLinkedLineitemValue}
        dialogText="Would you like to populate packing list line item/s"
      />
      <Card
        sx={{
          marginBottom: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          Receipt
        </Typography>
        <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <MUIGrid item xs={4}>
            <Autocomplete
              value={customerValue}
              onChange={(event: any, newValue) => {
                onLoadPurchaseOrderNumberByWarehouseCustomer(
                  newValue.warehouseCustomerId,
                );
                loadBillingChargesDetailByModule(newValue.warehouseCustomerId);
                setPurchaseOrderIdOrNumber('');

                setFormValues((prevState) => ({
                  ...prevState,
                  customer: event.target.innerText,
                  customerId: newValue.warehouseCustomerId,
                }));

                if (newValue === null || newValue === undefined) {
                  if (options.length === 1) {
                    options.forEach((o) => {
                      setFormValues((e) => ({
                        ...e,
                        customerId: o.warehouseCustomerId,
                        customer: o.name,
                      }));
                    });
                  }
                } else {
                  setCustomerValue(newValue);
                }
              }}
              disableClearable
              options={options}
              getOptionLabel={(option) => option.name || ''}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  {...(formErrors.customer && {
                    error: true,
                    helperText: formErrors.customer,
                  })}
                  id="outlined-required"
                  label="Client"
                  size="small"
                  sx={{ width: '100%' }}
                />
              )}
              disabled={pageViewerReceipt.isViewOrEdit()}
            />
          </MUIGrid>
          <MUIGrid item xs={4}>
            <Autocomplete
              disableClearable
              sx={{ width: '100%' }}
              options={optionFacility}
              value={facilityValue || null}
              getOptionLabel={(option) => (option.label ? option.label : '')}
              size="small"
              disabled={
                receiptStatus === ReceiptStatus.Confirmed ||
                pageViewerReceipt.isView()
              }
              onChange={(event: any, newValue) => {
                setFormValues((prevState) => ({
                  ...prevState,
                  customerFacilityId: newValue.value,
                }));
                onLoadGetLocation(newValue.value);
                onLoadGetLocationCrossDock(newValue.value);

                if (newValue === null || newValue === undefined) {
                  if (optionFacility.length === 1) {
                    optionFacility.forEach((o) => {
                      setFormValues((e) => ({
                        ...e,
                        customerFacilityId: o.value,
                      }));
                    });
                  }
                } else {
                  setFacilityValue(newValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  required
                  sx={{
                    '& .MuiInputBase-root': {
                      background: 'white',
                    },
                  }}
                  {...params}
                  label="Facility"
                  {...(formErrors.customerFacilityId && {
                    error: true,
                    helperText: formErrors.customerFacilityId,
                  })}
                />
              )}
            />
          </MUIGrid>
          <MUIGrid item xs={4}>
            <TextField
              required={
                queryStringItemReceipts.form === FormQueryStringStatus.Edit
              }
              id="outlined-required"
              label="Reference No"
              size="small"
              sx={{ width: '100%' }}
              {...(pageViewerReceipt.isView() && {
                disabled: true,
              })}
              value={formValues.referenceNo}
              onChange={(event: any) => {
                setFormValues((prevState) => ({
                  ...prevState,
                  referenceNo: event.target.value,
                }));
              }}
              {...(formErrors.referenceNo && {
                error: true,
                helperText: formErrors.referenceNo,
              })}
            />
          </MUIGrid>
          {formValues.isReturnOrder !== true && (
            <MUIGrid item xs={4}>
              <Autocomplete
                sx={{ width: '100%' }}
                id="free-solo-demo"
                freeSolo
                disableClearable
                options={purchaseOrderOptions}
                value={purchaseOrderIdOrNumber}
                size="small"
                disabled={
                  receiptStatus === ReceiptStatus.Confirmed ||
                  pageViewerReceipt.isView()
                }
                getOptionLabel={(option) =>
                  option.label ? option.label : purchaseOrderIdOrNumber
                }
                filterOptions={handleFilterOption}
                onBlur={(e) => handleOnBlurPurchaseOrderIdOrNumber(e)}
                renderOption={(props, option: PackingListDropDownListItem) => (
                  <li {...props} key={option.value}>
                    <Box display="flex">
                      <Box display="flex" flexDirection="column">
                        <Typography color="text.primary">
                          {option.label}
                        </Typography>
                      </Box>
                    </Box>
                  </li>
                )}
                renderInput={(params) =>
                  formErrors.purchaseOrder ? (
                    <TextField
                      sx={{
                        '& .MuiInputBase-root': {
                          background: 'white',
                        },
                      }}
                      {...params}
                      label="PO Packing List"
                      error
                      helperText={formErrors.purchaseOrder}
                    />
                  ) : (
                    <TextField
                      sx={{
                        '& .MuiInputBase-root': {
                          background: 'white',
                        },
                      }}
                      {...params}
                      label="PO Packing List"
                    />
                  )
                }
              />
            </MUIGrid>
          )}
          <MUIGrid item xs={4}>
            <Autocomplete
              sx={{ width: '100%' }}
              options={optionLocation}
              value={locationValue || null}
              size="small"
              disabled={
                receiptStatus === ReceiptStatus.Confirmed ||
                pageViewerReceipt.isView()
              }
              onChange={(event: any, newValue) => {
                if (newValue === null || newValue === undefined) {
                  if (optionLocation.length === 1) {
                    optionLocation.forEach((o) => {
                      setFormValues((e) => ({
                        ...e,
                        location: o.value,
                      }));
                    });
                  }
                  setLocationValue(null);
                  setFormValues((prevState) => ({
                    ...prevState,
                    location: null,
                  }));
                } else {
                  setFormValues((prevState) => ({
                    ...prevState,
                    location: newValue.value,
                  }));
                  setLocationValue(newValue);
                }
              }}
              renderInput={(params) =>
                formErrors.location ? (
                  <TextField
                    sx={{
                      '& .MuiInputBase-root': {
                        background: 'white',
                      },
                    }}
                    {...params}
                    label="Receive To Location"
                    error
                    helperText={formErrors.location}
                  />
                ) : (
                  <TextField
                    sx={{
                      '& .MuiInputBase-root': {
                        background: 'white',
                      },
                    }}
                    {...params}
                    label="Receive To Location"
                  />
                )
              }
            />
          </MUIGrid>
          <MUIGrid item xs={4}>
            <DatePickerInput
              label="Expected Date"
              inputFormat="MM/dd/yyyy"
              value={formValues.expectedDate}
              onChange={(e) => {
                if (e !== null && e !== 'Invalid Date') {
                  setFormValues((prevState) => ({
                    ...prevState,
                    expectedDate: e,
                  }));
                } else {
                  setFormValues((prevState) => ({
                    ...prevState,
                    expectedDate: null,
                  }));
                }
              }}
              {...(pageViewerReceipt.isView() && {
                disabled: true,
              })}
              renderInput={(params) => (
                <TextField {...params} error={false} size="small" />
              )}
            />
          </MUIGrid>
          <MUIGrid item xs={4}>
            <DatePickerInput
              label="Arrival Date"
              inputFormat="MM/dd/yyyy"
              value={formValues.arrivalDate}
              onChange={(e) => {
                if (e !== null && e !== 'Invalid Date') {
                  onChangeDatePickerValue(e, true);
                } else {
                  setFormValues((prevState) => ({
                    ...prevState,
                    arrivalDate: null,
                  }));
                }
              }}
              {...(pageViewerReceipt.isView() && {
                disabled: true,
              })}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  {...(formErrors.arrivalDate && {
                    error: true,
                    helperText: formErrors.arrivalDate,
                  })}
                  id="outlined-required"
                  size="small"
                />
              )}
            />
          </MUIGrid>
          <MUIGrid
            item
            xs={4}
            sx={{
              paddingLeft: '0px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Checkbox
              checked={formValues.isNonBillable}
              onChange={(e) => {
                hanldeUpdateNonBillable(e, e.target.checked);
              }}
            />
            <Typography variant="subtitle2">Non-Billable Receipt</Typography>
          </MUIGrid>
        </MUIGrid>
      </Card>
    </>
  );
};

const OrderSection = () => {
  const {
    formValues,
    formErrors,
    orderDetails,
    lineItemRows,
    setFormValues,
    setFormErrors,
    receiptStatus,
    orderReturnValue,
    pageViewerReceipt,
    optionOrdersForReturn,
    facilityValue,
    customerValue,
    setOrderDetails,
    setOrderReturnValue,
    setOptionOrdersForReturn,
    onLoadOrderDetailsForReturn,
  } = useSubpageReceiptsContext();

  const [loading, setLoading] = useState<boolean>(false);

  const fetchSuggestions = async (searchText: string) => {
    if (!searchText) {
      setOptionOrdersForReturn([]);
      return;
    }

    const temp: any = {};
    temp.customer =
      formValues?.customer !== '' && formValues.customer !== undefined
        ? ''
        : 'This field is required';

    temp.customerFacilityId =
      formValues?.customerFacilityId !== '' ||
      formValues?.customerFacilityId === undefined
        ? ''
        : 'This field is required';

    setFormErrors({
      ...temp,
    });
    if (!Object.values(temp).every((x) => x === '')) {
      return;
    }

    if (!searchText || Number(searchText) <= 0) {
      return;
    }

    setLoading(true);

    try {
      await getCompletedOrdersForReturn(
        formValues?.customerId,
        formValues?.customerFacilityId,
        searchText,
      ).then((res) => {
        setOptionOrdersForReturn(res);
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      pageViewerReceipt.orderNo &&
      pageViewerReceipt.isReturn() &&
      facilityValue &&
      customerValue &&
      orderReturnValue === null
    ) {
      fetchSuggestions(pageViewerReceipt.orderNo);
    }
  }, [facilityValue]);

  useEffect(() => {
    if (
      pageViewerReceipt.orderNo &&
      pageViewerReceipt.isReturn() &&
      optionOrdersForReturn?.length > 0 &&
      orderReturnValue === null
    ) {
      const getOrderDetail = optionOrdersForReturn.find(
        (r) => r.text === pageViewerReceipt.orderNo,
      );

      if (getOrderDetail) {
        setOrderReturnValue(getOrderDetail);
        onLoadOrderDetailsForReturn(getOrderDetail.value);
        setFormValues((e) => ({
          ...e,
          returnOrderId: getOrderDetail.value,
        }));
      }
    }
  }, [optionOrdersForReturn]);

  return (
    <Card
      sx={{
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" fontWeight="bold" sx={{ marginRight: '20px' }}>
          Order
        </Typography>

        {pageViewerReceipt.isNew() ? (
          <Autocomplete
            sx={{ width: '70%' }}
            options={optionOrdersForReturn}
            value={orderReturnValue || null}
            size="small"
            disabled={
              receiptStatus === ReceiptStatus.Confirmed ||
              pageViewerReceipt.isView() ||
              lineItemRows?.length > 0
            }
            // eslint-disable-next-line @typescript-eslint/no-shadow
            onInputChange={(_, newInputValue) => {
              fetchSuggestions(newInputValue);
            }}
            onChange={(event: any, newValue) => {
              if (newValue != null) {
                setOrderReturnValue(newValue);
                onLoadOrderDetailsForReturn(newValue.value);
                setFormValues((e) => ({
                  ...e,
                  returnOrderId: newValue.value,
                }));
              } else {
                setOrderReturnValue(null);
                setFormValues((e) => ({
                  ...e,
                  returnOrderId: null,
                }));
                setOrderDetails(null);
              }
            }}
            getOptionLabel={(option) => option.text || ''}
            noOptionsText="Type Order# To Search"
            renderInput={(params) => (
              <TextField
                sx={{
                  '& .MuiInputBase-root': {
                    background: 'white',
                  },
                }}
                {...params}
                label="Return Order No"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null,
                }}
                {...(formErrors.orderReturn && {
                  error: true,
                  helperText: formErrors.orderReturn,
                })}
                required
              />
            )}
          />
        ) : (
          <TextField
            id="outlined"
            name="returnOrderNo"
            value={orderReturnValue?.text || ''}
            label="Return Order No"
            size="small"
            sx={{ width: '100%' }}
            disabled
          />
        )}
      </Box>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={6}>
          <Typography fontWeight="bold" variant="body2">
            Recipient:
          </Typography>
          <Typography fontWeight="bold" variant="body2">
            Address:
          </Typography>
          <Typography
            fontWeight="bold"
            variant="body2"
            sx={{ marginTop: '44px' }}
          >
            Ship Date:
          </Typography>
          <Typography fontWeight="bold" variant="body2">
            Carrier:
          </Typography>
          <Typography fontWeight="bold" variant="body2">
            Service:
          </Typography>
        </MUIGrid>
        <MUIGrid item xs={6}>
          <MUIGrid item xs={12}>
            <Typography variant="body2">
              {orderDetails?.recipientName}
            </Typography>
          </MUIGrid>
          <MUIGrid item xs={12}>
            <Typography variant="subtitle2">{orderDetails?.address}</Typography>
          </MUIGrid>
          <MUIGrid item xs={12}>
            <Typography variant="subtitle2">
              {orderDetails?.shipDate &&
                moment(orderDetails?.shipDate).format('MM/DD/YYYY')}
            </Typography>
          </MUIGrid>
          <MUIGrid item xs={12}>
            <Typography variant="subtitle2">
              {orderDetails?.carrierName}
            </Typography>
          </MUIGrid>
          <MUIGrid item xs={12}>
            <Typography variant="subtitle2">
              {orderDetails?.carrierService}
            </Typography>
          </MUIGrid>
        </MUIGrid>
      </MUIGrid>
    </Card>
  );
};

const Transportation = () => {
  const {
    queryStringItemReceipts,
    formValues,
    handleOnChange,
    pageViewerReceipt,
  } = useSubpageReceiptsContext();
  return (
    <Card
      sx={{
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Typography variant="h6" fontWeight="bold">
        Transportation
      </Typography>
      <MUIGrid
        container
        spacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ marginBottom: 1 }}
      >
        <MUIGrid item xs={4}>
          <TextField
            id="outlined"
            name="carrier"
            value={formValues.carrier || ''}
            onChange={(e) => handleOnChange(e)}
            label="Carrier"
            size="small"
            sx={{ width: '100%' }}
            {...(pageViewerReceipt.isView() && {
              disabled: true,
            })}
          />
        </MUIGrid>
        <MUIGrid item xs={4}>
          <TextField
            id="outlined"
            name="trailerNo"
            value={formValues.trailerNo}
            onChange={(e) => handleOnChange(e)}
            label="Trailer No."
            size="small"
            sx={{ width: '100%' }}
            {...(pageViewerReceipt.isView() && {
              disabled: true,
            })}
          />
        </MUIGrid>
        <MUIGrid item xs={4}>
          <TextField
            id="outlined"
            name="sealNo"
            value={formValues.sealNo}
            onChange={(e) => handleOnChange(e)}
            label="Seal No."
            size="small"
            sx={{ width: '100%' }}
            {...(pageViewerReceipt.isView() && {
              disabled: true,
            })}
          />
        </MUIGrid>
        <MUIGrid item xs={4}>
          <TextField
            id="outlined"
            name="billOfLadingNumber"
            value={formValues.billOfLadingNumber}
            onChange={(e) => handleOnChange(e)}
            label="Bill of Lading No."
            size="small"
            sx={{ width: '100%' }}
            {...(pageViewerReceipt.isView() && {
              disabled: true,
            })}
          />
        </MUIGrid>
        <MUIGrid item xs={4}>
          <TextField
            id="outlined"
            name="trackingNo"
            value={formValues.trackingNo || ''}
            onChange={(e) => handleOnChange(e)}
            label="Tracking No."
            size="small"
            sx={{ width: '100%' }}
            {...(pageViewerReceipt.isView() && {
              disabled: true,
            })}
          />
        </MUIGrid>
        <MUIGrid item xs={4}>
          <TextField
            id="outlined"
            name="container"
            value={formValues.container}
            onChange={(e) => handleOnChange(e)}
            label="Container"
            size="small"
            sx={{ width: '100%' }}
            {...(pageViewerReceipt.isView() && {
              disabled: true,
            })}
          />
        </MUIGrid>
      </MUIGrid>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={8}>
          <TextField
            rows={1}
            multiline
            id="outlined-multiline-flexible"
            name="notes"
            value={formValues.notes}
            onChange={(e) => handleOnChange(e)}
            label="Notes"
            size="small"
            sx={{ width: '100%' }}
            {...(pageViewerReceipt.isView() && {
              disabled: true,
            })}
          />
        </MUIGrid>
      </MUIGrid>
    </Card>
  );
};

const LineItems = () => {
  const theme = useTheme();

  const {
    setPrintTypeData,
    setIsPrintQtyModal,
    setSKUPrintModelData,
    idsToPrintLpnFormTemplate,
    onOpenConfirmReceiptModal,
    setOtherUOMPrintModelData,
    setEditActualQtyModalOpen,
    onOpenReceiptLineItemModal,
    onOpenPrintFormTemplateModal,
    setGlobalMessageModal,
    setIsPackingListNotesModalOpen,
    setIsReturnLineItemModalOpen,
  } = useContext(GlobalContext);

  const {
    formValues,
    itemSummary,
    setSkuValue,
    lineItemRows,
    setFormValues,
    setLotNoValue,
    receiptStatus,
    setModalTitle,
    setLineItemRows,
    printAllOfLPItem,
    pageViewerReceipt,
    setPrintAllOfLPItem,
    setSingleLineItemRow,
    setFormLineItemErrors,
    setFormLineItemValues,
    queryStringItemReceipts,
    setOptionStatusOnArrivalValue,
    setUomValue,
    setFormErrors,
    setOptionsUOM,
    setItemSummary,
    setNumberToPrint,
    handleUpdateCheckLineItem,
    setReasonOnStatusTypeValue,
  } = useSubpageReceiptsContext();

  const { handleUserPermissionAllow } = useContext(AuthContext);

  const componentSinglePrintRef = useRef(null);
  const componentPrintAllRef = useRef(null);
  const buttonSinglePrintRef = useRef(null);
  const buttonPrintAllRef = useRef(null);
  const [singlePrintOfLPItem, setSinglePrintOfLPItem] = useState<any>('');
  const [receiptLineItemId, setReceiptLineItemId] = useState(0);

  const [editRowsModel, setEditRowsModel] = React.useState({});

  const [modalMixedPallet, setModalMixedPallet] = useState({
    open: false,
    title: 'Add Mixed Pallet',
    isEdit: false,
  });

  const handleEditSingleRowModelChange = React.useCallback(
    (model: GridEditRowsModel) => {
      setEditRowsModel(model);
      const data = [...lineItemRows];

      const newValue = data.filter(
        (row) => row.lineId.toString() === Object.keys(model).toString(),
      );

      if (
        Object.values(model).length > 0 &&
        Object.values(model)[0] !== null &&
        Object.values(model)[0].actual !== undefined
      ) {
        newValue[0].actual = Object.values(model)[0].actual.value;

        lineItemRows.forEach((x) => {
          if (x.lineId.toString() === newValue[0].lineId.toString()) {
            setLineItemRows((prev) =>
              prev.map((row) =>
                row.lineId.toString() === newValue[0].lineId.toString()
                  ? {
                      ...row,
                      actual: newValue[0].actual,
                    }
                  : row,
              ),
            );
          }
        });

        setFormValues((current) => ({
          ...current,
          lineItems: current.lineItems.map((row) =>
            row.lineId.toString() === newValue[0].lineId.toString()
              ? {
                  ...row,
                  actual: newValue[0].actual,
                }
              : row,
          ),
        }));
      }
    },
    [lineItemRows],
  );

  const onLoadReceiptLineItemToPrint = async (id) => {
    try {
      return await getReceiptLineItemToPrint(id);
    } catch (error) {
      return false;
    }
  };

  const handleOnClickSinglePrint = useReactToPrint({
    content: () => componentSinglePrintRef.current,
  });

  const handleOnClickPrintAll = useReactToPrint({
    content: () => componentPrintAllRef.current,
  });

  const handleOnClickMixedPallet = async () => {
    setModalMixedPallet((prev: any) => ({
      ...prev,
      open: true,
      title:
        queryStringItemReceipts.form === FormQueryStringStatus.New
          ? 'Add Mixed Pallet Modal'
          : 'Edit Mixed Pallet Modal',
    }));
  };

  const hanldeModalMixedPalletConfirm = async () => {
    setModalMixedPallet((prev: any) => ({
      ...prev,
      open: false,
      title:
        queryStringItemReceipts.form === FormQueryStringStatus.New
          ? 'Add Mixed Pallet Modal'
          : 'Edit Mixed Pallet Modal',
    }));
  };

  const hanldeModalMixedPalletClose = async () => {
    setModalMixedPallet((prev: any) => ({
      ...prev,
      open: false,
    }));
  };

  const computeReceiptItemSummary = () => {
    if (lineItemRows && lineItemRows.length > 0) {
      const arrItems = [...lineItemRows];
      const mapItems = arrItems.map((item) => {
        const newItem = item;
        newItem.qty = Number(item.qty);
        return newItem;
      });

      const items = _.map(_.groupBy(mapItems, 'itemId'), (o, idx) => ({
        itemId: idx,
        name: o[0].sku,
        qty: _.sumBy(o, 'qty'),
        qtyExpected: o[0]?.qtyExpected,
      }));

      if (items && items.length > 0) {
        const total =
          items.map((q) => Number(q.qty)).reduce((a, b) => a + b) ?? 0;

        setItemSummary((prev) => ({
          ...prev,
          items,
          total,
        }));
      }
    }
  };

  React.useEffect(() => {
    if (receiptLineItemId > 0) {
      onLoadReceiptLineItemToPrint(receiptLineItemId).then((x) => {
        setSinglePrintOfLPItem((prev) => ({
          ...prev,
          receiptLineItemId: x.receiptLineItemId,
          customer: x.customer,
          reference: x.reference,
          licensePlateNo: x.licensePlateNo,
          sku: x.sku,
          description: x.description,
          each: x.each,
          eaCase: x.eaCase,
          totalCases: x.totalCases,
          lot: {
            lotId: x.lot?.lotId,
            lotNo: x.lot?.lotNo,
            lotCreationDate: x.lot?.creationDate,
            lotExpirationDate: x.lot?.expirationDate,
          },
          // button: buttonSinglePrintRef.current,
          isSku: false,
        }));
      });
    }
  }, [receiptLineItemId]);

  React.useEffect(() => {
    if (
      printAllOfLPItem.lps !== 0 ||
      printAllOfLPItem !== '' ||
      printAllOfLPItem !== undefined
    ) {
      setPrintAllOfLPItem((prev) => ({
        ...prev,
        button: buttonPrintAllRef.current,
      }));
    }
  }, []);

  useEffect(() => {
    computeReceiptItemSummary();
  }, [lineItemRows, formValues]);

  const lineItemsColumns: GridEnrichedColDef[] = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      hideable: true,
      hide: !handleUserPermissionAllow(UserPermissionEnum.Receiving_Edit),
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => {
            onOpenReceiptLineItemModal();
            setModalTitle(ModalTitleStatus.Edit);
            setFormLineItemValues((prevState) => ({
              ...prevState,
              lineId: params.row.lineId,
              status: params.row.status,
            }));
            setOptionStatusOnArrivalValue({
              value: params.row.status,
              label: params.row.statusStr,
            });
            setSingleLineItemRow(params.row);
            setFormLineItemErrors({});
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => {
            onOpenConfirmReceiptModal();
            setModalTitle(ModalTitleStatus.Delete);
            setSingleLineItemRow(params.row);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Add Hold"
          disabled={
            params.row.status === ReceiptLineItemStatusOnArrivalEnum.Hold
          }
          onClick={() => {
            setModalTitle(ModalTitleStatus.AddHold);
            setSingleLineItemRow((prev) => ({
              ...prev,
              lineIdToUpdateQty: params.row?.lineId,
              lineId: `NEW_${formValues.lineItems.length}`,
              statusOnArrival: 2,
              qty: params.row?.qty,
              totalCases: params.row?.totalCases,
              primaryLength: params.row?.primaryLength,
              primaryWidth: params.row?.primaryWidth,
              primaryHeight: params.row?.primaryHeight,
              primaryWeight: params.row?.primaryWeight,
            }));
            onOpenReceiptLineItemModal();
            setFormLineItemErrors({});
            setReasonOnStatusTypeValue(null);
          }}
          showInMenu
        />,
      ],
    },
    {
      field: 'sku',
      minWidth: 250,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">SKU</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <>
          <Link
            sx={{
              fontSize: 14,
              fontWeight: 600,
              color: '#303538',
              textDecoration: 'none',
            }}
            href={`/inventory/item-details?form=view&id=${params.row.itemId}`}
            target="_blank"
            underline="none"
          >
            <Typography
              sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
            >
              {params.row.sku}
            </Typography>
          </Link>
          {/* {params.row.status &&
            params.row.status ===
              ReceiptLineItemStatusOnArrivalEnum.Available && (
              <Chip
                label={params.row.statusStr}
                size="small"
                sx={{
                  marginLeft: 1,
                  color: 'white',
                  bgcolor: '#5cb85c',
                }}
              />
            )} */}

          {params.row.status &&
            params.row.status !==
              ReceiptLineItemStatusOnArrivalEnum.Available && (
              <Chip
                label={params.row.statusStr}
                size="small"
                sx={{
                  marginLeft: 1,
                  color: 'white',
                  bgcolor: '#CC3333',
                }}
              />
            )}
        </>
      ),
    },
    {
      field: 'description',
      minWidth: 250,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Description</Typography>
        </Box>
      ),
    },
    {
      field: 'qty',
      minWidth: 130,
      flex: 0.5,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Quantity</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params?.row?.totalCases
            ? `${Number(params.row.totalCases).toLocaleString()} ${
                params?.row?.otherUOMAbbr
              } (${params.row.otherUOMCount} ${params.row.primaryUOMAbbr})`
            : `${params.row.qty.toLocaleString()} ${params.row.primaryUOMAbbr}`}
        </Typography>
      ),
    },
    {
      field: 'qtyExpected',
      minWidth: 180,
      flex: 0.5,
      hide: !formValues?.packingListId,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Total Conf. / Total Exp.</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params.row?.qtyTotal}{' '}
          {params?.row?.qtyExpected ? `of ${params?.row?.qtyExpected}` : ''}
        </Typography>
      ),
    },
    {
      field: 'totalqty',
      width: 200,
      renderHeader: () => <Typography>Total Quantity</Typography>,
      // renderCell: (params) => <Typography>{params.row.qty}</Typography>,
      renderCell: (params) => (
        <Typography>
          {params.row.qty.toLocaleString()} {params.row.primaryUOMAbbr}
        </Typography>
      ),
    },
    {
      field: 'actual',
      minWidth: 140,
      hide: true,
      valueFormatter: ({ value }) => (value < 0 ? null : value),
      headerAlign: 'left',
      flex: 0.5,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">
            Actual
            <Tooltip
              title="If left blank, the actual quantity will get updated after receipt is confirmed."
              placement="bottom"
              arrow
            >
              <IconButton aria-label="print" color="primary">
                <HelpOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Typography>
        </Box>
      ),
      renderCell: (params) =>
        params?.row?.otherUOMCount ? (
          // other uom
          <Typography
            sx={{
              color:
                params?.row?.actual?.toString() !==
                params?.row?.totalCases?.toString()
                  ? '#DE4A50'
                  : '',
              display: 'flex',
              width: '100%',
              justifyContent: 'right',
              marginLeft: '15px',
            }}
            className="Mui-icon-in-textfield"
          >
            {params?.row?.actual}{' '}
            {params?.row?.actual ? params?.row?.otherUOMAbbr : null}
            {params?.row?.actual
              ? `(${params?.row?.qtyActual} ${params?.row?.primaryUOMAbbr})`
              : ''}
            <IconButton
              color="primary"
              sx={{ display: pageViewerReceipt.isEdit() ? 'block' : 'none' }}
              onClick={() => {
                setSingleLineItemRow(params.row);
                setEditActualQtyModalOpen(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Typography>
        ) : (
          <Typography
            sx={{
              color:
                params?.row?.actual?.toString() !== params?.row?.qty?.toString()
                  ? '#DE4A50'
                  : '',
              display: 'flex',
              width: '100%',
              justifyContent: 'right',
              marginLeft: '15px',
            }}
            className="Mui-icon-in-textfield"
          >
            {params?.row?.actual}{' '}
            {params?.row?.actual ? params.row.primaryUOMAbbr : null}
            <IconButton
              color="primary"
              sx={{ display: pageViewerReceipt.isEdit() ? 'block' : 'none' }}
              onClick={() => {
                setSingleLineItemRow(params.row);
                setEditActualQtyModalOpen(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Typography>
        ),
    },
    {
      field: 'lotNo',
      minWidth: 100,
      flex: 1,
      hide: !formValues?.isShowLotColumns,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Lot No.</Typography>
        </Box>
      ),
    },
    {
      field: 'lotExpirationDate',
      minWidth: 150,
      flex: 1,
      type: 'date',
      hide: !formValues?.isShowLotColumns,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Lot Exp. Date</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography variant="inherit">
          {params.row.lotExpirationDate !== undefined &&
          params.row.lotExpirationDate !== '' &&
          params.row.lotExpirationDate !== null &&
          params.row.lotExpirationDate !== '0001-01-01T00:00:00' &&
          params.row.lotExpirationDate !== '0001-01-02T00:00:00'
            ? moment(params.row.lotExpirationDate).format('MMM DD YYYY')
            : undefined}
        </Typography>
      ),
    },
    {
      field: 'serialNo',
      minWidth: 100,
      flex: 1,
      hide: !formValues?.isShowSerialNoColumn,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Serial No.</Typography>
        </Box>
      ),
    },
    {
      field: 'lp',
      minWidth: 120,
      flex: 0.5,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">LP</Typography>
        </Box>
      ),
    },
    {
      field: 'notes',
      minWidth: 100,
      flex: 0.5,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Notes</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Box sx={{ gap: '5px', display: 'flex', alignItems: 'center' }}>
          {params.row?.statusStr === 'Damaged' && (
            <Typography fontWeight="bold">
              {params.row?.reasonStatusStr}
            </Typography>
          )}
          {params.row?.notes && (
            <Typography>
              <IconButton
                aria-label="print"
                color="primary"
                onClick={() => {
                  setGlobalMessageModal(params.row?.notes);
                  setIsPackingListNotesModalOpen(true);
                }}
              >
                <NoteIcon />
              </IconButton>
            </Typography>
          )}
        </Box>
      ),
    },
    {
      field: 'print',
      minWidth: 120,
      flex: 0.5,
      hide: queryStringItemReceipts.form === FormQueryStringStatus.New,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Print Label</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <>
          {params.row?.lp !== undefined && (
            <IconButton
              aria-label="print"
              color="primary"
              onClick={() => {
                setReceiptLineItemId(params.row?.lineId);
                onOpenPrintFormTemplateModal();
              }}
            >
              <PrintIcon />
            </IconButton>
          )}
          {params.row?.lp === undefined && (
            <>
              <Button
                variant="text"
                size="large"
                onClick={() => {
                  setSKUPrintModelData((prevState: SKUPrintModel) => ({
                    ...prevState,
                    sku: params.row.upc ? params.row.upc : params.row.sku,
                    description: params.row.description,
                    qty: Number(params.row.qty),
                    buttonPrint: null,
                  }));
                  setNumberToPrint(Number(params.row.qty));
                  setPrintTypeData(PrintTypeEnum.SKU);
                  setIsPrintQtyModal(true);
                }}
              >
                SKU
              </Button>
              {params?.row?.isOtherUOM && (
                <Button
                  variant="text"
                  size="large"
                  onClick={() => {
                    let barcode: string;
                    if (params.row.otherUOMUpc) {
                      barcode = params.row.otherUOMUpc;
                    } else {
                      barcode = `{${params.row.otherUOMAbbr}}{${params.row.sku}}{${params.row.otherUOMCount}}`;
                    }

                    setOtherUOMPrintModelData(
                      (prevState: OtherUOMPrintModel) => ({
                        ...prevState,
                        clientName: `${params.row.customer}`,
                        barcode,
                        description: `${params.row.sku} / ${params.row.description}`,
                        qtyToDisplay: `${params.row.totalCases} ${params.row.otherUOMAbbr} (${params.row.otherUOMCount} ${params.row.primaryUOMAbbr})`,
                        qty: Number(params.row.qty),
                        buttonPrint: null,
                      }),
                    );
                    setNumberToPrint(Number(params.row.totalCases));
                    setPrintTypeData(PrintTypeEnum.OtherUOM);
                    setIsPrintQtyModal(true);
                  }}
                >
                  Case
                </Button>
              )}
            </>
          )}
        </>
      ),
    },
    {
      field: 'isLineItemConfirm',
      width: 60,
      hide: pageViewerReceipt.isNew(),
      renderHeader: () => <Typography> </Typography>,
      renderCell: (params) => (
        <Typography>
          <IconButton
            color="primary"
            onClick={() => {
              handleUpdateCheckLineItem(
                params.row?.lineId,
                params.row.isLineItemConfirm,
              );
              // setGlobalMessageModal(params.row?.isLineItemConfirm);
              // setIsPackingListNotesModalOpen(true);
            }}
            disabled={receiptStatus !== ReceiptStatus.Open}
          >
            <CheckCircleTwoToneIcon
              sx={{
                color: params.row?.isLineItemConfirm ? '#45cd45' : '#a9a9a9',
              }}
            />
          </IconButton>
        </Typography>
      ),
    },
  ];

  const handleAddLineItemForReturn = () => {
    const temp: any = {};

    temp.orderReturn = formValues?.returnOrderId ? '' : 'Please select order';
    setFormErrors({
      ...temp,
    });
    if (Object.values(temp).every((x) => x === '')) {
      setIsReturnLineItemModalOpen(true);
    }
  };

  return (
    <Card
      sx={{
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        <PackingListNotesModal />
        <div style={{ display: 'none' }}>
          <SinglePrintLineItemV2
            ref={componentSinglePrintRef}
            data={singlePrintOfLPItem}
          />
          <PrintAllLineItemV2
            ref={componentPrintAllRef}
            data={printAllOfLPItem}
          />
          {receiptLineItemId !== null && receiptLineItemId > 0 && (
            <Printformtemplate
              preview={false}
              dynamicId={receiptLineItemId}
              formtemplateTypeId={FormTemplateTypeEnum.PalletLabels}
              forPalletLabelsIsUseLicense={false}
            />
          )}
          {idsToPrintLpnFormTemplate !== null &&
            idsToPrintLpnFormTemplate?.length > 0 && (
              <Printformtemplate
                preview={false}
                dynamicId={idsToPrintLpnFormTemplate}
                formtemplateTypeId={FormTemplateTypeEnum.PalletLabels}
                isPrintAllLpn
              />
            )}
          <button
            type="button"
            ref={buttonSinglePrintRef}
            onClick={handleOnClickSinglePrint}
          >
            This one is trigger on single print receipt line item
          </button>
          <button
            type="button"
            ref={buttonPrintAllRef}
            onClick={handleOnClickPrintAll}
          >
            This one is trigger on print all receipt line item
          </button>
        </div>
        <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <MUIGrid item xs={6}>
            <Typography variant="h6" fontWeight="bold">
              Line Items
            </Typography>
          </MUIGrid>
          <MUIGrid
            item
            xs={6}
            sx={{ display: 'flex', justifyContent: 'flex-end', columnGap: 1 }}
          >
            <ButtonBlue
              variant="outlined"
              size="small"
              onClick={handleOnClickMixedPallet}
              style={{ display: 'none' }}
            >
              Add Mixed Pallet
            </ButtonBlue>
            <ButtonBlue
              variant="outlined"
              size="small"
              onClick={() => {
                if (formValues.isReturnOrder === true) {
                  handleAddLineItemForReturn();
                } else {
                  onOpenReceiptLineItemModal();
                  setModalTitle('Add');
                  setSkuValue(null);
                  setLotNoValue(null);
                  setFormLineItemErrors({});
                  setFormLineItemValues({});
                  setUomValue(null);
                  setOptionsUOM([]);
                  setReasonOnStatusTypeValue(null);
                  setOptionStatusOnArrivalValue({
                    value: 1,
                    label: 'Available',
                  });
                }
              }}
            >
              Add Line Item
            </ButtonBlue>
          </MUIGrid>
          <MUIGrid item xs={12}>
            <DatagridPremium
              autoHeight
              rows={lineItemRows}
              density="compact"
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
              hideFooter
              columns={lineItemsColumns}
              editRowsModel={editRowsModel}
              onEditRowsModelChange={handleEditSingleRowModelChange}
              getRowId={(row) => row.lineId}
              disableSelectionOnClick
            />
          </MUIGrid>
        </MUIGrid>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Box sx={{ width: '33%', padding: 3, paddingBottom: 0 }}>
            <Box
              sx={{
                display: 'flex',
                paddingBottom: 1,
                justifyContent: 'end',
              }}
            >
              <Typography variant="body2">
                <b>Summary</b>
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="caption" fontWeight="bold">
                SKU
              </Typography>
              <Typography variant="caption" fontWeight="bold">
                Total
              </Typography>
            </Box>
            {itemSummary.items.map((item) => (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="caption">{item.name}</Typography>
                <Typography variant="caption">
                  {item.qty}{' '}
                  {item?.qtyExpected && item?.qtyExpected > 0
                    ? `of ${item?.qtyExpected}`
                    : ''}{' '}
                </Typography>
              </Box>
            ))}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: 1,
              }}
            >
              <Typography variant="body2">
                <b>Grand Total:</b>
              </Typography>
              <Typography variant="body2" fontWeight="bold">
                {itemSummary.total}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <MixedPalletModal
        title={modalMixedPallet.title}
        isOpen={modalMixedPallet.open}
        onConfirm={hanldeModalMixedPalletConfirm}
        onClose={hanldeModalMixedPalletClose}
      />
    </Card>
  );
};

const BillingChargeSection = () => {
  const {
    billingAdhocChargesData,
    errorBillingAdhocItemValues,
    handleChangeBillingChargeSection,
    handleRemoveBillingChargeSection,
    pageViewerReceipt,
    handleOnClickEditBillingCharge,
    handleOnClickCancelBillingCharge,
    handleOnClickSaveBillingCharge,
    isBillingChargeSectionEditable,
    isBillingChargeSectionBusy,
  } = useSubpageReceiptsContext();

  const { handleUserPermissionAllow } = useContext(AuthContext);

  return (
    <Card
      sx={{
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ marginBottom: 4 }}
        >
          <MUIGrid item xs={6}>
            <Typography variant="h6" fontWeight="bold">
              Billing Charge
            </Typography>
          </MUIGrid>
          {pageViewerReceipt.isViewOrEdit() && (
            <MUIGrid
              item
              xs={6}
              sx={{ display: 'flex', justifyContent: 'flex-end', columnGap: 1 }}
            >
              {isBillingChargeSectionEditable === false &&
                isBillingChargeSectionBusy === false && (
                  <ButtonBlue
                    variant="outlined"
                    size="small"
                    onClick={handleOnClickEditBillingCharge}
                  >
                    Edit
                  </ButtonBlue>
                )}
              {isBillingChargeSectionEditable &&
                isBillingChargeSectionBusy === false && (
                  <>
                    <ButtonBlue
                      variant="outlined"
                      size="small"
                      onClick={handleOnClickCancelBillingCharge}
                    >
                      Cancel
                    </ButtonBlue>
                    <ButtonBlue
                      variant="outlined"
                      size="small"
                      onClick={handleOnClickSaveBillingCharge}
                    >
                      Save
                    </ButtonBlue>
                  </>
                )}
              {isBillingChargeSectionBusy && (
                <>
                  <ButtonBlue disabled variant="outlined" size="small">
                    Cancel
                  </ButtonBlue>
                  <ButtonBlue disabled variant="contained" size="small">
                    <CircularProgress
                      sx={{
                        width: '25px !important',
                        height: '25px !important',
                      }}
                    />
                    <Box sx={{ paddingLeft: '5px' }}>Save</Box>
                  </ButtonBlue>
                </>
              )}
            </MUIGrid>
          )}
        </MUIGrid>

        {billingAdhocChargesData &&
          billingAdhocChargesData.map((model, index) => (
            <>
              <MUIGrid
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                container
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ flex: 1 }}
              >
                <MUIGrid item xs={0.4} xl={0.2} alignSelf="center">
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    align="right"
                  >
                    {' '}
                  </Typography>
                </MUIGrid>
                <MUIGrid item xs={3} xl={2} alignSelf="center">
                  <Typography variant="subtitle1" fontWeight="bold">
                    {model.chargeName}
                  </Typography>
                </MUIGrid>
                {handleUserPermissionAllow(
                  UserPermissionEnum.Client_Billing_View_Charge_Rate,
                ) && (
                  <MUIGrid item xs={2} xl={1.5} alignSelf="center">
                    <TextField
                      id="outlined"
                      name="rate"
                      value={model.rate}
                      onChange={(e) => {
                        handleChangeBillingChargeSection(e, index);
                      }}
                      label="Rate"
                      size="small"
                      sx={{ width: '100%' }}
                      error={errorBillingAdhocItemValues[index]?.rate}
                      helperText={errorBillingAdhocItemValues[index]?.rate}
                      {...((isBillingChargeSectionEditable === false ||
                        isBillingChargeSectionBusy) && {
                        disabled: true,
                      })}
                    />
                  </MUIGrid>
                )}

                <MUIGrid item xs={1.5} xl={1} alignSelf="center">
                  <TextField
                    id="outlined"
                    name="qty"
                    value={model.qty}
                    onChange={(e) => {
                      handleChangeBillingChargeSection(e, index);
                    }}
                    label="Qty"
                    size="small"
                    sx={{ width: '100%' }}
                    error={errorBillingAdhocItemValues[index]?.qty}
                    helperText={errorBillingAdhocItemValues[index]?.qty}
                    {...((isBillingChargeSectionEditable === false ||
                      isBillingChargeSectionBusy) && {
                      disabled: true,
                    })}
                  />
                </MUIGrid>
                {handleUserPermissionAllow(
                  UserPermissionEnum.Client_Billing_View_Charge_Rate,
                ) && (
                  <>
                    <MUIGrid item xs={1} xl={0.5} alignSelf="center">
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        align="right"
                      >
                        Total:
                      </Typography>
                    </MUIGrid>
                    <MUIGrid item xs={1} alignSelf="center">
                      <Typography variant="subtitle1">{model.total}</Typography>
                    </MUIGrid>
                  </>
                )}
                <MUIGrid item xs={2} xl={1.5}>
                  <TextField
                    rows={1}
                    multiline
                    id="outlined-multiline-flexible"
                    name="notes"
                    value={model.notes}
                    onChange={(e) => {
                      handleChangeBillingChargeSection(e, index);
                    }}
                    label="Notes"
                    size="small"
                    {...((isBillingChargeSectionEditable === false ||
                      isBillingChargeSectionBusy) && {
                      disabled: true,
                    })}
                  />
                </MUIGrid>
                <MUIGrid item xs={1} xl={0.5} alignSelf="center">
                  <IconButton
                    sx={{
                      background: '#F8F9FA',
                      borderRadius: 1,
                      width: 30,
                      height: 30,
                    }}
                    onClick={() => handleRemoveBillingChargeSection(model)}
                    {...((isBillingChargeSectionEditable === false ||
                      isBillingChargeSectionBusy) && {
                      disabled: true,
                    })}
                    color="primary"
                    aria-label="Minus Quantity"
                    component="label"
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </MUIGrid>
              </MUIGrid>
              <Divider sx={{ mt: 1, mb: 1 }} />
            </>
          ))}
      </Box>
    </Card>
  );
};

const ReceiptsSubpageContainer = React.memo(() => {
  const {
    isSaving,
    formValues,
    pageViewerReceipt,
    billingAdhocChargesData,
    handleOnClickCreateReceipt,
    dataBillingChargeSectionSelected,
  } = useSubpageReceiptsContext();

  return (
    <Box
      autoComplete="off"
      component="form"
      onSubmit={handleOnClickCreateReceipt}
      noValidate
    >
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={12}>
          <ReceiptProgressBar />
        </MUIGrid>
        <MUIGrid item xs={6}>
          <Receipt />
        </MUIGrid>
        <MUIGrid item xs={formValues?.isReturnOrder === true ? 3 : 6}>
          <Transportation />
        </MUIGrid>
        {formValues?.isReturnOrder === true && (
          <MUIGrid item xs={3}>
            <OrderSection />
          </MUIGrid>
        )}
      </MUIGrid>
      <MUIGrid item xs={12}>
        <LineItems />
      </MUIGrid>
      {billingAdhocChargesData.length > 0 && <BillingChargeSection />}
      {!pageViewerReceipt.isView() && !isSaving && (
        <ButtonBlue
          type="submit"
          variant="contained"
          size="large"
          sx={{ padding: 1 }}
        >
          Save
        </ButtonBlue>
      )}

      {!pageViewerReceipt.isView() && isSaving && (
        <ButtonBlue
          disabled
          type="submit"
          variant="contained"
          size="large"
          sx={{ padding: 1 }}
        >
          <CircularProgress
            sx={{ width: '25px !important', height: '25px !important' }}
          />
          <Box sx={{ paddingLeft: '5px' }}>Save</Box>
        </ButtonBlue>
      )}
      <ConfirmationDialog
        billingChargeSectionData={dataBillingChargeSectionSelected}
        deleteBillingChargeSectionReceipt
      />
    </Box>
  );
});

function ReceiptsTempSubpage() {
  const {
    isEditReceiptLineItemModalOpen,
    isEditActualQtyModalOpen,
    printTypeData,
    skuPrintModelData,
    setSKUPrintModelData,
    otherUOMPrintModelData,
    setOtherUOMPrintModelData,
  } = useContext(GlobalContext);
  const query = useQuery();

  const skuPrintRef = useRef(null);
  const buttonSKUPrintRef = useRef(null);

  const otherUOMPrintRef = useRef(null);
  const buttonOtherUOMPrintRef = useRef(null);

  const printQtyModalCallback = (qty: number) => {
    if (printTypeData === PrintTypeEnum.SKU) {
      setSKUPrintModelData((prevState: SKUPrintModel) => ({
        ...prevState,
        qty,
        buttonPrint: buttonSKUPrintRef,
      }));
    } else if (printTypeData === PrintTypeEnum.OtherUOM) {
      setOtherUOMPrintModelData((prevState: OtherUOMPrintModel) => ({
        ...prevState,
        qty,
        buttonPrint: buttonOtherUOMPrintRef,
      }));
    }
  };

  const skuPrintClickTriggerHandler = useReactToPrint({
    content: () => skuPrintRef.current,
  });

  const otherUOMPrintClickTriggerHandler = useReactToPrint({
    content: () => otherUOMPrintRef.current,
  });

  return (
    <MainContainer>
      <SubpageReceiptsContextProvider>
        <Header />
        <ContentContainer>
          {isEditReceiptLineItemModalOpen && <EditReceiptModal />}
          {isEditActualQtyModalOpen && <ActualQuantityModal />}
          <QtyAndExpectedCheckModal />
          <PrintQtyModal onCallback={printQtyModalCallback} />
          <div style={{ display: 'none' }}>
            <SKUPrint ref={skuPrintRef} data={skuPrintModelData} />
            <button
              type="button"
              ref={buttonSKUPrintRef}
              onClick={skuPrintClickTriggerHandler}
            >
              This one is trigger on print
            </button>

            <OtherUOMPrint
              ref={otherUOMPrintRef}
              data={otherUOMPrintModelData}
            />
            <button
              type="button"
              ref={buttonOtherUOMPrintRef}
              onClick={otherUOMPrintClickTriggerHandler}
            >
              This one is trigger on print
            </button>
          </div>

          <ConfirmReceiptModal />
          <EditConfirmedReceiptmodal />
          <DocumentsModal />
          <ReceiptsSubpageContainer />
          <ModalReturnLineItem />
        </ContentContainer>
      </SubpageReceiptsContextProvider>
    </MainContainer>
  );
}

export default React.memo(ReceiptsTempSubpage);
