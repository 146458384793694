import React, { useContext, useEffect, useState } from 'react';

import { snackActions } from 'config/snackbar.js';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import { useSubpageReceiptsContext } from 'pages/receiving/receipts/context';
import { ItemUnitOfMeasureType } from 'pages/receiving/receipts/subpage';
import { UOMType } from 'pages/sales/salesorder/context';
import {
  MUIContainer,
  MUIContent,
  StatusOnArrivalType,
} from 'pages/shared/editreceiptmodal';
import {
  getItemUnitOfMeasureWithPalletDDL,
  getItemDetail,
  GetReasonTransferData,
} from 'services/api/item/item.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  TextField,
  Autocomplete,
  Grid as MUIGrid,
  createFilterOptions,
  Button,
} from '@mui/material';

export const ModalReturnQuantityLineItem = () => {
  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);

  const {
    isReturnQuantityLineItemModalOpen,
    setIsReturnQuantityLineItemModalOpen,
  } = useContext(GlobalContext);

  const {
    orderItemRows,
    returnOrderItemRows,
    returnQuantityLineItem,
    optionLocationCrossDock,
    reasonOnStatusTypeValue,
    optionStatusOnArrivalType,
    optionsReasonOnStatusType,
    optionStatusOnArrivalValue,
    optionLocationCrossDockValue,
    setOptionStatusOnArrivalValue,
    setOptionLocationCrossDockValue,
    setReturnOrderItemRows,
    setReturnQuantityLineItem,
    setReasonOnStatusTypeValue,
    setOptionsReasonOnStatusType,
    setFormValues,
    setLineItemRows,
    lineItemRows,
  } = useSubpageReceiptsContext();

  const [optionsUOM, setOptionsUOM] = useState<ItemUnitOfMeasureType[]>([]);
  const [uomValue, setUomValue] = useState<ItemUnitOfMeasureType>(null);

  const [quantityError, setQuantityError] = useState<any>({});

  const onLoadGetItemUnitOfMeasureDDLOnChange = async (itemId) => {
    try {
      return await getItemUnitOfMeasureWithPalletDDL(itemId);
    } catch (err) {
      return err;
    }
  };
  const onLoadReasonsDetails = async () => {
    try {
      const transferReasonsFromApi = await GetReasonTransferData();

      return transferReasonsFromApi;
    } catch (err) {
      return err;
    }
  };

  const validateOnlick = () => {
    const tempValue = orderItemRows.find(
      (o) => o?.lineId === returnQuantityLineItem?.lineId,
    );

    const temp: any = {};
    if (returnQuantityLineItem?.uomType === UOMType.Primary) {
      if (
        returnQuantityLineItem.qty === null ||
        returnQuantityLineItem.qty === ''
      ) {
        temp.totalCases = 'This field is required';
      } else if (returnQuantityLineItem.qty < 1) {
        temp.totalCases = 'Value must be greater than 0';
      } else if (returnQuantityLineItem.qty > tempValue.qty) {
        temp.totalCases = 'Value must not be greater than original order';
      }
    } else {
      if (
        returnQuantityLineItem.totalCases === null ||
        returnQuantityLineItem.totalCases === '' ||
        returnQuantityLineItem.totalCases === undefined
      ) {
        temp.totalCases = 'This field is required';
      }
      if (returnQuantityLineItem.totalCases < 1) {
        temp.totalCases = 'Value must be greater than 0';
      }
      if (
        Number(
          returnQuantityLineItem.totalCases *
            returnQuantityLineItem.otherUOMCount,
        ) > Number(tempValue.qty)
      ) {
        temp.totalCases = 'Value must not be greater than original order';
      }
    }

    if (returnQuantityLineItem.crossDockBinId === undefined) {
      returnQuantityLineItem.crossDockBinId = '';
    }
    if (returnQuantityLineItem.crossDockBinId === null) {
      returnQuantityLineItem.crossDockBinId = '';
    }

    if (
      optionStatusOnArrivalValue?.value === 5 &&
      returnQuantityLineItem.crossDockBinId === ''
    ) {
      temp.locationCrossDock = 'This field is required';
    } else {
      temp.locationCrossDock = '';
    }

    if (
      optionStatusOnArrivalValue?.value > 1 &&
      optionStatusOnArrivalValue?.value < 5
    ) {
      temp.reasonStatus = returnQuantityLineItem.reasonStatus
        ? ''
        : 'This field is required';
    } else {
      temp.reasonStatus = '';
    }

    setQuantityError({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  const handleUpdateReturnRowsToReceiptLineItem = () => {
    const main = [...lineItemRows];
    const datas = [...lineItemRows];
    let toAdds = [...lineItemRows];

    const toAdd = {
      lineId: returnQuantityLineItem.lineId,
      itemId: returnQuantityLineItem.itemId,
      lotId: returnQuantityLineItem?.lotId ? returnQuantityLineItem?.lotId : '',
      lotNo: returnQuantityLineItem?.lotNo ? returnQuantityLineItem?.lotNo : '',
      lotCreationDate: returnQuantityLineItem?.creationDate
        ? returnQuantityLineItem?.creationDate
        : null,
      lotExpirationDate: returnQuantityLineItem?.lot
        ? returnQuantityLineItem?.expirationDate
        : null,
      sku: returnQuantityLineItem?.sku,
      upc: returnQuantityLineItem?.upc,
      qty: returnQuantityLineItem?.qty,
      oldQty: '',
      actual: '',
      description: returnQuantityLineItem?.description,
      licensePlateType: 1,
      statusOnArrival: returnQuantityLineItem?.statusOnArrival,
      reasonStatus: returnQuantityLineItem?.reasonStatus,
      eaCase: returnQuantityLineItem?.eaCase,
      totalCases: returnQuantityLineItem?.totalCases,
      eachesPerCase: returnQuantityLineItem?.otherUOMCount,
      isSerialNo: returnQuantityLineItem?.isSerialNo,
      serialNo: returnQuantityLineItem?.serialNo,
      primaryUOM: returnQuantityLineItem?.primaryUOM,
      primaryUOMStr: returnQuantityLineItem?.primaryUOMStr,
      primaryUOMAbbr: returnQuantityLineItem?.primaryUOMAbbr,
      isOtherUOM: returnQuantityLineItem?.isOtherUOM,
      itemUnitOfMeasureId: returnQuantityLineItem?.itemUnitOfMeasureId,
      otherUOMStr: returnQuantityLineItem?.otherUOMStr,
      otherUOMAbbr: returnQuantityLineItem?.otherUOMAbbr,
      otherUOMCount: returnQuantityLineItem?.otherUOMCount,
      otherUOMUpc: returnQuantityLineItem?.otherUOMUpc,
      primaryLength: uomValue?.primaryLength,
      primaryWidth: uomValue?.primaryWidth,
      primaryHeight: uomValue?.primaryHeight,
      primaryWeight: uomValue?.primaryWeight,
      weight: returnQuantityLineItem?.weight,
      notes: returnQuantityLineItem?.notes,
    };

    const getData = datas.find((o) => o.lineId === toAdd.lineId);

    if (getData === undefined) {
      toAdds = [...lineItemRows, toAdd];
    } else {
      const index = main.findIndex((o) => o.lineId === toAdd.lineId);
      main.splice(index, 1);
      toAdds = [...main, toAdd];
    }
    setLineItemRows(toAdds);
    setFormValues((prevState) => ({
      ...prevState,
      lineItems: toAdds,
    }));
  };

  const handleUpdateReturnRows = (event) => {
    event.preventDefault();

    if (!validateOnlick()) {
      return;
    }

    const main = [...returnOrderItemRows];
    const datas = [...returnOrderItemRows];

    const getData = datas.find(
      (o) => o.lineId === returnQuantityLineItem.lineId,
    );

    if (getData === undefined) {
      setReturnOrderItemRows([...returnOrderItemRows, returnQuantityLineItem]);
    } else {
      const setData = main.findIndex((o) => o.lineId === getData.lineId);
      datas.splice(setData, 1);
      setReturnOrderItemRows([...datas, returnQuantityLineItem]);
    }

    handleUpdateReturnRowsToReceiptLineItem();
    setReasonOnStatusTypeValue(null);
    setOptionLocationCrossDockValue(null);
    setIsReturnQuantityLineItemModalOpen(false);
  };

  useEffect(() => {
    if (isReturnQuantityLineItemModalOpen) {
      getItemDetail(
        currentUser.Claim_CustomerId,
        currentLocationAndFacility.customerFacilityId,
        returnQuantityLineItem.itemId,
      )
        .then(() => {
          onLoadGetItemUnitOfMeasureDDLOnChange(
            returnQuantityLineItem.itemId,
          ).then((x) => {
            setOptionsUOM([]);
            x.map((uom) =>
              setOptionsUOM((prev) => [
                ...prev,
                {
                  value: uom.value,
                  label: uom.text,
                  uomAbbr: uom.uomAbbr,
                  isPrimaryUOM: uom.isPrimaryUOM,
                  qty: uom.qty,
                  primaryUOM: uom.primaryUOM,
                  primaryUOMStr: uom.primaryUOMStr,
                  primaryUOMAbbr: uom.primaryUOMAbbr,
                  uomType: uom.uomType,
                  primaryWeight: uom?.primaryWeight,
                  primaryHeight: uom?.primaryHeight,
                  primaryWidth: uom?.primaryWidth,
                  primaryLength: uom?.primaryLength,
                },
              ]),
            );
          });
        })
        // eslint-disable-next-line no-console
        .catch((e) => {
          // console.log(e)
        });

      onLoadReasonsDetails()
        .then((x) => {
          const { reasonDDL } = x;
          setOptionsReasonOnStatusType(
            reasonDDL?.map((dropdown) => ({
              value: dropdown.value,
              label: dropdown.text,
            })),
          );
        })
        .catch((err) => snackActions.error(err));

      setOptionStatusOnArrivalValue({
        value: 1,
        label: 'Available',
      });
      setReturnQuantityLineItem((prevState) => ({
        ...prevState,
        statusOnArrival: 1, // available
      }));
    }
  }, [isReturnQuantityLineItemModalOpen]);

  useEffect(() => {
    if (returnQuantityLineItem.uomType === UOMType.Primary) {
      // set primaryUom Id to 0 at the backend

      const resultUom = optionsUOM.find((u) => u.value === 0);
      if (resultUom !== undefined) {
        setUomValue((prev) => ({
          ...prev,
          value: resultUom.value,
          label: resultUom.label,
          uomAbbr: resultUom.uomAbbr,
          isPrimaryUOM: resultUom.isPrimaryUOM,
          qty: resultUom.qty,
          primaryUOM: resultUom.primaryUOM,
          primaryUOMStr: resultUom.primaryUOMStr,
          primaryUOMAbbr: resultUom.primaryUOMAbbr,
          uomType: resultUom.uomType,
          primaryWeight: resultUom?.primaryWeight,
          primaryHeight: resultUom?.primaryHeight,
          primaryWidth: resultUom?.primaryWidth,
          primaryLength: resultUom?.primaryLength,
        }));
      }
      // other uom
    } else if (returnQuantityLineItem.uomType === UOMType.Other) {
      const resultUom = optionsUOM.find(
        (u) => u.value === returnQuantityLineItem.itemUnitOfMeasureId,
      );
      if (resultUom !== undefined) {
        setUomValue((prev) => ({
          ...prev,
          value: resultUom.value,
          label: resultUom.label,
          uomAbbr: resultUom.uomAbbr,
          isPrimaryUOM: resultUom.isPrimaryUOM,
          qty: resultUom.qty,
          primaryUOM: resultUom.primaryUOM,
          primaryUOMStr: resultUom.primaryUOMStr,
          primaryUOMAbbr: resultUom.primaryUOMAbbr,
          uomType: resultUom.uomType,
          primaryWeight: resultUom?.primaryWeight,
          primaryHeight: resultUom?.primaryHeight,
          primaryWidth: resultUom?.primaryWidth,
          primaryLength: resultUom?.primaryLength,
        }));
      }
    }
    // pallet
    else if (returnQuantityLineItem.uomType === UOMType.Pallet) {
      const resultUom = optionsUOM.find((u) => u.uomType === UOMType.Pallet);
      if (resultUom !== undefined) {
        setUomValue((prev) => ({
          ...prev,
          value: resultUom.value,
          label: resultUom.label,
          uomAbbr: resultUom.uomAbbr,
          isPrimaryUOM: resultUom.isPrimaryUOM,
          qty: resultUom.qty,
          primaryUOM: resultUom.primaryUOM,
          primaryUOMStr: resultUom.primaryUOMStr,
          primaryUOMAbbr: resultUom.primaryUOMAbbr,
          uomType: resultUom.uomType,
          primaryWeight: resultUom?.primaryWeight,
          primaryHeight: resultUom?.primaryHeight,
          primaryWidth: resultUom?.primaryWidth,
          primaryLength: resultUom?.primaryLength,
        }));
      }
    }
  }, [optionsUOM]);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
  });

  return (
    <Modal open={isReturnQuantityLineItemModalOpen}>
      <MUIContainer sx={{ width: '25% !important' }}>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Return Items
        </Typography>
        <MUIContent sx={{ padding: '8px 20px !important ' }}>
          <Box autoComplete="off" component="form" noValidate>
            <MUIGrid
              container
              direction="row"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={4}>
                <Autocomplete
                  disableClearable
                  onChange={(event: any, newValue: ItemUnitOfMeasureType) => {
                    if (newValue !== null) {
                      setUomValue(newValue);
                      if (newValue.isPrimaryUOM) {
                        setReturnQuantityLineItem((prevState) => ({
                          ...prevState,
                          primaryUOM: newValue.value,
                          primaryUOMAbbr: newValue.uomAbbr,
                          itemUnitOfMeasureId: null,
                          otherUOMStr: '',
                          otherUOMAbbr: '',
                          otherUOMCount: null,
                          uomType: newValue.uomType,
                          sellPrice: '',
                        }));
                      } else {
                        setReturnQuantityLineItem((prevState) => ({
                          ...prevState,
                          primaryUOM: newValue.primaryUOM,
                          primaryUOMAbbr: newValue.primaryUOMAbbr,
                          itemUnitOfMeasureId: newValue.value,
                          otherUOMStr: newValue.label,
                          otherUOMAbbr: newValue.uomAbbr,
                          otherUOMCount: newValue.qty,
                          uomType: newValue.uomType,
                          sellPrice: '',
                        }));
                      }
                    } else {
                      setUomValue(null);
                      setReturnQuantityLineItem((prevState) => ({
                        ...prevState,
                        primaryUOM: 0,
                        primaryUOMAbbr: '',
                        itemUnitOfMeasureId: null,
                        otherUOMStr: '',
                        otherUOMAbbr: '',
                        otherUOMCount: null,
                        uomType: 0,
                        quantity: '',
                        totalCases: null,
                        sellPrice: '',
                      }));
                    }
                    setQuantityError({});
                  }}
                  id="controllable-states"
                  options={optionsUOM}
                  getOptionLabel={(option: ItemUnitOfMeasureType) =>
                    option.label
                  }
                  value={uomValue}
                  disabled
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option: ItemUnitOfMeasureType) => (
                    <li
                      {...props}
                      key={option?.value}
                      data-uomabbr={option?.uomAbbr}
                      data-isprimaryuom={option?.isPrimaryUOM}
                      data-qty={option?.qty}
                      data-primaryuom={option?.primaryUOM}
                      data-primaryuomstr={option?.primaryUOMStr}
                      data-primaryuomabbr={option?.primaryUOMAbbr}
                      data-uomtype={option?.uomType}
                    >
                      {option?.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      required
                      id="outlined-required"
                      label="UOM"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={uomValue?.isPrimaryUOM === false ? 3 : 8}>
                <TextField
                  required
                  type="number"
                  label="Qty"
                  size="small"
                  value={
                    returnQuantityLineItem?.uomType === UOMType.Primary
                      ? returnQuantityLineItem?.qty
                      : returnQuantityLineItem?.totalCases || ''
                  }
                  onChange={(e) => {
                    if (returnQuantityLineItem?.uomType === UOMType.Primary) {
                      setReturnQuantityLineItem((prev) => ({
                        ...prev,
                        qty: e.target.value,
                      }));
                    } else {
                      setReturnQuantityLineItem((prev) => ({
                        ...prev,
                        totalCases: e.target.value,
                      }));
                    }
                  }}
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                    marginBottom: '16px',
                  }}
                  {...(quantityError.qty && {
                    error: true,
                    helperText: quantityError.qty,
                  })}
                  {...(quantityError.totalCases && {
                    error: true,
                    helperText: quantityError.totalCases,
                  })}
                />
              </MUIGrid>
              {uomValue?.isPrimaryUOM === false && (
                <MUIGrid item xs={5}>
                  <TextField
                    inputProps={{
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    id="outlined"
                    label="Primary Units per Package"
                    value={uomValue.qty}
                    size="small"
                    disabled
                    sx={{ width: '100%' }}
                  />
                </MUIGrid>
              )}
              <MUIGrid item xs={6}>
                <Autocomplete
                  disableClearable
                  filterOptions={filterOptions}
                  onChange={(event: any, newValue: StatusOnArrivalType) => {
                    if (newValue !== null) {
                      setOptionStatusOnArrivalValue(newValue);
                      setOptionLocationCrossDockValue(null);
                      setReturnQuantityLineItem((prevState) => ({
                        ...prevState,
                        statusOnArrival: newValue.value,
                        crossDockBinId: '',
                        reasonStatus: '',
                      }));
                    } else {
                      setOptionStatusOnArrivalValue(null);
                    }
                  }}
                  id="controllable-states"
                  options={optionStatusOnArrivalType}
                  value={optionStatusOnArrivalValue}
                  getOptionLabel={(option: StatusOnArrivalType) => option.label}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                      }}
                      id="outlined"
                      label="Status on Arrival"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </MUIGrid>

              {optionStatusOnArrivalValue?.value > 1 &&
                optionStatusOnArrivalValue?.value < 5 && (
                  <MUIGrid item xs={6}>
                    <Autocomplete
                      onChange={(event: any, newValue: any) => {
                        if (newValue !== null) {
                          setReasonOnStatusTypeValue(newValue);
                          setReturnQuantityLineItem((prevState) => ({
                            ...prevState,
                            reasonStatus: newValue.value,
                          }));
                        } else {
                          setReasonOnStatusTypeValue(null);
                          setReturnQuantityLineItem((prevState) => ({
                            ...prevState,
                            reasonStatus: '',
                          }));
                        }
                      }}
                      id="controllable-states"
                      options={optionsReasonOnStatusType}
                      getOptionLabel={(option: any) => option.label}
                      value={reasonOnStatusTypeValue}
                      isOptionEqualToValue={(option, selected) =>
                        option.value === selected.value
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                          id="outlined-required"
                          label="Reason"
                          size="small"
                          sx={{ width: '100%' }}
                          {...(quantityError.reasonStatus && {
                            error: true,
                            helperText: quantityError.reasonStatus,
                          })}
                          required
                        />
                      )}
                    />
                  </MUIGrid>
                )}
              {optionStatusOnArrivalValue?.value === 5 && (
                <MUIGrid item xs={6}>
                  <Autocomplete
                    sx={{ width: '100%' }}
                    options={optionLocationCrossDock}
                    value={optionLocationCrossDockValue}
                    size="small"
                    onChange={(event: any, newValue) => {
                      if (newValue === null || newValue === undefined) {
                        setOptionLocationCrossDockValue(null);
                        setReturnQuantityLineItem((prevState) => ({
                          ...prevState,
                          crossDockBinId: '',
                        }));
                      } else {
                        setOptionLocationCrossDockValue(newValue);
                        setReturnQuantityLineItem((prevState) => ({
                          ...prevState,
                          crossDockBinId: newValue.value,
                        }));
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          '& .MuiInputBase-root': {
                            background: 'white',
                          },
                        }}
                        {...params}
                        label="Cross Dock Location*"
                        {...(quantityError.locationCrossDock && {
                          error: true,
                          helperText: quantityError.locationCrossDock,
                        })}
                        required
                      />
                    )}
                  />
                </MUIGrid>
              )}
            </MUIGrid>
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={8}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '100%' }}
                onClick={() => {
                  setUomValue(null);
                  setIsReturnQuantityLineItemModalOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="small"
                sx={{ padding: 1, width: '100%' }}
                onClick={(e) => handleUpdateReturnRows(e)}
              >
                Save
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
};
