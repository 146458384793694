/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router';

import { ItemResponseModel } from 'common/models/serialnumbers';
import {
  ItemRequestModel,
  ValidateSerialNumberBySKURequestModel,
  ValidateSerialNumberBySKURequestTypeEnum,
} from 'common/models/serialnumbers/ValidateSerialNumberBySKU';
import { REPORT_URL } from 'config/constants';
import { snackActions } from 'config/snackbar.js';
import useQuery from 'helpers/useQuery/useQuery';
import moment from 'moment';
import { decimalValidationHelper } from 'pages/inventory/item/details';
import { ILineItemType } from 'pages/sales/salesorder/context';
import {
  getCustomerBinsByFacilityId,
  getBinLookupCrossDock,
} from 'services/api/customerfacilities/customerfacilities.api';
import {
  getCompletedOrdersForReturn,
  getOrderDetailsForReturn,
} from 'services/api/orders/orders.api';
import {
  updateReceiptLineItem,
  createReceiptLineItem,
  createReceiptLineItemV2,
  deleteReceiptLineItem,
  getReceiptLineItemToPrint,
  getReceiptLineItemToPrintAll,
  createReceipt,
  createReceiptV2,
  updateReceiptV2,
  getWarehouseCustomerLookup,
  getFacilitiesWithLocationByCustomerId,
  getSkuLookup,
  getReceiptDetail,
  getLicensePlate,
  getStatusArrival,
  confirmReceipt,
  getPurchaseOrderPackingList,
  getPackingListToReceiptByPackingListId,
  getReceipts,
  updateIsCheckLineItemAsync,
  ManageReceiptBillingChargeSectionAdhoc,
  GetReceiptBillingChargeSectionAdhoc,
} from 'services/api/receipts/receipts-new.api';
import { ValidateSerialNumberBySKU } from 'services/api/serialnumbers/serialnumbers.api';
import { getCustomerInboundSection } from 'services/api/uoms/uoms.api';
import { getBillingChargesDetailByModule } from 'services/api/warehousecustomer/warehouseCustomer.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { reportUrlVariable } from 'store/reporturl';

import {
  ItemUnitOfMeasureType,
  LotNoType,
  ModalTitleStatus,
  ReceiptLineItemImageType,
  ReceiptLineItemStatusOnArrivalEnum,
  ReceiptStatus,
} from '../subpage';

type AutocompleteBase = {
  label?: string;
  value?: number;
};

type LicensePlateType = AutocompleteBase;
type StatusOnArrivalType = AutocompleteBase;
type ReasonOnStatusType = AutocompleteBase;

type LocationCrossDockType = AutocompleteBase;

export const itemPalletTypeOption: AutocompleteBase[] = [
  { value: 1, label: 'High' },
  { value: 2, label: 'Medium' },
  { value: 3, label: 'Standard' },
  { value: 4, label: 'Extra Wide' },
  { value: 5, label: 'Long' },
];

enum FormQueryStringStatus {
  // eslint-disable-next-line no-unused-vars
  View = 'view',
  // eslint-disable-next-line no-unused-vars
  New = 'new',
  // eslint-disable-next-line no-unused-vars
  Edit = 'edit',
}
type QueryStringType = {
  form: string;
  id: number;
  lineItem?: number;
  isReturn?: boolean;
  orderNo?: string;
};
type PageViewerType = {
  isNew: () => boolean;
  isView?: () => boolean;
  isEdit?: () => boolean;
  isViewOrEdit: () => boolean;
  isReturn: () => boolean;
  orderNo?: string;
};

interface IReceiptDetail {
  customerId?: string;
  warehouseCustomerId?: string;
  customer?: string;
  customerFacilityId?: string;
  warehouse?: string;
  referenceNo?: string;
  purchaseOrder?: string;
  packingListId?: string;
  returnOrderId?: string;
  expectedDate?: string;
  arrivalDate?: string;
  container?: string;
  location?: string;
  carrier?: string;
  trailerNo?: string;
  sealNo?: string;
  billOfLadingNumber?: string;
  trackingNo?: string;
  notes?: string;
  isNonBillable?: boolean;
  isReturnOrder?: boolean;
  isPutAwayCompleted?: boolean;
  statusProgressValue?: string;
  lineItems: any | [];
  billingChargeSection: any | [];
  isShowLotColumns?: boolean;
  isShowSerialNoColumn?: boolean;
  pallets?: [];
  totalQty?: string;
  totalQtyExpected?: string;
  status: number;
  packingListDropDownName: string;
  purchaseOrderNo: string;
}

interface IReceipt {
  queryStringItemReceipts: QueryStringType;
  setQueryStringItemReceipts: React.Dispatch<
    React.SetStateAction<QueryStringType>
  >;
  receiptStatus: any;
  options: any;
  optionsSku: any;
  setOptionsSku: any;
  optionFacility: any;
  optionLocation: any;
  optionLotNo: any;
  setOptionLotNo: any;
  lotNoValue: any;
  setLotNoValue: any;
  optionSearchSku: any;
  setOptionSearchSku: any;
  optionLicensePlateType: any;
  optionLicensePlateValue: any;
  setOptionLicensePlateValue: any;
  optionStatusOnArrivalType: any;
  optionStatusOnArrivalValue: any;
  setOptionStatusOnArrivalValue: any;
  customerValue: any;
  setCustomerValue: any;
  skuValue: any;
  setSkuValue: any;
  formValues: any;
  setFormValues: any;
  formLineItemValues: any;
  setFormLineItemValues: any;
  lineItemRows: any;
  singleLineItemRow: any;
  setSingleLineItemRow: any;
  setLineItemRows: any;
  formErrors: any;
  setFormErrors: any;
  formLineItemErrors: any;
  setFormLineItemErrors: any;
  modalTitle: any;
  setModalTitle: any;
  validate: any;
  printAllOfLPItem: any;
  setPrintAllOfLPItem: any;
  handleOnClickPrintAllLP: any;
  handleOnClickConfirmReceipt: any;
  handleOnClickCreateReceipt: any;
  isSaving: boolean;
  handleOnClickAddLineItem: any;
  handleOnClickDeleteLineItem: any;
  handleOnChange: any;
  handleOnClickEditConfirmedReceipt: any;
  onLoadPurchaseOrderNumberByWarehouseCustomer: any;
  loadBillingChargesDetailByModule: any;
  purchaseOrderOptions: any;
  handleOnBlurPurchaseOrderIdOrNumber: any;
  setPurchaseOrderIdOrNumber: any;
  purchaseOrderIdOrNumber: any;
  setFacilityValue: any;
  facilityValue: any;
  onLoadGetLocation: any;
  setLocationValue: any;
  locationValue: any;
  billingAdhocChargesData: any;
  setBillingAdhocChargesData: any;
  errorBillingAdhocItemValues: any;
  handleChangeBillingChargeSection: any;
  handleRemoveBillingChargeSection: any;
  viewLoadData: any;
  dataBillingChargeSectionSelected: any;
  isBillingChargeDeleteHasId: any;
  setIsBillingChargeDeleteHasId: any;
  optionLocationCrossDock: any;
  onLoadGetLocationCrossDock: any;
  optionLocationCrossDockValue: any;
  setOptionLocationCrossDockValue: any;
  onLoadPurchaseOrderDetailById: (id: any) => Promise<unknown>;
  setPoIdLinkedLineitemValue: React.Dispatch<React.SetStateAction<number>>;
  poIdLinkedLineitemValue: any;
  setOptionsUOM: React.Dispatch<React.SetStateAction<ItemUnitOfMeasureType[]>>;
  optionsUOM: ItemUnitOfMeasureType[];
  setUomValue: React.Dispatch<React.SetStateAction<ItemUnitOfMeasureType>>;
  uomValue: ItemUnitOfMeasureType;
  pageViewerReceipt: PageViewerType;
  setOptionStatusOnArrivalType: React.Dispatch<
    React.SetStateAction<AutocompleteBase[]>
  >;
  setOptionsReasonOnStatusType: React.Dispatch<
    React.SetStateAction<AutocompleteBase[]>
  >;
  optionsReasonOnStatusType: AutocompleteBase[];
  setReasonOnStatusTypeValue: React.Dispatch<
    React.SetStateAction<AutocompleteBase>
  >;
  reasonOnStatusTypeValue: AutocompleteBase;
  setNumberToPrint: React.Dispatch<any>;
  numberToPrint: any;
  setItemSummary: React.Dispatch<
    React.SetStateAction<{
      items: any[];
      total: number;
    }>
  >;
  itemSummary: {
    items: any[];
    total: number;
  };
  setReceipts: React.Dispatch<React.SetStateAction<any[]>>;
  receipts: any[];
  setFilteredReceipts: React.Dispatch<React.SetStateAction<any[]>>;
  filteredReceipts: any[];
  onLoadReceiptsData: (searchParamValue?: any) => Promise<unknown>;
  setSelectedFilter: React.Dispatch<React.SetStateAction<string>>;
  selectedFilter: string;
  setReceiptIdToDelete: React.Dispatch<any>;
  receiptIdToDelete: any;
  inboundData: any;
  handleUpdateCheckLineItem: (
    receiptLineItemId: any,
    value: any,
  ) => Promise<void>;
  onClickFinalReceiptSummary: (
    warehouseCustomerId: any,
    receiptId: any,
  ) => void;
  setOptionOrdersForReturn: React.Dispatch<React.SetStateAction<any[]>>;
  optionOrdersForReturn: any[];
  setOrderReturnValue: React.Dispatch<any>;
  orderReturnValue: any;
  setOrderDetails: React.Dispatch<any>;
  orderDetails: any;
  onLoadOrderDetailsForReturn: (returnOrderId: any) => Promise<void>;
  setReturnOrderItemRows: React.Dispatch<
    React.SetStateAction<[] | ILineItemType[]>
  >;
  returnOrderItemRows: [] | ILineItemType[];
  setReturnQuantityLineItem: React.Dispatch<any>;
  returnQuantityLineItem: any;
  setOrderItemRows: React.Dispatch<React.SetStateAction<any[]>>;
  orderItemRows: any[];

  // billing section
  isBillingChargeSectionEditable: boolean;
  isBillingChargeSectionBusy: boolean;
  handleOnClickEditBillingCharge: any;
  handleOnClickCancelBillingCharge: any;
  handleOnClickSaveBillingCharge: any;
}

type ReceiptProviderProps = {
  children: React.ReactNode;
};

const SubpageReceiptsContext = createContext<IReceipt>({} as IReceipt);
export const useSubpageReceiptsContext = () =>
  useContext(SubpageReceiptsContext);
export const SubpageReceiptsContextProvider = ({
  children,
}: ReceiptProviderProps) => {
  const navigate = useNavigate();
  const query = useQuery();
  const dt = new Date().toLocaleDateString();

  const {
    onOpenConfirmDeleteDialog,
    onCloseConfirmReceiptModal,
    onOpenConfirmLinkedPOModal,
    onCloseReceiptLineItemModal,
    onOpenPrintFormTemplateModal,
    setIdsToPrintLpnFormTemplate,
    onCloseEditConfirmedReceiptModal,
  } = useContext(GlobalContext);

  const initialStateOfQueryParams: QueryStringType = {
    form: query.get('form'),
    id: Number(query.get('id')),
    lineItem: Number(query.get('lineItem')),
    isReturn: query.get('isReturn') === 'true',
    orderNo: query.get('orderNo'),
  };

  const [queryStringItemReceipts, setQueryStringItemReceipts] = useState(
    initialStateOfQueryParams,
  );

  const pageViewerReceipt: PageViewerType = {
    isNew: () => queryStringItemReceipts.form === FormQueryStringStatus.New,
    isView: () =>
      queryStringItemReceipts.form === FormQueryStringStatus.View &&
      queryStringItemReceipts.id > 0,
    isEdit: () =>
      queryStringItemReceipts.form === FormQueryStringStatus.Edit &&
      queryStringItemReceipts.id > 0,
    isViewOrEdit: () =>
      (queryStringItemReceipts.form === FormQueryStringStatus.View ||
        queryStringItemReceipts.form === FormQueryStringStatus.Edit) &&
      queryStringItemReceipts.id > 0,
    isReturn: () => queryStringItemReceipts.isReturn,
    orderNo: queryStringItemReceipts?.orderNo,
  };

  const initialStateOfForm: IReceiptDetail = {
    customerId: '',
    warehouseCustomerId: '',
    customer: '',
    customerFacilityId: '',
    warehouse: '',
    referenceNo: '',
    purchaseOrder: '',
    packingListId: '',
    expectedDate: new Date().toString(),
    arrivalDate: null,
    container: '',
    location: '',
    carrier: '',
    trailerNo: '',
    sealNo: '',
    billOfLadingNumber: '',
    trackingNo: '',
    notes: '',
    lineItems: [],
    billingChargeSection: [],
    pallets: [],
    statusProgressValue: '13',
    status: null,
    packingListDropDownName: '',
    purchaseOrderNo: '',
    isNonBillable: false,
    isReturnOrder: queryStringItemReceipts.isReturn,
  };

  const initialStateOfLineItemForm = {
    lineId: '',
    itemId: '',
    lotId: '',
    lotNo: '',
    lotCreationDate: null,
    lotExpirationDate: null,
    sku: '',
    qty: '',
    oldQty: '',
    actual: '',
    description: '',
    weight: '',
    licensePlateType: '',
    qtyOfPrimaryOUMPerLP: '', // Total Number of Pallets
    length: '',
    width: '',
    height: '',
    palletWeight: '',
    statusOnArrival: '',
    reasonStatus: '',
    eaCase: '',
    totalCases: '',
    eachesPerCase: '',
    isSerialNo: false,
    serialNo: '',
    crossDockBinId: '',
    lineIdToUpdateQty: '',
    primaryUOM: '',
    primaryUOMAbbr: '',
    itemUnitOfMeasureId: '',
    otherUOMStr: '',
    otherUOMAbbr: '',
    otherUOMCount: null,
    onHoldOldQty: '',
    onHoldOldTotalCases: '',
    primaryLength: '',
    primaryWidth: '',
    primaryHeight: '',
    primaryWeight: '',
    images: [] as ReceiptLineItemImageType[],
    receiptLineItemPalletType: '',
    notes: '',
  };

  const initialStateOfLineItemRows = [];

  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);
  const [formValues, setFormValues] = useState(initialStateOfForm);
  const [formErrors, setFormErrors] = useState({});
  const [formLineItemErrors, setFormLineItemErrors] = useState({});
  const [formLineItemValues, setFormLineItemValues] = useState(
    initialStateOfLineItemForm,
  );

  const [lineItemRows, setLineItemRows] = useState(initialStateOfLineItemRows);
  const [customerValue, setCustomerValue] = useState<[]>(null);
  const [facilityValue, setFacilityValue] = useState({
    value: '',
    label: '',
  });
  const [locationValue, setLocationValue] = useState({
    value: '',
    label: '',
  });
  const [options, setOptions] = useState([]);
  const [optionsSku, setOptionsSku] = useState<any>([]);
  const [optionsUOM, setOptionsUOM] = useState<ItemUnitOfMeasureType[]>([]);
  const [skuValue, setSkuValue] = useState<string | null | any>(null);
  const [uomValue, setUomValue] = useState<ItemUnitOfMeasureType>(null);
  const [optionFacility, setOptionFacility] = useState([]);
  const [optionLocation, setOptionLocation] = useState([]);
  const [optionLocationCrossDock, setOptionLocationCrossDock] = useState([]);
  const [poIdLinkedLineitemValue, setPoIdLinkedLineitemValue] =
    useState<number>(null);
  const [optionsPurchaseOrderIdAndNumber, setOptionsPurchaseOrderIdAndNumber] =
    useState([]);
  const [purchaseOrderIdOrNumber, setPurchaseOrderIdOrNumber] = useState<
    string | null | any
  >(null);
  const [purchaseOrderExist, setPurchaseOrderExist] = useState(false);
  const [optionSearchSku, setOptionSearchSku] = useState('');
  const [optionLicensePlateType, setOptionLicensePlateType] = useState<
    LicensePlateType[]
  >([]);
  const [optionLicensePlateValue, setOptionLicensePlateValue] =
    useState<LicensePlateType>(null);
  const [optionStatusOnArrivalType, setOptionStatusOnArrivalType] = useState<
    StatusOnArrivalType[]
  >([]);
  const [optionStatusOnArrivalValue, setOptionStatusOnArrivalValue] =
    useState<StatusOnArrivalType>(null);

  const [optionsReasonOnStatusType, setOptionsReasonOnStatusType] = useState<
    ReasonOnStatusType[]
  >([]);
  const [reasonOnStatusTypeValue, setReasonOnStatusTypeValue] =
    useState<ReasonOnStatusType>(null);

  const [optionLocationCrossDockValue, setOptionLocationCrossDockValue] =
    useState<LocationCrossDockType>(null);

  const [optionLotNo, setOptionLotNo] = useState<LotNoType[]>([]);
  const [lotNoValue, setLotNoValue] = useState<LotNoType>(null);

  const [modalTitle, setModalTitle] = useState<string | null>(null);
  const [receiptStatus, setReceiptStatus] = useState<number | null>(null);

  const [singleLineItemRow, setSingleLineItemRow] = useState<any>(null);
  const [printAllOfLPItem, setPrintAllOfLPItem] = useState<any>('');
  const [numberToPrint, setNumberToPrint] = useState(null);

  // Expected qty modal and summary --------------------------------------------------------------
  const [itemSummary, setItemSummary] = useState({
    items: [],
    total: 0,
  });

  // Billing Charge Section - Adhoc -------------------------------------------------------------------------
  const initialStateOfBillingChargeSection = [
    {
      warehouseCustomerBillingChargeSectionId: '',
      warehouseCustomerAdhocChargeId: '',
      chargeName: '',
      rate: '',
      qty: '',
      total: '',
      notes: '',
    },
  ];

  const [billingAdhocChargesData, setBillingAdhocChargesData] = useState([]);
  const [errorBillingAdhocItemValues, setErrorBillingAdhocItemValues] =
    useState(initialStateOfBillingChargeSection);

  const [
    dataBillingChargeSectionSelected,
    setDataBillingChargeSectionSelected,
  ] = useState({
    warehouseCustomerBillingChargeSectionId: '',
  });
  const [isBillingChargeDeleteHasId, setIsBillingChargeDeleteHasId] =
    useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  // inbound setttings state
  const initialFormStateInbound = {
    customerId: currentUser.Claim_CustomerId,
    location: {
      value: -1,
      label: '',
    },
    isQualityControl: false,
    itemModels: [],
  };
  const [inboundData, setInboundData] = useState<any>(initialFormStateInbound);

  // po link to receipt
  const onLoadPurchaseOrderDetailById = async (id: any) => {
    try {
      const getResult = await getPackingListToReceiptByPackingListId(id);
      const datas = [...getResult.packingListLineItems];
      let toAdds = [...lineItemRows];
      datas.forEach((x, index) => {
        const toAdd = {
          lineId: `NEW_${index}_${Math.random()}`,
          itemId: x.itemId,
          lotId: x?.lot ? x?.lot.lotId : '',
          lotNo: x?.lot ? x?.lot.lotNo : '',
          lotCreationDate: x?.lot ? x?.lot.creationDate : null,
          lotExpirationDate: x?.lot ? x?.lot.expirationDate : null,
          sku: x?.sku,
          upc: x?.upc,
          qty: x?.qty,
          oldQty: '',
          actual: '',
          description: x?.description,
          licensePlateType: x?.binItemType,
          statusOnArrival: 1,
          reasonStatus: '',
          eaCase: x?.eaCase,
          totalCases: x?.totalCases,
          eachesPerCase: x?.eachesPerCase,
          isSerialNo: x?.isSerialNo,
          serialNo: x?.serialNo,
          primaryUOM: x?.primaryUOM,
          primaryUOMStr: x?.primaryUOMStr,
          primaryUOMAbbr: x?.primaryUOMAbbr,
          isOtherUOM: x?.isOtherUOM,
          itemUnitOfMeasureId: x?.itemUnitOfMeasureId,
          otherUOMStr: x?.otherUOMStr,
          otherUOMAbbr: x?.otherUOMAbbr,
          otherUOMCount: x?.otherUOMCount,
          otherUOMUpc: x?.otherUOMUpc,
          primaryLength: x?.primaryLength,
          primaryWidth: x?.primaryWidth,
          primaryHeight: x?.primaryHeight,
          weight: x?.weight,
          notes: x?.notes,
        };
        toAdds = [...toAdds, toAdd];
        // }
      });

      setLineItemRows(toAdds);
      setFormValues((prevState) => ({
        ...prevState,
        expectedDate: getResult?.estimatedArrival,
        carrier: getResult?.carrier,
        trackingNo: getResult?.trackingNo,
        lineItems: toAdds,
      }));

      return true;
    } catch (err) {
      return err;
    }
  };

  // RECEIPT MAIN LIST -------------------------------------------------------

  const [receipts, setReceipts] = useState([]);
  const [filteredReceipts, setFilteredReceipts] = useState([]);

  const [selectedFilter, setSelectedFilter] = useState('Open');
  const [receiptIdToDelete, setReceiptIdToDelete] = useState(null);

  // FINAL RECEIPT SUMMARY ---------------------------------------------------
  const onClickFinalReceiptSummary = (warehouseCustomerId, receiptId) => {
    const UrlStringSummary = `${reportUrlVariable(
      REPORT_URL,
    )}/reports/final-receipt-summary/${currentUser.Claim_CustomerId}/${
      warehouseCustomerId ?? 0
    }/${receiptId}`;

    window.open(UrlStringSummary, '_blank', 'noreferrer');
  };

  // -RETURN ------------------------------------------------------------------

  const [orderDetails, setOrderDetails] = useState(null);
  const [optionOrdersForReturn, setOptionOrdersForReturn] = useState([]);
  const [orderReturnValue, setOrderReturnValue] = useState(null);
  const [returnOrderItemRows, setReturnOrderItemRows] = useState<
    ILineItemType[] | []
  >([]);
  const [returnQuantityLineItem, setReturnQuantityLineItem] =
    useState<any>(null);
  const [orderItemRows, setOrderItemRows] = useState([]);

  const onLoadOrderDetailsForReturn = async (returnOrderId) => {
    await getOrderDetailsForReturn(returnOrderId).then((res) => {
      setOrderDetails({
        recipientName: res.shipTo?.recipientName,
        address: `${res.shipTo?.address1} ${res.shipTo?.cityTownship}, ${res.shipTo?.stateProvinceRegion} ${res.shipTo?.countryStr}`,
        shipDate: res.shipDate,
        carrierName: res.carrier,
        carrierService: res.carrierService,
      });
    });
  };
  // -------------------------------------------------------------------------
  const onLoadReceiptsData = async (searchParamValue?: any) => {
    try {
      const getStoredWarehouseValue = JSON.parse(
        localStorage.getItem('storeWarehouseCustomer'),
      );

      const receiptsFromApi = await getReceipts(
        currentLocationAndFacility.customerFacilityId,
        getStoredWarehouseValue ? getStoredWarehouseValue.id : null,
        searchParamValue,
      );

      setReceipts(receiptsFromApi);
      setFilteredReceipts(
        receiptsFromApi.filter((x) => x.status.toLowerCase() === 'open'),
      );

      return true;
    } catch (err) {
      return err;
    }
  };

  React.useEffect(() => {
    onLoadReceiptsData();
  }, [currentLocationAndFacility]);
  // -------------------------------------------------------------------------

  const onLoadPurchaseOrderNumberByWarehouseCustomer = async (
    warehouseCustomerId,
  ) => {
    try {
      const setPOIdAndNumberLookupFromApi = await getPurchaseOrderPackingList(
        currentLocationAndFacility.customerFacilityId,
        warehouseCustomerId,
      );
      setOptionsPurchaseOrderIdAndNumber(setPOIdAndNumberLookupFromApi);
      return true;
    } catch (err) {
      return err;
    }
  };

  const onLoadWarehouseCustomerLookup = async () => {
    try {
      const onlyActiveWarehouseCustomers = true;
      const warehouseCustomerLookupFromApi = await getWarehouseCustomerLookup(
        currentUser.Claim_CustomerId,
        customerValue,
        onlyActiveWarehouseCustomers,
      );
      setOptions(warehouseCustomerLookupFromApi);
      if (warehouseCustomerLookupFromApi.length === 1) {
        warehouseCustomerLookupFromApi.forEach((e) => {
          setFormValues((current) => ({
            ...current,
            customerId: e.warehouseCustomerId.toString(),
            customer: e.name,
          }));
          setCustomerValue(e);
          onLoadPurchaseOrderNumberByWarehouseCustomer(e.warehouseCustomerId);
        });
      }

      return true;
    } catch (err) {
      return err;
    }
  };

  const onLoadFacilitiesLookup = async () => {
    try {
      const facilitiesLookupFromApi =
        await getFacilitiesWithLocationByCustomerId(
          currentUser.Claim_CustomerId,
        );
      return facilitiesLookupFromApi;
      // return true;
    } catch (err) {
      return err;
    }
  };

  const onLoadSkuLookup = async () => {
    try {
      const skuSearchCallback = (callback) => callback();

      const skuLookupFromApi = await getSkuLookup(
        currentUser.Claim_CustomerId,
        skuSearchCallback(() => {
          let value = '';
          if (optionSearchSku !== null || optionSearchSku !== undefined) {
            value = optionSearchSku;
          }
          return value;
        }),
      );
      setOptionsSku(skuLookupFromApi);
      return true;
    } catch (err) {
      return err;
    }
  };

  const onLoadReceiptDetail = async () => {
    try {
      return await getReceiptDetail(queryStringItemReceipts.id);
    } catch (err) {
      return err;
    }
  };

  // onlocation
  const onLoadGetLocation = async (e: any) => {
    try {
      const optionsLocation = await getCustomerBinsByFacilityId(e);

      setOptionLocation(
        await Promise.all(
          optionsLocation.map((c: any) => ({
            value: c.binId,
            label: c.name,
          })),
        ),
      );
      return null;
    } catch (err) {
      return err;
    }
  };

  const onLoadGetReceiptBillingChargeSectionAdhoc = async () => {
    try {
      return await GetReceiptBillingChargeSectionAdhoc(
        queryStringItemReceipts.id,
      );
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    if (queryStringItemReceipts.form === 'new' && inboundData.location.value) {
      const isFound = optionLocation.some((c) => {
        if (c.value === inboundData.location.value) {
          return true;
        }
        return false;
      });

      setLocationValue((prevState) => ({
        ...prevState,
        value: isFound === true ? inboundData.location.value : -1,
        label: isFound === true ? inboundData.location.label : '',
      }));
      setFormValues((prevState) => ({
        ...prevState,
        location: isFound === true ? inboundData.location.value : '',
      }));
    }
  }, [optionLocation]);

  // onlocation - crossDock
  const onLoadGetLocationCrossDock = async (e: any) => {
    try {
      const optionsLocationCrossDock = await getBinLookupCrossDock(e);
      setOptionLocationCrossDock(
        optionsLocationCrossDock.map((c: any) => ({
          value: c.binId,
          label: c.name,
        })),
      );

      return true;
    } catch (err) {
      return err;
    }
  };

  const onLoadLicensePlate = async () => {
    try {
      return await getLicensePlate();
    } catch (err) {
      return err;
    }
  };

  const onLoadStatusOnArrival = async () => {
    try {
      return await getStatusArrival();
    } catch (err) {
      return err;
    }
  };

  const loadBillingChargesDetailByModule = async (warehouseCustomerId) => {
    const result = await getBillingChargesDetailByModule(
      warehouseCustomerId,
      'Adhoc',
      'Receiving',
    );

    setBillingAdhocChargesData(
      result.adhocCharges.map((v: any) => ({
        warehouseCustomerAdhocChargeId: v.warehouseCustomerAdhocChargeId,
        chargeName: v.chargeName,
        rate: v.rate,
        required: v.required,
      })),
    );
  };

  const purchaseOrderOptions = Array.isArray(optionsPurchaseOrderIdAndNumber)
    ? optionsPurchaseOrderIdAndNumber.map((v, index) => ({
        id: v.value,
        label: v.text,
        purchaseOrderNo: v.purchaseOrderNo,
      }))
    : [{ id: 0, label: '', purchaseOrderNo: '' }];

  const onLoadInboundSection = async () => {
    try {
      const resultInbound = await getCustomerInboundSection(
        currentUser.Claim_CustomerId,
      );
      setInboundData((e) => ({
        ...e,
        inboundSectionSettingId: resultInbound?.inboundSectionSettingId,
        customerId: currentUser.Claim_CustomerId,
        location: {
          value: resultInbound?.binId ? resultInbound.binId : '-1',
          label: resultInbound?.binName ? resultInbound.binName : '',
        },
        isQualityControl: resultInbound.isQualityControl,
        isConfirmReceiptLineItem: resultInbound.isConfirmReceiptLineItem,
        isAllowMultiplePalletsInOneLocation:
          resultInbound.isAllowMultiplePalletsInOneLocation,
        itemModels: resultInbound?.itemModels ? resultInbound.itemModels : [],
      }));

      return true;
    } catch (err) {
      return err;
    }
  };

  const handleUpdateCheckLineItem = async (receiptLineItemId, value) => {
    await updateIsCheckLineItemAsync(receiptLineItemId, value)
      .then(() => {
        onLoadReceiptDetail()
          .then((x) => {
            setLineItemRows(
              x.items.map((v) => ({
                lineId: v.receiptLineItemId,
                itemId: v.itemId,
                sku: v.itemSKU,
                description: v.description,
                upc: v.upc,
                uom: v.uom,
                qty: v.qty,
                qtyExpected: v?.qtyExpected,
                actual: v.actual,
                qtyActual: v.qtyActual,
                weight: v.weight,
                lotId: v.lot?.lotId,
                lotNo: v.lot?.lotNo,
                lotCreationDate: v.lot?.creationDate,
                lotExpirationDate: v.lot?.expirationDate,
                lp: v.lp,
                eaCase: v.eaCase,
                totalCases: v.totalCases,
                eachesPerCase: v?.eachesPerCase,
                isSerialNo: v.isSerialNo,
                serialNo: v.serialNo,
                isOtherUOM: v.isOtherUOM,
                primaryUOMAbbr: v.primaryUOMAbbr,
                otherUOMAbbr: v.otherUOMAbbr,
                otherUOMCount: v.otherUOMCount,
                otherUOMUpc: v.otherUOMUpc,
                customer: v.customer,
                status: v.status,
                statusStr: v.statusStr,
                reasonStatus: v.reasonStatus,
                reasonStatusStr: v.reasonStatusStr,
                receiptLineItemPalletType: v.receiptLineItemPalletType,
                receiptLineItemPalletTypeStr: v.receiptLineItemPalletTypeStr,
                primaryLength: v.primaryLength,
                primaryWidth: v.primaryWidth,
                primaryHeight: v.primaryHeight,
                primaryWeight: v.primaryWeight,
                notes: v.notes,
                qtyTotal: v.qtyTotal,
                isLineItemConfirm: v.isLineItemConfirm,
              })),
            );
          })
          .catch(() => {
            snackActions.error('Something went wrong!');
          });
      })
      .catch(() => {
        snackActions.error('Something went wrong!');
      });
  };

  const viewLoadData = async () => {
    if (pageViewerReceipt.isViewOrEdit()) {
      const res = onLoadReceiptDetail();
      res
        .then((x) => {
          setFormValues((prevState) => ({
            ...prevState,
            // customerId: '',
            customer: x.receiptDetail.customer,
            customerFacilityId: x.receiptDetail.customerFacilityId,
            referenceNo: x.receiptDetail.referenceNo,
            purchaseOrder: x.receiptDetail?.purchaseOrder,
            packingListId: x.receiptDetail?.packingListId,
            location: x.receiptDetail.binId,
            expectedDate:
              x.receiptDetail?.expectedDate !== undefined
                ? x.receiptDetail?.expectedDate
                : null,
            arrivalDate:
              x.receiptDetail?.arrivalDate !== undefined
                ? x.receiptDetail?.arrivalDate
                : null,
            container: x.receiptDetail.container,
            carrier: x.receiptDetail.carrier,
            trailerNo: x.receiptDetail.trailerNo,
            sealNo: x.receiptDetail.sealNo,
            billOfLadingNumber: x.receiptDetail.billOfLadingNumber,
            trackingNo: x.receiptDetail.trackingNo,
            notes: x.receiptDetail.notes,
            lineItems: [],
            warehouseCustomerId: x.receiptDetail.warehouseCustomerId, // <-- additional object do not put on initial state
            isSerialNo: x.isSerialNo,
            serialNo: x.serialNo,
            isPutAwayCompleted: x.receiptDetail.isPutAwayCompleted,
            crossDockBinId: x.crossDockBinId,
            isShowLotColumns: x.receiptDetail.isShowLotColumns,
            isShowSerialNoColumn: x.receiptDetail.isShowSerialNoColumn,
            statusProgressValue: x.receiptDetail.statusProgressValue,
            totalQty: x.receiptDetail.totalQty,
            totalQtyExpected: x.receiptDetail.totalQtyExpected,
            packingListDropDownName: x.receiptDetail?.packingListDropDownName,
            status: x.receiptDetail?.status,
            isNonBillable: x.receiptDetail?.isNonBillable,
            isReturnOrder: x.receiptDetail?.isReturnOrder,
            returnOrderId: x.receiptDetail?.returnOrderId,
          }));

          if (x.receiptDetail?.isReturnOrder === true) {
            setOrderReturnValue({
              value: x.receiptDetail?.returnOrderId,
              text: x.receiptDetail?.returnOrderNo,
            });
            onLoadOrderDetailsForReturn(x.receiptDetail?.returnOrderId);
          }

          onLoadPurchaseOrderNumberByWarehouseCustomer(
            x.receiptDetail.warehouseCustomerId,
          );

          setCustomerValue((prev) => ({
            ...prev,
            name: x.receiptDetail.customer,
            warehouseCustomerId: x.receiptDetail.warehouseCustomerId,
          }));

          setLineItemRows(
            x.items.map((v) => ({
              lineId: v.receiptLineItemId,
              itemId: v.itemId,
              sku: v.itemSKU,
              description: v.description,
              upc: v.upc,
              uom: v.uom,
              qty: v.qty,
              qtyExpected: v?.qtyExpected,
              actual: v.actual,
              qtyActual: v.qtyActual,
              weight: v.weight,
              lotId: v.lot?.lotId,
              lotNo: v.lot?.lotNo,
              lotCreationDate: v.lot?.creationDate,
              lotExpirationDate: v.lot?.expirationDate,
              lp: v.lp,
              eaCase: v.eaCase,
              totalCases: v.totalCases,
              eachesPerCase: v?.eachesPerCase,
              isSerialNo: v.isSerialNo,
              serialNo: v.serialNo,
              isOtherUOM: v.isOtherUOM,
              primaryUOMAbbr: v.primaryUOMAbbr,
              otherUOMAbbr: v.otherUOMAbbr,
              otherUOMCount: v.otherUOMCount,
              otherUOMUpc: v.otherUOMUpc,
              customer: v.customer,
              status: v.status,
              statusStr: v.statusStr,
              reasonStatus: v.reasonStatus,
              reasonStatusStr: v.reasonStatusStr,
              receiptLineItemPalletType: v.receiptLineItemPalletType,
              receiptLineItemPalletTypeStr: v.receiptLineItemPalletTypeStr,
              primaryLength: v.primaryLength,
              primaryWidth: v.primaryWidth,
              primaryHeight: v.primaryHeight,
              primaryWeight: v.primaryWeight,
              notes: v.notes,
              qtyTotal: v.qtyTotal,
              isLineItemConfirm: v.isLineItemConfirm,
            })),
          );

          onLoadGetLocation(x.receiptDetail.customerFacilityId);
          onLoadGetLocationCrossDock(x.receiptDetail.customerFacilityId);
          setReceiptStatus(parseInt(x.receiptDetail.status, 10));

          setBillingAdhocChargesData(
            x.receiptBillingChargeSectionAdhocs.map((v: any) => ({
              warehouseCustomerBillingChargeSectionId:
                v.warehouseCustomerBillingChargeSectionId,
              warehouseCustomerAdhocChargeId: v.warehouseCustomerAdhocChargeId,
              chargeName: v.chargeName,
              rate: v.rate,
              qty: v.qty,
              total: v.total,
              notes: v.notes,
            })),
          );
        })
        .catch(() => {
          snackActions.error('Something went wrong!');
        });
    }
    onLoadInboundSection();
  };

  const handleChangeBillingChargeSection = (e, index) => {
    const clone = [...billingAdhocChargesData];

    const obj = clone[index];

    if (e.target.name === 'rate') {
      if (e.target.value < 0) {
        e.target.value = 0;
      }
      const validateDecimal = decimalValidationHelper(e.target.value);

      obj.rate = validateDecimal.trim();
    }
    if (e.target.name === 'qty') {
      if (e.target.value < 0) {
        e.target.value = 0;
      }
      const validateDecimal = decimalValidationHelper(e.target.value);

      obj.qty = validateDecimal.trim();
    }

    if (e.target.name === 'notes') {
      obj.notes = e.target.value;
    }

    if (
      (obj.rate !== null || obj.rate !== '') &&
      (obj.qty !== null || obj.qty !== '')
    ) {
      const checkRate = obj.rate.includes('$')
        ? obj.rate.replace('$', '')
        : obj.rate;

      obj.total = `$${(Number(checkRate) * Number(obj.qty)).toFixed(2)}`;
    } else {
      obj.total = '0';
    }

    clone[index] = obj;
    setBillingAdhocChargesData([...clone]);
  };

  const validateBillingCharge = (
    selectedItemId = null,
    clearValidationState = false,
  ) => {
    const temp: any = {};

    let tempBillingAdhocChargesData = billingAdhocChargesData;

    if (selectedItemId != null) {
      tempBillingAdhocChargesData = tempBillingAdhocChargesData.filter(
        (x) => x.warehouseCustomerAdhocChargeId === selectedItemId,
      );
    }

    temp.billingAdhocChargesData = [];
    tempBillingAdhocChargesData.forEach((element, index) => {
      const TempData = {
        rate: element.rate ? '' : 'This field is required',
        qty: element.qty ? '' : 'This field is required',
      };

      if (!element.required) {
        TempData.rate = '';
        TempData.qty = '';
      }

      if (clearValidationState) {
        TempData.rate = '';
        TempData.qty = '';
      }

      temp.billingAdhocChargesData = [
        ...temp.billingAdhocChargesData,
        TempData,
      ];
    });

    setErrorBillingAdhocItemValues({
      ...temp.billingAdhocChargesData,
    });

    return temp.billingAdhocChargesData.every((e) =>
      Object.values(e).every((x) => x === ''),
    );
  };

  const handleRemoveBillingChargeSection = (model) => {
    if (
      !validateBillingCharge(model.warehouseCustomerAdhocChargeId) ||
      model.required
    ) {
      return;
    }

    if (model.warehouseCustomerBillingChargeSectionId !== undefined) {
      setDataBillingChargeSectionSelected((prev) => ({
        ...prev,
        warehouseCustomerBillingChargeSectionId:
          model.warehouseCustomerBillingChargeSectionId,
      }));
      onOpenConfirmDeleteDialog();
    } else {
      const filteredArray = billingAdhocChargesData.filter(
        (v) =>
          v.warehouseCustomerAdhocChargeId !==
          model.warehouseCustomerAdhocChargeId,
      );

      setBillingAdhocChargesData(filteredArray);
      setIsBillingChargeDeleteHasId(false);
    }
  };

  const callBackForBillingChargeDelete = async () => {
    const res = onLoadReceiptDetail();
    res
      .then((x) => {
        const filteredReceiptBillingChargeSectionAdhocs =
          x.receiptBillingChargeSectionAdhocs.filter(
            (v) => v.warehouseCustomerBillingChargeSectionId !== undefined,
          );
        setBillingAdhocChargesData(
          filteredReceiptBillingChargeSectionAdhocs.map((v: any) => ({
            warehouseCustomerBillingChargeSectionId:
              v.warehouseCustomerBillingChargeSectionId,
            warehouseCustomerAdhocChargeId: v.warehouseCustomerAdhocChargeId,
            chargeName: v.chargeName,
            rate: v.rate,
            qty: v.qty,
            actual: v.actual,
            total: v.total,
            notes: v.notes,
          })),
        );
      })
      .catch(() => {
        snackActions.error('Something went wrong!');
      });

    setIsBillingChargeDeleteHasId(false);
  };

  const validate = () => {
    const temp: any = {};
    temp.customer =
      formValues.customer !== '' && formValues.customer !== undefined
        ? ''
        : 'This field is required';

    temp.customerFacilityId =
      formValues.customerFacilityId !== '' ||
      formValues.customerFacilityId === undefined
        ? ''
        : 'This field is required';

    // referenceNo only required at edit.
    // at new, if leave blank, backend will auto populate this
    if (queryStringItemReceipts.form === FormQueryStringStatus.Edit) {
      temp.referenceNo =
        formValues.referenceNo !== '' || formValues.referenceNo === undefined
          ? ''
          : 'This field is required';
    } else {
      temp.referenceNo = '';
    }

    temp.arrivalDate =
      formValues.arrivalDate !== null ? '' : 'This field is required';

    setFormErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  const validateLineItemForm = () => {
    const temp: any = {};
    const re = /[0-9]+/g;

    temp.sku =
      formLineItemValues.sku !== '' && formLineItemValues.sku !== undefined
        ? ''
        : 'This field is required';

    // primary uom
    if (formLineItemValues.itemUnitOfMeasureId) {
      if (
        formLineItemValues.totalCases === '' ||
        formLineItemValues.totalCases === null
      ) {
        temp.totalCases = 'This field is required';
      } else if (!re.test(formLineItemValues.qty)) {
        temp.totalCases = 'Must be a number';
      } else {
        temp.totalCases = '';
      }

      if (
        modalTitle === ModalTitleStatus.AddHold &&
        Number(formLineItemValues.totalCases) >
          Number(formLineItemValues.onHoldOldTotalCases)
      ) {
        temp.totalCases = `Value cannot exceed ${formLineItemValues.onHoldOldTotalCases}`;
      }
    }
    // other uom
    else {
      if (formLineItemValues.qty === '' || formLineItemValues.qty === null) {
        temp.qty = 'This field is required';
      } else if (!re.test(formLineItemValues.qty)) {
        temp.qty = 'Must be a number';
      } else {
        temp.qty = '';
      }

      if (
        modalTitle === ModalTitleStatus.AddHold &&
        Number(formLineItemValues.qty) > Number(formLineItemValues.onHoldOldQty)
      ) {
        temp.qty = `Value cannot exceed ${formLineItemValues.onHoldOldQty}`;
      }
    }

    // pallet
    if (Number(formLineItemValues.licensePlateType) === 2) {
      if (
        formLineItemValues?.qtyOfPrimaryOUMPerLP === undefined ||
        formLineItemValues?.qtyOfPrimaryOUMPerLP === '' ||
        formLineItemValues?.qtyOfPrimaryOUMPerLP === '0' ||
        formLineItemValues?.qtyOfPrimaryOUMPerLP === null
      ) {
        temp.qtyOfPrimaryOUMPerLP = 'This field is required';
      }
    }

    if (
      formLineItemValues.isSerialNo &&
      formLineItemValues.serialNo !== '' &&
      formLineItemValues.qty !== undefined
    ) {
      const qty = Number(formLineItemValues.qty);
      const serialNos = formLineItemValues.serialNo.split(',');

      if (qty !== serialNos.length) {
        temp.qty = 'Qty value should match the number of serial numbers.';
      }
    }

    if (formLineItemValues.crossDockBinId === undefined) {
      formLineItemValues.crossDockBinId = '';
    }
    if (formLineItemValues.crossDockBinId === null) {
      formLineItemValues.crossDockBinId = '';
    }

    if (
      optionStatusOnArrivalValue?.value === 5 &&
      formLineItemValues.crossDockBinId === ''
    ) {
      temp.locationCrossDock = 'This field is required';
    } else {
      temp.locationCrossDock = '';
    }

    if (
      optionStatusOnArrivalValue?.value > 1 &&
      optionStatusOnArrivalValue?.value < 5
    ) {
      temp.reasonStatus = formLineItemValues.reasonStatus
        ? ''
        : 'This field is required';
    } else {
      temp.reasonStatus = '';
    }

    temp.uomValue = uomValue ? '' : 'This field is required';

    setFormLineItemErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleOnClickEditConfirmedReceipt = (e: any) => {
    e.preventDefault();
    setQueryStringItemReceipts((prevState) => ({
      ...prevState,
      form: 'edit',
    }));
    window.history.replaceState(
      null,
      '',
      `?form=edit&id=${queryStringItemReceipts.id}`,
    );
    viewLoadData();
    onCloseEditConfirmedReceiptModal();
  };

  function getReceiptLineItemStatusOnArrivalEnumStr(): string {
    let statusStr = '';

    if (
      Number(formLineItemValues.statusOnArrival) ===
      ReceiptLineItemStatusOnArrivalEnum.Available
    ) {
      statusStr = 'Available';
    } else if (
      Number(formLineItemValues.statusOnArrival) ===
      ReceiptLineItemStatusOnArrivalEnum.Hold
    ) {
      statusStr = 'Hold';
    } else if (
      Number(formLineItemValues.statusOnArrival) ===
      ReceiptLineItemStatusOnArrivalEnum.Damaged
    ) {
      statusStr = 'Damaged';
    } else if (
      Number(formLineItemValues.statusOnArrival) ===
      ReceiptLineItemStatusOnArrivalEnum.Quarantine
    ) {
      statusStr = 'Quarantine';
    } else if (
      Number(formLineItemValues.statusOnArrival) ===
      ReceiptLineItemStatusOnArrivalEnum.CrossDock
    ) {
      statusStr = 'Cross Dock';
    }

    return statusStr;
  }

  const handleOnClickAddLineItem = async (e: any) => {
    e.preventDefault();

    if (!validateLineItemForm()) {
      snackActions.error('Please complete required fields');
    } else {
      const dtNow = Date.now();

      if (pageViewerReceipt.isNew()) {
        const itemSerialNumbers: ItemRequestModel[] = [];
        let requestType: ValidateSerialNumberBySKURequestTypeEnum = null;
        let packingListId: number = null;

        const tempformLineItemValues = JSON.parse(
          JSON.stringify(formLineItemValues),
        );
        itemSerialNumbers.push({
          id: null,
          itemId: tempformLineItemValues.itemId,
          isSerialNo: tempformLineItemValues.isSerialNo,
          serialNo: tempformLineItemValues.serialNo,
          isSelected: true,
        });

        // add other line item's serial number
        // if not from PO, lineItem == packingListId
        if (Number(query.get('lineItem')) === 0) {
          requestType = ValidateSerialNumberBySKURequestTypeEnum.New;

          const tempformValues = JSON.parse(JSON.stringify(formValues));
          tempformValues.lineItems.forEach((x) => {
            if (x.isSerialNo && x.lineId !== tempformLineItemValues.lineId) {
              itemSerialNumbers.push({
                id: null,
                itemId: x.itemId,
                isSerialNo: x.isSerialNo,
                serialNo: x.serialNo,
                isSelected: false,
              });
            }
          });
        } else {
          requestType =
            ValidateSerialNumberBySKURequestTypeEnum.NewFromPackingList;
          packingListId = Number(query.get('lineItem'));
        }

        const payloadSerialNumber: ValidateSerialNumberBySKURequestModel = {
          requestType,
          packingListId,
          items: itemSerialNumbers,
        };
        const responseSerialNumber = await ValidateSerialNumberBySKU(
          payloadSerialNumber,
        );

        if (responseSerialNumber !== '' || responseSerialNumber) {
          responseSerialNumber.duplicates.forEach((d: ItemResponseModel) => {
            snackActions.error(
              `${d.sku} have a duplicate serial no. ${d.serialNo}`,
            );
          });

          if (responseSerialNumber.duplicates.length > 0) {
            return;
          }
        }
      }

      if (pageViewerReceipt.isEdit()) {
        const itemSerialNumbers: ItemRequestModel[] = [];
        let requestType: ValidateSerialNumberBySKURequestTypeEnum = null;

        const tempformLineItemValues = JSON.parse(
          JSON.stringify(formLineItemValues),
        );

        if (modalTitle === ModalTitleStatus.Add) {
          requestType = ValidateSerialNumberBySKURequestTypeEnum.New;

          itemSerialNumbers.push({
            id: null,
            itemId: tempformLineItemValues.itemId,
            isSerialNo: tempformLineItemValues.isSerialNo,
            serialNo: tempformLineItemValues.serialNo,
            isSelected: true,
          });
        }

        if (modalTitle === ModalTitleStatus.Edit) {
          requestType =
            ValidateSerialNumberBySKURequestTypeEnum.ReceiptLineItemId;

          itemSerialNumbers.push({
            id: tempformLineItemValues.lineId,
            itemId: tempformLineItemValues.itemId,
            isSerialNo: tempformLineItemValues.isSerialNo,
            serialNo: tempformLineItemValues.serialNo,
            isSelected: true,
          });
        }

        const payloadSerialNumber: ValidateSerialNumberBySKURequestModel = {
          requestType,
          items: itemSerialNumbers,
        };
        const responseSerialNumber = await ValidateSerialNumberBySKU(
          payloadSerialNumber,
        );

        if (responseSerialNumber !== '' || responseSerialNumber) {
          responseSerialNumber.duplicates.forEach((d: ItemResponseModel) => {
            snackActions.error(
              `${d.sku} have a duplicate serial no. ${d.serialNo}`,
            );
          });

          if (responseSerialNumber.duplicates.length > 0) {
            return;
          }
        }
      }

      if (pageViewerReceipt.isNew() && modalTitle === ModalTitleStatus.Add) {
        // pallet
        if (Number(formLineItemValues.licensePlateType) === 2) {
          const totalNumberOfPallets = [];
          const quantity = parseInt(
            formLineItemValues.qtyOfPrimaryOUMPerLP,
            10,
          );

          for (let index = 0; index < quantity; index += 1) {
            totalNumberOfPallets.push(index);
          }

          totalNumberOfPallets.forEach((data, index) => {
            setLineItemRows((prevState) => [
              ...prevState,
              {
                lineId: `NEW_${index}_${dtNow}`,
                sku: formLineItemValues.sku,
                description: formLineItemValues.description,
                qty: formLineItemValues.qty,
                actual: formLineItemValues.actual,
                lotId: formLineItemValues.lotId,
                lotNo: formLineItemValues.lotNo,
                lotCreationDate: formLineItemValues.lotCreationDate,
                lotExpirationDate: formLineItemValues.lotExpirationDate,
                lp: `NEW`,
                eaCase: formLineItemValues.eaCase,
                totalCases: formLineItemValues.totalCases,
                eachesPerCase: formLineItemValues.eachesPerCase,
                expirationDate: formLineItemValues.lotExpirationDate, // //receipt line item table
                statusOnArrival: formLineItemValues.statusOnArrival,
                reasonStatus: formLineItemValues.reasonStatus,
                isSerialNo: formLineItemValues.isSerialNo,
                serialNo: formLineItemValues.serialNo,
                crossDockBinId: formLineItemValues.crossDockBinId,
                primaryUOM: formLineItemValues.primaryUOM,
                primaryUOMAbbr: formLineItemValues.primaryUOMAbbr,
                receiptLineItemPalletType:
                  formLineItemValues.receiptLineItemPalletType,
                primaryLength: formLineItemValues.primaryLength,
                primaryWidth: formLineItemValues.primaryWidth,
                primaryHeight: formLineItemValues.primaryHeight,
                primaryWeight: formLineItemValues.primaryWeight,
                isOtherUOM: !!formLineItemValues.otherUOMCount,
                itemUnitOfMeasureId: formLineItemValues.itemUnitOfMeasureId,
                otherUOMStr: formLineItemValues.otherUOMStr,
                otherUOMAbbr: formLineItemValues.otherUOMAbbr,
                otherUOMCount: formLineItemValues.otherUOMCount,
                otherUOMUpc: '',
                status: formLineItemValues.statusOnArrival,
                statusStr: getReceiptLineItemStatusOnArrivalEnumStr(),
                images: formLineItemValues.images,
                notes: formLineItemValues.notes,
              },
            ]);

            setFormValues((prevState) => ({
              ...prevState,
              lineItems: [
                ...prevState.lineItems,
                {
                  lineId: `NEW_${index}_${dtNow}`,
                  itemId: formLineItemValues.itemId,
                  sku: formLineItemValues.sku,
                  qty: formLineItemValues.qty,
                  lotId: formLineItemValues.lotId,
                  lotNo: formLineItemValues.lotNo,
                  lotCreationDate: formLineItemValues.lotCreationDate,
                  lotExpirationDate: formLineItemValues.lotExpirationDate,
                  description: formLineItemValues.description,
                  weight: formLineItemValues.weight,
                  licensePlateType: formLineItemValues.licensePlateType,
                  qtyOfPrimaryOUMPerLP: 1, // Total Number of Pallets
                  length: formLineItemValues.length,
                  width: formLineItemValues.width,
                  height: formLineItemValues.height,
                  palletWeight: formLineItemValues.palletWeight,
                  statusOnArrival: formLineItemValues.statusOnArrival,
                  reasonStatus: formLineItemValues.reasonStatus,
                  eaCase: formLineItemValues.eaCase,
                  totalCases: formLineItemValues.totalCases,
                  eachesPerCase: formLineItemValues.eachesPerCase,
                  expirationDate: formLineItemValues.lotExpirationDate, // //receipt line item table
                  isSerialNo: formLineItemValues.isSerialNo,
                  serialNo: formLineItemValues.serialNo,
                  crossDockBinId: formLineItemValues.crossDockBinId,
                  primaryUOM: formLineItemValues.primaryUOM,
                  primaryUOMAbbr: formLineItemValues.primaryUOMAbbr,
                  isOtherUOM: !!formLineItemValues.otherUOMCount,
                  itemUnitOfMeasureId: formLineItemValues.itemUnitOfMeasureId,
                  otherUOMStr: formLineItemValues.otherUOMStr,
                  otherUOMAbbr: formLineItemValues.otherUOMAbbr,
                  otherUOMCount: formLineItemValues.otherUOMCount,
                  otherUOMUpc: '',
                  images: formLineItemValues.images,
                  receiptLineItemPalletType:
                    formLineItemValues.receiptLineItemPalletType,
                  primaryLength: formLineItemValues.primaryLength,
                  primaryWidth: formLineItemValues.primaryWidth,
                  primaryHeight: formLineItemValues.primaryHeight,
                  primaryWeight: formLineItemValues.primaryWeight,
                  notes: formLineItemValues.notes,
                },
              ],
            }));
          });
        } else {
          setFormValues((prevState) => ({
            ...prevState,
            lineItems: [...prevState.lineItems, formLineItemValues],
          }));

          setLineItemRows((prevState) => [
            ...prevState,
            {
              lineId: `NEW_${formValues.lineItems.length}`,
              sku: formLineItemValues.sku,
              itemId: Number(formLineItemValues.itemId),
              description: formLineItemValues.description,
              qty: formLineItemValues.qty,
              actual: formLineItemValues.actual,
              lotId: formLineItemValues.lotId,
              lotNo: formLineItemValues.lotNo,
              lotCreationDate: formLineItemValues.lotCreationDate,
              lotExpirationDate: formLineItemValues.lotExpirationDate,
              lp: '',
              eaCase: formLineItemValues.eaCase,
              totalCases: formLineItemValues.totalCases,
              eachesPerCase: formLineItemValues.eachesPerCase,
              expirationDate: formLineItemValues.lotExpirationDate, // //receipt line item table
              statusOnArrival: formLineItemValues.statusOnArrival,
              reasonStatus: formLineItemValues.reasonStatus,
              isSerialNo: formLineItemValues.isSerialNo,
              serialNo: formLineItemValues.serialNo,
              crossDockBinId: formLineItemValues.crossDockBinId,
              primaryUOM: formLineItemValues.primaryUOM,
              primaryUOMAbbr: formLineItemValues.primaryUOMAbbr,
              isOtherUOM: !!formLineItemValues.otherUOMCount,
              itemUnitOfMeasureId: formLineItemValues.itemUnitOfMeasureId,
              otherUOMStr: formLineItemValues.otherUOMStr,
              otherUOMAbbr: formLineItemValues.otherUOMAbbr,
              otherUOMCount: formLineItemValues.otherUOMCount,
              otherUOMUpc: '',
              status: formLineItemValues.statusOnArrival,
              statusStr: getReceiptLineItemStatusOnArrivalEnumStr(),
              images: formLineItemValues.images,
              receiptLineItemPalletType:
                formLineItemValues.receiptLineItemPalletType,
              primaryLength: formLineItemValues.primaryLength,
              primaryWidth: formLineItemValues.primaryWidth,
              primaryHeight: formLineItemValues.primaryHeight,
              primaryWeight: formLineItemValues.primaryWeight,
              notes: formLineItemValues.notes,
            },
          ]);
        }
      } else if (
        pageViewerReceipt.isNew() &&
        modalTitle === ModalTitleStatus.Edit
      ) {
        // pallet
        if (Number(formLineItemValues.licensePlateType) === 2) {
          const totalNumberOfPallets = [];
          const quantity = parseInt(
            formLineItemValues.qtyOfPrimaryOUMPerLP,
            10,
          );

          for (let index = 1; index < quantity; index += 1) {
            totalNumberOfPallets.push(index);
          }

          if (quantity > 1) {
            totalNumberOfPallets.forEach((data, index) => {
              setLineItemRows((prevState) => [
                ...prevState,
                {
                  lineId: `NEW_${index}_${dtNow}`,
                  sku: formLineItemValues.sku,
                  description: formLineItemValues.description,
                  weight: formLineItemValues.weight,
                  licensePlateType: formLineItemValues.licensePlateType,
                  qty: formLineItemValues.qty,
                  actual: formLineItemValues.actual,
                  lotId: formLineItemValues.lotId,
                  lotNo: formLineItemValues.lotNo,
                  lotCreationDate: formLineItemValues.lotCreationDate,
                  lotExpirationDate: formLineItemValues.lotExpirationDate,
                  lp: `NEW`,
                  eaCase: formLineItemValues.eaCase,
                  totalCases: formLineItemValues.totalCases,
                  eachesPerCase: formLineItemValues.eachesPerCase,
                  expirationDate: formLineItemValues.lotExpirationDate, // //receipt line item table
                  statusOnArrival: formLineItemValues.statusOnArrival,
                  reasonStatus: formLineItemValues.reasonStatus,
                  isSerialNo: formLineItemValues.isSerialNo,
                  serialNo: formLineItemValues.serialNo,
                  crossDockBinId: formLineItemValues.crossDockBinId,
                  primaryUOM: formLineItemValues.primaryUOM,
                  primaryUOMAbbr: formLineItemValues.primaryUOMAbbr,
                  isOtherUOM: !!formLineItemValues.otherUOMCount,
                  itemUnitOfMeasureId: formLineItemValues.itemUnitOfMeasureId,
                  otherUOMStr: formLineItemValues.otherUOMStr,
                  otherUOMAbbr: formLineItemValues.otherUOMAbbr,
                  otherUOMCount: formLineItemValues.otherUOMCount,
                  otherUOMUpc: '',
                  status: formLineItemValues.statusOnArrival,
                  statusStr: getReceiptLineItemStatusOnArrivalEnumStr(),
                  images: formLineItemValues.images,
                  receiptLineItemPalletType:
                    formLineItemValues.receiptLineItemPalletType,
                  primaryLength: formLineItemValues.primaryLength,
                  primaryWidth: formLineItemValues.primaryWidth,
                  primaryHeight: formLineItemValues.primaryHeight,
                  primaryWeight: formLineItemValues.primaryWeight,
                  notes: formLineItemValues.notes,
                },
              ]);

              setFormValues((prevState) => ({
                ...prevState,
                lineItems: [
                  ...prevState.lineItems,
                  {
                    lineId: `NEW_${index}_${dtNow}`,
                    itemId: formLineItemValues.itemId,
                    sku: formLineItemValues.sku,
                    qty: formLineItemValues.qty,
                    lotId: formLineItemValues.lotId,
                    lotNo: formLineItemValues.lotNo,
                    lotCreationDate: formLineItemValues.lotCreationDate,
                    lotExpirationDate: formLineItemValues.lotExpirationDate,
                    description: formLineItemValues.description,
                    weight: formLineItemValues.weight,
                    licensePlateType: formLineItemValues.licensePlateType,
                    qtyOfPrimaryOUMPerLP: 1, // Total Number of Pallets
                    length: formLineItemValues.length,
                    width: formLineItemValues.width,
                    height: formLineItemValues.height,
                    palletWeight: formLineItemValues.palletWeight,
                    statusOnArrival: formLineItemValues.statusOnArrival,
                    reasonStatus: formLineItemValues.reasonStatus,
                    eaCase: formLineItemValues.eaCase,
                    totalCases: formLineItemValues.totalCases,
                    eachesPerCase: formLineItemValues.eachesPerCase,
                    expirationDate: formLineItemValues.lotExpirationDate, // //receipt line item table
                    isSerialNo: formLineItemValues.isSerialNo,
                    serialNo: formLineItemValues.serialNo,
                    crossDockBinId: formLineItemValues.crossDockBinId,
                    primaryUOM: formLineItemValues.primaryUOM,
                    primaryUOMAbbr: formLineItemValues.primaryUOMAbbr,
                    isOtherUOM: !!formLineItemValues.otherUOMCount,
                    itemUnitOfMeasureId: formLineItemValues.itemUnitOfMeasureId,
                    otherUOMStr: formLineItemValues.otherUOMStr,
                    otherUOMAbbr: formLineItemValues.otherUOMAbbr,
                    otherUOMCount: formLineItemValues.otherUOMCount,
                    otherUOMUpc: '',
                    images: formLineItemValues.images,
                    receiptLineItemPalletType:
                      formLineItemValues.receiptLineItemPalletType,
                    primaryLength: formLineItemValues.primaryLength,
                    primaryWidth: formLineItemValues.primaryWidth,
                    primaryHeight: formLineItemValues.primaryHeight,
                    primaryWeight: formLineItemValues.primaryWeight,
                    notes: formLineItemValues.notes,
                    lp: `NEW`,
                  },
                ],
              }));
            });
          }
          setFormValues((current) => ({
            ...current,
            lineItems: current.lineItems.map((obj) => {
              if (obj.lineId === formLineItemValues.lineId) {
                const obj1 = {
                  lineId: obj.lineId,
                  itemId: Number(obj.itemId),
                  sku: obj.sku,
                  qty: obj.qty,
                  lotId: obj.lotId,
                  lotNo: obj.lotNo,
                  lotCreationDate: obj.lotCreationDate,
                  lotExpirationDate: obj.lotExpirationDate,
                  description: obj.description,
                  weight: obj.weight,
                  licensePlateType: obj.licensePlateType,
                  qtyOfPrimaryOUMPerLP: obj.qtyOfPrimaryOUMPerLP, // Total Number of Pallets
                  length: obj.length,
                  width: obj.width,
                  height: obj.height,
                  statusOnArrival: obj.statusOnArrival,
                  reasonStatus: formLineItemValues.reasonStatus,
                  eaCase: formLineItemValues.eaCase,
                  totalCases: formLineItemValues.totalCases,
                  eachesPerCase: formLineItemValues.eachesPerCase,
                  expirationDate: formLineItemValues.lotExpirationDate, // //receipt line item table
                  isSerialNo: formLineItemValues.isSerialNo,
                  serialNo: formLineItemValues.serialNo,
                  crossDockBinId: formLineItemValues.crossDockBinId,
                  primaryUOM: formLineItemValues.primaryUOM,
                  primaryUOMAbbr: formLineItemValues.primaryUOMAbbr,
                  isOtherUOM: !!formLineItemValues.otherUOMCount,
                  itemUnitOfMeasureId: formLineItemValues.itemUnitOfMeasureId,
                  otherUOMStr: formLineItemValues.otherUOMStr,
                  otherUOMAbbr: formLineItemValues.otherUOMAbbr,
                  otherUOMCount: formLineItemValues.otherUOMCount,
                  otherUOMUpc: '',
                  images: formLineItemValues.images,
                  receiptLineItemPalletType:
                    formLineItemValues.receiptLineItemPalletType,
                  primaryLength: formLineItemValues.primaryLength,
                  primaryWidth: formLineItemValues.primaryWidth,
                  primaryHeight: formLineItemValues.primaryHeight,
                  primaryWeight: formLineItemValues.primaryWeight,
                  notes: formLineItemValues.notes,
                  lp: `NEW`,
                };

                if (
                  JSON.stringify(obj1) !== JSON.stringify(formLineItemValues)
                ) {
                  return {
                    ...obj,
                    sku: formLineItemValues.sku,
                    description: formLineItemValues.description,
                    qty: formLineItemValues.qty,
                    lotId: formLineItemValues.lotId,
                    lotNo: formLineItemValues.lotNo,
                    lotCreationDate: formLineItemValues.lotCreationDate,
                    lotExpirationDate: formLineItemValues.lotExpirationDate,
                    weight: formLineItemValues.weight,
                    itemId: formLineItemValues.itemId,
                    licensePlateType: formLineItemValues.licensePlateType,
                    qtyOfPrimaryOUMPerLP:
                      formLineItemValues.qtyOfPrimaryOUMPerLP, // Total Number of Pallets
                    length: formLineItemValues.length,
                    width: formLineItemValues.width,
                    height: formLineItemValues.height,
                    palletWeight: formLineItemValues.palletWeight,
                    statusOnArrival: formLineItemValues.statusOnArrival,
                    reasonStatus: formLineItemValues.reasonStatus,
                    eaCase: formLineItemValues.eaCase,
                    totalCases: formLineItemValues.totalCases,
                    eachesPerCase: formLineItemValues.eachesPerCase,
                    expirationDate: formLineItemValues.lotExpirationDate, // //receipt line item table
                    isSerialNo: formLineItemValues.isSerialNo,
                    serialNo: formLineItemValues.serialNo,
                    crossDockBinId: formLineItemValues.crossDockBinId,
                    primaryUOM: formLineItemValues.primaryUOM,
                    primaryUOMAbbr: formLineItemValues.primaryUOMAbbr,
                    isOtherUOM: !!formLineItemValues.otherUOMCount,
                    itemUnitOfMeasureId: formLineItemValues.itemUnitOfMeasureId,
                    otherUOMStr: formLineItemValues.otherUOMStr,
                    otherUOMAbbr: formLineItemValues.otherUOMAbbr,
                    otherUOMCount: formLineItemValues.otherUOMCount,
                    otherUOMUpc: '',
                    images: formLineItemValues.images,
                    receiptLineItemPalletType:
                      formLineItemValues.receiptLineItemPalletType,
                    primaryLength: formLineItemValues.primaryLength,
                    primaryWidth: formLineItemValues.primaryWidth,
                    primaryHeight: formLineItemValues.primaryHeight,
                    primaryWeight: formLineItemValues.primaryWeight,
                    notes: formLineItemValues.notes,
                    lp: `NEW`,
                  };
                }
                return obj1;
              }
              return obj;
            }),
          }));
          setLineItemRows((prevRows) =>
            prevRows.map((row, index) =>
              row.lineId === formLineItemValues.lineId
                ? {
                    ...row,
                    sku: formLineItemValues.sku,
                    description: formLineItemValues.description,
                    qty: formLineItemValues.qty,
                    actual: formLineItemValues.actual,
                    lotId: formLineItemValues.lotId,
                    lotNo: formLineItemValues.lotNo,
                    lotCreationDate: formLineItemValues.lotCreationDate,
                    lotExpirationDate: formLineItemValues.lotExpirationDate,
                    weight: formLineItemValues.weight,
                    licensePlateType: formLineItemValues.licensePlateType,
                    eaCase: formLineItemValues.eaCase,
                    totalCases: formLineItemValues.totalCases,
                    eachesPerCase: formLineItemValues.eachesPerCase,
                    expirationDate: formLineItemValues.lotExpirationDate, // //receipt line item table
                    statusOnArrival: formLineItemValues.statusOnArrival,
                    reasonStatus: formLineItemValues.reasonStatus,
                    isSerialNo: formLineItemValues.isSerialNo,
                    serialNo: formLineItemValues.serialNo,
                    crossDockBinId: formLineItemValues.crossDockBinId,
                    primaryUOM: formLineItemValues.primaryUOM,
                    primaryUOMAbbr: formLineItemValues.primaryUOMAbbr,
                    isOtherUOM: !!formLineItemValues.otherUOMCount,
                    itemUnitOfMeasureId: formLineItemValues.itemUnitOfMeasureId,
                    otherUOMStr: formLineItemValues.otherUOMStr,
                    otherUOMAbbr: formLineItemValues.otherUOMAbbr,
                    otherUOMCount: formLineItemValues.otherUOMCount,
                    otherUOMUpc: '',
                    status: formLineItemValues.statusOnArrival,
                    statusStr: getReceiptLineItemStatusOnArrivalEnumStr(),
                    images: formLineItemValues.images,
                    receiptLineItemPalletType:
                      formLineItemValues.receiptLineItemPalletType,
                    primaryLength: formLineItemValues.primaryLength,
                    primaryWidth: formLineItemValues.primaryWidth,
                    primaryHeight: formLineItemValues.primaryHeight,
                    primaryWeight: formLineItemValues.primaryWeight,
                    notes: formLineItemValues.notes,
                    lp: `NEW`,
                  }
                : row,
            ),
          );
        } else {
          setFormValues((current) => ({
            ...current,
            lineItems: current.lineItems.map((obj) => {
              if (obj.lineId === formLineItemValues.lineId) {
                return {
                  ...obj,
                  sku: formLineItemValues.sku,
                  itemId: Number(formLineItemValues.itemId),
                  description: formLineItemValues.description,
                  qty: formLineItemValues.qty,
                  lotId: formLineItemValues.lotId,
                  lotNo: formLineItemValues.lotNo,
                  lotCreationDate: formLineItemValues.lotCreationDate,
                  lotExpirationDate: formLineItemValues.lotExpirationDate,
                  weight: formLineItemValues.weight,
                  licensePlateType: formLineItemValues.licensePlateType,
                  eaCase: formLineItemValues.eaCase,
                  totalCases: formLineItemValues.totalCases,
                  eachesPerCase: formLineItemValues.eachesPerCase,
                  expirationDate: formLineItemValues.lotExpirationDate, // //receipt line item table
                  statusOnArrival: formLineItemValues.statusOnArrival,
                  reasonStatus: formLineItemValues.reasonStatus,
                  isSerialNo: formLineItemValues.isSerialNo,
                  serialNo: formLineItemValues.serialNo,
                  crossDockBinId: formLineItemValues.crossDockBinId,
                  primaryUOM: formLineItemValues.primaryUOM,
                  primaryUOMAbbr: formLineItemValues.primaryUOMAbbr,
                  isOtherUOM: !!formLineItemValues.otherUOMCount,
                  itemUnitOfMeasureId: formLineItemValues.itemUnitOfMeasureId,
                  otherUOMStr: formLineItemValues.otherUOMStr,
                  otherUOMAbbr: formLineItemValues.otherUOMAbbr,
                  otherUOMCount: formLineItemValues.otherUOMCount,
                  otherUOMUpc: '',
                  images: formLineItemValues.images,
                  receiptLineItemPalletType:
                    formLineItemValues.receiptLineItemPalletType,
                  primaryLength: formLineItemValues.primaryLength,
                  primaryWidth: formLineItemValues.primaryWidth,
                  primaryHeight: formLineItemValues.primaryHeight,
                  primaryWeight: formLineItemValues.primaryWeight,
                  notes: formLineItemValues.notes,
                };
              }
              return obj;
            }),
          }));

          setLineItemRows((prevRows) =>
            prevRows.map((row, index) =>
              row.lineId === formLineItemValues.lineId
                ? {
                    ...row,
                    sku: formLineItemValues.sku,
                    description: formLineItemValues.description,
                    qty: formLineItemValues.qty,
                    actual: formLineItemValues.actual,
                    lotId: formLineItemValues.lotId,
                    lotNo: formLineItemValues.lotNo,
                    lotCreationDate: formLineItemValues.lotCreationDate,
                    lotExpirationDate: formLineItemValues.lotExpirationDate,
                    weight: formLineItemValues.weight,
                    licensePlateType: formLineItemValues.licensePlateType,
                    eaCase: formLineItemValues.eaCase,
                    totalCases: formLineItemValues.totalCases,
                    eachesPerCase: formLineItemValues.eachesPerCase,
                    expirationDate: formLineItemValues.lotExpirationDate, // //receipt line item table
                    statusOnArrival: formLineItemValues.statusOnArrival,
                    reasonStatus: formLineItemValues.reasonStatus,
                    isSerialNo: formLineItemValues.isSerialNo,
                    serialNo: formLineItemValues.serialNo,
                    crossDockBinId: formLineItemValues.crossDockBinId,
                    primaryUOM: formLineItemValues.primaryUOM,
                    primaryUOMAbbr: formLineItemValues.primaryUOMAbbr,
                    isOtherUOM: !!formLineItemValues.otherUOMCount,
                    itemUnitOfMeasureId: formLineItemValues.itemUnitOfMeasureId,
                    otherUOMStr: formLineItemValues.otherUOMStr,
                    otherUOMAbbr: formLineItemValues.otherUOMAbbr,
                    otherUOMCount: formLineItemValues.otherUOMCount,
                    otherUOMUpc: '',
                    status: formLineItemValues.statusOnArrival,
                    statusStr: getReceiptLineItemStatusOnArrivalEnumStr(),
                    images: formLineItemValues.images,
                    receiptLineItemPalletType:
                      formLineItemValues.receiptLineItemPalletType,
                    primaryLength: formLineItemValues.primaryLength,
                    primaryWidth: formLineItemValues.primaryWidth,
                    primaryHeight: formLineItemValues.primaryHeight,
                    primaryWeight: formLineItemValues.primaryWeight,
                    notes: formLineItemValues.notes,
                    lp: '',
                  }
                : row,
            ),
          );
        }
      }

      if (
        pageViewerReceipt.isViewOrEdit() &&
        modalTitle === ModalTitleStatus.Add
      ) {
        const CREATE_PAYLOAD = {
          customerId: parseInt(currentUser.Claim_CustomerId, 10),
          customerLocationId: currentLocationAndFacility.customerLocationId,
          receiptId: queryStringItemReceipts.id,
          itemId: formLineItemValues.itemId,
          weight: formLineItemValues.weight,
          licensePlateType: formLineItemValues.licensePlateType,
          qtyOfPrimaryOUMPerLP: formLineItemValues.qtyOfPrimaryOUMPerLP, // Total Number of Pallets
          length: formLineItemValues.length,
          width: formLineItemValues.width,
          height: formLineItemValues.height,
          palletWeight: formLineItemValues.palletWeight,
          statusOnArrival: formLineItemValues.statusOnArrival,
          reasonStatus: formLineItemValues.reasonStatus,
          lotId: formLineItemValues.lotId,
          lotCreationDate: formLineItemValues.lotCreationDate
            ? moment(formLineItemValues.lotCreationDate).format('MM/DD/YY')
            : '', // //receipt line item table
          lotExpirationDate: formLineItemValues.lotExpirationDate
            ? moment(formLineItemValues.lotExpirationDate).format('MM/DD/YY')
            : '', // //receipt line item table
          qty: formLineItemValues.qty,
          // eaCase: formLineItemValues.eaCase,
          totalCases: formLineItemValues.totalCases,
          eachesPerCase: formLineItemValues.eachesPerCase,
          serialNo: formLineItemValues.serialNo,
          crossDockBinId: formLineItemValues.crossDockBinId,
          itemUnitOfMeasureId: formLineItemValues.itemUnitOfMeasureId,
          updateQtyReceiptLineItemId: singleLineItemRow?.lineIdToUpdateQty,
          images: formLineItemValues.images,
          receiptLineItemPalletType:
            formLineItemValues.receiptLineItemPalletType,
          primaryLength: formLineItemValues.primaryLength,
          primaryWidth: formLineItemValues.primaryWidth,
          primaryHeight: formLineItemValues.primaryHeight,
          primaryWeight: formLineItemValues.primaryWeight,
          notes: formLineItemValues.notes,
        };

        try {
          await createReceiptLineItemV2(CREATE_PAYLOAD);
          snackActions.success('Receipt line item created successfully.');
          setQueryStringItemReceipts((prevState) => ({
            ...prevState,
            form: 'view',
            id: queryStringItemReceipts.id,
          }));
          window.history.replaceState(
            null,
            '',
            `?form=view&id=${queryStringItemReceipts.id}`,
          );
          viewLoadData();
        } catch (err) {
          snackActions.error(err);
          return;
        }
      } else if (
        pageViewerReceipt.isViewOrEdit() &&
        modalTitle === ModalTitleStatus.Edit
      ) {
        const UPDATE_PAYLOAD = {
          receiptLineItemId: formLineItemValues.lineId,
          weight: formLineItemValues.weight,
          licensePlateType: formLineItemValues.licensePlateType,
          qtyOfPrimaryOUMPerLP: formLineItemValues.qtyOfPrimaryOUMPerLP, // Total Number of Pallets
          length: formLineItemValues.length,
          width: formLineItemValues.width,
          height: formLineItemValues.height,
          palletWeight: formLineItemValues.palletWeight,
          lotId: formLineItemValues.lotId,
          lotCreationDate: formLineItemValues.lotCreationDate
            ? moment(formLineItemValues.lotCreationDate).format('MM/DD/YY')
            : '', // //receipt line item table
          lotExpirationDate: formLineItemValues.lotExpirationDate
            ? moment(formLineItemValues.lotExpirationDate).format('MM/DD/YY')
            : '', // //receipt line item table
          statusOnArrival: formLineItemValues.statusOnArrival,
          reasonStatus: formLineItemValues.reasonStatus,
          qty: formLineItemValues.qty.replace(',', ''),
          // eaCase: formLineItemValues.eaCase,
          totalCases: formLineItemValues.totalCases
            ? formLineItemValues.totalCases.replace(',', '')
            : '',
          eachesPerCase: formLineItemValues.eachesPerCase,
          // isSerialNo: formLineItemValues.isSerialNo,
          serialNo: formLineItemValues.serialNo,
          crossDockBinId: formLineItemValues.crossDockBinId,
          primaryUOM: formLineItemValues.primaryUOM,
          primaryUOMAbbr: formLineItemValues.primaryUOMAbbr,
          itemUnitOfMeasureId: formLineItemValues.itemUnitOfMeasureId,
          otherUOMStr: formLineItemValues.otherUOMStr,
          otherUOMAbbr: formLineItemValues.otherUOMAbbr,
          otherUOMCount: formLineItemValues.otherUOMCount,
          images: formLineItemValues.images,
          receiptLineItemPalletType:
            formLineItemValues.receiptLineItemPalletType,
          primaryLength: formLineItemValues.primaryLength,
          primaryWidth: formLineItemValues.primaryWidth,
          primaryHeight: formLineItemValues.primaryHeight,
          primaryWeight: formLineItemValues.primaryWeight,
          notes: formLineItemValues.notes,
        };
        try {
          await updateReceiptLineItem(UPDATE_PAYLOAD);
          snackActions.success('Receipt line item updated successfully.');
          setQueryStringItemReceipts((prevState) => ({
            ...prevState,
            form: 'view',
            id: queryStringItemReceipts.id,
          }));
          window.history.replaceState(
            null,
            '',
            `?form=view&id=${queryStringItemReceipts.id}`,
          );
          viewLoadData();
        } catch (err) {
          snackActions.error(err);
        }
      } else if (
        pageViewerReceipt.isViewOrEdit() &&
        modalTitle === ModalTitleStatus.AddHold
      ) {
        const CREATE_PAYLOAD = {
          customerId: parseInt(currentUser.Claim_CustomerId, 10),
          customerLocationId: currentLocationAndFacility.customerLocationId,
          receiptId: queryStringItemReceipts.id,
          itemId: formLineItemValues.itemId,
          weight: formLineItemValues.weight,
          licensePlateType: formLineItemValues.licensePlateType,
          qtyOfPrimaryOUMPerLP: formLineItemValues.qtyOfPrimaryOUMPerLP, // Total Number of Pallets
          length: formLineItemValues.length,
          width: formLineItemValues.width,
          height: formLineItemValues.height,
          palletWeight: formLineItemValues.palletWeight,
          statusOnArrival: formLineItemValues.statusOnArrival,
          reasonStatus: formLineItemValues.reasonStatus,
          lotId: formLineItemValues.lotId,
          lotCreationDate: formLineItemValues.lotCreationDate
            ? moment(formLineItemValues.lotCreationDate).format('MM/DD/YY')
            : '', // //receipt line item table
          lotExpirationDate: formLineItemValues.lotExpirationDate
            ? moment(formLineItemValues.lotExpirationDate).format('MM/DD/YY')
            : '', // //receipt line item table
          qty: formLineItemValues.qty,
          // eaCase: formLineItemValues.eaCase,
          totalCases: formLineItemValues.totalCases,
          eachesPerCase: formLineItemValues.eachesPerCase,
          serialNo: formLineItemValues.serialNo,
          crossDockBinId: formLineItemValues.crossDockBinId,
          itemUnitOfMeasureId: formLineItemValues.itemUnitOfMeasureId,
          updateQtyReceiptLineItemId: singleLineItemRow?.lineIdToUpdateQty,
          receiptLineItemPalletType:
            formLineItemValues.receiptLineItemPalletType,
          primaryLength: formLineItemValues.primaryLength,
          primaryWidth: formLineItemValues.primaryWidth,
          primaryHeight: formLineItemValues.primaryHeight,
          primaryWeight: formLineItemValues.primaryWeight,
          notes: formLineItemValues.notes,
        };

        try {
          await createReceiptLineItemV2(CREATE_PAYLOAD);
          snackActions.success('Receipt line item created successfully.');
          setQueryStringItemReceipts((prevState) => ({
            ...prevState,
            form: 'view',
            id: queryStringItemReceipts.id,
          }));
          window.history.replaceState(
            null,
            '',
            `?form=view&id=${queryStringItemReceipts.id}`,
          );
          viewLoadData();
        } catch (err) {
          snackActions.error(err);
          return;
        }
      }
      onCloseReceiptLineItemModal();
      setModalTitle(null);
      setSkuValue(null);
      setSingleLineItemRow(null);
      setReasonOnStatusTypeValue(null);
      setFormLineItemValues(initialStateOfLineItemForm);
      setFormLineItemErrors({});
      onCloseReceiptLineItemModal();
    }
  };

  const [isBillingChargeSectionEditable, setIsBillingChargeSectionEditable] =
    useState<boolean>(false);

  const [isBillingChargeSectionBusy, setIsBillingChargeSectionBusy] =
    useState<boolean>(false);

  const handleOnClickDeleteLineItem = async (e?: any) => {
    e.preventDefault();
    const deleteLineItem = formValues.lineItems.filter(
      (i) => i.lineId !== singleLineItemRow.lineId,
    );

    const deleteLineItemUI = lineItemRows.filter(
      (i) => i.lineId !== singleLineItemRow.lineId,
    );

    if (
      queryStringItemReceipts.form === FormQueryStringStatus.Edit ||
      queryStringItemReceipts.form === FormQueryStringStatus.View
    ) {
      const DELETE_PAYLOAD = {
        receiptLineItemId: singleLineItemRow.lineId,
      };
      try {
        await deleteReceiptLineItem(DELETE_PAYLOAD);
        snackActions.success('Receipt line item deleted successfully.');
        setLineItemRows(deleteLineItemUI);
        setQueryStringItemReceipts((prevState) => ({
          ...prevState,
          form: 'view',
          id: queryStringItemReceipts.id,
        }));
        window.history.replaceState(
          null,
          '',
          `?form=view&id=${queryStringItemReceipts.id}`,
        );
        viewLoadData();
      } catch (err) {
        snackActions.error(err);
      }
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        lineItems: deleteLineItem,
      }));

      setLineItemRows(deleteLineItemUI);

      if (pageViewerReceipt.isReturn()) {
        const datas = [...returnOrderItemRows];

        const getData = datas.findIndex(
          (o) => o.lineId === singleLineItemRow.lineId,
        );
        datas.splice(getData, 1);
        setReturnOrderItemRows(datas);
      }
    }

    onCloseConfirmReceiptModal();
    setModalTitle(null);
  };

  const handleOnClickConfirmReceipt = async (
    e: any,
    settingId?: number,
    backToList?: boolean,
    isReadyForPutAwayParam?: boolean,
  ) => {
    e.preventDefault();
    try {
      const payload = {
        receiptId: settingId || queryStringItemReceipts.id,
        isReadyForPutAway: !!isReadyForPutAwayParam,
        createPutAwayTask: true,
      };

      await confirmReceipt(payload);
      snackActions.success(
        `Receipt # ${settingId ?? queryStringItemReceipts.id} has been closed.`,
      );
      onCloseConfirmReceiptModal();
      if (backToList) {
        navigate('/receiving/receipts');
        return;
      }
      if (!isReadyForPutAwayParam) {
        navigate('/put-away');
      } else {
        viewLoadData();
      }
    } catch (err) {
      snackActions.error(err);
    }
  };

  const handleOnClickCreateReceipt = async (e: any) => {
    e.preventDefault();

    setIsSaving(true);

    if (!validate()) {
      setIsSaving(false);
      snackActions.error('Please complete required fields');
    } else if (
      queryStringItemReceipts.form === 'new' &&
      formValues.lineItems.length === 0
    ) {
      setIsSaving(false);
      snackActions.error('Please add line item');
    } else if (queryStringItemReceipts.form === 'new') {
      if (billingAdhocChargesData.length > 0) {
        if (!validateBillingCharge()) {
          setIsSaving(false);
          snackActions.error(`Please complete rate and quantity fields`);
          return;
        }
      }

      const SAVE_PAYLOAD = {
        customerId: parseInt(currentUser.Claim_CustomerId, 10),
        customerFacilityId: formValues.customerFacilityId,
        warehouseCustomerId: formValues.customerId,
        referenceNo: formValues.referenceNo,
        PurchaseOrderId: '',
        purchaseOrder: formValues.purchaseOrder,
        packingListId: '',
        isReturnOrder: formValues?.isReturnOrder,
        returnOrderId: '',
        binId: formValues.location,
        expectedDate: formValues.expectedDate
          ? moment(formValues.expectedDate).format('MM/DD/YY')
          : null,
        arrivalDate: formValues.arrivalDate,
        container: formValues.container,
        carrier: formValues.carrier,
        trailerNo: formValues.trailerNo,
        sealNo: formValues.sealNo,
        billOfLadingNumber: formValues.billOfLadingNumber,
        trackingNo: formValues.trackingNo,
        notes: formValues.notes,
        isNonBillable: formValues?.isNonBillable,
        lineItems: formValues.lineItems,
        billingChargeSectionAdhoc: billingAdhocChargesData,
      };
      if (purchaseOrderExist) {
        SAVE_PAYLOAD.packingListId = formValues.packingListId;
      }
      if (formValues.isReturnOrder === true) {
        SAVE_PAYLOAD.isReturnOrder = true;
        SAVE_PAYLOAD.returnOrderId = formValues?.returnOrderId;
      }

      try {
        const response = await createReceiptV2(SAVE_PAYLOAD);
        setIsSaving(false);
        snackActions.success('Successfully created new receipt.');
        setQueryStringItemReceipts((prevState) => ({
          ...prevState,
          form: 'view',
          id: response.receiptId,
        }));
        window.history.replaceState(
          null,
          '',
          `?form=view&id=${response.receiptId}`,
        );

        setIsBillingChargeSectionEditable(false);
        setIsBillingChargeSectionBusy(false);
        setBillingAdhocChargesData([]);
        if (!inboundData.isQualityControl === true) {
          handleOnClickConfirmReceipt(e, response.receiptId, true);
        }

        viewLoadData();
      } catch (err) {
        snackActions.error(err);
      }
    } else if (pageViewerReceipt.isEdit()) {
      const UPDATE_PAYLOAD_LINEITEM = [];
      lineItemRows.forEach((x) => {
        UPDATE_PAYLOAD_LINEITEM.push({
          receiptLineItemId: x.lineId,
          actual: x.actual,
        });
      });

      const UPDATE_PAYLOAD = {
        receiptId: queryStringItemReceipts.id,
        customerFacilityId: formValues.customerFacilityId,
        warehouseCustomerId: formValues.warehouseCustomerId,
        referenceNo: formValues.referenceNo,
        PurchaseOrderId: '',
        purchaseOrder: formValues?.purchaseOrder,
        packingListId: '',
        binId: formValues.location,
        expectedDate: formValues.expectedDate,
        arrivalDate: formValues.arrivalDate,
        container: formValues.container,
        carrier: formValues.carrier,
        trailerNo: formValues.trailerNo,
        sealNo: formValues.sealNo,
        billOfLadingNumber: formValues.billOfLadingNumber,
        trackingNo: formValues.trackingNo,
        notes: formValues.notes,
        isNonBillable: formValues?.isNonBillable,
        lineItems: UPDATE_PAYLOAD_LINEITEM,
      };

      if (purchaseOrderExist) {
        UPDATE_PAYLOAD.packingListId = formValues.packingListId;
      }

      try {
        await updateReceiptV2(UPDATE_PAYLOAD);
        setIsSaving(false);
        snackActions.success('Successfully updated receipt.');

        setQueryStringItemReceipts((prevState) => ({
          ...prevState,
          form: 'view',
          id: queryStringItemReceipts.id,
        }));
        window.history.replaceState(
          null,
          '',
          `?form=view&id=${queryStringItemReceipts.id}`,
        );
        viewLoadData();
      } catch (err) {
        setIsSaving(false);
        snackActions.error(err);
      }
    }
  };

  const handleOnClickEditBillingCharge = async (e: any) => {
    e.preventDefault();

    setIsBillingChargeSectionEditable(true);
  };

  const handleOnClickCancelBillingCharge = async (e: any) => {
    e.preventDefault();

    viewLoadData().finally(() => {
      validateBillingCharge(null, true);
      setIsBillingChargeSectionEditable(false);
    });
  };

  const handleOnClickSaveBillingCharge = async (e: any) => {
    e.preventDefault();

    if (billingAdhocChargesData.length === 0) {
      return;
    }

    if (!validateBillingCharge()) {
      snackActions.error(`Please complete rate and quantity fields`);
      return;
    }

    setIsBillingChargeSectionBusy(true);
    setIsBillingChargeSectionEditable(false);

    const UPDATE_PAYLOAD = {
      receiptId: queryStringItemReceipts.id,
      warehouseCustomerId: formValues.warehouseCustomerId,
      billingChargeSectionAdhocs: billingAdhocChargesData,
    };

    ManageReceiptBillingChargeSectionAdhoc(UPDATE_PAYLOAD)
      .then(() => {
        setIsBillingChargeSectionBusy(false);
        snackActions.success('Successfully updated billing charge.');

        setQueryStringItemReceipts((prevState) => ({
          ...prevState,
          form: 'view',
          id: queryStringItemReceipts.id,
        }));
        window.history.replaceState(
          null,
          '',
          `?form=view&id=${queryStringItemReceipts.id}`,
        );
        viewLoadData();
      })
      .catch(() => {
        setIsBillingChargeSectionBusy(false);
        snackActions.error('Something went wrong!');
      });
  };

  const handleOnClickPrintAllLP = async (e: any) => {
    e.preventDefault();
    try {
      const data = await getReceiptLineItemToPrintAll(
        queryStringItemReceipts.id,
      );

      const hasLicensePlate = (x) => x?.licensePlateNo !== undefined;
      const licensePlates = data.filter(hasLicensePlate);

      setIdsToPrintLpnFormTemplate(
        licensePlates
          .sort((a, b) => a.licensePlateNo - b.licensePlateNo)
          .map((v) => v.receiptLineItemId),
      );
      onOpenPrintFormTemplateModal();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const handleOnBlurPurchaseOrderIdOrNumber = async (rowData) => {
    const selectedRowData = optionsPurchaseOrderIdAndNumber.filter(
      (f) => f.text === rowData.target.value,
    );

    if (selectedRowData[0] === undefined) {
      setPurchaseOrderExist(false);
      setPurchaseOrderIdOrNumber(rowData.target.value);
      setFormValues((prevState) => ({
        ...prevState,
        purchaseOrder: rowData.target.value,
      }));
    } else {
      setPurchaseOrderExist(true);
      setPurchaseOrderIdOrNumber(selectedRowData[0].text);
      setFormValues((prevState) => ({
        ...prevState,
        packingListId: selectedRowData[0].value,
        purchaseOrder: selectedRowData[0].text,
      }));

      setPoIdLinkedLineitemValue(selectedRowData[0].value);
      onOpenConfirmLinkedPOModal();
    }
  };

  useEffect(() => {
    if (!purchaseOrderIdOrNumber) {
      if (
        formValues.packingListId !== undefined &&
        purchaseOrderOptions.length > 0
      ) {
        const value = purchaseOrderOptions.find(
          (v) => v.id === formValues.packingListId,
        );
        setPurchaseOrderIdOrNumber(value?.label);
        setPurchaseOrderExist(true);
      } else if (formValues.status === ReceiptStatus.Confirmed) {
        setPurchaseOrderIdOrNumber(formValues?.packingListDropDownName);
      } else {
        setPurchaseOrderIdOrNumber(formValues.purchaseOrderNo);
      }
    }
  }, [purchaseOrderOptions]);

  useEffect(() => {
    if (optionFacility.length === 1) {
      optionFacility.forEach((e) => {
        setFormValues((current) => ({
          ...current,
          customerFacilityId: e.value.toString(),
        }));
        setFacilityValue(e);
        onLoadGetLocation(e.value);
        onLoadGetLocationCrossDock(e.value);
      });
    }
  }, [optionFacility]);

  useEffect(() => {
    onLoadWarehouseCustomerLookup();
    const optfacilities = onLoadFacilitiesLookup();
    optfacilities.then((opt) => {
      setOptionFacility(
        opt.map((c: any) => ({
          value: c.value,
          label: c.text,
        })),
      );
    });

    onLoadSkuLookup();
    const optionsLicensePlate = onLoadLicensePlate();
    const optionsStatusOnArrival = onLoadStatusOnArrival();
    optionsLicensePlate.then((opt) => {
      setOptionLicensePlateType(
        opt.map((c: any) => ({
          label: c.name === 'Pallet' ? 'Yes' : c.name,
          value: c.id,
        })),
      );
    });
    optionsStatusOnArrival.then((opt) => {
      setOptionStatusOnArrivalType(
        opt.map((c: any) => ({
          label: c.name,
          value: c.id,
        })),
      );
    });

    viewLoadData();

    // billing section should be editable if new receipt
    if (queryStringItemReceipts.form === 'new') {
      setIsBillingChargeSectionEditable(true);
    }
  }, [currentUser, queryStringItemReceipts, optionSearchSku]);

  useEffect(() => {
    if (isBillingChargeDeleteHasId) {
      callBackForBillingChargeDelete();
    }
  }, [isBillingChargeDeleteHasId]);

  useEffect(() => {
    async function fetchData() {
      if (options?.length > 0 && queryStringItemReceipts?.lineItem) {
        const getResult = await getPackingListToReceiptByPackingListId(
          queryStringItemReceipts?.lineItem,
        );
        const getCustomer = options.find(
          (v) =>
            v.warehouseCustomerId ===
            getResult?.purchaseOrderDetail?.warehouseCustomerId,
        );
        const getFacility = optionFacility.find(
          (v) => v.value === getResult?.purchaseOrderDetail?.customerFacilityId,
        );

        setFacilityValue(getFacility);
        setCustomerValue(getCustomer);
        // set Value
        setFormValues((current) => ({
          ...current,
          customerId: getResult?.purchaseOrderDetail?.warehouseCustomerId,
          customer: getCustomer.name,
          packingListId: queryStringItemReceipts?.lineItem.toString(),
          purchaseOrder: getResult?.purchaseOrderDetail?.purchaseOrderNo,
          customerFacilityId:
            getResult?.purchaseOrderDetail?.customerFacilityId,
        }));

        onLoadPurchaseOrderNumberByWarehouseCustomer(
          getResult.purchaseOrderDetail.warehouseCustomerId,
        );
        // Set PO Selected Packing Line item to Reeceipt Line item
        const datas = [...getResult.packingListLineItems];
        let toAdds = [...lineItemRows];
        datas.forEach((x, index) => {
          if (x?.binItemType === 2 && x?.palletCount > 1) {
            for (let i = 0; i < x?.palletCount; i += 1) {
              const toAdd = {
                lineId: `NEW_${i}_${Math.random()}`,
                itemId: x.itemId,
                lotId: x?.lotId,
                lotNo: x?.lotNo,
                lotCreationDate: x?.lotCreationDate,
                lotExpirationDate: x?.lotExpirationDate,
                sku: x?.sku,
                qty: x?.qty,
                oldQty: '',
                actual: '',
                description: x?.description,
                licensePlateType: x?.binItemType,
                qtyOfPrimaryOUMPerLP: x?.binItemType === 2 ? '1' : '',
                statusOnArrival: 1,
                reasonStatus: '',
                eaCase: x?.eaCase,
                totalCases: x?.totalCases,
                eachesPerCase: x?.eachesPerCase,
                isSerialNo: x?.isSerialNo,
                serialNo: x?.serialNo,
                primaryUOM: x?.primaryUOM,
                primaryUOMStr: x?.primaryUOMStr,
                primaryUOMAbbr: x?.primaryUOMAbbr,
                itemUnitOfMeasureId: x?.itemUnitOfMeasureId,
                otherUOMStr: x?.otherUOMStr,
                otherUOMAbbr: x?.otherUOMAbbr,
                otherUOMCount: x?.otherUOMCount,
                primaryLength: x?.primaryLength,
                primaryWidth: x?.primaryWidth,
                primaryHeight: x?.primaryHeight,
                lp: `NEW`,
                weight: x?.weight,
                notes: x?.notes,
              };
              toAdds = [...toAdds, toAdd];
            }
          } else {
            const toAdd = {
              lineId: `NEW_${index}_${Math.random()}`,
              itemId: x.itemId,
              lotId: x?.lotId,
              lotNo: x?.lotNo,
              lotCreationDate: x?.lotCreationDate,
              lotExpirationDate: x?.lotExpirationDate,
              sku: x?.sku,
              qty: x?.qty,
              oldQty: '',
              actual: '',
              description: x?.description,
              licensePlateType: x?.binItemType,
              qtyOfPrimaryOUMPerLP: x?.binItemType === 2 ? '1' : '',
              statusOnArrival: 1,
              reasonStatus: '',
              eaCase: x?.eaCase,
              totalCases: x?.totalCases,
              eachesPerCase: x?.eachesPerCase,
              isSerialNo: x?.isSerialNo,
              serialNo: x?.serialNo,
              primaryUOM: x?.primaryUOM,
              primaryUOMStr: x?.primaryUOMStr,
              primaryUOMAbbr: x?.primaryUOMAbbr,
              itemUnitOfMeasureId: x?.itemUnitOfMeasureId,
              otherUOMStr: x?.otherUOMStr,
              otherUOMAbbr: x?.otherUOMAbbr,
              otherUOMCount: x?.otherUOMCount,
              primaryLength: x?.primaryLength,
              primaryWidth: x?.primaryWidth,
              primaryHeight: x?.primaryHeight,
              weight: x?.weight,
              notes: x?.notes,
            };
            toAdds = [...toAdds, toAdd];
          }
        });

        setLineItemRows(toAdds);
        setFormValues((prevState) => ({
          ...prevState,
          expectedDate: getResult?.estimatedArrival,
          carrier: getResult?.carrier,
          trackingNo: getResult?.trackingNo,
          lineItems: toAdds,
        }));
      }
    }
    fetchData();
  }, [options]);

  useEffect(() => {
    if (
      queryStringItemReceipts?.lineItem &&
      !purchaseOrderIdOrNumber &&
      purchaseOrderOptions.length > 0
    ) {
      const getPo = purchaseOrderOptions.find(
        (v) => v.id === queryStringItemReceipts?.lineItem,
      );
      setPurchaseOrderIdOrNumber(getPo);
      setPurchaseOrderExist(true);

      setQueryStringItemReceipts((prev) => ({
        ...prev,
        lineItem: null,
      }));
    }
  }, [purchaseOrderOptions]);

  const value = useMemo(
    () => ({
      queryStringItemReceipts,
      setQueryStringItemReceipts,
      options,
      optionsSku,
      setOptionsSku,
      optionFacility,
      optionLocation,
      optionLotNo,
      setOptionLotNo,
      optionSearchSku,
      setOptionSearchSku,
      optionLicensePlateType,
      optionLicensePlateValue,
      setOptionLicensePlateValue,
      optionStatusOnArrivalType,
      optionStatusOnArrivalValue,
      setOptionStatusOnArrivalValue,
      receiptStatus,
      skuValue,
      setSkuValue,
      lotNoValue,
      setLotNoValue,
      customerValue,
      setCustomerValue,
      formValues,
      setFormValues,
      formLineItemValues,
      setFormLineItemValues,
      formErrors,
      setFormErrors,
      formLineItemErrors,
      setFormLineItemErrors,
      lineItemRows,
      singleLineItemRow,
      setSingleLineItemRow,
      setLineItemRows,
      modalTitle,
      setModalTitle,
      printAllOfLPItem,
      setPrintAllOfLPItem,
      handleOnClickPrintAllLP,
      handleOnClickConfirmReceipt,
      handleOnClickCreateReceipt,
      isSaving,
      handleOnClickAddLineItem,
      handleOnClickDeleteLineItem,
      handleOnChange,
      handleOnClickEditConfirmedReceipt,
      validate,
      validateLineItemForm,
      onLoadPurchaseOrderNumberByWarehouseCustomer,
      loadBillingChargesDetailByModule,
      purchaseOrderOptions,
      handleOnBlurPurchaseOrderIdOrNumber,
      setPurchaseOrderIdOrNumber,
      purchaseOrderIdOrNumber,
      setFacilityValue,
      facilityValue,
      locationValue,
      setLocationValue,
      onLoadGetLocation,
      billingAdhocChargesData,
      setBillingAdhocChargesData,
      errorBillingAdhocItemValues,
      handleChangeBillingChargeSection,
      handleRemoveBillingChargeSection,
      viewLoadData,
      dataBillingChargeSectionSelected,
      isBillingChargeDeleteHasId,
      setIsBillingChargeDeleteHasId,
      optionLocationCrossDock,
      onLoadGetLocationCrossDock,
      optionLocationCrossDockValue,
      setOptionLocationCrossDockValue,
      onLoadPurchaseOrderDetailById,
      poIdLinkedLineitemValue,
      setPoIdLinkedLineitemValue,
      setOptionsUOM,
      optionsUOM,
      setUomValue,
      uomValue,
      pageViewerReceipt,
      setOptionStatusOnArrivalType,
      optionsReasonOnStatusType,
      setOptionsReasonOnStatusType,
      reasonOnStatusTypeValue,
      setReasonOnStatusTypeValue,
      numberToPrint,
      setNumberToPrint,
      itemSummary,
      setItemSummary,
      setReceipts,
      receipts,
      setFilteredReceipts,
      filteredReceipts,
      onLoadReceiptsData,
      selectedFilter,
      setSelectedFilter,
      receiptIdToDelete,
      setReceiptIdToDelete,
      inboundData,
      handleUpdateCheckLineItem,
      onClickFinalReceiptSummary,
      optionOrdersForReturn,
      setOptionOrdersForReturn,
      orderReturnValue,
      setOrderReturnValue,
      onLoadOrderDetailsForReturn,
      orderDetails,
      setOrderDetails,
      returnOrderItemRows,
      setReturnOrderItemRows,
      returnQuantityLineItem,
      setReturnQuantityLineItem,
      orderItemRows,
      setOrderItemRows,

      // billing section
      isBillingChargeSectionEditable,
      isBillingChargeSectionBusy,
      handleOnClickEditBillingCharge,
      handleOnClickCancelBillingCharge,
      handleOnClickSaveBillingCharge,
    }),
    [
      orderItemRows,
      returnQuantityLineItem,
      returnOrderItemRows,
      orderDetails,
      orderReturnValue,
      optionOrdersForReturn,
      inboundData,
      receiptIdToDelete,
      selectedFilter,
      filteredReceipts,
      receipts,
      itemSummary,
      options,
      optionsSku,
      optionLotNo,
      optionFacility,
      optionLocation,
      skuValue,
      lotNoValue,
      customerValue,
      formValues,
      formLineItemValues,
      lineItemRows,
      queryStringItemReceipts,
      purchaseOrderOptions,
      purchaseOrderIdOrNumber,
      facilityValue,
      locationValue,
      billingAdhocChargesData,
      errorBillingAdhocItemValues,
      isBillingChargeDeleteHasId,
      optionLocationCrossDock,
      optionLocationCrossDockValue,
      poIdLinkedLineitemValue,
      optionsUOM,
      uomValue,
      pageViewerReceipt,
      optionsReasonOnStatusType,
      reasonOnStatusTypeValue,
      numberToPrint,
    ],
  );

  return (
    <SubpageReceiptsContext.Provider value={value}>
      {children}
    </SubpageReceiptsContext.Provider>
  );
};
