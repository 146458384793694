/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  Reducer,
  useState,
} from 'react';

import { InvoiceTypeEnum, PageLoadStateEnum } from 'common/enums';
import { Action, StateBase } from 'common/models';
import {
  GenerateInvoiceModel,
  InvoiceCreateUpdateModel,
  InvoiceDeleteModel,
  InvoiceDetailGetModel,
  InvoiceLineItemModel,
  WarehouseCustomerInvoiceListGetModel,
} from 'common/models/invoices';
import { snackActions } from 'config/snackbar.js';
import moment from 'moment';
import { useWarehouseCustomerContext } from 'pages/ordercustomers/warehousecustomer';
import { IinvoiceDetails } from 'pages/ordercustomers/warehousecustomer/invoicedialog';
import { useInvoiceDetailFormContext } from 'pages/shared/invoicedetailform/context';
import { InvoiceDetailFormModel } from 'pages/shared/invoicedetailform/models';
import {
  CreateUpdateInvoice,
  DeleteInvoice,
  GenerateInvoiceForBilling,
  GetInvoiceDetail,
  GetWarehouseCustomerInvoiceList,
  updateInvoiceStatus,
} from 'services/api/invoice/invoice.api';
import { getInvoiceByCustomer } from 'services/api/warehousecustomer/warehouseCustomer.api';
import { AuthContext } from 'store/contexts/AuthContext';

import { InvoiceDetailFormModal, InvoiceError, InvoiceModal } from '../models';

function getFirstDayOfMonth(year, month) {
  return new Date(year, month, 1);
}
function getLastDayOfMonth(year, month) {
  return new Date(year, month + 1, 0);
}

const date = new Date();
const firstDay = getFirstDayOfMonth(date.getFullYear(), date.getMonth());
const lastDayCurrentMonth = getLastDayOfMonth(
  date.getFullYear(),
  date.getMonth(),
);

const INITIAL_STATE_INVOICEMODAL_INVOICEERROR: InvoiceError = {
  from: '',
  to: '',
};

const INITIAL_STATE_INVOICEMODAL_OPEN: InvoiceModal = {
  invoice: {
    from: moment(firstDay).toDate(),
    to: moment(lastDayCurrentMonth).toDate(),
  },
  invoiceError: { ...INITIAL_STATE_INVOICEMODAL_INVOICEERROR },
  invoiceIsValid: false,
};

type ViewModel = {
  warehouseCustomerId?: number;
  invoices: WarehouseCustomerInvoiceListGetModel[];
  invoiceModal?: InvoiceModal;
  invoiceDetailFormModal?: InvoiceDetailFormModal;
};

const INITIAL_STATE_VM: ViewModel = {
  warehouseCustomerId: null,
  invoices: [],
  invoiceModal: null,
  invoiceDetailFormModal: null,
};

type State = StateBase<ViewModel> & {
  isBusy: boolean;
};

const INITIAL_STATE: State = {
  pageLoadState: PageLoadStateEnum.LOAD_NONE,
  isBusy: false,
  vm: INITIAL_STATE_VM,
};

export enum InvoiceModalStateEnum {
  Generated = 'Generated',
  Edit = 'Edit',
}
enum ACTION_TYPES {
  SET_INITIALSTATE = 'SET_INITIALSTATE',
  PAGE_LOADING_START = 'PAGE_LOADING_START',
  SET_VM = 'SET_VM',
  SET_STATE_ISBUSY = 'SET_STATE_ISBUSY',

  // INVOICE MODAL
  INVOICEMODAL_OPEN = 'INVOICEMODAL_OPEN',
  INVOICEMODAL_CLOSE = 'INVOICEMODAL_CLOSE',
  INVOICEMODAL_GENERATE = 'INVOICEMODAL_GENERATE',
  SET_INVOICEMODAL_INVOICE_FROM = 'SET_INVOICEMODAL_INVOICE_FROM',
  SET_INVOICEMODAL_INVOICE_TO = 'SET_INVOICEMODAL_INVOICE_TO',
  SET_INVOICEMODAL_INVOICE_INVOICEISVALID_TO_FALSE = 'SET_INVOICEMODAL_INVOICE_INVOICEISVALID_TO_FALSE',

  // INVOICE DETAIL FORM MODAL
  INVOICEDETAILFORMMODAL_OPEN = 'INVOICEDETAILFORMMODAL_OPEN',
  INVOICEDETAILFORMMODAL_CLOSE = 'INVOICEDETAILFORMMODAL_CLOSE',
  INVOICEDETAILFORMMODAL_SAVE = 'INVOICEDETAILFORMMODAL_SAVE',
}

function reducer(state: State, action: Action<ACTION_TYPES>) {
  const { type } = action;

  switch (type) {
    case ACTION_TYPES.SET_INITIALSTATE: {
      return { ...INITIAL_STATE };
    }
    case ACTION_TYPES.PAGE_LOADING_START: {
      return { ...state, pageLoadState: PageLoadStateEnum.LOAD_START };
    }
    case ACTION_TYPES.SET_VM: {
      const vm = { ...INITIAL_STATE_VM };
      vm.invoices = action.payload;

      return {
        ...state,
        pageLoadState: PageLoadStateEnum.LOAD_FINISH,
        vm,
      };
    }
    case ACTION_TYPES.SET_STATE_ISBUSY: {
      return {
        ...state,
        isBusy: action.payload,
      } as State;
    }

    // INVOICE MODAL
    case ACTION_TYPES.INVOICEMODAL_OPEN: {
      return {
        ...state,
        vm: {
          ...state.vm,
          invoiceModal: { ...INITIAL_STATE_INVOICEMODAL_OPEN },
        },
      } as State;
    }
    case ACTION_TYPES.INVOICEMODAL_CLOSE: {
      return {
        ...state,
        vm: {
          ...state.vm,
          invoiceModal: null,
        },
      } as State;
    }
    case ACTION_TYPES.INVOICEMODAL_GENERATE: {
      const invoiceError = { ...INITIAL_STATE_INVOICEMODAL_INVOICEERROR };

      if (state.vm.invoiceModal?.invoice?.from === null) {
        invoiceError.from = 'This field is required';
      }

      if (state.vm.invoiceModal?.invoice.to === null) {
        invoiceError.to = 'This field is required';
      }

      const invoiceIsValid = Object.values(invoiceError).every((x) => x === '');

      return {
        ...state,
        vm: {
          ...state.vm,
          invoiceModal: {
            ...state.vm.invoiceModal,
            invoiceError,
            invoiceIsValid,
          },
        },
      } as State;
    }
    case ACTION_TYPES.SET_INVOICEMODAL_INVOICE_FROM: {
      return {
        ...state,
        vm: {
          ...state.vm,
          invoiceModal: {
            ...state.vm.invoiceModal,
            invoice: {
              ...state.vm.invoiceModal.invoice,
              from: action.payload,
            },
          },
        },
      } as State;
    }
    case ACTION_TYPES.SET_INVOICEMODAL_INVOICE_TO: {
      return {
        ...state,
        vm: {
          ...state.vm,
          invoiceModal: {
            ...state.vm.invoiceModal,
            invoice: {
              ...state.vm.invoiceModal.invoice,
              to: action.payload,
            },
          },
        },
      } as State;
    }
    case ACTION_TYPES.SET_INVOICEMODAL_INVOICE_INVOICEISVALID_TO_FALSE: {
      return {
        ...state,
        vm: {
          ...state.vm,
          invoiceModal: {
            ...state.vm.invoiceModal,
            invoice: {
              ...state.vm.invoiceModal.invoice,
              invoiceIsValid: false,
            },
          },
        },
      } as State;
    }

    // INVOICE DETAIL FORM MODAL
    case ACTION_TYPES.INVOICEDETAILFORMMODAL_OPEN: {
      return {
        ...state,
        vm: {
          ...state.vm,
          invoiceDetailFormModal: action.payload,
        },
      } as State;
    }
    case ACTION_TYPES.INVOICEDETAILFORMMODAL_CLOSE: {
      return {
        ...state,
        vm: {
          ...state.vm,
          invoiceDetailFormModal: null,
        },
      } as State;
    }
    case ACTION_TYPES.INVOICEDETAILFORMMODAL_SAVE: {
      return {
        ...state,
      } as State;
    }
    default:
      return state;
  }
}

// MEMO STATE
interface IInvoiceInterfaceTab {
  state: State;
  viewLoadData();
  setIsBusy(isBusy: boolean);

  // INVOICES
  deleteInvoice(invoiceId: number);

  // INVOICE MODAL
  openInvoiceModal: () => void;
  closeInvoiceModal: () => void;
  submitInvoiceModal: () => void;
  setInvoiceFrom(from?: Date);
  setInvoiceTo(to?: Date);
  setInvoiceIsValidToFalse();

  // INVOICE DETAIL FORM MODAL
  closeInvoiceDetailFormModal: () => void;
  saveInvoiceDetailFormModal: () => void;
  editInvoice(invoiceId: number);
  setIsView: React.Dispatch<React.SetStateAction<boolean>>;
  isView: boolean;
  setInvoiceDateDialog: React.Dispatch<React.SetStateAction<IinvoiceDetails>>;
  invoiceDateDialog: IinvoiceDetails;
  handleUpdateInvoiceStatus: (invoiceId: any, status: any) => Promise<void>;
}

type InvoiceInterfaceTabProviderProps = {
  children: React.ReactNode;
};
const InvoiceInterfaceTabContext = createContext<IInvoiceInterfaceTab>(
  {} as IInvoiceInterfaceTab,
);

export const useInvoiceInterfaceTabContext = () =>
  useContext(InvoiceInterfaceTabContext);

export const InvoiceInterfaceTabContextProvider = ({
  children,
}: InvoiceInterfaceTabProviderProps) => {
  const [state, dispatch] = useReducer<Reducer<State, Action<ACTION_TYPES>>>(
    reducer,
    INITIAL_STATE,
  );

  const { currentLocationAndFacility, currentUser } = useContext(AuthContext);

  const { queryString } = useWarehouseCustomerContext();

  const IInvoiceDetailForm = useInvoiceDetailFormContext();

  const [isView, setIsView] = useState(true);
  const [invoiceDateDialog, setInvoiceDateDialog] = useState<IinvoiceDetails>();

  function viewLoadData() {
    const vm: ViewModel = INITIAL_STATE_VM;

    if (queryString?.id !== 'new') {
      GetWarehouseCustomerInvoiceList(
        queryString.id ?? currentUser.Claim_WarehouseCustomerId,
      )
        .then((x: WarehouseCustomerInvoiceListGetModel[]) => {
          dispatch({ type: ACTION_TYPES.SET_VM, payload: x });
        })
        .catch((err) => {
          dispatch({ type: ACTION_TYPES.SET_VM, payload: vm });
          snackActions.error(err);
        });
    } else {
      dispatch({ type: ACTION_TYPES.SET_VM, payload: vm });
    }
  }

  const handleUpdateInvoiceStatus = async (invoiceId, status) => {
    try {
      await updateInvoiceStatus(invoiceId, status).then(() => {
        viewLoadData();
      });
    } catch (err) {
      snackActions.error(err);
    }
  };

  function setIsBusy(isBusy: boolean) {
    dispatch({
      type: ACTION_TYPES.SET_STATE_ISBUSY,
      payload: isBusy,
    });
  }

  // INVOICES
  function deleteInvoice(invoiceId: number) {
    setIsBusy(true);

    const PAYLOAD: InvoiceDeleteModel = {
      invoiceId,
    };

    DeleteInvoice(PAYLOAD)
      .then(() => {
        setIsBusy(false);

        // this will automatically trigger viewLoadData
        dispatch({ type: ACTION_TYPES.PAGE_LOADING_START, payload: null });
      })
      .catch((err) => {
        setIsBusy(false);
        snackActions.error(err);
      });
  }

  function openInvoiceModal() {
    dispatch({
      type: ACTION_TYPES.INVOICEMODAL_OPEN,
      payload: null,
    });
  }

  function closeInvoiceModal() {
    dispatch({
      type: ACTION_TYPES.INVOICEMODAL_CLOSE,
      payload: null,
    });
  }

  function submitInvoiceModal() {
    dispatch({
      type: ACTION_TYPES.INVOICEMODAL_GENERATE,
      payload: null,
    });
  }

  function setInvoiceFrom(from?: Date) {
    dispatch({
      type: ACTION_TYPES.SET_INVOICEMODAL_INVOICE_FROM,
      payload: from,
    });
  }

  function setInvoiceTo(to?: Date) {
    dispatch({
      type: ACTION_TYPES.SET_INVOICEMODAL_INVOICE_TO,
      payload: to,
    });
  }

  function setInvoiceIsValidToFalse() {
    dispatch({
      type: ACTION_TYPES.SET_INVOICEMODAL_INVOICE_INVOICEISVALID_TO_FALSE,
      payload: null,
    });
  }

  function closeInvoiceDetailFormModal() {
    dispatch({
      type: ACTION_TYPES.INVOICEDETAILFORMMODAL_CLOSE,
      payload: null,
    });
  }

  function saveInvoiceDetailFormModal() {
    // this will trigger a state changed at state.vm.invoiceModal?.invoiceIsValid
    // state.vm.invoiceModal?.invoiceIsValid has useEffect where we can trigger the actual save
    IInvoiceDetailForm.validateForm();
  }

  async function editInvoice(invoiceId: number) {
    await getInvoiceByCustomer(invoiceId)
      .then((x) => {
        // use null-coalescing, so we don't get undefined value
        const model: InvoiceDetailFormModel = {
          invoiceId: x.invoiceId,
          invoiceNo: x.invoiceNo ?? null,
          invoiceDate: x.invoiceDate ?? null,
          invoiceStatus: x.status ?? null,
          warehouseCustomerId: x.warehouseCustomerId ?? null,
          date: x.date,
          orderId: x.orderId ?? null,
          invoiceType: x.invoiceType,
          dueDate: x.dueDate ?? null,
          totalQuantity: x?.totalQuantities,
          adjustmentAmount: x.adjustmentAmount ?? null,
          subtotal: x?.subtotal,
          total: x.total ?? null,
          comment: x.comment ?? null,
          lineItems: x.descriptionDetails.map((z: InvoiceLineItemModel) => ({
            invoiceDetailId: z.invoiceDetailId,
            description: z.description,
            quantity: z.quantity,
            amount: z?.amount ? Number(z.amount) : null,
            rate: z.rate,
          })),
          companyName: x.companyName,
          address1: x.address1,
          address2: x.address2,
          city: x.city,
          state: x.state,
          zipCode: x.zipCode,
          billTo: {
            companyName: x.billToCompanyName,
            address1: x.billToAddress1,
            address2: x.billToAddress2,
            city: x.billToCity,
            state: x.billToState,
            zipCode: x.billToZipCode,
          },
          modalState: InvoiceModalStateEnum.Edit,
        };
        IInvoiceDetailForm.setForm(model);

        // dispatch({
        //   type: ACTION_TYPES.INVOICEDETAILFORMMODAL_OPEN,
        //   payload: x,
        // });
      })
      .catch((err) => {
        snackActions.error(err);
      });
  }

  useEffect(() => {
    if (state.vm.invoiceModal?.invoiceIsValid) {
      setIsBusy(true);

      const PAYLOAD: GenerateInvoiceModel = {
        warehouseCustomerId: queryString?.id,
        dateFrom: moment(state.vm.invoiceModal?.invoice?.from).format(
          'MM/DD/YYYY',
        ),
        dateTo: moment(state.vm.invoiceModal?.invoice?.to).format('MM/DD/YYYY'),
      };

      GenerateInvoiceForBilling(PAYLOAD)
        .then((x) => {
          setInvoiceIsValidToFalse();
          setIsBusy(false);

          const responseData = x.data as InvoiceDetailGetModel;

          // use null-coalescing, so we don't get undefined value
          const model: InvoiceDetailFormModel = {
            invoiceId: responseData.invoiceId,
            warehouseCustomerId: responseData.warehouseCustomerId ?? null,
            orderId: null,
            invoiceType: InvoiceTypeEnum.Billing,
            invoiceNo: responseData.invoiceNo,
            invoiceDate: new Date(),
            dueDate: null,
            totalQuantity: responseData.totalQuantities,
            subtotal: responseData.total,
            adjustmentAmount: null,
            total: responseData.total,
            comment: null,
            lineItems: responseData.lineItems.map(
              (z: InvoiceLineItemModel) => ({
                rowId: Math.random(),
                invoiceDetailId: null,
                description: z.description,
                quantity: z.quantity ?? null,
                rate: z.rate ?? null,
                amount: (z.quantity ?? 0) * (z.rate ?? 0),
                isRequired: z.isRequired ?? null,
              }),
            ),
            modalState: InvoiceModalStateEnum.Generated,
          };
          IInvoiceDetailForm.setForm(model);

          dispatch({
            type: ACTION_TYPES.INVOICEMODAL_CLOSE,
            payload: responseData,
          });

          dispatch({
            type: ACTION_TYPES.INVOICEDETAILFORMMODAL_OPEN,
            payload: responseData,
          });
        })
        .catch((err) => {
          setInvoiceIsValidToFalse();
          setIsBusy(false);
          snackActions.error(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.vm.invoiceModal?.invoiceIsValid]);

  useEffect(() => {
    if (IInvoiceDetailForm.state.vm.formIsValid) {
      IInvoiceDetailForm.setIsBusy(true);
      setIsBusy(true);

      const invoiceForm = IInvoiceDetailForm.state.vm.form;

      const PAYLOAD: InvoiceCreateUpdateModel = {
        invoiceId: invoiceForm?.invoiceId,
        warehouseCustomerId: invoiceForm?.warehouseCustomerId,
        orderId: invoiceForm?.orderId,
        invoiceType: invoiceForm?.invoiceType,
        invoiceNo: invoiceForm?.invoiceNo,
        invoiceDate: invoiceForm?.invoiceDate,
        dueDate: invoiceForm?.dueDate,
        subTotal: invoiceForm?.subtotal,
        adjustmentAmount: invoiceForm?.adjustmentAmount,
        total: invoiceForm?.total,
        comment: invoiceForm?.comment,
        dateFrom: state.vm.invoiceModal?.invoice?.from,
        dateTo: state.vm.invoiceModal?.invoice?.to,
        lineItems: invoiceForm?.lineItems,
      };
      CreateUpdateInvoice(PAYLOAD)
        .then(() => {
          IInvoiceDetailForm.setFormIsValidToFalse();
          IInvoiceDetailForm.setIsBusy(false);
          setIsBusy(false);
          closeInvoiceDetailFormModal();

          snackActions.success(`Invoice has been successfully saved.`);

          // this will automatically trigger viewLoadData
          dispatch({ type: ACTION_TYPES.PAGE_LOADING_START, payload: null });
        })
        .catch((err) => {
          IInvoiceDetailForm.setFormIsValidToFalse();
          IInvoiceDetailForm.setIsBusy(false);
          setIsBusy(false);
          closeInvoiceDetailFormModal();

          snackActions.error(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IInvoiceDetailForm.state.vm.formIsValid]);

  // page load
  useEffect(() => {
    if (state.pageLoadState === PageLoadStateEnum.LOAD_NONE) {
      dispatch({ type: ACTION_TYPES.PAGE_LOADING_START, payload: null });
    }
  }, [state.pageLoadState]);

  // load data upon page load
  useEffect(() => {
    if (state.pageLoadState === PageLoadStateEnum.LOAD_START) {
      viewLoadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.pageLoadState, currentLocationAndFacility]);

  // facility change
  useEffect(() => {
    // this will automatically trigger viewLoadData
    dispatch({ type: ACTION_TYPES.PAGE_LOADING_START, payload: null });
  }, [currentLocationAndFacility]);

  const value: IInvoiceInterfaceTab = useMemo(
    () => ({
      state,
      viewLoadData,
      setIsBusy,

      // INVOICES
      deleteInvoice,

      // INVOICE MODAL
      openInvoiceModal,
      closeInvoiceModal,
      submitInvoiceModal,
      setInvoiceFrom,
      setInvoiceTo,
      setInvoiceIsValidToFalse,

      // INVOICE  DETAIL FORM MODAL
      closeInvoiceDetailFormModal,
      saveInvoiceDetailFormModal,
      editInvoice,

      isView,
      setIsView,
      invoiceDateDialog,
      setInvoiceDateDialog,

      handleUpdateInvoiceStatus,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state, isView, invoiceDateDialog],
  );

  return (
    <InvoiceInterfaceTabContext.Provider value={value}>
      {children}
    </InvoiceInterfaceTabContext.Provider>
  );
};
