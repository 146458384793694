import React, { useContext, useState, useEffect, useRef } from 'react';

import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import {
  getPickTicketDetailsFormTemplate,
  getPurchaseOrderDetailsFormTemplate,
  GetPackingListDetailsFormTemplate,
  GetBillofLadingDetailsFormTemplate,
  GetLPNFormTemplate,
  GetPrintAllLPNFormTemplate,
  GetBulkPrintPickTicketDetailsFormTemplate,
  GetBulkPrintPackingSlipFormTemplate,
  getOrderDetailsFormTemplate,
  getPrintOrderListFormTemplate,
  getInvoiceFormTemplate,
} from 'services/api/printoutforms/printoutform.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import PrintIcon from '@mui/icons-material/Print';
import { Button, CircularProgress, Dialog, IconButton } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';

export enum FormTemplateTypeEnum {
  PickTicket = 1,
  BillofLading = 2,
  PackingList = 3,
  PurchaseOrder = 4,
  Invoice = 5,
  PalletLabels = 6,
  OrderDetail = 7,
}

interface PrintFormTemplateProps {
  callBack?: () => void;
  dynamicId?: any;
  preview?: boolean;
  previewId?: any;
  formtemplateTypeId?: number;
  forPalletLabelsIsUseLicense?: boolean;
  isPrintAllLpn?: boolean;
  isComingFromOrder?: boolean;
  isBulkPrintPickTicket?: boolean;
  isBulkPrintPackingSlip?: boolean;
  isPrintOrderList?: boolean;
}

export default React.memo(
  ({
    callBack,
    dynamicId,
    preview,
    previewId,
    formtemplateTypeId,
    forPalletLabelsIsUseLicense,
    isPrintAllLpn,
    isComingFromOrder,
    isBulkPrintPickTicket,
    isBulkPrintPackingSlip,
    isPrintOrderList,
  }: PrintFormTemplateProps) => {
    const [htmlLoadData, setHtmlLoadData] = useState('');
    const [formtemplateTypeInputId, setformtemplateTypeInputId] =
      useState(formtemplateTypeId);

    const {
      isPrintFormTemplateOpen,
      setIdToPrintLpnFormTemplate,
      setIdsToPrintLpnFormTemplate,
      setIsPrintFormTemplateLoading,
      onClosePrintFormTemplateModal,
    } = useContext(GlobalContext);

    const buttonPrinter = useRef<HTMLButtonElement>(undefined);
    const printRef = useRef();

    const handleClosePrintFormTemplateModal = () => {
      setHtmlLoadData('');
      onClosePrintFormTemplateModal();
    };

    const handlePrintPO = async () => {
      window.print();
    };

    const handleDownloadPdf = async () => {
      const element = printRef.current;

      // Use html2canvas to capture the content
      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL('image/png');

      // Create a new jsPDF instance
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('p', 'mm', 'letter');
      const imgWidth = 200;
      const pageHeight = pdf.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      // Add the image to the PDF
      pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(`Order-Details-${Date.now()}.pdf`);
    };

    const onLoadWarehouseCustomerLookup = async () => {
      try {
        if (preview) {
          const result = await getPickTicketDetailsFormTemplate(
            previewId,
            preview,
          );
          const parser = new DOMParser();
          const htmlDoc = parser.parseFromString(
            result.getPickTicketDetailsModelString,
            'text/html',
          );
          return setHtmlLoadData(htmlDoc.body.innerText);
        }

        if (
          formtemplateTypeInputId !== 0 &&
          formtemplateTypeInputId !== undefined &&
          formtemplateTypeInputId !== null
        ) {
          if (formtemplateTypeInputId === FormTemplateTypeEnum.PickTicket) {
            if (isBulkPrintPickTicket) {
              const result = await GetBulkPrintPickTicketDetailsFormTemplate(
                dynamicId,
              );
              const parser = new DOMParser();
              const htmlDoc = parser.parseFromString(
                result.htmlString,
                'text/html',
              );
              setHtmlLoadData(htmlDoc.body.innerText);
            } else {
              const result = await getPickTicketDetailsFormTemplate(
                dynamicId,
                preview,
              );
              const parser = new DOMParser();
              const htmlDoc = parser.parseFromString(
                result.getPickTicketDetailsModelString,
                'text/html',
              );
              setHtmlLoadData(htmlDoc.body.innerText);
            }
          } else if (
            formtemplateTypeInputId === FormTemplateTypeEnum.PurchaseOrder
          ) {
            const result = await getPurchaseOrderDetailsFormTemplate(dynamicId);
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(
              result.getPurchaseOrderDetailsModelString,
              'text/html',
            );
            setHtmlLoadData(htmlDoc.body.innerText);
          } else if (
            formtemplateTypeInputId === FormTemplateTypeEnum.PackingList &&
            forPalletLabelsIsUseLicense !== true
          ) {
            if (isBulkPrintPackingSlip) {
              const result = await GetBulkPrintPackingSlipFormTemplate(
                dynamicId,
              );
              const parser = new DOMParser();
              const htmlDoc = parser.parseFromString(
                result.htmlString,
                'text/html',
              );
              setHtmlLoadData(htmlDoc.body.innerText);
            } else {
              const result = await GetPackingListDetailsFormTemplate(
                dynamicId,
                isComingFromOrder || false,
              );
              const parser = new DOMParser();
              const htmlDoc = parser.parseFromString(
                result.getPackingListDetailsModelString,
                'text/html',
              );
              setHtmlLoadData(htmlDoc.body.innerText);
            }
          } else if (
            formtemplateTypeInputId === FormTemplateTypeEnum.BillofLading
          ) {
            const result = await GetBillofLadingDetailsFormTemplate(dynamicId);
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(
              result.getBillofLadingDetailsModelString,
              'text/html',
            );
            setHtmlLoadData(htmlDoc.body.innerText);
          } else if (
            formtemplateTypeInputId === FormTemplateTypeEnum.PalletLabels
          ) {
            if (isPrintAllLpn) {
              const result = await GetPrintAllLPNFormTemplate(dynamicId);
              const parser = new DOMParser();
              const htmlDoc = parser.parseFromString(
                result.htmlString,
                'text/html',
              );
              setHtmlLoadData(htmlDoc.body.innerText);
            } else {
              const result = await GetLPNFormTemplate(
                dynamicId,
                forPalletLabelsIsUseLicense,
              );
              const parser = new DOMParser();
              const htmlDoc = parser.parseFromString(
                result.htmlString,
                'text/html',
              );
              setHtmlLoadData(htmlDoc.body.innerText);
            }
          } else if (
            formtemplateTypeInputId === FormTemplateTypeEnum.OrderDetail
          ) {
            if (isPrintOrderList) {
              const result = await getPrintOrderListFormTemplate(dynamicId);
              const parser = new DOMParser();
              const htmlDoc = parser.parseFromString(
                result.getOrderDetailsModelString,
                'text/html',
              );
              setHtmlLoadData(htmlDoc.body.innerText);
            } else {
              const result = await getOrderDetailsFormTemplate(dynamicId);
              const parser = new DOMParser();
              const htmlDoc = parser.parseFromString(
                result.getOrderDetailsModelString,
                'text/html',
              );
              setHtmlLoadData(htmlDoc.body.innerText);
            }
          } else if (formtemplateTypeInputId === FormTemplateTypeEnum.Invoice) {
            const result = await getInvoiceFormTemplate(dynamicId);
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(
              result.getInvoiceModelString,
              'text/html',
            );
            setHtmlLoadData(htmlDoc.body.innerText);
          }
        }

        return true;
      } catch (err) {
        return err;
      }
    };
    useEffect(() => {
      onLoadWarehouseCustomerLookup();

      const timeoutId = setTimeout(() => {
        if (formtemplateTypeInputId === FormTemplateTypeEnum.PurchaseOrder) {
          if (isPrintFormTemplateOpen) {
            setIsPrintFormTemplateLoading(false);
            setIdsToPrintLpnFormTemplate(null);
            setIdToPrintLpnFormTemplate(null);
            if (buttonPrinter.current) {
              handleClosePrintFormTemplateModal();
              buttonPrinter.current.click();
            }
          }
        }
      }, 1000);
      return () => clearTimeout(timeoutId);
    }, [isPrintFormTemplateOpen]);

    if (!isPrintFormTemplateOpen) return null;

    return (
      <Dialog
        sx={{
          height: '90% !important',
        }}
        maxWidth={
          formtemplateTypeInputId === FormTemplateTypeEnum.OrderDetail &&
          isPrintOrderList !== true
            ? 'md'
            : 'lg'
        }
        fullWidth
        className="printable"
        open={isPrintFormTemplateOpen}
        onClose={() => handleClosePrintFormTemplateModal()}
      >
        <DialogContent
          sx={{
            backgroundColor: '#ffffff !important',
          }}
        >
          {!htmlLoadData ? (
            <div style={{ textAlignLast: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            <div
              style={{
                margin: '0px',
                padding: '0px',
                width: '100% !important',
              }}
            >
              <style>
                {`  @media print {
                    html, body {
                      height: 100vh !important;
                      margin: 0 !important;
                      padding: 0 !important;
                      overflow: hidden;
                       ${
                         (formtemplateTypeInputId ===
                           FormTemplateTypeEnum.PackingList ||
                           formtemplateTypeInputId ===
                             FormTemplateTypeEnum.PalletLabels) &&
                         `font-size: small !important;`
                       }
                    }
                    body * {
                      visibility: hidden;
                    }

                    .printable * {
                      visibility: visible !important;
                      top: 0;
                      left: 0;
                      padding: 0px;
                      margin: 0px;
                        ${
                          (formtemplateTypeInputId ===
                            FormTemplateTypeEnum.PackingList ||
                            formtemplateTypeInputId ===
                              FormTemplateTypeEnum.PalletLabels) &&
                          `background-color: white !important;`
                        }
                    }
                  
                    .buttonHide{
                     display: none;
                    }
                  }
              `}
              </style>
              {formtemplateTypeInputId === FormTemplateTypeEnum.OrderDetail ? (
                <IconButton
                  ref={buttonPrinter}
                  sx={{
                    float: 'right',
                    textTransform: 'capitalize !important',
                  }}
                  className="buttonHide"
                  type="button"
                  onClick={handlePrintPO}
                  style={{ color: '#3375e0' }}
                >
                  <PrintIcon />
                </IconButton>
              ) : (
                <Button
                  ref={buttonPrinter}
                  sx={{
                    float: 'right',
                    textTransform: 'capitalize !important',
                    display:
                      formtemplateTypeInputId ===
                      FormTemplateTypeEnum.PurchaseOrder
                        ? 'none'
                        : 'block',
                  }}
                  className="buttonHide"
                  variant="contained"
                  size="large"
                  type="button"
                  onClick={handlePrintPO}
                >
                  Print
                </Button>
              )}
              <div
                ref={printRef}
                style={{
                  paddingBottom:
                    formtemplateTypeInputId ===
                      FormTemplateTypeEnum.OrderDetail &&
                    isPrintOrderList !== true
                      ? '50px'
                      : undefined,
                }}
                className="printable"
                dangerouslySetInnerHTML={{
                  __html: htmlLoadData,
                }}
              />
            </div>
          )}
        </DialogContent>
      </Dialog>
    );
  },
);
