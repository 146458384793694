/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useContext, useState } from 'react';

import { PageLoadStateEnum } from 'common/enums';
import { WarehouseCustomerInvoiceListGetModel } from 'common/models/invoices';
import { ModelParameter } from 'common/models/ModelParameter';
import { USDollar } from 'common/utils';
import Card from 'components/card';
import DatePickerInput from 'components/datepicker';
import Input from 'components/input';
import { REPORT_URL } from 'config/constants';
import { snackActions } from 'config/snackbar.js';
import moment from 'moment';
import { decimalValidationHelper } from 'pages/inventory/item/details';
import InvoiceDialog from 'pages/ordercustomers/warehousecustomer/invoicedialog';
import InvoiceEmail from 'pages/ordercustomers/warehousecustomer/invoiceemail';
import {
  InvoiceDetailFormContextProvider,
  useInvoiceDetailFormContext,
} from 'pages/shared/invoicedetailform/context';
import { InvoiceLineItemModel } from 'pages/shared/invoicedetailform/models';
import PrintFormTemplate, {
  FormTemplateTypeEnum,
} from 'pages/shared/printformtemplate';
import { getExternalInvoiceById } from 'services/api/integrations/integrations.api';
import { updateInvoiceStatus } from 'services/api/invoice/invoice.api';
import { getInvoiceByCustomer } from 'services/api/warehousecustomer/warehouseCustomer.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { reportUrlVariable } from 'store/reporturl';
import { DatagridPremium } from 'styles';

import BlockIcon from '@mui/icons-material/Block';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CancelIcon from '@mui/icons-material/Cancel';
import DescriptionIcon from '@mui/icons-material/Description';
import EmailIcon from '@mui/icons-material/Email';
import PrintIcon from '@mui/icons-material/Print';
import PublishIcon from '@mui/icons-material/Publish';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SaveIcon from '@mui/icons-material/Save';
import {
  Typography,
  Grid as MUIGrid,
  Button,
  Box,
  Link,
  TextField,
  InputAdornment,
  Divider,
  Chip,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  GridActionsCellItem,
  GridColumns,
  GridRowParams,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';

import { useWarehouseCustomerContext } from '../..';
import {
  InvoiceInterfaceTabContextProvider,
  useInvoiceInterfaceTabContext,
} from './context';
import GenerateInvoiceModal from './modals/generateinvoicemodal';
import InvoiceDetailFormModal from './modals/invoicedetailformmodal';
import VoidConfirmationModal from './modals/void-confirmation-modal';

export enum InvoiceStatusEnum {
  Order = 1,
  Billing = 2,
}

function CustomToolbar() {
  const { isWarehouseCustomerAccount } = useContext(AuthContext);
  const { onOpenBillingReportModal } = useContext(GlobalContext);
  const { queryString } = useWarehouseCustomerContext();

  const { openInvoiceModal } = useInvoiceInterfaceTabContext();

  return (
    <GridToolbarContainer>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid
          item
          sm={12}
          md={5}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ color: 'black', padding: '0px 10px' }}
            fontWeight="bold"
          >
            Invoices
          </Typography>
          <GridToolbarFilterButton />
          <GridToolbarExport />
        </MUIGrid>

        <MUIGrid item sm={12} md={7}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!isWarehouseCustomerAccount && (
              <>
                <Button
                  sx={{
                    marginRight: '8px',
                    height: '40px',
                    textTransform: 'none',
                  }}
                  variant="outlined"
                  size="small"
                  disabled={queryString?.id === 'new'}
                  onClick={() => {
                    openInvoiceModal();
                  }}
                  startIcon={<DescriptionIcon />}
                >
                  Generate Invoice
                </Button>
                <Button
                  sx={{
                    marginRight: '8px',
                    height: '40px',
                    textTransform: 'none',
                  }}
                  variant="outlined"
                  size="small"
                  disabled={queryString?.id === 'new'}
                  onClick={() => onOpenBillingReportModal()}
                  startIcon={<ReceiptIcon />}
                >
                  Billing Report
                </Button>
              </>
            )}

            <GridToolbarQuickFilter
              variant="outlined"
              size="small"
              sx={{ width: '30%', borderRadius: '14px' }}
            />
          </Box>
        </MUIGrid>
      </MUIGrid>
    </GridToolbarContainer>
  );
}

function InvoicePreviewHeader() {
  const {
    setIsInvoiceSendEmail,
    setisDeleteInvoiceModalOpen,
    setIsSubmitInvoiceModalOpen,
    onOpenPrintFormTemplateModal,
    setIsVoidConfirmationModalOpen,
  } = useContext(GlobalContext);

  const { setIsView } = useInvoiceInterfaceTabContext();

  const { state } = useInvoiceDetailFormContext();

  const [isShowSaveOrCancel, setIsShowSaveOrCancel] = useState(false);

  const handleCancelOnClick = () => {
    setIsView(true);
    setIsShowSaveOrCancel(false);
  };

  const handleEditOnClick = () => {
    setIsView(false);
    setIsShowSaveOrCancel(true);
  };

  const [formTemplateType, setFormTemplateType] =
    useState<FormTemplateTypeEnum | null>(null);

  return (
    <div>
      <MUIGrid
        container
        spacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ paddingTop: '20px', paddingRight: '20px', alignItems: 'center' }}
      >
        <MUIGrid item sm={12} lg={4.5} sx={{ display: 'flex' }}>
          <Typography
            variant="subtitle2"
            sx={{ color: '#1C9DCC' }}
            fontWeight="bold"
          >
            Invoice Preview &nbsp;
          </Typography>
          <Typography variant="subtitle2" fontWeight="bold">
            {state?.vm?.form?.invoiceNo && ` - ${state.vm.form?.invoiceNo}`}
            &nbsp;
            {state.vm.form?.invoiceStatus === 'Draft' && (
              <span style={{ color: '#fb0a0a' }}>
                ({state.vm.form?.invoiceStatus})
              </span>
            )}
            {state.vm.form?.invoiceStatus === 'Submitted' && (
              <span style={{ color: '#13ab13' }}>
                ({state.vm.form?.invoiceStatus})
              </span>
            )}
            {state.vm.form?.invoiceStatus &&
              state.vm.form?.invoiceStatus !== 'Submitted' &&
              state.vm.form?.invoiceStatus !== 'Draft' && (
                <span style={{ color: '#857676' }}>
                  ({state.vm.form?.invoiceStatus})
                </span>
              )}
          </Typography>
        </MUIGrid>
        <MUIGrid item sm={12} lg={7.5} sx={{ textAlignLast: 'right' }}>
          {isShowSaveOrCancel === false ? (
            <>
              <IconButton
                color="primary"
                aria-label="email"
                size="small"
                sx={{
                  marginRight: '8px',
                }}
                title="Email Invoice"
                disabled={
                  !state.vm.form?.invoiceId ||
                  state.vm.form?.invoiceStatus === 'Void'
                }
                onClick={() => setIsInvoiceSendEmail(true)}
              >
                <EmailIcon fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="print"
                color="primary"
                size="small"
                sx={{
                  marginRight: '8px',
                }}
                title="Print Invoice"
                disabled={!state.vm.form?.invoiceId}
                onClick={() => {
                  onOpenPrintFormTemplateModal();
                  setFormTemplateType(FormTemplateTypeEnum.Invoice);
                }}
              >
                <PrintIcon fontSize="small" />
              </IconButton>

              <Button
                sx={{
                  marginRight: '8px',
                  textTransform: 'none',
                }}
                variant="outlined"
                size="small"
                startIcon={<BorderColorIcon />}
                disabled={!state.vm.form?.invoiceId}
                onClick={handleEditOnClick}
              >
                Edit
              </Button>
              {state.vm.form?.invoiceStatus !== 'Draft' &&
                state.vm.form?.invoiceStatus !== 'Void' && (
                  <Button
                    sx={{
                      marginRight: '8px',
                      textTransform: 'none',
                    }}
                    variant="outlined"
                    size="small"
                    disabled={!state.vm.form?.invoiceId}
                    startIcon={<BlockIcon />}
                    onClick={() => setIsVoidConfirmationModalOpen(true)}
                  >
                    Void
                  </Button>
                )}
              {state.vm.form?.invoiceStatus !== 'Submitted' &&
                state.vm.form?.invoiceStatus !== 'Void' && (
                  <>
                    <Button
                      sx={{
                        marginRight: '8px',
                        textTransform: 'none',
                      }}
                      variant="outlined"
                      size="small"
                      disabled={!state.vm.form?.invoiceId}
                      startIcon={<BlockIcon />}
                      onClick={() => setisDeleteInvoiceModalOpen(true)}
                    >
                      Delete
                    </Button>
                    <Button
                      sx={{
                        textTransform: 'none',
                      }}
                      variant="contained"
                      size="small"
                      disabled={!state.vm.form?.invoiceId}
                      startIcon={<PublishIcon />}
                      onClick={() => setIsSubmitInvoiceModalOpen(true)}
                    >
                      Submit
                    </Button>
                  </>
                )}
            </>
          ) : (
            <>
              <Button
                sx={{
                  marginRight: '8px',
                  textTransform: 'none',
                }}
                variant="outlined"
                size="small"
                startIcon={<CancelIcon />}
                onClick={handleCancelOnClick}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  marginRight: '8px',
                  textTransform: 'none',
                }}
                variant="contained"
                size="small"
                startIcon={<SaveIcon />}
                onClick={() => setIsShowSaveOrCancel(false)}
              >
                Save
              </Button>
            </>
          )}
        </MUIGrid>
      </MUIGrid>
      {formTemplateType === FormTemplateTypeEnum.Invoice && (
        <PrintFormTemplate
          preview={false}
          dynamicId={state.vm.form?.invoiceId}
          formtemplateTypeId={formTemplateType}
        />
      )}
    </div>
  );
}

function InvoiceDetailPreview() {
  const {
    state,

    // FORM
    setFormInvoiceNo,
    setFormInvoiceDate,
    setFormAdjustmentAmount,
    setFormComment,
    // LINE ITEM
    addLineItem,
    removeLineItem,
    setLineItemDescription,
    setLineItemAmount,
    setLineItemQuantity,
  } = useInvoiceDetailFormContext();

  const { isView, invoiceDateDialog: data } = useInvoiceInterfaceTabContext();

  return (
    <MUIGrid
      sx={{
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        {isView === false && (
          <>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{
                padding: '30px !important',
                alignItems: 'center',
              }}
            >
              <MUIGrid item xs={6}>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  sx={{ marginBottom: '10px', float: 'left', color: '#0052CC' }}
                >
                  Invoice
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={6}>
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <MUIGrid item xs={5} sx={{ alignSelf: 'center' }}>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      sx={{ color: 'gray' }}
                    >
                      Invoice no.:
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={7}>
                    <TextField
                      placeholder="Invoice No"
                      value={state.vm.form?.invoiceNo}
                      name="invoiceNo"
                      disabled={state.isBusy}
                      onChange={(e) => {
                        setFormInvoiceNo(e.target.value);
                      }}
                      sx={{ width: '100%' }}
                      size="small"
                      required
                      {...(state.vm.formError?.invoiceNo && {
                        error: true,
                        helperText: state.vm.formError?.invoiceNo,
                      })}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={5} sx={{ alignSelf: 'center' }}>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      sx={{ color: 'gray' }}
                    >
                      Invoice date:
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={7}>
                    <DatePickerInput
                      label="Invoice Date"
                      inputFormat="MM/dd/yyyy"
                      value={state.vm.form?.invoiceDate}
                      disabled={state.isBusy}
                      onChange={(e) => {
                        if (e !== null && e !== 'Invalid Date') {
                          setFormInvoiceDate(e);
                        } else {
                          setFormInvoiceDate(null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={false}
                          size="small"
                          required
                          {...(state.vm.formError?.invoiceDate && {
                            error: true,
                            helperText: state.vm.formError?.invoiceDate,
                          })}
                        />
                      )}
                    />
                  </MUIGrid>
                </MUIGrid>
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{
                padding: '20px !important',
                alignItems: 'center',
                marginBottom: 1,
              }}
            >
              {/* header ------------------------------------------------------------ */}
              <MUIGrid item xs={12}>
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <MUIGrid item xs={0.5}>
                    <p style={{ tabSize: '2' }}> </p>
                  </MUIGrid>
                  <MUIGrid item xs={4.2}>
                    <Typography variant="subtitle2" fontWeight="bold">
                      Description
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={2}>
                    <Typography variant="subtitle2" fontWeight="bold">
                      Quantity
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={2.3}>
                    <Typography variant="subtitle2" fontWeight="bold">
                      Rate
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <Typography variant="subtitle2" fontWeight="bold">
                      Total
                    </Typography>
                  </MUIGrid>
                </MUIGrid>
                <hr />
              </MUIGrid>

              {/* lineitem ------------------------------------------------------------ */}
              <MUIGrid item xs={12}>
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ marginBottom: 1 }}
                >
                  {state.vm.form?.lineItems.map(
                    (lineItem: InvoiceLineItemModel, index: number) => (
                      <>
                        <MUIGrid item xs={0.5}>
                          <Typography variant="subtitle1">
                            {index + 1}
                          </Typography>
                        </MUIGrid>
                        <MUIGrid item xs={4.2}>
                          <TextField
                            size="small"
                            sx={{
                              width: '100%',
                              backgroundColor: '#ffffff !important',
                            }}
                            name="description"
                            value={lineItem.description}
                            disabled={state.isBusy}
                            onChange={(e) => {
                              const model: ModelParameter<
                                InvoiceLineItemModel,
                                string,
                                string
                              > = {
                                model: lineItem,
                                key: 'description',
                                value: e.target.value,
                              };

                              setLineItemDescription(model);
                            }}
                            {...(lineItem.error?.description && {
                              error: true,
                              helperText: lineItem.error?.description,
                            })}
                            required
                          />
                        </MUIGrid>
                        <MUIGrid item xs={2}>
                          <Input
                            size="small"
                            type="number"
                            sx={{
                              width: '100%',
                              backgroundColor: '#ffffff !important',
                            }}
                            placeholder=""
                            value={lineItem.quantity}
                            disabled={state.isBusy}
                            onChange={(e) => {
                              if (e.target.value < 0) {
                                e.target.value = 0;
                              }
                              const validateDecimal = decimalValidationHelper(
                                e.target.value,
                              );

                              const model: ModelParameter<
                                InvoiceLineItemModel,
                                string,
                                number
                              > = {
                                model: lineItem,
                                key: 'quantity',
                                value: Number(validateDecimal),
                              };

                              setLineItemQuantity(model);
                            }}
                            {...(lineItem.error?.quantity && {
                              error: true,
                              helperText: lineItem.error?.quantity,
                            })}
                            required
                          />
                        </MUIGrid>
                        <MUIGrid item xs={2.3}>
                          <Input
                            size="small"
                            sx={{
                              width: '100%',
                              backgroundColor: '#ffffff !important',
                            }}
                            placeholder=""
                            type="number"
                            value={lineItem.rate}
                            disabled={state.isBusy}
                            onChange={(e) => {
                              if (e.target.value < 0) {
                                e.target.value = 0;
                              }
                              const validateDecimal = decimalValidationHelper(
                                e.target.value,
                              );

                              const model: ModelParameter<
                                InvoiceLineItemModel,
                                string,
                                number
                              > = {
                                model: lineItem,
                                key: 'rate',
                                value: Number(validateDecimal),
                              };

                              setLineItemAmount(model);
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            {...(lineItem.error?.rate && {
                              error: true,
                              helperText: lineItem.error?.rate,
                            })}
                            required
                          />
                        </MUIGrid>
                        <MUIGrid item xs={2} sx={{ alignContent: 'center' }}>
                          <Typography variant="subtitle2" fontWeight="bold">
                            {USDollar.format(lineItem?.amount ?? 0)}
                          </Typography>
                        </MUIGrid>
                        <MUIGrid item xs={1}>
                          <Button
                            disabled={state.isBusy}
                            onClick={() => removeLineItem(lineItem)}
                          >
                            X
                          </Button>
                        </MUIGrid>
                      </>
                    ),
                  )}
                </MUIGrid>
                <MUIGrid item xs={12} sx={{ padding: '20px 0 0 50px' }}>
                  <Button disabled={state.isBusy} onClick={() => addLineItem()}>
                    Add Item
                  </Button>
                </MUIGrid>
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{
                padding: '0px 60px !important',
                justifyContent: 'right',
              }}
            >
              <MUIGrid item xs={6}>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ alignItems: 'center' }}
                >
                  <MUIGrid item xs={6}>
                    <Typography variant="body2" fontWeight="bold">
                      Subtotal ({state.vm.form?.totalQuantity} Item/s):
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={6}>
                    <Typography
                      sx={{ float: 'right' }}
                      variant="body2"
                      fontWeight="bold"
                    >
                      {state.vm.form?.subtotal}
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={6}>
                    <Typography variant="body2" fontWeight="bold">
                      Adjustment Amount:
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={6}>
                    <Input
                      size="small"
                      placeholder=""
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      type="number"
                      value={state.vm.form?.adjustmentAmount}
                      disabled={state.isBusy}
                      onChange={(e) => {
                        setFormAdjustmentAmount(e.target.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </MUIGrid>
                </MUIGrid>
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{
                padding: '0 60px !important',
                justifyContent: 'right',
              }}
            >
              <MUIGrid item xs={6}>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ alignItems: 'center' }}
                >
                  <MUIGrid item xs={6}>
                    <Typography variant="body2" fontWeight="bold">
                      Total:
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={6}>
                    <Typography
                      sx={{ float: 'right' }}
                      variant="body2"
                      fontWeight="bold"
                    >
                      {state.vm.form?.total ?? `$0.00`}
                    </Typography>
                  </MUIGrid>
                </MUIGrid>
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{
                padding: '30px !important',
                alignItems: 'center',
              }}
            >
              <MUIGrid item xs={12}>
                <Typography variant="body2" fontWeight="bold">
                  Other Comments
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={12}>
                <TextField
                  autoComplete="off"
                  value={state.vm.form?.comment}
                  disabled={state.isBusy}
                  onChange={(e) => {
                    setFormComment(e.target.value);
                  }}
                  multiline
                  minRows={2}
                  sx={{ width: '100%' }}
                  size="small"
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  sx={{ color: '#0052CC', paddingTop: '3%' }}
                >
                  Thank You For Your Business!
                </Typography>
              </MUIGrid>
            </MUIGrid>
          </>
        )}

        {!state.vm.form?.invoiceId && (
          <div
            style={{
              marginTop: 20,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Typography variant="body1" fontWeight="bold">
              No Invoice Found
            </Typography>
          </div>
        )}
        {state.vm.form?.invoiceId && isView === true && (
          <table width="100%" style={{ padding: '15px' }}>
            <tr>
              <td width="50%">
                <p
                  style={{
                    fontSize: '40px',
                    color: 'rgb(8 20 165)',
                  }}
                >
                  <b>Invoice</b>
                </p>
              </td>
              <td align="right">
                <table width="70%">
                  <tr>
                    <td>
                      <Typography style={{ color: 'GrayText' }}>
                        Invoice no.:
                      </Typography>
                    </td>
                    <td align="right">
                      <p>{state.vm.form?.invoiceNo} </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography style={{ color: 'GrayText' }}>
                        Invoice date:
                      </Typography>
                    </td>
                    <td align="right">
                      <p>
                        {state.vm.form?.invoiceDate
                          ? moment(state.vm.form?.invoiceDate).format(
                              'MM/DD/YYYY',
                            )
                          : ''}{' '}
                      </p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td width="50%">
                <br />
                <table width="100%">
                  <tr>
                    <td>
                      <Typography
                        style={{ fontSize: '20px', fontWeight: 'bold' }}
                      >
                        {state.vm.form?.companyName}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>
                        {state.vm.form?.address1} {state.vm.form?.address2}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>
                        {state.vm.form?.city} {state.vm.form?.state}{' '}
                        {state.vm.form?.zipCode}
                      </Typography>
                    </td>
                  </tr>
                </table>
              </td>
              <td align="right">
                <br />
                <table width="70%">
                  <tr>
                    <td>
                      <Typography
                        style={{ fontSize: '18px', fontWeight: 'bold' }}
                      >
                        BILL TO
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>
                        {state.vm.form?.billTo?.companyName}{' '}
                        {state.vm.form?.billTo?.address1}
                        {state.vm.form?.billTo?.address2}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>
                        {state.vm.form?.billTo?.city}
                        {state.vm.form?.billTo?.state}
                        {state.vm.form?.billTo?.zipCode}
                      </Typography>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td colSpan={2}>
                <br />
                <br />
                <table width="100%" cellSpacing="0">
                  <thead style={{ fontWeight: 'bold' }}>
                    <tr>
                      <td width="40%">Description</td>
                      <td width="20%">Quantity</td>
                      <td width="20%">Rate</td>
                      <td align="right">Total</td>
                    </tr>
                  </thead>
                </table>
                <Divider sx={{ mt: 1, mb: 1, borderWidth: '1.8px' }} />
                {state.vm.form?.lineItems !== undefined &&
                  state.vm.form?.lineItems.length > 0 && (
                    <div>
                      {state.vm.form?.lineItems.map((row, index) => (
                        <>
                          <table width="100%" cellSpacing="0">
                            <tbody>
                              <tr>
                                <td width="40%">{row?.description}</td>
                                <td width="20%">{row?.quantity}</td>
                                <td width="20%">
                                  ${row.rate ? row.rate.toFixed(2) : `0.00`}
                                </td>
                                <td align="right">
                                  $
                                  {row?.amount ? row.amount.toFixed(2) : `0.00`}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <Divider sx={{ mt: 1, mb: 1 }} />
                        </>
                      ))}
                    </div>
                  )}
              </td>
            </tr>
            <tr>
              <td width="50%" />
              <td align="right" valign="top">
                <br />
                <table width="100%">
                  <tr>
                    <td>
                      <b>Subtotal:</b>
                    </td>
                    <td align="right">{state.vm.form?.subtotal}</td>
                  </tr>
                </table>

                <table width="100%">
                  <tr>
                    <td>
                      <b>Adjustment Amount:</b>
                    </td>
                    <td align="right">{state.vm.form?.adjustmentAmount}</td>
                  </tr>
                </table>

                <Divider sx={{ mt: 1, mb: 1, borderWidth: '1.8px' }} />
                <table width="100%">
                  <tr>
                    <td>
                      <b>Total:</b>
                    </td>
                    <td align="right">{state.vm.form?.total}</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td width="50%">
                <br />
                <table width="70%">
                  <tr>
                    <td>
                      <b>Other Comments:</b>
                    </td>
                  </tr>
                  <tr>
                    <td>{state.vm.form?.comment}</td>
                  </tr>
                </table>
                <br />
                <table>
                  <tr>
                    <td>
                      <b style={{ color: '#0052CC' }}>
                        Thank You For Your Business!
                      </b>
                    </td>
                  </tr>
                </table>
              </td>
              <td />
            </tr>
          </table>
        )}
      </Box>
    </MUIGrid>
  );
}

function InvoiceInterface() {
  // const { setInvoiceDateDialog, invoiceDateDialog, queryString } =
  //   useWarehouseCustomerContext();

  const {
    state,
    invoiceDateDialog,
    openInvoiceModal,
    editInvoice,
    deleteInvoice,
    viewLoadData,
    setInvoiceDateDialog,
  } = useInvoiceInterfaceTabContext();

  const { loading, updateLoading } = useContext(GlobalContext);

  const { isWarehouseCustomerAccount } = useContext(AuthContext);

  const onHandleInvoiceView = async (id: number) => {
    editInvoice(id);
    // if (isEmail && isEmail === true) {
    //   setIsInvoiceSendEmail(true);
    // }
  };

  useEffect(() => {
    if (state.pageLoadState !== PageLoadStateEnum.LOAD_FINISH) {
      updateLoading(true);
    } else {
      updateLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, state.pageLoadState]);

  const handleInvoiceBillingReport = (invoiceId) => {
    const url = `${reportUrlVariable(
      REPORT_URL,
    )}/reports/invoice-billing-report?invoiceId=${invoiceId}`;

    window.open(url, '_blank', 'noreferrer');
  };

  const invoicesColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (
        params: GridRowParams<WarehouseCustomerInvoiceListGetModel>,
      ) => [
        // <GridActionsCellItem
        //   label="View"
        //   showInMenu
        //   onClick={() => onHandleInvoiceView(params.row.invoiceId)}
        // />,
        // <GridActionsCellItem
        //   label="Edit"
        //   showInMenu
        //   disabled={isWarehouseCustomerAccount}
        //   onClick={() => editInvoice(params.row.invoiceId)}
        // />,
        // <GridActionsCellItem
        //   label="Delete"
        //   showInMenu
        //   disabled={isWarehouseCustomerAccount}
        //   onClick={() => deleteInvoice(params.row.invoiceId)}
        // />,
        // <GridActionsCellItem
        //   label="Email"
        //   showInMenu
        //   disabled={isWarehouseCustomerAccount}
        //   onClick={() => onHandleInvoiceView(params.row.invoiceId, true)}
        // />,
        // <GridActionsCellItem
        //   label="Void"
        //   showInMenu
        //   disabled={isWarehouseCustomerAccount}
        //   onClick={(e) => handleUpdateInvoiceStatus(params.row.invoiceId, 4)}
        // />,
        // <GridActionsCellItem
        //   label="Submit"
        //   showInMenu
        //   disabled={isWarehouseCustomerAccount}
        //   onClick={(e) => handleUpdateInvoiceStatus(params.row.invoiceId, 1)}
        // />,
        <GridActionsCellItem
          label="View Billing Details"
          showInMenu
          onClick={() => handleInvoiceBillingReport(params.row.invoiceId)}
        />,
      ],
    },
    {
      field: 'invoiceNo',
      minWidth: 120,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Invoice No.</Typography>
        </Box>
      ),
    },
    {
      field: 'date',
      minWidth: 120,
      flex: 1,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Invoice Date</Typography>
        </Box>
      ),
    },
    {
      field: 'billingPeriod',
      minWidth: 130,
      flex: 1,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Billing Period</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{`${moment(params.row?.dateFrom).format(
          'MM/DD/YYYY',
        )} - ${moment(params.row?.dateTo).format('MM/DD/YYYY')}`}</Typography>
      ),
    },
    {
      field: 'total',
      minWidth: 120,
      flex: 1,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Invoice Amount</Typography>
        </Box>
      ),
    },
    {
      field: 'status',
      minWidth: 150,
      flex: 1,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Status</Typography>
        </Box>
      ),
      renderCell: (params) => {
        let backgroundColor;
        switch (params?.row?.status) {
          case 'Submitted':
            backgroundColor = '#13ab13';
            break;
          case 'Draft':
            backgroundColor = '#fb0a0a';
            break;
          default:
            backgroundColor = '#857676';
        }

        return (
          <Chip
            label={params?.row?.status}
            size="small"
            sx={{
              backgroundColor,
              color: 'white',
            }}
          />
        );
      },
    },
    {
      field: 'emailedDate',
      minWidth: 120,
      flex: 1,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Emailed Date</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params.row?.emailedDate &&
            moment(params.row?.emailedDate).format('MM/DD/YYYY')}
        </Typography>
      ),
    },
  ];

  return (
    <>
      {/* <InvoiceDialog data={invoiceDateDialog} /> */}
      <InvoiceEmail />
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item sm={12} md={7}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              border: '0.5px solid #ebeaea',
              margin: '15px',
              marginRight: '0px !important',
              padding: '12px !important',
            }}
          >
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12}>
                <DatagridPremium
                  autoHeight
                  rows={state.vm.invoices}
                  columns={invoicesColumns}
                  density="compact"
                  disableColumnSelector
                  disableDensitySelector
                  hideFooterSelectedRowCount
                  getRowId={() => Math.random()}
                  components={{ Toolbar: CustomToolbar }}
                  initialState={{ pagination: { pageSize: 10 } }}
                  rowsPerPageOptions={[10, 15, 20]}
                  pagination
                  onRowClick={(params) =>
                    onHandleInvoiceView(params.row.invoiceId)
                  }
                  sx={{
                    // pointer cursor on ALL rows
                    '& .MuiDataGrid-row:hover': {
                      cursor: 'pointer',
                    },
                  }}
                />
              </MUIGrid>
            </MUIGrid>
          </Card>
        </MUIGrid>
        <MUIGrid item sm={12} md={5}>
          <InvoicePreviewHeader />
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              border: '0.5px solid #ebeaea',
              margin: '15px',
              marginLeft: '0px !important',
              padding: '12px !important',
              maxHeight: '70vh',
              overflow: 'auto',
            }}
          >
            <InvoiceDetailPreview />
          </Card>
        </MUIGrid>
      </MUIGrid>
    </>
  );
}

function InvoiceInterfaceTab() {
  return (
    <InvoiceDetailFormContextProvider>
      <InvoiceInterfaceTabContextProvider>
        <InvoiceInterface />
        <GenerateInvoiceModal />
        <VoidConfirmationModal />
        <InvoiceDetailFormModal />
      </InvoiceInterfaceTabContextProvider>
    </InvoiceDetailFormContextProvider>
  );
}

export default InvoiceInterfaceTab;
