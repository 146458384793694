import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { IReportInfo } from 'common/interfaces';
import Card from 'components/card';
import CardWithHeader from 'components/card/CardWithHeader';
import DatePickerInput from 'components/datepicker';
import Header from 'components/header';
import { REPORT_URL } from 'config/constants';
import moment from 'moment';
import PrintFormTemplate, {
  FormTemplateTypeEnum,
} from 'pages/shared/printformtemplate';
import { getCustomerSuppliers } from 'services/api/customer/customer.api';
import {
  getPrimaryInventoryUOMLookup,
  getTransactionDropdown,
  getWarehouseCustomerLookup,
} from 'services/api/inventory/inventory-new.api';
import { getItemLookup } from 'services/api/item/item.api';
import { getLotNoByCustomerId } from 'services/api/receipts/receipts-new.api';
import { getSkuLookupWithwarehouseCustomer } from 'services/api/salesorders/salesorders-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { reportUrlVariable } from 'store/reporturl';

import {
  Checkbox,
  Autocomplete,
  TextField,
  Button,
  Box,
  Paper,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Grid as MUIGrid,
  Typography,
} from '@mui/material';

import { MainContainer, ContentContainer } from '../styles';

export enum ReportCategoryTypeEnum {
  InventoryManagement = 'Inventory Reports',
  OrderManagement = 'Order Reports',
  Productivity = 'Productivity Reports',
  Billing = 'Billing Reports',
}

type AutocompleteBase = {
  label?: string;
  value?: string;
};
type CustomerType = AutocompleteBase;
type UomType = AutocompleteBase;
type ReportsContextProviderProps = {
  children: React.ReactNode;
};

const ReportsContext = createContext(
  {} as {
    currentUser: any;
    customerValueSummary: any;
    isNoQtySummary: any;
    selectedDate: any;
    optionWarehouseCustomers: any;
    optionsSupplierCustomers: any;
    optionsProductCustomers: any;
    setCustomerValueSummary: any;
    setIsNoQtySummary: any;
    setSelectedDate: any;
    OnClickReportViewer: any;
    isWarehouseCustomerAccount: any;
    reportTypeValue: any;
    setReportTypeValue: any;
    outboundFrom: any;
    outboundTo: any;
    setOutboundFrom: any;
    setOutboundTo: any;
    paramErrors: any;
    inventoryDetailFrom: any;
    setInventoryDetailFrom: any;
    inventoryDetailTo: any;
    setInventoryDetailTo: any;
    paramInventoryDetailErrors: any;
    setParamInventoryDetailErrors: any;
    setAvgDailyOrderPeriod: any;
    avgDailyOrderPeriod: any;
    setsupplierValue: any;
    supplierValue: any;
    setproductValue: any;
    productValue: any;
    expiresIn?: any;
    setExpiresIn?: any;
    lotNoValue?: any;
    setLotNoValue?: any;
    optionsLotNo: any;
    setOptionsLotNo: any;
    setInboundFrom: React.Dispatch<React.SetStateAction<string>>;
    setInboundTo: React.Dispatch<React.SetStateAction<string>>;
    inboundFrom: string;
    inboundTo: string;
    setIsShowOrderDetails: React.Dispatch<React.SetStateAction<boolean>>;
    isShowOrderDetails: boolean;
    setOptionsProductCustomers: React.Dispatch<
      React.SetStateAction<AutocompleteBase[]>
    >;
    setPrintOrderListErrors: React.Dispatch<any>;
    printOrderListErrors: any;
    setFormTemplateType: React.Dispatch<
      React.SetStateAction<FormTemplateTypeEnum>
    >;
    formTemplateType: FormTemplateTypeEnum;
    setReportOrderListUrl: React.Dispatch<any>;
    reportOrderListUrl: any;
    setTransactionTypeOptions: React.Dispatch<any>;
    transactionTypeOptions: any;
    setTransactionTypeValue: React.Dispatch<any>;
    transactionTypeValue: any;
    setUomTypeOptions: React.Dispatch<any>;
    uomTypeOptions: any;
    setUomTypeValue: React.Dispatch<any>;
    uomTypeValue: any;
  },
);

export const useReportsContext = () => useContext(ReportsContext);

const ReportsContextProvider = ({ children }: ReportsContextProviderProps) => {
  const {
    currentUser,
    isWarehouseCustomerAccount,
    currentLocationAndFacility,
  } = useContext(AuthContext);

  const { onOpenPrintFormTemplateModal } = useContext(GlobalContext);

  const [optionWarehouseCustomers, setOptionsWarehouseCustomers] = useState<
    CustomerType[]
  >([]);
  const [optionsSupplierCustomers, setOptionsSupplierCustomers] = useState<
    CustomerType[]
  >([]);
  const [optionsProductCustomers, setOptionsProductCustomers] = useState<
    CustomerType[]
  >([]);

  const [optionsLotNo, setOptionsLotNo] = useState<CustomerType[]>([]);

  const [reportTypeValue, setReportTypeValue] = useState<IReportInfo>({
    name: 'inventory-summary',
    description: 'Inventory Summary Report',
    allowed: ['Customer'],
    category: ReportCategoryTypeEnum.InventoryManagement,
  });

  const [customerValueSummary, setCustomerValueSummary] =
    useState<CustomerType>(null);
  const [supplierValue, setsupplierValue] = useState<CustomerType>(null);
  const [productValue, setproductValue] = useState<CustomerType>(null);
  const [isNoQtySummary, setIsNoQtySummary] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState('');
  const [outboundFrom, setOutboundFrom] = useState('');
  const [outboundTo, setOutboundTo] = useState('');
  const [inboundFrom, setInboundFrom] = useState('');
  const [inboundTo, setInboundTo] = useState('');

  const [paramErrors, setParamErrors] = useState({});
  const [lotNoValue, setLotNoValue] = useState<CustomerType>(null);

  const [inventoryDetailFrom, setInventoryDetailFrom] = useState('');
  const [inventoryDetailTo, setInventoryDetailTo] = useState('');
  const [paramInventoryDetailErrors, setParamInventoryDetailErrors] = useState(
    {},
  );
  const [printOrderListErrors, setPrintOrderListErrors] = useState(null);
  // replenishment report
  const [avgDailyOrderPeriod, setAvgDailyOrderPeriod] = useState<number>(90);
  const [expiresIn, setExpiresIn] = useState('');
  const [isShowOrderDetails, setIsShowOrderDetails] = useState(false);

  const [formTemplateType, setFormTemplateType] =
    useState<FormTemplateTypeEnum | null>(null);
  const [reportOrderListUrl, setReportOrderListUrl] = useState(null);
  const [transactionTypeOptions, setTransactionTypeOptions] = useState(null);
  const [transactionTypeValue, setTransactionTypeValue] = useState(null);

  const [uomTypeOptions, setUomTypeOptions] = useState(null);
  const [uomTypeValue, setUomTypeValue] = useState(null);

  const onLoadWarehouseCustomerLookup = async () => {
    try {
      return await getWarehouseCustomerLookup(currentUser.Claim_CustomerId, '');
    } catch (err) {
      return err;
    }
  };
  const onLoadSuppliersCustomerLookup = async () => {
    try {
      return await getCustomerSuppliers(currentUser.Claim_CustomerId);
    } catch (err) {
      return err;
    }
  };
  const onLoadItemLookup = async () => {
    try {
      return await getItemLookup(currentUser.Claim_CustomerId, '');
    } catch (err) {
      return err;
    }
  };

  const onLoadGetLotNoByCustomerId = async () => {
    try {
      return await getLotNoByCustomerId();
    } catch (err) {
      return err;
    }
  };
  const onLoadGetTransactionDropdown = async () => {
    try {
      return await getTransactionDropdown();
    } catch (err) {
      return err;
    }
  };

  const onLoadInventoryUOMLookup = async () => {
    try {
      return await getPrimaryInventoryUOMLookup('');
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    const optionsCustomer = onLoadWarehouseCustomerLookup();
    optionsCustomer.then((opt) => {
      setOptionsWarehouseCustomers(
        opt.map((c: any) => ({
          label: c.name,
          value: c.warehouseCustomerId,
        })),
      );
    });

    const optionsSupplier = onLoadSuppliersCustomerLookup();
    optionsSupplier.then((opt) => {
      setOptionsSupplierCustomers(
        opt.map((c: any) => ({
          label: c.name,
          value: c.supplierId,
        })),
      );
    });

    const optionsProduct = onLoadItemLookup();
    optionsProduct.then((opt) => {
      setOptionsProductCustomers(
        opt.map((c: any) => ({
          label: c.sku,
          value: c.itemId,
        })),
      );
    });

    const optionsLotNoResult = onLoadGetLotNoByCustomerId();
    optionsLotNoResult.then((opt) => {
      setOptionsLotNo(
        opt.map((c: any) => ({
          label: c.lotNo,
          value: c.lotId,
        })),
      );
    });

    const optionsTransactionTypeResult = onLoadGetTransactionDropdown();
    optionsTransactionTypeResult.then((opt) => {
      setTransactionTypeOptions(
        opt.map((c: any) => ({
          label: c.text,
          value: c.value,
        })),
      );
    });

    onLoadInventoryUOMLookup().then((opt) => {
      setUomTypeOptions(
        opt.map((c: any) => ({
          label: c.name,
          value: c.id,
        })),
      );
    });
  }, [currentUser.Claim_CustomerId]);

  const OutboundValidation = () => {
    const temp: any = {};

    if (outboundTo) {
      if (!outboundFrom) {
        temp.outboundFrom = 'Please input Outbound From.';
      }
    }

    if (outboundFrom) {
      if (!outboundTo) {
        temp.outboundTo = 'Please input Outbound To.';
      }
    }

    if (outboundFrom && outboundTo) {
      if (
        moment(outboundFrom).format('MM-DD-yyyy') >
        moment(outboundTo).format('MM-DD-yyyy')
      ) {
        temp.outboundTo = 'Outbound From date is greater than Outbound To.';
        setOutboundTo('');
      } else {
        temp.outboundTo = '';
      }
    }
    setParamErrors({ ...temp });
    return Object.values(temp).every((x) => x === '');
  };

  const InboundValidation = () => {
    const temp: any = {};

    if (reportTypeValue.name === 'inventory-inbound-summary') {
      temp.inboundFrom = inboundFrom ? '' : 'Please field is required';
      temp.inboundTo = inboundTo ? '' : 'Please field is required';
    }

    if (inboundFrom && inboundTo) {
      if (
        moment(inboundFrom).format('MM-DD-yyyy') >
        moment(inboundTo).format('MM-DD-yyyy')
      ) {
        temp.inboundTo = 'Date From is greater than Outbound To.';
        setInboundTo('');
      } else {
        temp.inboundTo = '';
      }
    }
    setParamErrors({ ...temp });
    return Object.values(temp).every((x) => x === '');
  };

  const InventoryDetailReportValidation = () => {
    const temp: any = {};

    if (selectedDate === null || selectedDate === '') {
      temp.selectedDate = 'Please input date.';
    }

    setParamErrors({ ...temp });
    return Object.values(temp).every((x) => x === '');
  };

  const InventoryDetailValidation = () => {
    const temp: any = {};

    if (inventoryDetailTo) {
      if (!inventoryDetailFrom) {
        temp.inventoryDetailFrom = 'Please input Date From.';
      }
    }

    if (inventoryDetailFrom) {
      if (!inventoryDetailTo) {
        temp.inventoryDetailTo = 'Please input Date To.';
      }
    }

    if (inventoryDetailFrom && inventoryDetailTo) {
      if (
        moment(inventoryDetailFrom).format('MM-DD-yyyy') >
        moment(inventoryDetailTo).format('MM-DD-yyyy')
      ) {
        temp.inventoryDetailTo = 'Date From is greater than Date To.';
        setInventoryDetailTo('');
      } else {
        temp.inventoryDetailTo = '';
      }
    }
    if (reportTypeValue.name === 'billing-report') {
      if (customerValueSummary === null) {
        temp.customer = 'Please select customer!';
      } else {
        temp.customer = '';
      }
    }

    setParamInventoryDetailErrors({ ...temp });
    return Object.values(temp).every((x) => x === '');
  };

  const printOrderListValidation = () => {
    const temp: any = {};

    if (inventoryDetailTo) {
      if (!inventoryDetailFrom) {
        temp.shipDateFrom = 'Please input Ship Date From.';
      }
    } else {
      temp.shipDateFrom = '';
    }

    if (inventoryDetailFrom) {
      if (!inventoryDetailTo) {
        temp.shipDateTo = 'Please input Ship Date To.';
      }
    } else {
      temp.shipDateTo = '';
    }

    if (inventoryDetailFrom && inventoryDetailTo) {
      if (
        moment(inventoryDetailFrom).format('MM-DD-yyyy') >
        moment(inventoryDetailTo).format('MM-DD-yyyy')
      ) {
        temp.shipDateTo = 'Ship Date From is greater than Ship Date To.';
        setInventoryDetailTo('');
      } else {
        temp.shipDateTo = '';
      }
    }

    if (outboundTo) {
      if (!outboundFrom) {
        temp.orderDateFrom = 'Please input Order Date From.';
      }
    } else {
      temp.orderDateFrom = '';
    }

    if (outboundFrom) {
      if (!outboundTo) {
        temp.orderDateTo = 'Please input Order Date To.';
      }
    } else {
      temp.orderDateTo = '';
    }

    if (outboundFrom && outboundTo) {
      if (
        moment(outboundFrom).format('MM-DD-yyyy') >
        moment(outboundTo).format('MM-DD-yyyy')
      ) {
        temp.orderDateTo =
          'Order Date From date is greater than Order Date To.';
        setOutboundTo('');
      } else {
        temp.orderDateTo = '';
      }
    }

    setPrintOrderListErrors({ ...temp });
    return Object.values(temp).every((x) => x === '');
  };

  const OnClickReportViewer = async () => {
    let UrlStringSummary = '';
    if (!InventoryDetailValidation()) {
      return;
    }

    if (customerValueSummary === null) {
      if (reportTypeValue.name === 'inventory-outbound') {
        if (!OutboundValidation()) {
          return;
        }

        if (outboundFrom && outboundTo) {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&dateFrom=${moment(outboundFrom).format(
            'MM-DD-yyyy',
          )}&dateTo=${moment(outboundTo).format(
            'MM-DD-yyyy',
          )}&isShowOrderDetails=${isShowOrderDetails}`;
        } else {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&isShowOrderDetails=${isShowOrderDetails}`;
        }
      }
      if (reportTypeValue.name === 'inventory-detail') {
        if (!InventoryDetailReportValidation()) {
          return;
        }

        if (selectedDate) {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&facility=${btoa(
            currentLocationAndFacility.customerFacilityId,
          )}&dateTo=${moment(selectedDate).format('MM-DD-yyyy')}`;
        } else {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&facility=${btoa(currentLocationAndFacility.customerFacilityId)}`;
        }
      }
      if (reportTypeValue.name === 'receipt-summary') {
        if (!InventoryDetailValidation()) {
          return;
        }

        if (inventoryDetailFrom && inventoryDetailTo) {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&dateFrom=${moment(inventoryDetailFrom).format(
            'MM-DD-yyyy',
          )}&dateTo=${moment(inventoryDetailTo).format('MM-DD-yyyy')}`;
        } else {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(currentUser.Claim_CustomerId)}`;
        }
        if (productValue) {
          UrlStringSummary += `&itemId=${productValue?.value}`;
        }
      }

      if (reportTypeValue.name === 'activity-report') {
        if (!InventoryDetailValidation()) {
          return;
        }

        if (inventoryDetailFrom && inventoryDetailTo) {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&dateFrom=${moment(inventoryDetailFrom).format(
            'MM-DD-yyyy',
          )}&dateTo=${moment(inventoryDetailTo).format('MM-DD-yyyy')}`;
        } else {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(currentUser.Claim_CustomerId)}`;
        }

        if (transactionTypeValue) {
          UrlStringSummary += `&transactionType=${transactionTypeValue?.label}`;
        }
      }
    } else {
      UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
        reportTypeValue.name
      }/${isNoQtySummary}/${btoa(currentUser.Claim_CustomerId)}/${btoa(
        customerValueSummary.value,
      )}`;

      if (reportTypeValue.name === 'billing-report') {
        if (!InventoryDetailValidation()) {
          return;
        }

        UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
          reportTypeValue.name
        }?customerIdString=${btoa(
          currentUser.Claim_CustomerId,
        )}&warehouseCustomeridString=${btoa(customerValueSummary.value)}`;

        if (inventoryDetailFrom && inventoryDetailTo) {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&warehouseCustomeridString=${btoa(
            customerValueSummary.value,
          )}&from=${moment(inventoryDetailFrom).format(
            'MM-DD-yyyy',
          )}&to=${moment(inventoryDetailTo).format('MM-DD-yyyy')}`;
        }
      }

      if (reportTypeValue.name === 'inventory-outbound') {
        if (!OutboundValidation()) {
          return;
        }

        if (outboundFrom && outboundTo) {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&warehouseCustomeridString=${btoa(
            customerValueSummary.value,
          )}&dateFrom=${moment(outboundFrom).format(
            'MM-DD-yyyy',
          )}&dateTo=${moment(outboundTo).format(
            'MM-DD-yyyy',
          )}&isShowOrderDetails=${isShowOrderDetails}`;
        } else {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&warehouseCustomeridString=${btoa(
            customerValueSummary.value,
          )}&isShowOrderDetails=${isShowOrderDetails}`;
        }
      }

      if (reportTypeValue.name === 'inventory-detail') {
        if (!InventoryDetailValidation()) {
          return;
        }

        if (selectedDate) {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&warehouseCustomeridString=${btoa(
            customerValueSummary.value,
          )}&facility=${btoa(
            currentLocationAndFacility.customerFacilityId,
          )}&dateTo=${moment(selectedDate).format('MM-DD-yyyy')}`;
        } else {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&warehouseCustomeridString=${btoa(
            customerValueSummary.value,
          )}&facility=${btoa(currentLocationAndFacility.customerFacilityId)}`;
        }
      }

      if (reportTypeValue.name === 'receipt-summary') {
        if (!InventoryDetailValidation()) {
          return;
        }

        if (inventoryDetailFrom && inventoryDetailTo) {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&warehouseCustomeridString=${btoa(
            customerValueSummary.value,
          )}&dateFrom=${moment(inventoryDetailFrom).format(
            'MM-DD-yyyy',
          )}&dateTo=${moment(inventoryDetailTo).format('MM-DD-yyyy')}`;
        } else {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&warehouseCustomeridString=${btoa(customerValueSummary.value)}`;
        }
        if (productValue) {
          UrlStringSummary += `&itemId=${productValue?.value}`;
        }
      }

      if (reportTypeValue.name === 'activity-report') {
        if (!InventoryDetailValidation()) {
          return;
        }

        if (inventoryDetailFrom && inventoryDetailTo) {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&warehouseCustomeridString=${btoa(
            customerValueSummary.value,
          )}&dateFrom=${moment(inventoryDetailFrom).format(
            'MM-DD-yyyy',
          )}&dateTo=${moment(inventoryDetailTo).format('MM-DD-yyyy')}`;
        } else {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&warehouseCustomeridString=${btoa(customerValueSummary.value)}`;
        }

        if (transactionTypeValue) {
          UrlStringSummary += `&transactionType=${transactionTypeValue?.label}`;
        }
      }

      if (
        isWarehouseCustomerAccount &&
        reportTypeValue.name === 'receipt-summary'
      ) {
        UrlStringSummary = `${UrlStringSummary}&isWarehouseCustomer=true`;
      }

      if (
        isWarehouseCustomerAccount &&
        reportTypeValue.name === 'inventory-detail'
      ) {
        UrlStringSummary = `${UrlStringSummary}&isWarehouseCustomer=true`;
      }
    }

    if (reportTypeValue.name === 'inventory-summary') {
      UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
        reportTypeValue.name
      }?isNoQty=${isNoQtySummary}&customerIdString=${btoa(
        currentUser.Claim_CustomerId,
      )}`;

      if (customerValueSummary) {
        UrlStringSummary += `&warehouseCustomeridString=${btoa(
          customerValueSummary.value,
        )}`;
      }

      if (isWarehouseCustomerAccount) {
        UrlStringSummary += `${UrlStringSummary}&isWarehouseCustomer=true`;
      }

      if (uomTypeValue) {
        UrlStringSummary += `&itemUom=${uomTypeValue?.value}`;
      }
    }

    if (reportTypeValue.name === 'locations-by-customer') {
      UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
        reportTypeValue.name
      }?customerIdString=${btoa(currentUser.Claim_CustomerId)}`;

      if (!InventoryDetailValidation()) {
        return;
      }

      if (isWarehouseCustomerAccount) {
        UrlStringSummary = `${UrlStringSummary}&warehouseCustomeridString=${btoa(
          currentUser.Claim_WarehouseCustomerId,
        )}`;
      }

      if (inventoryDetailFrom && inventoryDetailTo) {
        UrlStringSummary = `${UrlStringSummary}&dateFrom=${moment(
          inventoryDetailFrom,
        ).format('MM-DD-yyyy')}&dateTo=${moment(inventoryDetailTo).format(
          'MM-DD-yyyy',
        )}`;
      }
    }

    if (reportTypeValue.name === 'replenishment-report') {
      const temp: any = {};

      temp.avgDailyOrderPeriod =
        avgDailyOrderPeriod > 0 ? '' : 'This field is required';

      setParamErrors({ ...temp });

      if (!Object.values(temp).every((x) => x === '')) {
        return;
      }

      UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
        reportTypeValue.name
      }?customerIdString=${btoa(currentUser.Claim_CustomerId)}`;

      if (customerValueSummary) {
        UrlStringSummary += `&warehouseCustomeridString=${btoa(
          customerValueSummary.value,
        )}`;
      }

      if (isWarehouseCustomerAccount) {
        UrlStringSummary += `&warehouseCustomeridString=${btoa(
          currentUser.Claim_WarehouseCustomerId,
        )}`;
      }

      UrlStringSummary += `&avgDailyOrderPeriod=${avgDailyOrderPeriod}`;
    }

    if (reportTypeValue.name === 'purchase-order-report') {
      UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
        reportTypeValue.name
      }?customerIdString=${btoa(currentUser.Claim_CustomerId)}`;

      if (customerValueSummary) {
        UrlStringSummary += `&warehouseCustomeridString=${btoa(
          customerValueSummary.value,
        )}`;
      }
      if (supplierValue) {
        UrlStringSummary += `&supplierId=${btoa(supplierValue.value)}`;
      }

      if (productValue) {
        UrlStringSummary += `&product=${btoa(productValue.value)}`;
      }

      if (!InventoryDetailValidation()) {
        return;
      }

      if (inventoryDetailFrom && inventoryDetailTo) {
        UrlStringSummary += `&dateFrom=${moment(inventoryDetailFrom).format(
          'MM-DD-yyyy',
        )}&dateTo=${moment(inventoryDetailTo).format('MM-DD-yyyy')}`;
      }
    }

    if (reportTypeValue.name === 'cycle-count-report') {
      UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
        reportTypeValue.name
      }?customerIdString=${btoa(currentUser.Claim_CustomerId)}`;

      if (customerValueSummary) {
        UrlStringSummary += `&warehouseCustomeridString=${btoa(
          customerValueSummary.value,
        )}`;
      }

      if (!InventoryDetailValidation()) {
        return;
      }

      if (inventoryDetailFrom && inventoryDetailTo) {
        UrlStringSummary += `&dateFrom=${moment(inventoryDetailFrom).format(
          'MM-DD-yyyy',
        )}&dateTo=${moment(inventoryDetailTo).format('MM-DD-yyyy')}`;
      }
    }

    if (reportTypeValue.name === 'product-expiry-report') {
      UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
        reportTypeValue.name
      }?customerIdString=${btoa(currentUser.Claim_CustomerId)}`;

      if (customerValueSummary) {
        UrlStringSummary += `&warehouseCustomeridString=${btoa(
          customerValueSummary.value,
        )}`;
      }
      if (lotNoValue && lotNoValue.value !== null) {
        UrlStringSummary += `&lotNo=${lotNoValue.value}`;
      }
      if (expiresIn) {
        UrlStringSummary += `&expiresIn=${expiresIn}`;
      }
    }

    if (reportTypeValue.name === 'performance-tracking-report') {
      UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
        reportTypeValue.name
      }?customerIdString=${btoa(currentUser.Claim_CustomerId)}`;

      if (!InventoryDetailValidation()) {
        return;
      }

      if (inventoryDetailFrom && inventoryDetailTo) {
        UrlStringSummary += `&dateFrom=${moment(inventoryDetailFrom).format(
          'MM-DD-yyyy',
        )}&dateTo=${moment(inventoryDetailTo).format('MM-DD-yyyy')}`;
      }
    }

    if (reportTypeValue.name === 'inventory-inbound-summary') {
      if (!InboundValidation()) {
        return;
      }

      UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
        reportTypeValue.name
      }?customerIdString=${btoa(currentUser.Claim_CustomerId)}`;

      if (customerValueSummary) {
        UrlStringSummary += `&warehouseCustomeridString=${btoa(
          customerValueSummary.value,
        )}`;
      }

      if (isWarehouseCustomerAccount) {
        UrlStringSummary = `${UrlStringSummary}&warehouseCustomeridString=${btoa(
          currentUser.Claim_WarehouseCustomerId,
        )}`;
      }

      if (inboundFrom && inboundTo) {
        UrlStringSummary += `&dateFrom=${moment(inboundFrom).format(
          'MM-DD-yyyy',
        )}&dateTo=${moment(inboundTo).format('MM-DD-yyyy')}`;
      }
      if (productValue) {
        UrlStringSummary += `&itemId=${productValue?.value}`;
      }
    }

    if (reportTypeValue.name === 'shipment-cost-report') {
      UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
        reportTypeValue.name
      }?customerIdString=${btoa(currentUser.Claim_CustomerId)}`;

      if (!InventoryDetailValidation()) {
        return;
      }

      if (customerValueSummary) {
        UrlStringSummary += `&warehouseCustomeridString=${btoa(
          customerValueSummary.value,
        )}`;
      }

      if (inventoryDetailFrom && inventoryDetailTo) {
        UrlStringSummary += `&dateFrom=${moment(inventoryDetailFrom).format(
          'MM-DD-yyyy',
        )}&dateTo=${moment(inventoryDetailTo).format('MM-DD-yyyy')}`;
      }
    }

    if (reportTypeValue.name === 'print-order-list') {
      if (!printOrderListValidation()) {
        return;
      }

      UrlStringSummary = `?customerId=${currentUser.Claim_CustomerId}`;

      if (isWarehouseCustomerAccount) {
        UrlStringSummary = `${UrlStringSummary}&warehouseCustomerid=${currentUser.Claim_WarehouseCustomerId}`;
      }
      if (customerValueSummary) {
        UrlStringSummary += `&warehouseCustomerid=${customerValueSummary.value}`;
      }

      if (inventoryDetailFrom && inventoryDetailTo) {
        UrlStringSummary = `${UrlStringSummary}&shipDateFrom=${moment(
          inventoryDetailFrom,
        ).format('MM-DD-yyyy')}&shipDateTo=${moment(inventoryDetailTo).format(
          'MM-DD-yyyy',
        )}`;
      }

      if (outboundFrom && outboundTo) {
        UrlStringSummary = `${UrlStringSummary}&orderDateFrom=${moment(
          outboundFrom,
        ).format('MM-DD-yyyy')}&orderDateTo=${moment(outboundTo).format(
          'MM-DD-yyyy',
        )}`;
      }
      setReportOrderListUrl(UrlStringSummary);
      onOpenPrintFormTemplateModal();
      setFormTemplateType(FormTemplateTypeEnum.OrderDetail);
      return;
    }

    window.open(UrlStringSummary, '_blank', 'noreferrer');
  };

  const value = useMemo(
    () => ({
      expiresIn,
      lotNoValue,
      outboundTo,
      currentUser,
      paramErrors,
      uomTypeValue,
      selectedDate,
      optionsLotNo,
      outboundFrom,
      productValue,
      supplierValue,
      isNoQtySummary,
      uomTypeOptions,
      reportTypeValue,
      formTemplateType,
      inventoryDetailTo,
      reportOrderListUrl,
      isShowOrderDetails,
      inventoryDetailFrom,
      avgDailyOrderPeriod,
      printOrderListErrors,
      customerValueSummary,
      transactionTypeValue,
      transactionTypeOptions,
      optionsProductCustomers,
      optionsSupplierCustomers,
      optionWarehouseCustomers,
      isWarehouseCustomerAccount,
      paramInventoryDetailErrors,
      inboundFrom,
      setInboundFrom,
      inboundTo,
      setInboundTo,
      setOutboundTo,
      setExpiresIn,
      setLotNoValue,
      setUomTypeValue,
      setproductValue,
      setOptionsLotNo,
      setOutboundFrom,
      setSelectedDate,
      setsupplierValue,
      setUomTypeOptions,
      setIsNoQtySummary,
      setReportTypeValue,
      setFormTemplateType,
      OnClickReportViewer,
      setInventoryDetailTo,
      setReportOrderListUrl,
      setIsShowOrderDetails,
      setAvgDailyOrderPeriod,
      setInventoryDetailFrom,
      setTransactionTypeValue,
      setPrintOrderListErrors,
      setCustomerValueSummary,
      setTransactionTypeOptions,
      setOptionsProductCustomers,
      setParamInventoryDetailErrors,
    }),
    [
      expiresIn,
      outboundTo,
      lotNoValue,
      inboundFrom,
      inboundTo,
      paramErrors,
      uomTypeValue,
      productValue,
      outboundFrom,
      selectedDate,
      optionsLotNo,
      supplierValue,
      isNoQtySummary,
      uomTypeOptions,
      reportTypeValue,
      inventoryDetailTo,
      isShowOrderDetails,
      inventoryDetailFrom,
      avgDailyOrderPeriod,
      transactionTypeValue,
      customerValueSummary,
      printOrderListErrors,
      transactionTypeOptions,
      optionsProductCustomers,
      optionWarehouseCustomers,
      isWarehouseCustomerAccount,
      paramInventoryDetailErrors,
      setReportTypeValue,
    ],
  );
  return (
    <ReportsContext.Provider value={value}>{children}</ReportsContext.Provider>
  );
};

const ListReports: IReportInfo[] = [
  {
    name: 'activity-report',
    description: 'Transaction History Report',
    allowed: [],
    category: ReportCategoryTypeEnum.Productivity,
  },
  {
    name: 'billing-report',
    description: 'Billing Report',
    allowed: [],
    category: ReportCategoryTypeEnum.Billing,
  },
  {
    name: 'inventory-summary',
    description: 'Inventory Summary Report',
    allowed: ['Customer'],
    category: ReportCategoryTypeEnum.InventoryManagement,
  },
  {
    name: 'inventory-detail',
    description: 'Inventory Detail Report',
    allowed: ['Customer'],
    category: ReportCategoryTypeEnum.InventoryManagement,
  },
  {
    name: 'inventory-outbound',
    description: 'Inventory Outbound Report',
    allowed: ['Customer'],
    category: ReportCategoryTypeEnum.InventoryManagement,
  },
  {
    name: 'locations-by-customer',
    description: 'Locations By Client Report',
    allowed: ['Customer'],
    category: ReportCategoryTypeEnum.InventoryManagement,
  },
  {
    name: 'performance-tracking-report',
    description: 'Performance Tracking Report',
    allowed: [],
    category: ReportCategoryTypeEnum.Productivity,
  },
  {
    name: 'purchase-order-report',
    description: 'Purchase Order Report',
    allowed: ['Customer'],
    category: ReportCategoryTypeEnum.InventoryManagement,
  },
  {
    name: 'receipt-summary',
    description: 'Receipt Summary Report',
    allowed: ['Customer'],
    category: ReportCategoryTypeEnum.InventoryManagement,
  },
  {
    name: 'replenishment-report',
    description: 'Replenishment Report',
    allowed: ['Customer'],
    category: ReportCategoryTypeEnum.InventoryManagement,
  },
  {
    name: 'cycle-count-report',
    description: 'Cycle Count Report',
    allowed: [],
    category: ReportCategoryTypeEnum.InventoryManagement,
  },
  {
    name: 'product-expiry-report',
    description: 'Product Expiry Report',
    allowed: ['Customer'],
    category: ReportCategoryTypeEnum.InventoryManagement,
  },
  {
    name: 'inventory-inbound-summary',
    description: 'Inventory Inbound Summary',
    allowed: [],
    category: ReportCategoryTypeEnum.InventoryManagement,
  },
  {
    name: 'order-status-report',
    description: 'Order Status Report',
    allowed: [],
    category: ReportCategoryTypeEnum.OrderManagement,
  },
  {
    name: 'shipment-cost-report',
    description: 'Shipment Cost Report',
    allowed: [],
    category: ReportCategoryTypeEnum.OrderManagement,
  },
  {
    name: 'print-order-list',
    description: 'Print Order List',
    allowed: [],
    category: ReportCategoryTypeEnum.OrderManagement,
  },
];

interface IReportListProps {
  activeReport: IReportInfo;
  onSelect: (name: string) => void;
  reports: IReportInfo[];
}

const ReportsList = (props: IReportListProps) => {
  const { activeReport, onSelect, reports } = props;
  const {
    setReportTypeValue,
    setCustomerValueSummary,
    formTemplateType,
    reportOrderListUrl,
  } = useReportsContext();

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reportName = (event.target as HTMLInputElement).value;
    const currentReport = reports.find((r) => r.name === reportName);
    setReportTypeValue(currentReport);

    if (reportName === 'billing-report') {
      setCustomerValueSummary(null);
    }

    onSelect(reportName);
  };
  return (
    <>
      <Card
        sx={{
          marginBottom: '16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          padding: '0px !important',
        }}
      >
        <Box className="kittingBox">
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <MUIGrid item>
              <Typography variant="subtitle1" fontWeight="bold">
                {ReportCategoryTypeEnum.InventoryManagement}
              </Typography>
            </MUIGrid>
          </MUIGrid>
        </Box>

        <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <MUIGrid
            item
            xs={12}
            sx={{
              height: '150px',
              margin: '5px 20px !important',
            }}
          >
            <FormControl>
              <RadioGroup
                sx={{
                  display: 'inline-flex',
                  flexFlow: 'wrap',
                }}
                value={activeReport.name}
                onChange={handleRadioChange}
              >
                {reports
                  .filter(
                    (e) =>
                      e.category === ReportCategoryTypeEnum.InventoryManagement,
                  )
                  .map((report: IReportInfo) => (
                    <FormControlLabel
                      value={report.name}
                      control={<Radio size="small" color="info" />}
                      label={report.description}
                      key={`ky-${report.name}`}
                      sx={{
                        width: { sm: '100%', md: '32%' },
                        marginRight: 0,
                        marginLeft: 0,
                        minHeight: '35px',
                        '& .MuiFormControlLabel-label': {
                          fontSize: '13px',
                        },
                        '& .MuiButtonBase-root': {
                          padding: '0 5px 0 0;',
                          color: '#d6d9dd',
                        },
                      }}
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </MUIGrid>
        </MUIGrid>
      </Card>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={4}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              padding: '0px !important',
            }}
          >
            <Box className="kittingBox">
              <MUIGrid
                container
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <MUIGrid item>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {ReportCategoryTypeEnum.OrderManagement}
                  </Typography>
                </MUIGrid>
              </MUIGrid>
            </Box>
            <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <MUIGrid item xs={12} className="contentCardBox">
                <FormControl>
                  <RadioGroup
                    sx={{
                      display: 'inline-flex',
                      flexFlow: 'wrap',
                    }}
                    value={activeReport.name}
                    onChange={handleRadioChange}
                  >
                    {reports
                      .filter(
                        (e) =>
                          e.category === ReportCategoryTypeEnum.OrderManagement,
                      )
                      .map((report: IReportInfo) => (
                        <FormControlLabel
                          value={report.name}
                          control={<Radio size="small" color="info" />}
                          label={report.description}
                          key={`ky-${report.name}`}
                          sx={{
                            width: { sm: '100%' },
                            marginRight: 0,
                            marginLeft: 0,
                            minHeight: '35px',
                            '& .MuiFormControlLabel-label': {
                              fontSize: '13px',
                            },
                            '& .MuiButtonBase-root': {
                              padding: '0 5px 0 0;',
                              color: '#d6d9dd',
                            },
                          }}
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
              </MUIGrid>
            </MUIGrid>
          </Card>
        </MUIGrid>
        <MUIGrid item xs={4}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              padding: '0px !important',
            }}
          >
            <Box className="kittingBox">
              <MUIGrid
                container
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <MUIGrid item>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {ReportCategoryTypeEnum.Productivity}
                  </Typography>
                </MUIGrid>
              </MUIGrid>
            </Box>
            <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <MUIGrid item xs={12} className="contentCardBox">
                <FormControl>
                  <RadioGroup
                    sx={{
                      display: 'inline-flex',
                      flexFlow: 'wrap',
                    }}
                    value={activeReport.name}
                    onChange={handleRadioChange}
                  >
                    {reports
                      .filter(
                        (e) =>
                          e.category === ReportCategoryTypeEnum.Productivity,
                      )
                      .map((report: IReportInfo) => (
                        <FormControlLabel
                          value={report.name}
                          control={<Radio size="small" color="info" />}
                          label={report.description}
                          key={`ky-${report.name}`}
                          sx={{
                            width: { sm: '100%' },
                            marginRight: 0,
                            marginLeft: 0,
                            minHeight: '35px',
                            '& .MuiFormControlLabel-label': {
                              fontSize: '13px',
                            },
                            '& .MuiButtonBase-root': {
                              padding: '0 5px 0 0;',
                              color: '#d6d9dd',
                            },
                          }}
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
              </MUIGrid>
            </MUIGrid>
          </Card>
        </MUIGrid>
        <MUIGrid item xs={4}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              padding: '0px !important',
            }}
          >
            <Box className="kittingBox">
              <MUIGrid
                container
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <MUIGrid item>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {ReportCategoryTypeEnum.Billing}
                  </Typography>
                </MUIGrid>
              </MUIGrid>
            </Box>
            <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <MUIGrid item xs={12} className="contentCardBox">
                <FormControl>
                  <RadioGroup
                    sx={{
                      display: 'inline-flex',
                      flexFlow: 'wrap',
                    }}
                    value={activeReport.name}
                    onChange={handleRadioChange}
                  >
                    {reports
                      .filter(
                        (e) => e.category === ReportCategoryTypeEnum.Billing,
                      )
                      .map((report: IReportInfo) => (
                        <FormControlLabel
                          value={report.name}
                          control={<Radio size="small" color="info" />}
                          label={report.description}
                          key={`ky-${report.name}`}
                          sx={{
                            width: { sm: '100%' },
                            marginRight: 0,
                            marginLeft: 0,
                            minHeight: '35px',
                            '& .MuiFormControlLabel-label': {
                              fontSize: '13px',
                            },
                            '& .MuiButtonBase-root': {
                              padding: '0 5px 0 0;',
                              color: '#d6d9dd',
                            },
                          }}
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
              </MUIGrid>
            </MUIGrid>
          </Card>
        </MUIGrid>
      </MUIGrid>
      {formTemplateType === FormTemplateTypeEnum.OrderDetail && (
        <PrintFormTemplate
          preview={false}
          dynamicId={reportOrderListUrl}
          formtemplateTypeId={formTemplateType}
          isPrintOrderList
        />
      )}
    </>
  );
};

interface IReportRunnerProps {
  activeReport: IReportInfo;
}

const ReportsRunner = (props: IReportRunnerProps) => {
  const { activeReport } = props;
  const navigate = useNavigate();

  const {
    currentUser,
    customerValueSummary,
    optionWarehouseCustomers,
    setCustomerValueSummary,
    setIsNoQtySummary,
    setSelectedDate,
    inventoryDetailFrom,
    inventoryDetailTo,
    isNoQtySummary,
    selectedDate,
    OnClickReportViewer,
    isWarehouseCustomerAccount,
    reportTypeValue,
    setReportTypeValue,
    outboundFrom,
    outboundTo,
    setInventoryDetailFrom,
    setInventoryDetailTo,
    setOutboundFrom,
    setOutboundTo,
    paramErrors,
    paramInventoryDetailErrors,
    setAvgDailyOrderPeriod,
    avgDailyOrderPeriod,
    optionsSupplierCustomers,
    optionsProductCustomers,
    setsupplierValue,
    supplierValue,
    setproductValue,
    productValue,
    expiresIn,
    setExpiresIn,
    lotNoValue,
    setLotNoValue,
    optionsLotNo,
    isShowOrderDetails,
    setIsShowOrderDetails,
    setOptionsProductCustomers,
    inboundFrom,
    setInboundFrom,
    inboundTo,
    setInboundTo,
    printOrderListErrors,
    transactionTypeOptions,
    transactionTypeValue,
    setTransactionTypeValue,
    uomTypeOptions,
    setUomTypeOptions,
    uomTypeValue,
    setUomTypeValue,
  } = useReportsContext();

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }
  function getLastDayOfMonth(year, month) {
    return new Date(year, month + 1, 0);
  }
  const date = new Date();
  const firstofWeek = date.getDate() - date.getDay();
  const lastofWeek = firstofWeek + 6;

  const firstDay = getFirstDayOfMonth(date.getFullYear(), date.getMonth());
  const lastDayCurrentMonth = getLastDayOfMonth(
    date.getFullYear(),
    date.getMonth(),
  );

  const [customerList, setCustomerList] = useState<CustomerType[]>([]);

  const handleRun = (name: string) => {
    if (name === 'order-status-report') {
      navigate('/reports/order-status-report');
      return;
    }
    OnClickReportViewer();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsNoQtySummary(event.target.checked);
  };

  const onLoadSkuLookupWithwarehouseCustomer = async (warehouseCustomerId) => {
    try {
      const skuLookupFromApi = await getSkuLookupWithwarehouseCustomer(
        currentUser.Claim_CustomerId,
        warehouseCustomerId,
      );
      return skuLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    setReportTypeValue(activeReport);
    setInventoryDetailFrom(firstDay.toLocaleString());
    setInventoryDetailTo(new Date().toLocaleString());
    setOutboundFrom(firstDay.toLocaleString());
    setOutboundTo(new Date().toLocaleString());
    setInboundFrom(firstDay.toLocaleString());
    setInboundTo(new Date().toLocaleString());
    if (isWarehouseCustomerAccount) {
      if (optionWarehouseCustomers.length !== 0) {
        if (optionWarehouseCustomers.length === 1) {
          const result = optionWarehouseCustomers.find(
            (o: any) =>
              o.value === Number(currentUser.Claim_WarehouseCustomerId),
          );
          setCustomerValueSummary(result);
        }
      }
    }
    if (
      activeReport.name === 'billing-report' ||
      activeReport.name === 'cycle-count-report' ||
      activeReport.name === 'shipment-cost-report'
    ) {
      const newOpt = optionWarehouseCustomers.filter((e) => e.value !== 0);
      setCustomerList(newOpt);
      setInventoryDetailFrom(moment(firstDay).format('MM/DD/yyyy'));
      setInventoryDetailTo(moment(lastDayCurrentMonth).format('MM/DD/yyyy'));
    } else {
      setCustomerList(optionWarehouseCustomers);
    }
  }, [
    currentUser.Claim_CustomerId,
    optionWarehouseCustomers,
    activeReport.name,
  ]);

  useEffect(() => {
    if (
      reportTypeValue.name === 'receipt-summary' ||
      activeReport.name === 'inventory-inbound-summary'
    ) {
      const result = onLoadSkuLookupWithwarehouseCustomer(
        customerValueSummary?.value ?? 0,
      );
      result.then((opt) => {
        setOptionsProductCustomers(
          opt.map((c: any) => ({
            label: c.sku,
            value: c.itemId,
          })),
        );
      });
    }
  }, [customerValueSummary]);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: '0px !important',
      }}
    >
      <Box className="kittingBox">
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <MUIGrid item>
            <Typography variant="subtitle1" fontWeight="bold">
              <strong>{activeReport.description}</strong>
            </Typography>
          </MUIGrid>
        </MUIGrid>
      </Box>
      <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid
          item
          xs={12}
          sx={{
            margin: '0px 20px !important;',
            marginBottom: '15px !important',
          }}
        >
          <Box sx={{ flex: 'auto', padding: '15px 0' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
              {(activeReport.name === 'activity-report' ||
                activeReport.name === 'billing-report' ||
                activeReport.name === 'inventory-summary' ||
                activeReport.name === 'inventory-detail' ||
                activeReport.name === 'inventory-outbound' ||
                activeReport.name === 'receipt-summary' ||
                activeReport.name === 'inventory-inbound-summary' ||
                activeReport.name === 'cycle-count-report' ||
                activeReport.name === 'shipment-cost-report' ||
                activeReport.name === 'print-order-list' ||
                activeReport.name === 'replenishment-report' ||
                activeReport.name === 'order-status-report') && (
                <Autocomplete
                  onChange={(event: any, newValue: CustomerType) => {
                    if (newValue !== null) {
                      setCustomerValueSummary(newValue);
                    } else {
                      setCustomerValueSummary(null);
                    }
                  }}
                  id="controllable-states"
                  options={customerList}
                  getOptionLabel={(option: CustomerType) => option.label}
                  value={customerValueSummary}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                      {...(activeReport.name === 'billing-report' && require)}
                      autoComplete="off"
                      id="outlined-required"
                      label="All Clients"
                      size="small"
                      type="text"
                      sx={{ width: '100%' }}
                      error={
                        activeReport.name === 'billing-report' &&
                        paramInventoryDetailErrors.customer
                      }
                      helperText={
                        activeReport.name === 'billing-report' &&
                        paramInventoryDetailErrors.customer
                      }
                    />
                  )}
                  sx={{ width: '50%' }}
                />
              )}
              {activeReport.name === 'purchase-order-report' && (
                <Box sx={{ display: 'flex', gap: '15px' }}>
                  <Autocomplete
                    onChange={(event: any, newValue: CustomerType) => {
                      if (newValue !== null) {
                        setsupplierValue(newValue);
                      } else {
                        setsupplierValue(null);
                      }
                    }}
                    id="controllable-states"
                    options={optionsSupplierCustomers}
                    getOptionLabel={(option: CustomerType) => option.label}
                    value={supplierValue}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                        autoComplete="off"
                        id="outlined-required"
                        label="Supplier"
                        size="small"
                        type="text"
                        sx={{ width: '100%' }}
                      />
                    )}
                    sx={{ flex: 'auto' }}
                  />
                  <Autocomplete
                    onChange={(event: any, newValue: CustomerType) => {
                      if (newValue !== null) {
                        setproductValue(newValue);
                      } else {
                        setproductValue(null);
                      }
                    }}
                    id="controllable-states"
                    options={optionsProductCustomers}
                    getOptionLabel={(option: CustomerType) => option.label}
                    value={productValue}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                        autoComplete="off"
                        id="outlined-required"
                        label="Product"
                        size="small"
                        type="text"
                        sx={{ width: '100%' }}
                      />
                    )}
                    sx={{ flex: 'auto' }}
                  />
                </Box>
              )}
              {activeReport.name === 'inventory-summary' && (
                <>
                  <Box>
                    <Autocomplete
                      onChange={(event: any, newValue: UomType) => {
                        if (newValue !== null) {
                          setUomTypeValue(newValue);
                        } else {
                          setUomTypeValue(null);
                        }
                      }}
                      id="controllable-states"
                      options={uomTypeOptions}
                      getOptionLabel={(option: UomType) => option.label}
                      value={uomTypeValue}
                      isOptionEqualToValue={(option, selected) =>
                        option.value === selected.value
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          autoComplete="off"
                          id="outlined"
                          label="Display UOM"
                          size="small"
                          sx={{ width: '50%' }}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Checkbox
                      color="primary"
                      size="medium"
                      checked={isNoQtySummary}
                      onChange={(e) => handleChange(e)}
                    />
                    Exclude SKUs with zero inventory
                  </Box>
                </>
              )}
              {activeReport.name === 'replenishment-report' && (
                <TextField
                  id="outlined-required"
                  label="Daily Order Period"
                  onChange={(e) => {
                    e.preventDefault();
                    setAvgDailyOrderPeriod(e.target.value);
                  }}
                  onKeyPress={(e) =>
                    !/[0-9.]/.test(e.key) && e.preventDefault()
                  }
                  type="number"
                  value={avgDailyOrderPeriod}
                  size="small"
                  sx={{ width: '50%' }}
                  {...(paramErrors.avgDailyOrderPeriod && {
                    error: true,
                    helperText: paramErrors.avgDailyOrderPeriod,
                  })}
                />
              )}
              {activeReport.name === 'product-expiry-report' && (
                <Box sx={{ display: 'flex', gap: '15px' }}>
                  <Autocomplete
                    onChange={(event: any, newValue: CustomerType) => {
                      if (newValue !== null) {
                        setLotNoValue(newValue);
                      } else {
                        setLotNoValue(null);
                      }
                    }}
                    id="controllable-states"
                    options={optionsLotNo}
                    getOptionLabel={(option: CustomerType) => option.label}
                    value={lotNoValue}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                        autoComplete="off"
                        id="outlined-required"
                        label="Lot #"
                        size="small"
                        type="text"
                        sx={{ width: '100%' }}
                      />
                    )}
                    sx={{ flex: 'auto' }}
                  />
                  <TextField
                    id="outlined-required"
                    label="Expires In:"
                    onChange={(e) => {
                      e.preventDefault();
                      setExpiresIn(e.target.value);
                    }}
                    onKeyPress={(e) =>
                      !/[0-9]/.test(e.key) && e.preventDefault()
                    }
                    type="number"
                    value={expiresIn}
                    size="small"
                    sx={{ flex: 'auto' }}
                  />
                </Box>
              )}
              {activeReport.name === 'inventory-detail' && (
                <Box>
                  <DatePickerInput
                    label="Date*"
                    inputFormat="MM/dd/yyyy"
                    value={selectedDate || null}
                    onChange={(e: any) => setSelectedDate(e)}
                    renderInput={(params: any) => (
                      <TextField
                        disabled
                        {...params}
                        size="small"
                        error={paramErrors.selectedDate}
                        helperText={paramErrors.selectedDate}
                      />
                    )}
                  />
                </Box>
              )}
              {(activeReport.name === 'activity-report' ||
                activeReport.name === 'billing-report' ||
                activeReport.name === 'locations-by-customer' ||
                activeReport.name === 'performance-tracking-report' ||
                activeReport.name === 'purchase-order-report' ||
                activeReport.name === 'receipt-summary' ||
                activeReport.name === 'cycle-count-report' ||
                activeReport.name === 'shipment-cost-report' ||
                activeReport.name === 'print-order-list' ||
                activeReport.name === 'order-status-report') && (
                <Box sx={{ display: 'flex', gap: '15px' }}>
                  <DatePickerInput
                    label={
                      activeReport.name === 'print-order-list'
                        ? 'Ship Date From'
                        : 'Date From'
                    }
                    inputFormat="MM/dd/yyyy"
                    value={inventoryDetailFrom || null}
                    onChange={(e: any) => setInventoryDetailFrom(e)}
                    renderInput={(params: any) => (
                      <TextField
                        disabled
                        {...params}
                        size="small"
                        error={paramInventoryDetailErrors.inventoryDetailFrom}
                        helperText={
                          paramInventoryDetailErrors.inventoryDetailFrom
                        }
                        {...(printOrderListErrors?.shipDateFrom && {
                          error: true,
                          helperText: printOrderListErrors?.shipDateFrom,
                        })}
                      />
                    )}
                  />
                  <DatePickerInput
                    label={
                      activeReport.name === 'print-order-list'
                        ? 'Ship Date To'
                        : 'Date To'
                    }
                    inputFormat="MM/dd/yyyy"
                    value={inventoryDetailTo || null}
                    onChange={(e: any) => setInventoryDetailTo(e)}
                    renderInput={(params: any) => (
                      <TextField
                        disabled
                        {...params}
                        size="small"
                        {...(paramInventoryDetailErrors?.inventoryDetailTo && {
                          error: true,
                          helperText:
                            paramInventoryDetailErrors?.inventoryDetailTo,
                        })}
                        {...(printOrderListErrors?.shipDateTo && {
                          error: true,
                          helperText: printOrderListErrors?.shipDateTo,
                        })}
                      />
                    )}
                  />
                </Box>
              )}
              {(activeReport.name === 'inventory-outbound' ||
                activeReport.name === 'print-order-list') && (
                <Box sx={{ display: 'flex', gap: '15px' }}>
                  <DatePickerInput
                    label={
                      activeReport.name === 'print-order-list'
                        ? 'Order Date From'
                        : 'Outbound From'
                    }
                    inputFormat="MM/dd/yyyy"
                    value={outboundFrom || null}
                    onChange={(e: any) => setOutboundFrom(e)}
                    renderInput={(params: any) => (
                      <TextField
                        disabled
                        {...params}
                        size="small"
                        {...(paramErrors?.outboundFrom && {
                          error: true,
                          helperText: paramErrors?.outboundFrom,
                        })}
                        {...(printOrderListErrors?.orderDateFrom && {
                          error: true,
                          helperText: printOrderListErrors?.orderDateFrom,
                        })}
                      />
                    )}
                  />
                  <DatePickerInput
                    label={
                      activeReport.name === 'print-order-list'
                        ? 'Order Date To'
                        : 'Outbound To'
                    }
                    inputFormat="MM/dd/yyyy"
                    value={outboundTo || null}
                    onChange={(e: any) => setOutboundTo(e)}
                    renderInput={(params: any) => (
                      <TextField
                        disabled
                        {...params}
                        size="small"
                        {...(paramErrors?.outboundTo && {
                          error: true,
                          helperText: paramErrors?.outboundTo,
                        })}
                        {...(printOrderListErrors?.orderDateTo && {
                          error: true,
                          helperText: printOrderListErrors?.orderDateTo,
                        })}
                      />
                    )}
                  />
                </Box>
              )}

              {activeReport.name === 'inventory-outbound' && (
                <Box>
                  <Checkbox
                    color="primary"
                    size="medium"
                    checked={isShowOrderDetails}
                    onChange={(e) => setIsShowOrderDetails(e.target.checked)}
                  />
                  Show Order Details
                </Box>
              )}
              {activeReport.name === 'inventory-inbound-summary' && (
                <Box sx={{ display: 'flex', gap: '15px' }}>
                  <DatePickerInput
                    label="Date From"
                    inputFormat="MM/dd/yyyy"
                    value={inboundFrom || null}
                    onChange={(e: any) => setInboundFrom(e)}
                    renderInput={(params: any) => (
                      <TextField
                        disabled
                        {...params}
                        size="small"
                        error={paramErrors.inboundFrom}
                        required
                        helperText={paramErrors.inboundFrom}
                      />
                    )}
                  />
                  <DatePickerInput
                    label="Date To"
                    inputFormat="MM/dd/yyyy"
                    value={inboundTo || null}
                    onChange={(e: any) => setInboundTo(e)}
                    renderInput={(params: any) => (
                      <TextField
                        disabled
                        {...params}
                        size="small"
                        error={paramErrors.inboundTo}
                        required
                        helperText={paramErrors.inboundTo}
                      />
                    )}
                  />
                </Box>
              )}
              {(activeReport.name === 'receipt-summary' ||
                activeReport.name === 'inventory-inbound-summary') && (
                <Box sx={{ display: 'flex', gap: '15px' }}>
                  <Autocomplete
                    onChange={(event: any, newValue: CustomerType) => {
                      if (newValue !== null) {
                        setproductValue(newValue);
                      } else {
                        setproductValue(null);
                      }
                    }}
                    id="controllable-states"
                    options={optionsProductCustomers}
                    getOptionLabel={(option: CustomerType) => option.label}
                    value={productValue}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                        autoComplete="off"
                        id="outlined-required"
                        label="Items"
                        size="small"
                        type="text"
                      />
                    )}
                    sx={{ width: '50%' }}
                  />
                </Box>
              )}
              {activeReport.name === 'activity-report' && (
                <Autocomplete
                  onChange={(event: any, newValue: CustomerType) => {
                    if (newValue !== null) {
                      setTransactionTypeValue(newValue);
                    } else {
                      setTransactionTypeValue(null);
                    }
                  }}
                  id="controllable-states"
                  options={transactionTypeOptions}
                  getOptionLabel={(option: CustomerType) => option.label}
                  value={transactionTypeValue}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="Transaction Type"
                      size="small"
                      type="text"
                      sx={{ width: '100%' }}
                    />
                  )}
                  sx={{ flex: 'auto' }}
                />
              )}
            </Box>
          </Box>
          <Box>
            <Button
              variant="contained"
              size="small"
              sx={{ float: 'right', textTransform: 'capitalize' }}
              color="info"
              onClick={() => handleRun(activeReport.name)}
            >
              Run Report
            </Button>
          </Box>
        </MUIGrid>
      </MUIGrid>
    </Card>
  );
};

const defReport: IReportInfo = {
  name: '',
  description: '',
  allowed: [],
  category: ReportCategoryTypeEnum.InventoryManagement,
};

const ReportsBox = () => {
  const { isWarehouseCustomerAccount } = useContext(AuthContext);

  const [reports, setReports] = useState<IReportInfo[]>([]);
  const [reportSelected, setReportSelected] = useState(defReport);

  const handleSelectReport = (name: string) => {
    const report = ListReports.find((r) => r.name === name);
    setReportSelected(report);
  };

  useEffect(() => {
    if (isWarehouseCustomerAccount) {
      const customerReports = ListReports.filter((r) =>
        r.allowed.includes('Customer'),
      );
      setReports(customerReports);
      if (customerReports.length > 0) {
        setReportSelected(customerReports[0]);
      }
    } else {
      setReports(ListReports);
      setReportSelected(ListReports[0]);
    }
  }, []);

  return (
    <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <MUIGrid item xs={6}>
        <ReportsList
          activeReport={reportSelected}
          onSelect={handleSelectReport}
          reports={reports}
        />
      </MUIGrid>
      <MUIGrid item xs={6}>
        <ReportsRunner activeReport={reportSelected} />
      </MUIGrid>
    </MUIGrid>
  );
};

const Reports = () => (
  <MainContainer>
    <ReportsContextProvider>
      <Header />
      <ContentContainer>
        <ReportsBox />
      </ContentContainer>
    </ReportsContextProvider>
  </MainContainer>
);

export default React.memo(Reports);
