/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';

import { GetISOIdString, GetRandomString } from 'common/utils';
import { snackActions } from 'config/snackbar.js';
import useQuery from 'helpers/useQuery/useQuery';
import { CustomerType } from 'pages/ordercustomers/warehousecustomer/customer-rules-management';
import {
  FormQueryStringStatus,
  PageViewerType,
  QueryStringType,
} from 'pages/receiving/purchaseordersv2/context';
import { CarrierType, CartonCodeDDLType } from 'pages/sales/salesorder/context';
import { optionStatusTypes } from 'pages/sales/updatestatusmodal';
import { getAllCarrierCartonCodeDDL } from 'services/api/cartoncodes/cartoncodes.api';
import {
  CreateOrderProcessingRule,
  GetDetailOrderProcessingRule,
  getOrderTagsLookup,
  updateRuleOrder,
  GetOrderProcessingRuleViewModel,
} from 'services/api/orderprocessingrule';
import {
  getCustomersLookup,
  getSkuLookupWithwarehouseCustomer,
} from 'services/api/salesorders/salesorders-new.api';
import { getCarrierLookup } from 'services/api/warehousecustomer/warehouseCustomer.api';
import { AuthContext } from 'store/contexts/AuthContext';

import { TriggerRulesModal } from '../models';

export type AutocompleteBase = {
  value?: number;
  label?: string;
};
type CustomerTypeOption = AutocompleteBase;
export type ItemOption = AutocompleteBase;

type OrderProcessingRulesProviderProps = {
  children: React.ReactNode;
};
const OrderProcessingRulesContext = createContext<IOrderProcessingRules>(
  {} as IOrderProcessingRules,
);
export const useOrderProcessingRulesContext = () =>
  useContext(OrderProcessingRulesContext);

export interface IOrderProcessingRulesValues {
  orderProcessingRuleId?: number;
  category: {
    value?: number;
    label?: string;
  };
  ruleName?: string;
  customerValue?: {
    value?: number;
    label?: string;
  };
  rows?: IRowConditon[] | [];
  results?: IRowConditon[] | [];
}

export interface IRowConditon {
  columnNameOption?: {
    value?: string;
    label?: string;
  };
  conditionOptionValue?: {
    value?: string;
    label?: string;
  };
  conditionSetValue?: string | number;
  conditionOptionSetValue?: {
    value?: number;
    label?: string;
  };
  conditionOptionSetMultiSelectValue: AutocompleteBase[];
  conditionOptionSetCartonCodeDropdownValue?: CartonCodeDDLType;
  conditionOptionSetCarrierDropdownValue?: CarrierType;
  isConditionSetValueText?: boolean;
  isConditionSetValueDate?: boolean;
  isConditionSetValueNumber?: boolean;
  isConditionSetValueDropdown?: boolean;
  isMultiSelectDropdown?: boolean;
  isCartonCodeDropdown?: boolean;
  isCarrierDropdown?: boolean;
  conditionId: string;
  isAdhocDropdown?: boolean;
}

interface IOrderProcessingRules {
  setConditionOption: React.Dispatch<React.SetStateAction<any[]>>;
  conditionOption: any[];
  setConditionValues: React.Dispatch<
    React.SetStateAction<IOrderProcessingRulesValues>
  >;
  conditionValues: IOrderProcessingRulesValues;
  addCondition: () => void;
  addResult: () => void;
  removeFieldOnRowsCondition: (arrayInput: any, index: any) => void;
  removeFieldOnResults: (arrayInput: any, index: any) => void;
  handleFormChangeOrderRules: (
    arrayInput: any,
    index: any,
    event: any,
    fieldName: any,
    isDropDown: boolean,
    dropDownValue?: any,
    isDate?: boolean,
  ) => void;
  handleFormChangeOrderRulesResults: (
    arrayInput: any,
    index: any,
    event: any,
    fieldName: any,
    isDropDown: boolean,
    dropDownValue?: any,
  ) => void;
  optionCustomers: [] | AutocompleteBase[];
  carrierOptions: [] | CarrierType[];
  tagsOptions: [] | AutocompleteBase[];
  packageTypeOptions: [] | CartonCodeDDLType[];
  orderProcessingRulesFormError: any;
  handleOnCreateUpdateOrderRule: (e?: any) => Promise<void>;
  pageViewerOPR: PageViewerType;
  queryStringOrderProcessingRule: QueryStringType;
  setQueryStringOrderProcessingRule: React.Dispatch<
    React.SetStateAction<QueryStringType>
  >;
  orderProcessingRulesGrid: any[];
  setIsShowOrderRuleDetail: React.Dispatch<React.SetStateAction<boolean>>;
  isShowOrderRuleDetail: boolean;
  AddNewRuleAtHeader: () => void;
  CancelRuleHeaderPage: () => void;
  EditRuleHeaderPage: (id: number) => void;
  changeRuleOrder: (id: number, direction: string) => void;
  setCustomerType: React.Dispatch<React.SetStateAction<CustomerType>>;
  optionItems: ItemOption[];
  itemClassifications: [] | AutocompleteBase[];
  adhocChargeOptions: [] | AutocompleteBase[];
  getComboboxOptions: (selectedColumn: any) =>
    | AutocompleteBase[]
    | {
        value: string;
        label: string;
      }[];
  setIsShowRulesForm: React.Dispatch<React.SetStateAction<boolean>>;
  isShowRulesForm: boolean;
  setIsDeleteCustomeRuleModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  isDeleteCustomeRuleModalOpen: boolean;
  setCustomeRuleIdToDelete: React.Dispatch<any>;
  customeRuleIdToDelete: any;
  viewLoadDataGrid: () => Promise<void>;
  setTriggerRulesModal: React.Dispatch<React.SetStateAction<TriggerRulesModal>>;
  triggerRulesModal: TriggerRulesModal;
}

export const OrderProcessingRulesContextProvider = ({
  children,
}: OrderProcessingRulesProviderProps) => {
  const query = useQuery();
  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);

  const [customerType, setCustomerType] = useState<CustomerType>(null);

  const [optionCustomers, setOptionCustomers] = useState<
    CustomerTypeOption[] | null | []
  >([]);

  const [optionItems, setOptionItems] = useState<ItemOption[]>([]);

  const [carrierOptions, setCarrierOptions] = useState<CarrierType[]>([]);
  const [tagsOptions, setTagsOptions] = useState<AutocompleteBase[]>([]);
  const [usersOptions, setUsersOptions] = useState<AutocompleteBase[]>([]);
  const [packageTypeOptions, setPackageTypeOptions] = useState<
    CartonCodeDDLType[]
  >([]);
  const [orderSourceOptions, setOrderSourceOptions] = useState<
    AutocompleteBase[]
  >([]);
  const [itemClassifications, setItemClassifications] = useState<
    AutocompleteBase[]
  >([]);
  const [adhocChargeOptions, setAdhocChargeOptions] = useState<
    AutocompleteBase[]
  >([]);

  const getComboboxOptions = (selectedColumn) => {
    if (selectedColumn.columnNameOption.label === 'Carrier') {
      return carrierOptions;
    }
    if (selectedColumn.columnNameOption.value === 'WarehouseCustomerId') {
      return optionCustomers;
    }
    if (selectedColumn.columnNameOption.label === 'Tags') {
      return tagsOptions;
    }
    if (selectedColumn.columnNameOption.label === 'Pick Assignment') {
      return usersOptions;
    }
    if (selectedColumn.columnNameOption.label === 'Order Rule') {
      return orderSourceOptions;
    }
    if (selectedColumn.columnNameOption.value === 'ItemClassification') {
      return itemClassifications;
    }
    if (selectedColumn.columnNameOption.value === 'AdhocCharge') {
      return adhocChargeOptions;
    }

    return optionStatusTypes;
  };

  const [conditionOption, setConditionOption] = useState([]);
  const InitialFormOrderProcessingRules: IOrderProcessingRulesValues = {
    orderProcessingRuleId: null,
    category: { value: null, label: '' },
    ruleName: '',
    customerValue: { value: null, label: '' },
    rows: [
      {
        columnNameOption: { value: '', label: '' },
        conditionOptionValue: { value: '', label: '' },
        conditionOptionSetValue: { value: null, label: '' },
        conditionOptionSetMultiSelectValue: [],
        conditionOptionSetCartonCodeDropdownValue: null,
        conditionOptionSetCarrierDropdownValue: null,
        conditionSetValue: '',
        conditionId: `${GetISOIdString()}-${GetRandomString(7)}`,
      },
    ],
    results: [
      {
        columnNameOption: { value: '', label: '' },
        conditionOptionSetValue: { value: null, label: '' },
        conditionOptionSetMultiSelectValue: [],
        conditionOptionSetCartonCodeDropdownValue: null,
        conditionOptionSetCarrierDropdownValue: null,
        conditionSetValue: '',
        conditionId: `${GetISOIdString()}-${GetRandomString(7)}`,
      },
    ],
  };

  const [conditionValues, setConditionValues] = useState(
    InitialFormOrderProcessingRules,
  );

  const InitialFormOrderProcessingRulesError = {
    ruleName: '',
    customerValue: '',
    category: '',
  };

  const [orderProcessingRulesFormError, setOrderProcessingRulesFormError] =
    useState<any>(InitialFormOrderProcessingRulesError);

  const [isShowRulesForm, setIsShowRulesForm] = useState(false);
  const [isDeleteCustomeRuleModalOpen, setIsDeleteCustomeRuleModalOpen] =
    useState(false);
  const [customeRuleIdToDelete, setCustomeRuleIdToDelete] = useState(null);

  const [triggerRulesModal, setTriggerRulesModal] =
    useState<TriggerRulesModal>(null);

  const initialStateOfQueryParams: QueryStringType = {
    form: query.get('form'),
    id: Number(query.get('id')),
  };
  // const { onCloseProductionReportModal } = useContext(GlobalContext);
  const [queryStringOrderProcessingRule, setQueryStringOrderProcessingRule] =
    useState(initialStateOfQueryParams);

  const pageViewerOPR: PageViewerType = {
    isNew: () =>
      queryStringOrderProcessingRule.form === FormQueryStringStatus.New,
    isView: () =>
      queryStringOrderProcessingRule.form === FormQueryStringStatus.View &&
      queryStringOrderProcessingRule.id > 0,
    isEdit: () =>
      queryStringOrderProcessingRule.form === FormQueryStringStatus.Edit &&
      queryStringOrderProcessingRule.id > 0,
    isViewOrEdit: () =>
      (queryStringOrderProcessingRule.form === FormQueryStringStatus.View ||
        queryStringOrderProcessingRule.form === FormQueryStringStatus.Edit) &&
      queryStringOrderProcessingRule.id > 0,
  };

  // Datagrid --------------------------------------------------------
  const [orderProcessingRulesGrid, setOrderProcessingRulesGrid] = useState([]);
  const [isShowOrderRuleDetail, setIsShowOrderRuleDetail] = useState(false);

  // -----------------------------------------------------------------
  const onLoadCustomersLookup = async () => {
    try {
      const warehouseCustomersLookupFromApi = await getCustomersLookup(
        currentUser.Claim_CustomerId,
        '',
      );

      return warehouseCustomersLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  const onLoadSkuLookupWithwarehouseCustomer = async (warehouseCustomerId) => {
    try {
      const skuLookupFromApi = await getSkuLookupWithwarehouseCustomer(
        currentUser.Claim_CustomerId,
        warehouseCustomerId,
      );

      return skuLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  const onLoadOrderProcessingRuleDetail = async (id: number) => {
    try {
      const result = await GetDetailOrderProcessingRule(id);

      return result;
    } catch (err) {
      return err;
    }
  };

  const addCondition = () => {
    const newfield: IRowConditon = {
      columnNameOption: { value: '', label: '' },
      conditionOptionValue: { value: '', label: '' },
      conditionOptionSetValue: { value: null, label: '' },
      conditionOptionSetMultiSelectValue: [],
      conditionOptionSetCartonCodeDropdownValue: null,
      conditionOptionSetCarrierDropdownValue: null,
      conditionSetValue: '',
      conditionId: `${GetISOIdString()}-${GetRandomString(7)}`,
    };
    setConditionValues((prev) => ({
      ...prev,
      rows: [...conditionValues.rows, newfield],
    }));
  };
  const addResult = () => {
    const newfield: IRowConditon = {
      columnNameOption: { value: '', label: '' },
      conditionSetValue: '',
      conditionOptionSetValue: { value: null, label: '' },
      conditionOptionSetMultiSelectValue: [],
      conditionOptionSetCartonCodeDropdownValue: null,
      conditionOptionSetCarrierDropdownValue: null,
      conditionId: `${GetISOIdString()}-${GetRandomString(7)}`,
    };
    setConditionValues((prev) => ({
      ...prev,
      results: [...conditionValues.results, newfield],
    }));
  };
  const removeFieldOnRowsCondition = (arrayInput, index) => {
    const datas = arrayInput;
    datas.splice(index, 1);
    setConditionValues((prev) => ({
      ...prev,
      rows: datas,
    }));
  };
  const removeFieldOnResults = (arrayInput, index) => {
    const datas = arrayInput;
    datas.splice(index, 1);
    setConditionValues((prev) => ({
      ...prev,
      results: datas,
    }));
  };
  const handleFormChangeOrderRules = (
    arrayInput,
    index,
    event,
    fieldName,
    isDropDown: boolean,
    dropDownValue?: any,
    isDate?: boolean,
  ) => {
    const datas = arrayInput;
    const rowData = datas[index];

    if (isDate) {
      rowData[fieldName] = event;
    } else {
      event.preventDefault();

      if (isDropDown) {
        if (fieldName === 'columnNameOption') {
          rowData.columnNameOption.value = dropDownValue.value;
          rowData.columnNameOption.label = dropDownValue.label;

          if (dropDownValue.type === 'Text') {
            rowData.isConditionSetValueText = true;
            rowData.isConditionSetValueDate = null;
            rowData.isConditionSetValueNumber = null;
            rowData.isConditionSetValueDropdown = null;
            rowData.isMultiSelectDropdown = null;
            rowData.isCarrierDropdown = null;
          } else if (dropDownValue.type === 'Number') {
            rowData.isConditionSetValueText = null;
            rowData.isConditionSetValueDate = null;
            rowData.isConditionSetValueNumber = true;
            rowData.isConditionSetValueDropdown = null;
            rowData.isMultiSelectDropdown = null;
            rowData.isCarrierDropdown = null;
          } else if (dropDownValue.type === 'Date') {
            rowData.isConditionSetValueText = null;
            rowData.isConditionSetValueDate = true;
            rowData.isConditionSetValueNumber = null;
            rowData.isConditionSetValueDropdown = null;
            rowData.isMultiSelectDropdown = null;
          } else if (dropDownValue.type === 'Dropdown') {
            rowData.isConditionSetValueText = null;
            rowData.isConditionSetValueDate = null;
            rowData.isConditionSetValueNumber = null;
            rowData.isConditionSetValueDropdown = true;
            rowData.isMultiSelectDropdown = null;
            rowData.isCarrierDropdown = null;
          } else if (dropDownValue.type === 'MultiSelectDropdown') {
            rowData.isConditionSetValueText = null;
            rowData.isConditionSetValueDate = null;
            rowData.isConditionSetValueNumber = null;
            rowData.isConditionSetValueDropdown = null;
            rowData.isMultiSelectDropdown = true;
            rowData.isCarrierDropdown = null;
          } else if (dropDownValue.type === 'CarrierDropdown') {
            rowData.isConditionSetValueText = null;
            rowData.isConditionSetValueDate = null;
            rowData.isConditionSetValueNumber = null;
            rowData.isConditionSetValueDropdown = null;
            rowData.isMultiSelectDropdown = null;
            rowData.isMultiSelectDropdown = null;
            rowData.isCarrierDropdown = true;
          }
        } else if (fieldName === 'conditionOptionValue') {
          rowData.conditionOptionValue.value = dropDownValue.value;
          rowData.conditionOptionValue.label = dropDownValue.label;
        } else if (fieldName === 'conditionOptionSetValue') {
          rowData.conditionOptionSetValue = dropDownValue;
        } else if (fieldName === 'conditionOptionSetMultiSelectValue') {
          rowData.conditionOptionSetMultiSelectValue = dropDownValue;
        } else if (fieldName === 'conditionOptionSetCarrierDropdownValue') {
          rowData.conditionOptionSetCarrierDropdownValue = dropDownValue;
        }
      } else {
        rowData[event.target.name] = event.target.value;
      }
    }

    datas[index] = rowData;
    setConditionValues((prev) => ({
      ...prev,
      rows: datas,
    }));
  };

  const handleFormChangeOrderRulesResults = (
    arrayInput,
    index,
    event,
    fieldName,
    isDropDown: boolean,
    dropDownValue?: any,
  ) => {
    event.preventDefault();

    const datas = arrayInput;
    const rowData = datas[index];
    // console.log(arrayInput, ' arrayInput');
    // console.log(dropDownValue, ' dropDownValue');
    // console.log(carrierOptions, ' carrierOptions');

    if (isDropDown) {
      if (fieldName === 'columnNameOption') {
        rowData.isConditionSetValueText = null;
        rowData.isConditionSetValueDate = null;
        rowData.isConditionSetValueNumber = null;
        rowData.isConditionSetValueDropdown = null;
        rowData.isCartonCodeDropdown = null;
        rowData.isCarrierDropdown = null;
        rowData.isAdhocDropdown = null;
        rowData.columnNameOption.value = dropDownValue.value;
        rowData.columnNameOption.label = dropDownValue.label;

        if (dropDownValue.type === 'Text') {
          rowData.isConditionSetValueText = true;
        } else if (dropDownValue.type === 'Number') {
          rowData.isConditionSetValueNumber = true;
        } else if (dropDownValue.type === 'Date') {
          rowData.isConditionSetValueDate = true;
        } else if (dropDownValue.type === 'Dropdown') {
          rowData.isConditionSetValueDropdown = true;
        } else if (dropDownValue.type === 'CartonCodeDropdown') {
          rowData.isCartonCodeDropdown = true;
        } else if (dropDownValue.type === 'CarrierDropdown') {
          rowData.isCarrierDropdown = true;
        } else if (dropDownValue.type === 'AdhocDropdown') {
          rowData.isAdhocDropdown = true;
        }
      } else if (fieldName === 'conditionOptionSetValue') {
        rowData.conditionOptionSetValue.value = dropDownValue.value;
        rowData.conditionOptionSetValue.label = dropDownValue.label;
        rowData.conditionSetValue = '';
      } else if (fieldName === 'conditionOptionSetCartonCodeDropdownValue') {
        rowData.conditionOptionSetCartonCodeDropdownValue = dropDownValue;
      } else if (fieldName === 'conditionOptionSetCarrierDropdownValue') {
        rowData.conditionOptionSetCarrierDropdownValue = dropDownValue;
      }
    } else {
      rowData[event.target.name] = event.target.value;
    }
    datas[index] = rowData;

    setConditionValues((prev) => ({
      ...prev,
      results: datas,
    }));
  };
  const AddNewRuleAtHeader = () => {
    setIsShowOrderRuleDetail(true);
    setConditionValues(InitialFormOrderProcessingRules);
  };
  const CancelRuleHeaderPage = () => {
    setIsShowOrderRuleDetail(false);
    setConditionValues(InitialFormOrderProcessingRules);
    setOrderProcessingRulesFormError(InitialFormOrderProcessingRulesError);
  };

  const EditRuleHeaderPage = (id: number) => {
    onLoadOrderProcessingRuleDetail(id).then((data: any) => {
      setConditionValues((prev) => ({
        ...prev,
        orderProcessingRuleId: data?.orderProcessingRuleId,
        ruleName: data?.ruleName,
        category: {
          value: data?.category,
          label: data?.category,
        },
        customerValue: {
          value: data?.warehouseCustomerId,
          label: data?.warehouseCustomerStr,
        },
        rows: data?.conditionAndResult.conditions.map((v) => ({
          columnNameOption: {
            value: v?.columnNameOption,
            label: v?.columnNameOptionStr,
          },
          conditionOptionValue: {
            value: v?.conditionOptionValue,
            label: v?.conditionOptionValueStr,
          },
          conditionSetValue: v.conditionSetValue,
          conditionOptionSetValue: {
            value: v?.conditionOptionSetValue,
            label: v?.conditionOptionSetValueStr,
          },
          isConditionSetValueText: v.isConditionSetValueText,
          isConditionSetValueDate: v.isConditionSetValueDate,
          isConditionSetValueNumber: v.isConditionSetValueNumber,
          isConditionSetValueDropdown: v.isConditionSetValueDropdown,
          conditionOptionSetMultiSelectValue:
            v.conditionOptionSetMultiSelectValue.map((x) => ({
              value: x.value,
              label: x.text,
            })),
          isMultiSelectDropdown: v.isMultiSelectDropdown,
          conditionOptionSetCarrierDropdownValue:
            v.conditionOptionSetCarrierDropdownValue !== undefined &&
            v.conditionOptionSetCarrierDropdownValue !== null
              ? {
                  label: v.conditionOptionSetCarrierDropdownValue.text,
                  value: v.conditionOptionSetCarrierDropdownValue.value,
                  isShippingRate:
                    v.conditionOptionSetCarrierDropdownValue.isShippingRate,
                  serviceCode:
                    v.conditionOptionSetCarrierDropdownValue.serviceCode ??
                    null,
                }
              : null,
          isCarrierDropdown: v.isCarrierDropdown,
        })),
        results: data?.conditionAndResult.results.map((v) => ({
          columnNameOption: {
            value: v?.columnNameOption,
            label: v?.columnNameOptionStr,
          },
          conditionOptionSetValue: {
            value: v?.conditionOptionSetValue,
            label: v?.conditionOptionSetValueStr,
          },
          conditionSetValue: v.conditionSetValue,
          isConditionSetValueText: v.isConditionSetValueText,
          isConditionSetValueDate: v.isConditionSetValueDate,
          isConditionSetValueNumber: v.isConditionSetValueNumber,
          isConditionSetValueDropdown: v.isConditionSetValueDropdown,
          isAdhocDropdown:
            v.columnNameOption?.toString()?.toLowerCase() === 'adhoccharge',
          conditionOptionSetCartonCodeDropdownValue:
            v.conditionOptionSetCartonCodeDropdownValue !== undefined &&
            v.conditionOptionSetCartonCodeDropdownValue !== null
              ? {
                  label: v.conditionOptionSetCartonCodeDropdownValue.text,
                  value: v.conditionOptionSetCartonCodeDropdownValue.value,
                  dimensions:
                    v.conditionOptionSetCartonCodeDropdownValue.dimensions ??
                    null,
                  isExternalPackage:
                    v.conditionOptionSetCartonCodeDropdownValue
                      .isExternalPackage,
                  carrier:
                    v.conditionOptionSetCartonCodeDropdownValue.carrier ?? null,
                }
              : null,
          isCartonCodeDropdown: v.isCartonCodeDropdown,
          conditionOptionSetCarrierDropdownValue:
            v.conditionOptionSetCarrierDropdownValue !== undefined &&
            v.conditionOptionSetCarrierDropdownValue !== null
              ? {
                  label: v.conditionOptionSetCarrierDropdownValue.text,
                  value: v.conditionOptionSetCarrierDropdownValue.value,
                  isShippingRate:
                    v.conditionOptionSetCarrierDropdownValue.isShippingRate,
                  serviceCode:
                    v.conditionOptionSetCarrierDropdownValue.serviceCode ??
                    null,
                }
              : null,
          isCarrierDropdown: v.isCarrierDropdown,
        })),
      }));
    });
    setIsShowOrderRuleDetail(true);
  };

  const viewLoadDataGrid = async () => {
    let response;
    if (customerType === CustomerType.Customer) {
      response = GetOrderProcessingRuleViewModel(
        customerType,
        0,
        currentLocationAndFacility.customerLocationId,
      );
    } else if (
      Number.isNaN(queryStringOrderProcessingRule?.id) === false &&
      queryStringOrderProcessingRule.id > 0
    ) {
      response = GetOrderProcessingRuleViewModel(
        customerType,
        queryStringOrderProcessingRule.id,
        currentLocationAndFacility.customerLocationId,
      );
    }

    response.then((data: any) => {
      setOrderProcessingRulesGrid(data.orderProcessingRules);

      setPackageTypeOptions(
        data.cartonCodeDDL.map((x: any) => ({
          label: x.text,
          value: x.value,
          dimensions: x.dimensions ?? null,
          isExternalPackage: x.isExternalPackage,
          carrier: x.carrier ?? null,
        })),
      );

      setCarrierOptions(
        data.carrierDDL.map((x) => ({
          ...x,
          label: x.text,
        })),
      );

      setOptionCustomers(
        data.customerDDL.map((x) => ({
          label: x.text,
          value: x.value,
        })),
      );

      setOptionItems(
        data.itemDDL.map((x) => ({
          label: x.text,
          value: x.value,
        })),
      );

      setTagsOptions(
        data.tagDDL.map((c) => ({
          label: c.tagName,
          value: c.tagColor,
        })),
      );

      setUsersOptions(
        data.usersDDl.map((c) => ({
          label: c.text,
          value: c.value,
        })),
      );

      setOrderSourceOptions(
        data.orderSourceDDl.map((c) => ({
          label: c.text,
          value: c.value,
        })),
      );

      setItemClassifications(
        data.itemClassificationDDL.map((x) => ({
          label: x.text,
          value: x.value,
        })),
      );

      setAdhocChargeOptions(
        data.adhocChargeOptions.map((x) => ({
          label: x.text,
          value: x.value,
        })),
      );
    });
  };

  const changeRuleOrder = (id: number, direction: string) => {
    updateRuleOrder(id, direction).then((res) => {
      viewLoadDataGrid();
    });
  };

  const viewLoadData = async () => {
    onLoadOrderProcessingRuleDetail(queryStringOrderProcessingRule.id).then(
      (data: any) => {
        setConditionValues((prev) => ({
          ...prev,
          orderProcessingRuleId: data?.orderProcessingRuleId,
          ruleName: data?.ruleName,
          customerValue: {
            value: data?.warehouseCustomerId,
            label: data?.warehouseCustomerStr,
          },
          rows: data?.conditionAndResult.conditions.map((v) => ({
            columnNameOption: {
              value: v?.columnNameOption,
              label: v?.columnNameOptionStr,
            },
            conditionOptionValue: {
              value: v?.conditionOptionValue,
              label: v?.conditionOptionValueStr,
            },
            conditionSetValue: v.conditionSetValue,
            conditionOptionSetValue: {
              value: v?.conditionOptionSetValue,
              label: v?.conditionOptionSetValueStr,
            },
            isConditionSetValueText: v.isConditionSetValueText,
            isConditionSetValueDate: v.isConditionSetValueDate,
            isConditionSetValueNumber: v.isConditionSetValueNumber,
            isConditionSetValueDropdown: v.isConditionSetValueDropdown,
            isMultiSelectDropdown: v.isMultiSelectDropdown,
          })),
          results: data?.conditionAndResult.results.map((v) => ({
            columnNameOption: {
              value: v?.columnNameOption,
              label: v?.columnNameOptionStr,
            },
            conditionSetValue: v.conditionSetValue,
            isConditionSetValueText: v.isConditionSetValueText,
            isConditionSetValueDate: v.isConditionSetValueDate,
            isConditionSetValueNumber: v.isConditionSetValueNumber,
            isConditionSetValueDropdown: v.isConditionSetValueDropdown,
            isMultiSelectDropdown: v.isMultiSelectDropdown,
          })),
        }));
      },
    );
  };

  const validateOnlick = (fieldValues = conditionValues) => {
    const temp: any = {};
    temp.ruleName = fieldValues.ruleName ? '' : 'This field is required';
    temp.category = fieldValues.category.value ? '' : 'This field is required';

    setOrderProcessingRulesFormError({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const handleOnCreateUpdateOrderRule = async (e?: any) => {
    e?.preventDefault();

    // trigger validation
    let hasEmptyColumnName = false;
    // item
    let itemRowCount = 0;
    let itemHasRow = false;
    let itemHasEmptyCondition = false;
    let itemHasEmptyValue = false;
    // carrier type
    let carrierTypeRowCountCondition = 0;
    let carrierTypeHasRowCondition = false;
    let carrierTypeHasEmptyValueCondition = false;

    if (!validateOnlick()) {
      snackActions.error('Please complete required fields');
      return;
    }

    conditionValues.rows.forEach((x: IRowConditon) => {
      if (x?.columnNameOption?.value === '') {
        hasEmptyColumnName = true;
      }

      if (x?.columnNameOption?.value === 'Items') {
        itemHasRow = true;

        if (x?.conditionOptionSetMultiSelectValue.length === 0) {
          itemHasEmptyValue = true;
        } else {
          itemRowCount += 1;
        }

        if (x?.conditionOptionValue?.value === '') {
          itemHasEmptyCondition = true;
        }
      }

      if (x?.columnNameOption?.value === 'CarrierType') {
        carrierTypeHasRowCondition = true;
        carrierTypeRowCountCondition += 1;

        if (x?.conditionOptionSetCarrierDropdownValue === null) {
          carrierTypeHasEmptyValueCondition = true;
        }

        // don't required value if condition is 'isEmpty'
        if (x?.conditionOptionValue?.value === 'isEmpty') {
          carrierTypeHasEmptyValueCondition = false;
        }
      }
    });

    if (hasEmptyColumnName) {
      snackActions.error('Column name cannot be empty');
      return;
    }

    if (itemHasEmptyCondition) {
      snackActions.error('Item have a empty condition');
      return;
    }

    if (itemHasEmptyValue) {
      snackActions.error('Item have a empty value');
      return;
    }

    if (itemHasRow && itemRowCount > 1) {
      snackActions.error('Item should only have one row');
      return;
    }

    if (carrierTypeHasEmptyValueCondition) {
      snackActions.error('Carrier have a empty value');
      return;
    }

    if (carrierTypeHasRowCondition && carrierTypeRowCountCondition > 1) {
      snackActions.error('Carrier should only have one row');
      return;
    }

    // action validation
    let hasEmptyColumnToUpdate = false;
    // package type
    let packageTypeRowCount = 0;
    let packageTypeHasRow = false;
    let packageTypeHasEmptyValue = false;
    // carrier type
    let carrierTypeRowCount = 0;
    let carrierTypeHasRow = false;
    let carrierTypeHasEmptyValue = false;
    // packaging instructions
    let packagingInstructionRowCount = 0;
    let packagingInstructionHasRow = false;
    let packagingInstructionHasEmptyValue = false;
    conditionValues.results.forEach((x: IRowConditon) => {
      if (x?.columnNameOption?.value === '') {
        hasEmptyColumnToUpdate = true;
      }

      if (x?.columnNameOption?.value === 'PackageType') {
        packageTypeHasRow = true;
        packageTypeRowCount += 1;

        if (x?.conditionOptionSetCartonCodeDropdownValue === null) {
          packageTypeHasEmptyValue = true;
        }
      }

      if (x?.columnNameOption?.value === 'CarrierType') {
        carrierTypeHasRow = true;
        carrierTypeRowCount += 1;

        if (x?.conditionOptionSetCarrierDropdownValue === null) {
          carrierTypeHasEmptyValue = true;
        }
      }

      if (x?.columnNameOption?.value === 'PackagingInstructions') {
        packagingInstructionHasRow = true;
        packagingInstructionRowCount += 1;

        if (x?.conditionSetValue === null || x?.conditionSetValue === '') {
          packagingInstructionHasEmptyValue = true;
        }
      }
    });

    if (hasEmptyColumnToUpdate) {
      snackActions.error('Column to update cannot be empty');
      return;
    }

    if (packageTypeHasEmptyValue) {
      snackActions.error('Package type have a empty value');
      return;
    }

    if (packageTypeHasRow && packageTypeRowCount > 1) {
      snackActions.error('Package type should only have one row');
      return;
    }

    if (carrierTypeHasEmptyValue) {
      snackActions.error('Carrier have a empty value');
      return;
    }

    if (carrierTypeHasRow && carrierTypeRowCount > 1) {
      snackActions.error('Carrier should only have one row');
      return;
    }

    if (packagingInstructionHasEmptyValue) {
      snackActions.error('Packaging instructions have a empty value');
      return;
    }

    if (packagingInstructionHasRow && packagingInstructionRowCount > 1) {
      snackActions.error('Packaging instructions should only have one row');
      return;
    }

    const payload = {
      OrderProcessingRuleId: conditionValues?.orderProcessingRuleId,
      WarehouseCustomerId:
        queryStringOrderProcessingRule.id > 0
          ? queryStringOrderProcessingRule.id
          : conditionValues?.customerValue?.value,
      RuleName: conditionValues?.ruleName,
      Category: conditionValues?.category?.value,
      ConditionAndResultModel: {
        Conditions: conditionValues.rows.map((v) => ({
          ColumnNameOption: v.columnNameOption?.value,
          ColumnNameOptionStr: v.columnNameOption?.label,
          ConditionOptionValue: v.conditionOptionValue?.value,
          ConditionOptionValueStr: v.conditionOptionValue?.label,
          ConditionSetValue: v.conditionSetValue,
          ConditionOptionSetValue: v.conditionOptionSetValue?.value,
          ConditionOptionSetValueStr: v.conditionOptionSetValue?.label,
          IsConditionSetValueText: v.isConditionSetValueText,
          IsConditionSetValueDate: v.isConditionSetValueDate,
          IsConditionSetValueNumber: v.isConditionSetValueNumber,
          IsConditionSetValueDropdown: v.isConditionSetValueDropdown,
          ConditionOptionSetMultiSelectValue:
            v.conditionOptionSetMultiSelectValue.map((x) => ({
              Value: x.value,
              Text: x.label,
            })),
          IsMultiSelectDropdown: v.isMultiSelectDropdown,
          ConditionOptionSetCarrierDropdownValue:
            v.conditionOptionSetCarrierDropdownValue !== undefined &&
            v.conditionOptionSetCarrierDropdownValue !== null
              ? {
                  Text: v.conditionOptionSetCarrierDropdownValue.label,
                  Value: v.conditionOptionSetCarrierDropdownValue.value,
                  IsShippingRate:
                    v.conditionOptionSetCarrierDropdownValue.isShippingRate,
                  ServiceCode:
                    v.conditionOptionSetCarrierDropdownValue.serviceCode ??
                    null,
                }
              : null,
          IsCarrierDropdown: v.isCarrierDropdown,
        })),
        Results: conditionValues.results.map((v) => ({
          ColumnNameOption: v.columnNameOption?.value,
          ColumnNameOptionStr: v.columnNameOption?.label,
          ConditionSetValue: v.conditionSetValue,
          ConditionOptionSetValue: v.conditionOptionSetValue?.value,
          ConditionOptionSetValueStr: v.conditionOptionSetValue?.label,
          IsConditionSetValueText: v.isConditionSetValueText,
          IsConditionSetValueDate: v.isConditionSetValueDate,
          IsConditionSetValueNumber: v.isConditionSetValueNumber,
          IsConditionSetValueDropdown: v.isConditionSetValueDropdown,
          ConditionOptionSetCartonCodeDropdownValue:
            v.conditionOptionSetCartonCodeDropdownValue !== undefined &&
            v.conditionOptionSetCartonCodeDropdownValue !== null
              ? {
                  Text: v.conditionOptionSetCartonCodeDropdownValue.label,
                  Value: v.conditionOptionSetCartonCodeDropdownValue.value,
                  Dimensions:
                    v.conditionOptionSetCartonCodeDropdownValue.dimensions ??
                    null,
                  IsExternalPackage:
                    v.conditionOptionSetCartonCodeDropdownValue
                      .isExternalPackage,
                  Carrier:
                    v.conditionOptionSetCartonCodeDropdownValue.carrier ?? null,
                }
              : null,
          IsCartonCodeDropdown: v.isCartonCodeDropdown,
          ConditionOptionSetCarrierDropdownValue:
            v.conditionOptionSetCarrierDropdownValue !== undefined &&
            v.conditionOptionSetCarrierDropdownValue !== null
              ? {
                  Text: v.conditionOptionSetCarrierDropdownValue.label,
                  Value: v.conditionOptionSetCarrierDropdownValue.value,
                  IsShippingRate:
                    v.conditionOptionSetCarrierDropdownValue.isShippingRate,
                  ServiceCode:
                    v.conditionOptionSetCarrierDropdownValue.serviceCode ??
                    null,
                }
              : null,
          IsCarrierDropdown: v.isCarrierDropdown,
        })),
      },
    };

    try {
      await CreateOrderProcessingRule(payload);

      snackActions.success(
        `The order processing rule has been saved successfully.`,
      );
      viewLoadDataGrid();
      CancelRuleHeaderPage();
    } catch (err) {
      snackActions.error(`${err}`);
    }
  };

  useEffect(() => {
    if (customerType !== null) {
      viewLoadDataGrid();
    }
  }, [
    currentUser.Claim_CustomerId,
    customerType,
    queryStringOrderProcessingRule.id,
  ]);

  const value: IOrderProcessingRules = useMemo(
    () => ({
      conditionOption,
      conditionValues,
      optionCustomers,
      carrierOptions,
      tagsOptions,
      packageTypeOptions,
      orderProcessingRulesFormError,
      pageViewerOPR,
      queryStringOrderProcessingRule,
      orderProcessingRulesGrid,
      isShowOrderRuleDetail,
      AddNewRuleAtHeader,
      CancelRuleHeaderPage,
      EditRuleHeaderPage,
      changeRuleOrder,
      setIsShowOrderRuleDetail,
      addCondition,
      addResult,
      setConditionOption,
      setConditionValues,
      removeFieldOnRowsCondition,
      removeFieldOnResults,
      handleOnCreateUpdateOrderRule,
      handleFormChangeOrderRules,
      handleFormChangeOrderRulesResults,
      setQueryStringOrderProcessingRule,
      setCustomerType,
      optionItems,
      getComboboxOptions,
      isShowRulesForm,
      setIsShowRulesForm,
      isDeleteCustomeRuleModalOpen,
      setIsDeleteCustomeRuleModalOpen,
      customeRuleIdToDelete,
      setCustomeRuleIdToDelete,
      viewLoadDataGrid,
      triggerRulesModal,
      setTriggerRulesModal,
      itemClassifications,
      adhocChargeOptions,
    }),
    [
      triggerRulesModal,
      customeRuleIdToDelete,
      isDeleteCustomeRuleModalOpen,
      isShowRulesForm,
      conditionOption,
      conditionValues,
      optionCustomers,
      carrierOptions,
      carrierOptions,
      orderProcessingRulesFormError,
      pageViewerOPR,
      queryStringOrderProcessingRule,
      orderProcessingRulesGrid,
      isShowOrderRuleDetail,
      changeRuleOrder,
      optionItems,
      getComboboxOptions,
      itemClassifications,
      adhocChargeOptions,
    ],
  );

  return (
    <OrderProcessingRulesContext.Provider value={value}>
      {children}
    </OrderProcessingRulesContext.Provider>
  );
};
