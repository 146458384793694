/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import ImgHelpInfo from 'assets/images/gif/help-info.gif';
import { UserPermissionEnum } from 'common/enums/enum-user-permissions';
import DropDownMenu from 'components/header/menu';
import NavigationPageIcon from 'components/icons/NavigationIcon';
import KeyPressGlobalFilter from 'components/key-press-global-filter';
import { snackActions } from 'config/snackbar.js';
import useQuery from 'helpers/useQuery/useQuery';
import moment from 'moment';
import {
  FormQueryStringStatus,
  cycleCountType,
  useCycleCountContext,
} from 'pages/cyclecount/context';
import { useInventoryItemDetailsContext } from 'pages/inventory/item/details';
import { useLicensePlateContext } from 'pages/licenseplates/createlicenseplate';
import { useWarehouseCustomerContext } from 'pages/ordercustomers/warehousecustomer';
import { usePackAndShipContext } from 'pages/packandship/context';
import PackAndShipButtons from 'pages/packandship/packandshipbutton';
import IsInvalidAddressModal from 'pages/packandship/status-chip';
import { usePrintOutFormContext } from 'pages/printoutforms/formdetails';
import { useProductionContext } from 'pages/production/context';
import ProductionJobStatusDropdown from 'pages/production/job-details/jobdetailsstatusdropdown';
import { usePurchaseOrderContext } from 'pages/receiving/purchaseordersv2/context';
import POStatusButtonDropdown from 'pages/receiving/purchaseordersv2/postatusbuttondropdown';
import { useSubpageReceiptsContext } from 'pages/receiving/receipts/context';
import { ReceiptStatus } from 'pages/receiving/receipts/subpage';
import { useSalesOrderDataTableViewContext } from 'pages/sales/context-provider';
import { useInvoiceDataTableViewContext } from 'pages/sales/invoiceorderdetailsview';
import {
  OrderStatus,
  useSalesOrderContext,
} from 'pages/sales/salesorder/context';
import OrderStatusChip from 'pages/sales/salesorder/orderstatuschip';
import OrderStatusDropdown from 'pages/sales/salesorder/orderstatusdropdown';
import AddQuickLinkModal from 'pages/shared/addquicklinkmodal';
import { useInvoiceDetailFormContext } from 'pages/shared/invoicedetailform/context';
import { PrintTypeEnum } from 'pages/shared/print/models/PrintTypeEnum';
import { FormTemplateTypeEnum } from 'pages/shared/printformtemplate';
import SelectLocationAndFacilityModal from 'pages/shared/selectlocationandfacilitymodal';
import { PackemInvoiceLogo } from 'pages/styles';
import { useSupplierContext } from 'pages/suppliers/supplierdetail';
import { getCustomerFacilityByFacilityId } from 'services/api/customerfacilities/customerfacilities.api';
import {
  deleteQuickLink,
  getUserQuickLinks,
} from 'services/api/quicklinks/quicklinks.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PrintIcon from '@mui/icons-material/Print';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SouthOutlinedIcon from '@mui/icons-material/SouthOutlined';
import {
  Typography,
  Box,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Button,
  CircularProgress,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { HeaderBox, LowHeaderBox, TopHeader, MenuButton } from '../styles';
import HeaderQRCodeDialog from './qr-code-dialog';

interface HeaderProps {
  customerLocations?: any[];
  orderCustomerDetails?: any;
  printSOTicketsCallBack?: () => void;
  poEditCallBack?: () => void;
  poSaveCallBack?: () => Promise<void>;
  onClickBulkDeleteBin?: () => Promise<void>;
  onClickPrintBarcode?: () => Promise<void>;
}

const QuickLinksIcon = ({ isExpanded }) =>
  isExpanded ? (
    <ExpandLessIcon
      style={{
        backgroundColor: '#fff',
        color: '#3375e0',
        borderRadius: '50%',
        fontSize: 20,
        marginRight: 5,
        padding: 3,
      }}
    />
  ) : (
    <ExpandMoreIcon
      style={{
        backgroundColor: '#fff',
        color: '#3375e0',
        borderRadius: '50%',
        fontSize: 20,
        marginRight: 5,
        padding: 3,
      }}
    />
  );

const QuickLinksButton = ({
  isExpanded,
  setQuicklinksExpanded,
  setQuickLinkModalOpen,
  userQuickLinksList,
  setUserQuickLinksList,
}) => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setQuicklinksExpanded(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setQuicklinksExpanded(false);
  };

  const getQuickLinksFromApi = async () => {
    try {
      const quickLinksFromApi = await getUserQuickLinks();
      setUserQuickLinksList(quickLinksFromApi);
      return true;
    } catch (err) {
      return err;
    }
  };

  const deleteAndRemoveQuickLinkFromList = async (quickLinkId) => {
    try {
      await deleteQuickLink(quickLinkId);

      const updatedQuickLinks = userQuickLinksList.filter(
        (quickLink) => quickLink.quickLinkId !== quickLinkId,
      );

      setUserQuickLinksList(updatedQuickLinks);
      return true;
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    getQuickLinksFromApi();
  }, [currentUser.Claim_CustomerId]);

  const open = Boolean(anchorEl);

  const navigationPageIconStyleProperties = {
    color: '#598BE0',
    fontSize: '20px',
    paddingRight: '5px',
  };

  const handleLinkClick = (link: any) => {
    navigate(link.navigationPagePath);
  };

  return (
    <>
      <IconButton
        style={{
          backgroundColor: '#598BE0',
          color: '#fff',
          borderRadius: 20,
          padding: 7,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={handlePopoverOpen}
        title="Quick Links"
      >
        <Typography
          variant="caption"
          style={{
            margin: '0 5px',
          }}
        >
          Quick Links
        </Typography>
        <QuickLinksIcon isExpanded={isExpanded} />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List
          style={{
            backgroundColor: '#fff',
            color: '#black',
            borderRadius: 0,
            padding: 0,
            margin: 0,
          }}
        >
          {userQuickLinksList.map((link: any) => (
            <ListItem key={link.quickLinkId} dense>
              <NavigationPageIcon
                iconName={link.navigationPageIconName}
                styleProperties={navigationPageIconStyleProperties}
              />
              <ListItemText
                primary={link.navigationPageName}
                onClick={() => handleLinkClick(link)}
                sx={{ cursor: 'pointer' }}
              />
              <ListItemText
                primary={`(${link.shortcutKeys})`}
                sx={{
                  color: 'rgba(0, 0, 0, 0.54)',
                  textAlign: 'right',
                  marginLeft: '50px',
                }}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label={`delete-${link.quickLinkId}`}
                  onClick={() => {
                    deleteAndRemoveQuickLinkFromList(link.quickLinkId);
                  }}
                >
                  <DeleteOutlineOutlinedIcon
                    sx={{
                      color: '#598BE0',
                      fontSize: '20px',
                    }}
                  />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
          <ListItem dense>
            <IconButton
              edge="start"
              aria-label="AddCircleRoundedIcon"
              sx={{
                fontSize: '12px',
                color: '#598BE0',
              }}
              onClick={() => {
                setQuickLinkModalOpen(true);
              }}
            >
              <AddCircleRoundedIcon />
            </IconButton>
            <Typography
              sx={{
                fontSize: '12px',
                color: '#598BE0',
              }}
            >
              Add Custom Link
            </Typography>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

function Header({
  orderCustomerDetails,
  onClickBulkDeleteBin,
  onClickPrintBarcode,
}: HeaderProps) {
  const urlQuery = useQuery();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [isQuickLinksExpanded, setQuicklinksExpanded] = useState(false);
  const location = useLocation();
  const currentLoc = location.pathname.split('/')[1];
  const menuButton = useRef();
  const {
    currentUser,
    currentLocationAndFacility,
    isWarehouseCustomerAccount,
    isSupplierAccount,
    handleUserPermissionAllow,
  } = useContext(AuthContext);
  const [customerFacility, setCustomerFacility] = useState([]);
  const {
    updateData,
    onOpenLocationAndFacilityModal,
    onOpenSupplierModal,
    onOpenCustomerModal,
    onOpenCustomerLocationModal,
    onOpenFacilityZoneModal,
    onOpenDeviceModal,
    onOpenDeviceTokenModal,
    onOpenUserModal,
    onOpenCreateBinModal,
    onOpenBinModal,
    onOpenPurchaseOrderItemModal,
    onOpenFileInputModal,
    onOpenManualSalesOrderModal,
    onOpenOrderCustomerAddressModal,
    onOpenPrintRollModal,
    onOpenConfirmReceiptModal,
    setIsConfirmQcValidationModalOpen,
    onOpenIntegrationModal,
    onOpenEditConfirmedReceiptModal,
    onOpenImportInventoryModal,
    onOpenImportOrderModal,
    onOpenCycleCountModal,
    onOpenBatchPrintLPNModal,
    onOpenReceiptDocumentModal,
    setIsPrintQtyModal,
    setPrintTypeData,
    setNumberToPrintGlobal,
    setIsCopyDefaultCustomerModalOpen,
    setAddQuickLinkModalOpen,
    quickLinks,
    setUserQuickLinks,
    setIsImportPurchaseOrderModalOpen,
    loading,
    onOpenInvoiceDialogModal,
    onOpenPrintFormTemplateModal,
  } = useContext(GlobalContext);

  const [openQRDialog, setOpenQRDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openCycleCount, setOpenCycleCount] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const anchorRefCycleCount = React.useRef<HTMLButtonElement>(null);

  const { OnClickCreateUpdateOrderInvoice, queryStringInvoiceDetailPage } =
    useInvoiceDataTableViewContext();

  const { state, setFormTemplateType } = useInvoiceDetailFormContext();

  const {
    formValues: receiptDetail,
    receiptStatus,
    queryStringItemReceipts,
    setQueryStringItemReceipts,
    handleOnClickCreateReceipt,
    handleOnClickPrintAllLP,
    handleOnClickConfirmReceipt,
    pageViewerReceipt,
    isSaving,
    inboundData,
    lineItemRows,
    onClickFinalReceiptSummary,
  } = useSubpageReceiptsContext();

  const {
    pageViewer,
    isFormDisable,
    salesOrderFlagStatus,
    queryStringItemOrders,
    setQueryStringItemOrders,
    createSalesOrderOnClick,
    printFormTemplateOnClick,
  } = useSalesOrderContext();

  const { onHandleRefreshOrders, isEnableToRefresh, setIsEnableToRefresh } =
    useSalesOrderDataTableViewContext();

  const {
    pageViewer: prodViewer,
    productionForm,
    queryStringItemProduction,
    createProductionJobOnClick,
    setQueryStringItemProduction,
  } = useProductionContext();

  const {
    handleOnClickSaveInventoryItemDetail,
    queryStringItemDetails,
    setQueryStringItemDetails,
    formValues: inventoryItemDetail,
    pageViewer: inventoryPageViewer,
    itemEcommerceSync,
  } = useInventoryItemDetailsContext();

  const {
    handleOnCreateWarehouseCustomer,
    isViewForm,
    isEditForm,
    handleEditViewOnlick,
    form,
    tabValue,
    queryString,
    setIsEditForm,
    setIsViewForm,
    setIsManageClientDefault,
  } = useWarehouseCustomerContext();

  const { pickTicketFormObj } = usePackAndShipContext();

  const {
    onClickCreateFormTemplate,
    pageViewerForms,
    queryStringItemForm,
    setQueryStringItemForm,
  } = usePrintOutFormContext();

  const { onSaveLicensePlate, onPutAwayQueue, print } =
    useLicensePlateContext();

  const {
    setQueryStringItemCycleCount,
    queryStringItemCycleCount,
    pageViewer: cycleCountViewer,
    onHandleCycleCountUpdate,
    onClickEndCycleCount,
    cycleCountDataDetailModal,
  } = useCycleCountContext();

  const {
    pageViewerPO,
    queryStringPurchaseOrder,
    setQueryStringPurchaseOrder,
    handleEditPurchaseOrder,
    handleOnCreatePurchaseOrder,
  } = usePurchaseOrderContext();

  const {
    pageViewerSupplier,
    queryStringSupplier,
    handleEditSupplier,
    setQueryStringSupplier,
  } = useSupplierContext();

  const handleClickMenu = () => {
    if (!anchorEl) setAnchorEl(menuButton.current);
    setOpenMenu(!openMenu);
  };

  const onMenuClose = () => {
    setAnchorEl(null);
    setOpenMenu(!openMenu);
  };

  const onLoadCustomerFacility = async () => {
    try {
      const customerFacilityFromApi = await getCustomerFacilityByFacilityId(
        location.pathname.split('/')[3],
      );

      setCustomerFacility(customerFacilityFromApi.name);
      return true;
    } catch (error) {
      return 'Facility';
    }
  };

  const handleGetRoute = () => {
    switch (currentLoc) {
      case 'settings':
        return 'Settings';
      case 'receiving':
        if (location.pathname.includes('/item')) {
          return `Receiving / PO ${location.pathname.split('/')[3]}`;
        }
        if (
          location.pathname.includes('/purchase-orders') &&
          urlQuery.get('form') === 'new'
        ) {
          return 'Purchase Orders / New Order';
        }
        if (location.pathname.includes('/receipts')) {
          if (location.pathname.includes('/receipts/subpage')) {
            return `Receipts / ${
              pageViewerReceipt.isViewOrEdit()
                ? ['Reference ', receiptDetail.referenceNo].join('')
                : 'New Receipts'
            }`;
          }
          return `Receipts`;
        }
        return 'Purchase Order';
      case 'warehouse-customer-integrations':
        return 'Integrations';
      case 'warehouse-customer-receiving':
        return 'Purchase Order Overview';
      case 'suppliers':
        return 'Suppliers';

      case 'customer-inventory':
        if (location.pathname.includes('/suppliers')) {
          return 'Inventory / Suppliers';
        }
        if (location.pathname.includes('/item')) {
          return `Inventory / Item ${location.pathname.split('/')[3]}`;
        }

        return 'Inventory';

      case 'inventory':
        if (location.pathname.includes('/suppliers')) {
          return 'Inventory / Suppliers';
        }
        if (location.pathname.includes('/supplier-details')) {
          return 'Supplier';
        }

        if (
          location.pathname.includes('/item') &&
          !location.pathname.includes('/item-details')
        ) {
          return `Inventory / Item ${decodeURI(
            location.pathname.split('/')[3],
          )} - ${urlQuery.get('description')}`;
        }

        if (location.pathname.includes('/item-details')) {
          return `Inventory /  ${
            (queryStringItemDetails.form === 'view' ||
              queryStringItemDetails.form === 'edit') &&
            parseInt(queryStringItemDetails.id, 10) > 0
              ? inventoryItemDetail.desc.sku
              : 'New Item'
          }`;
        }
        return 'Inventory';
      case 'warehouse-customer-inventory':
        return 'Inventory';
      case 'put-away':
        return 'Put Away Queue';
      case 'dashboard':
        return 'Dashboard';
      case 'users':
        return 'Users';
      case 'picking':
        if (location.pathname.includes('/picking')) {
          if (location.pathname.includes('/picking/issue'))
            return 'Picking Issues';
        }

        return 'Picking';
      case 'supplier-purchase-order':
        return 'Supplier Purchases Order';
      case 'supplier-purchase-order-detail':
        return `Purchase Orders / ${urlQuery.get('orderNo')}`;
      case 'customers':
        if (location.pathname.includes(`/customers/warehouseCustomer`)) {
          if (isEditForm || isViewForm) {
            return `Clients /  ${form.name}`;
          }
          return `Clients / New Client`;
        }
        if (location.pathname.includes(`/customers/`)) {
          if (
            orderCustomerDetails !== undefined &&
            orderCustomerDetails.customerName !== undefined
          ) {
            return `Client / ${
              orderCustomerDetails.customerName
            } - Last Order Date: ${moment(
              orderCustomerDetails.lastDateOrdered,
            ).format('MM/DD/YYYY')}`;
          }
          return 'Loading...';
        }
        return 'Clients';
      case 'locations':
        if (location.pathname.includes(`builder`)) {
          return 'Builder';
        }
        if (location.pathname.includes(`locations/model`)) {
          return 'Location Modeler';
        }
        if (location.pathname.includes(`transfer-queue`)) {
          return 'Transfers';
        }
        if (location.pathname.includes(`locations/location`)) {
          return `Storage Management / ${location.pathname
            .split('/')[3]
            .replaceAll('%20', ' ')}`;
        }
        return 'Storage Management';

      case 'order':
        return 'Invoice';

      case 'orders':
        if (location.pathname.includes('/orders/order')) {
          if (location.pathname.includes('/orders/order-processing-rules'))
            return 'Order Processing Rules';
          if (location.pathname.includes('/orders/order-rules'))
            return 'Order Processing Rules';
          return `Order / ${location.pathname.split('/')[3]}`;
        }
        if (location.pathname.includes('/sales-orders')) {
          // return !pageViewer.isViewOrEdit() ? 'Orders' : <OrderStatusChip />;
          return <OrderStatusChip />;
        }
        return 'Orders';

      case 'returns':
        return 'Returns';

      case 'warehouse-customer-orders':
        if (location.pathname.includes('/sales-orders')) {
          return <OrderStatusChip />;
        }
        return 'Order Overview';
      case 'warehouse-customer':
        return 'Dashboard';
      case 'facilities':
        if (location.pathname.includes('/facility')) {
          return `Facility / ${customerFacility}`;
        }
        return 'Locations and Facilities';
      case 'devices':
        if (location.pathname.includes('devices/device')) {
          return `Devices / ${location.pathname.split('/')[3]}`;
        }
        return 'Scanner Devices';
      case 'license-plates':
        if (location.pathname.includes('/all')) {
          return `License Plates / All`;
        }
        if (location.pathname.includes('/new')) {
          return 'New License Plate';
        }
        return 'License Plate Numbers';
      case 'pack-and-ship':
        if (location.pathname.includes('/find-pick-ticket')) {
          return `Pack and Ship`;
        }
        return <IsInvalidAddressModal />;
      case 'printoutforms':
        return 'Forms';
      case 'reports':
        if (location.pathname.includes(`order-status-report`)) {
          return 'Order Status Report';
        }
        return 'Reports';
      case 'warehouse-customer-reports':
        return 'Reports';
      case 'production':
        if (location.pathname.includes('/job-details')) {
          return `Job / ${prodViewer.isNew() ? 'New' : productionForm?.jobNo}`;
        }
        return `Production`;
      case 'cycle-count':
        return 'Cycle Count';
      case 'lots':
        return 'Item Activity By Lot, LPN and Purchase Order';
      case 'warehouse-customer-lots':
        return 'Item Activity By Lot, LPN and Purchase Order';
      case 'users-permission':
        return 'Users Permission';
      case 'permissions':
        return 'Permissions';
      case 'warehouse-customer-invoices':
        return 'Invoices';
      default:
        return currentLoc;
    }
  };

  const handleToggleCycleCount = () => {
    setOpenCycleCount((prevOpen) => !prevOpen);
  };

  const handleCloseCycleCount = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRefCycleCount.current &&
      anchorRefCycleCount.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenCycleCount(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  function handleListKeyDownCycleCount(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const hanldeDownloadAppClick = () => {
    setOpenQRDialog(true);
  };

  const hanldeHeaderQRDialogClose = () => {
    setOpenQRDialog(false);
  };

  const hanldeHelpCenterClick = () => {
    window.open('https://docs.packemwms.com', '_blank');
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);
  useEffect(() => {
    if (location.pathname.includes(`/facilities/facility`)) {
      onLoadCustomerFacility();
    }
  }, [updateData, currentLocationAndFacility, orderCustomerDetails]);

  const warehouseCustomerName =
    localStorage.getItem('storeWarehouseCustomer') != null
      ? JSON.parse(localStorage.getItem('storeWarehouseCustomer'))?.label
      : null;

  return (
    <>
      <HeaderQRCodeDialog
        isOpen={openQRDialog}
        onClose={hanldeHeaderQRDialogClose}
      />
      <KeyPressGlobalFilter />
      {!isWarehouseCustomerAccount && !isSupplierAccount && (
        <AddQuickLinkModal />
      )}
      <SelectLocationAndFacilityModal />
      <HeaderBox
        className="mainLayoutBorderBottom"
        style={{ backgroundColor: '#3375e0' }}
      >
        <TopHeader>
          <MenuButton ref={menuButton} onClick={() => handleClickMenu()} />
          <DropDownMenu
            open={openMenu}
            onClose={onMenuClose}
            anchorEl={anchorEl}
          />
          <Box
            sx={{ fontWeight: 'medium', color: 'white' }}
            className="headerNavbar"
          >
            <Typography variant="overline">
              <Typography
                sx={{
                  fontWeight: 'medium',
                  color: 'white',
                  cursor: 'pointer',
                }}
                className="headerNavbar"
                variant="overline"
              >
                {currentUser.Claim_CustomerName}
              </Typography>
              |
              {currentLocationAndFacility !== null && (
                <Typography
                  sx={{
                    fontWeight: 'medium',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                  className="headerNavbar"
                  variant="overline"
                  onClick={() => {
                    if (!isWarehouseCustomerAccount) {
                      onOpenLocationAndFacilityModal();
                    }
                  }}
                >
                  {currentLocationAndFacility?.locationName} •{' '}
                  {currentLocationAndFacility?.facilityName}
                  {warehouseCustomerName && ` / ${warehouseCustomerName}`}
                </Typography>
              )}
            </Typography>
            {!isWarehouseCustomerAccount && !isSupplierAccount && (
              <IconButton
                style={{
                  backgroundColor: '#598be0',
                  color: '#ffffff',
                  borderRadius: 20,
                  padding: 5,
                  fontWeight: 'bold',
                }}
                onClick={hanldeHelpCenterClick}
                title="Help Center"
              >
                <img
                  src={ImgHelpInfo}
                  alt="Help Center"
                  style={{
                    marginRight: 5,
                    padding: 0,
                    width: 20,
                    borderRadius: '50%',
                    backgroundColor: '#ffffff',
                  }}
                />
                <Typography
                  variant="caption"
                  style={{
                    marginRight: 5,
                  }}
                >
                  Help Center
                </Typography>
              </IconButton>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '12px',
              boxShadow: '0',
            }}
          >
            {!isWarehouseCustomerAccount && !isSupplierAccount && (
              <QuickLinksButton
                isExpanded={isQuickLinksExpanded}
                setQuicklinksExpanded={setQuicklinksExpanded}
                setQuickLinkModalOpen={setAddQuickLinkModalOpen}
                userQuickLinksList={quickLinks}
                setUserQuickLinksList={setUserQuickLinks}
              />
            )}

            {!isWarehouseCustomerAccount && !isSupplierAccount && (
              <IconButton
                style={{
                  backgroundColor: 'white',
                  color: '#3375e0',
                  borderRadius: 20,
                  padding: 5,
                  fontWeight: 'bold',
                }}
                onClick={hanldeDownloadAppClick}
                title="Download App"
              >
                <SouthOutlinedIcon
                  style={{
                    backgroundColor: '#3375e0',
                    color: 'white',
                    borderRadius: '50%',
                    fontSize: 20,
                    marginRight: 5,
                    padding: 3,
                  }}
                />
                <Typography
                  variant="caption"
                  style={{
                    marginRight: 5,
                  }}
                >
                  Download App
                </Typography>
              </IconButton>
            )}
            {!isWarehouseCustomerAccount && !isSupplierAccount && (
              <Box>
                <IconButton
                  sx={{ ml: 1, color: 'white' }}
                  onClick={() => navigate('/settings')}
                  color="inherit"
                >
                  <SettingsOutlinedIcon />
                </IconButton>
              </Box>
            )}
            <IconButton
              style={{
                backgroundColor: '#598BE0',
                color: '#fff',
                borderRadius: 20,
                padding: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              title="Profile"
            >
              <AccountCircleIcon />
              <Typography
                variant="caption"
                style={{
                  margin: '0 5px',
                }}
              >
                {currentUser.Claim_Fullname}
              </Typography>
            </IconButton>
          </Box>
        </TopHeader>
      </HeaderBox>
      <LowHeaderBox>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            gap: '8px',
          }}
        >
          <Box
            sx={{
              width: '50%',
              display: 'inline-flex',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              {handleGetRoute()}
            </Typography>
          </Box>

          {currentLoc === 'receiving' &&
            !location.pathname.includes('receiving/') && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: '8px',
                }}
              >
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => setIsImportPurchaseOrderModalOpen(true)}
                >
                  Import Purchase Order
                </Button>

                <Button
                  variant="contained"
                  size="large"
                  onClick={() =>
                    navigate('/receiving/purchase-orders?form=new')
                  }
                >
                  New PO
                </Button>
              </Box>
            )}
          {location.pathname.includes('warehouse-customer-receiving') &&
            !location.pathname.includes(`purchase-orders`) &&
            !isSupplierAccount && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: '8px',
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  onClick={() =>
                    navigate(
                      '/warehouse-customer-receiving/purchase-orders?form=new',
                    )
                  }
                >
                  New PO
                </Button>
              </Box>
            )}
          {location.pathname.includes('warehouse-customer-receiving') &&
            location.pathname.includes(`purchase-orders`) &&
            !isSupplierAccount &&
            urlQuery.get('id') !== 'new' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: '8px',
                }}
              >
                {pageViewerPO.isView() ? (
                  <>
                    <POStatusButtonDropdown />
                    <Button
                      variant="contained"
                      size="medium"
                      onClick={() => handleEditPurchaseOrder()}
                    >
                      Edit PO
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      size="medium"
                      sx={{
                        borderColor: 'rgb(5, 47, 255)',
                        color: 'rgb(5, 47, 255)',
                      }}
                      onClick={() => {
                        if (pageViewerPO.isNew()) {
                          navigate('/warehouse-customer-receiving');
                        }
                        if (pageViewerPO.isEdit()) {
                          setQueryStringPurchaseOrder((prevState) => ({
                            ...prevState,
                            form: 'view',
                          }));
                          navigate(
                            `/warehouse-customer-receiving/purchase-orders?form=view&id=${queryStringPurchaseOrder.id}`,
                          );
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      size="medium"
                      onClick={(e) => handleOnCreatePurchaseOrder(e)}
                    >
                      Save
                    </Button>
                  </>
                )}
              </Box>
            )}
          {currentLoc === 'receiving' &&
            location.pathname.includes('receiving/purchase-orders') &&
            urlQuery.get('id') !== 'new' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: '8px',
                }}
              >
                {pageViewerPO.isView() ? (
                  <>
                    <POStatusButtonDropdown />
                    {handleUserPermissionAllow(
                      UserPermissionEnum.PurchaseOrder_Edit,
                    ) && (
                      <Button
                        variant="contained"
                        size="medium"
                        onClick={() => handleEditPurchaseOrder()}
                      >
                        Edit PO
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      size="medium"
                      sx={{
                        borderColor: 'rgb(5, 47, 255)',
                        color: 'rgb(5, 47, 255)',
                      }}
                      onClick={() => {
                        if (pageViewerPO.isNew()) {
                          navigate('/receiving');
                        }
                        if (pageViewerPO.isEdit()) {
                          setQueryStringPurchaseOrder((prevState) => ({
                            ...prevState,
                            form: 'view',
                          }));
                          navigate(
                            `/receiving/purchase-orders?form=view&id=${queryStringPurchaseOrder.id}`,
                          );
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      size="medium"
                      onClick={(e) => handleOnCreatePurchaseOrder(e)}
                    >
                      Save
                    </Button>
                  </>
                )}
              </Box>
            )}

          {location.pathname.includes('supplier-purchase-order-detail') &&
            urlQuery.get('form') !== 'new' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: '8px',
                }}
              >
                <POStatusButtonDropdown />
                <Box>
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={(e) => handleOnCreatePurchaseOrder(e)}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            )}
          {currentLoc === 'receiving' && location.pathname.includes('/item') && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
                gap: '8px',
              }}
            >
              <Button
                variant="text"
                size="large"
                onClick={() => onOpenPurchaseOrderItemModal()}
              >
                ADD ITEMS
              </Button>
            </Box>
          )}
          {currentLoc === 'receiving' &&
            location.pathname.includes('/receipts') &&
            !location.pathname.includes('/receipts/subpage') && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: '8px',
                }}
              >
                {handleUserPermissionAllow(
                  UserPermissionEnum.Receiving_AddNew,
                ) && (
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() =>
                      navigate('/receiving/receipts/subpage?form=new')
                    }
                  >
                    Create New Receipt
                  </Button>
                )}
              </Box>
            )}
          {currentLoc === 'receiving' &&
            location.pathname.includes('/receipts/subpage') && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: '8px',
                }}
              >
                {pageViewerReceipt.isView() ? (
                  <>
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={() => {
                        onClickFinalReceiptSummary(
                          receiptDetail.warehouseCustomerId,
                          queryStringItemReceipts.id,
                        );
                      }}
                    >
                      Final Receipt Summary
                    </Button>
                    <Button
                      onClick={() => onOpenReceiptDocumentModal()}
                      variant="outlined"
                      size="large"
                      sx={{
                        display: pageViewerReceipt.isNew() ? 'none' : '',
                      }}
                    >
                      Documents
                    </Button>
                    <Button
                      sx={{
                        display: pageViewerReceipt.isNew() ? 'none' : 'block',
                      }}
                      onClick={handleOnClickPrintAllLP}
                      variant="outlined"
                      size="large"
                    >
                      Print LPS
                    </Button>
                    {receiptStatus === ReceiptStatus.Open && (
                      <Button
                        onClick={() => {
                          if (
                            inboundData?.isConfirmReceiptLineItem === true &&
                            !lineItemRows.every(
                              (x) => x?.isLineItemConfirm === true,
                            )
                          ) {
                            snackActions.error(
                              'You are required to confirm each line before confirming the receipt.',
                            );
                            return;
                          }

                          if (
                            receiptDetail?.totalQty !==
                              receiptDetail?.totalQtyExpected &&
                            receiptDetail?.purchaseOrderId
                          ) {
                            setIsConfirmQcValidationModalOpen(true);
                          } else {
                            onOpenConfirmReceiptModal();
                          }
                        }}
                        variant="outlined"
                        size="large"
                      >
                        Confirm Receipt
                      </Button>
                    )}
                    {receiptStatus === ReceiptStatus.ReadyforPutAway && (
                      <Button
                        onClick={(e) => {
                          handleOnClickConfirmReceipt(e);
                        }}
                        variant="outlined"
                        size="large"
                      >
                        Move to Put Away
                      </Button>
                    )}
                    {handleUserPermissionAllow(
                      UserPermissionEnum.Receiving_Edit,
                    ) && (
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => {
                          if (receiptStatus === ReceiptStatus.Confirmed) {
                            onOpenEditConfirmedReceiptModal();
                          } else {
                            setQueryStringItemReceipts((prevState) => ({
                              ...prevState,
                              form: 'edit',
                            }));
                            navigate(
                              `/receiving/receipts/subpage?form=edit&id=${queryStringItemReceipts.id}`,
                            );
                          }
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <Button
                      disabled={isSaving}
                      variant="outlined"
                      size="medium"
                      sx={{
                        borderColor: 'rgb(5, 47, 255)',
                        color: 'rgb(5, 47, 255)',
                        display: pageViewerReceipt.isView() ? 'none' : 'block',
                      }}
                      onClick={() => {
                        if (pageViewerReceipt.isNew()) {
                          navigate('/receiving/receipts');
                        }
                        if (pageViewerReceipt.isEdit()) {
                          setQueryStringItemReceipts((prevState) => ({
                            ...prevState,
                            form: 'view',
                          }));
                          navigate(
                            `/receiving/receipts/subpage?form=view&id=${queryStringItemReceipts.id}`,
                          );
                        }
                      }}
                    >
                      Cancel
                    </Button>

                    {!isSaving && (
                      <Button
                        variant="contained"
                        size="large"
                        onClick={handleOnClickCreateReceipt}
                      >
                        Save
                      </Button>
                    )}

                    {isSaving && (
                      <Button
                        disabled
                        variant="contained"
                        size="large"
                        onClick={handleOnClickCreateReceipt}
                      >
                        <CircularProgress
                          sx={{
                            width: '25px !important',
                            height: '25px !important',
                          }}
                        />
                        <Box sx={{ paddingLeft: '5px' }}>Save</Box>
                      </Button>
                    )}
                  </>
                )}
              </Box>
            )}
          {currentLoc === 'customer-management' &&
            !location.pathname.includes('customer-management/customer') &&
            handleUserPermissionAllow(UserPermissionEnum.Client_AddNew) && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: '8px',
                }}
              >
                <Button
                  variant="text"
                  size="large"
                  onClick={() => onOpenCustomerModal()}
                >
                  Add Client
                </Button>
              </Box>
            )}
          {currentLoc === 'inventory' &&
            !location.pathname.includes('inventory/') && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: '8px',
                }}
              >
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => onOpenImportInventoryModal()}
                >
                  Import Inventory
                </Button>

                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => navigate('/inventory/suppliers')}
                >
                  Suppliers
                </Button>
                {handleUserPermissionAllow(
                  UserPermissionEnum.Inventory_AddNew,
                ) && (
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => navigate('/inventory/item-details?form=new')}
                  >
                    Add Item
                  </Button>
                )}
              </Box>
            )}

          {location.pathname.includes('inventory/item-details') &&
            !location.pathname.includes(
              'warehouse-customer-inventory/item-details',
            ) && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: '8px',
                }}
              >
                {' '}
                {urlQuery.get('form') === 'view' &&
                  parseInt(urlQuery.get('id'), 10) > 0 && (
                    <>
                      <Tooltip title="If this is an eCommerce product, the available quantities will be synced to the store.">
                        <Button
                          variant="outlined"
                          size="large"
                          onClick={() => {
                            itemEcommerceSync();
                          }}
                        >
                          Ecommerce Sync
                        </Button>
                      </Tooltip>
                      <Button
                        variant="outlined"
                        size="large"
                        onClick={() => {
                          setPrintTypeData(PrintTypeEnum.SKU);
                          setIsPrintQtyModal(true);
                          setNumberToPrintGlobal(1);
                        }}
                      >
                        PRINT LABEL
                      </Button>
                    </>
                  )}
                {!inventoryPageViewer.isView() && (
                  <Button
                    variant="outlined"
                    size="medium"
                    sx={{
                      borderColor: 'rgb(5, 47, 255)',
                      color: 'rgb(5, 47, 255)',
                    }}
                    onClick={() => {
                      if (inventoryPageViewer.isNew()) {
                        navigate('/inventory');
                      }
                      if (inventoryPageViewer.isEdit()) {
                        setQueryStringItemDetails((prevState) => ({
                          ...prevState,
                          form: 'view',
                        }));
                        navigate(
                          `/inventory/item-details?form=view&id=${queryStringItemDetails.id}`,
                        );
                      }
                    }}
                  >
                    Cancel
                  </Button>
                )}
                {inventoryPageViewer.isView() ? (
                  <div>
                    {handleUserPermissionAllow(
                      UserPermissionEnum.Inventory_Edit,
                    ) && (
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => {
                          setQueryStringItemDetails((prevState) => ({
                            ...prevState,
                            form: 'edit',
                          }));
                          navigate(
                            `/inventory/item-details?form=edit&id=${queryStringItemDetails.id}`,
                          );
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    size="large"
                    onClick={handleOnClickSaveInventoryItemDetail}
                  >
                    Save
                  </Button>
                )}
              </Box>
            )}

          {location.pathname.includes('inventory/supplier-details') && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
                gap: '8px',
              }}
            >
              {handleUserPermissionAllow(UserPermissionEnum.Suppliers_Edit) && (
                <>
                  {pageViewerSupplier.isView() && (
                    <Button
                      variant="contained"
                      size="medium"
                      onClick={(e) => handleEditSupplier(e)}
                    >
                      EDIT
                    </Button>
                  )}
                  {pageViewerSupplier.isEdit() && (
                    <>
                      <Button
                        variant="outlined"
                        size="medium"
                        sx={{
                          color: 'rgb(5, 47, 255) !important',
                          marginRight: '10px',
                        }}
                        onClick={() => {
                          if (pageViewerSupplier.isNew()) {
                            navigate('/suppliers');
                          }
                          if (pageViewerSupplier.isEdit()) {
                            setQueryStringSupplier((prevState) => ({
                              ...prevState,
                              form: 'view',
                            }));
                          }
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        size="medium"
                        onClick={(e) => handleEditSupplier(e)}
                      >
                        SAVE
                      </Button>
                    </>
                  )}
                </>
              )}
            </Box>
          )}

          {currentLoc === 'license-plates' &&
            !location.pathname.includes('license-plates/') && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: '8px',
                }}
              >
                <Button
                  variant="text"
                  size="large"
                  onClick={() => onOpenBatchPrintLPNModal()}
                >
                  Reprint LPN
                </Button>
                <Button
                  variant="text"
                  size="large"
                  onClick={() => onOpenPrintRollModal()}
                >
                  Print Roll
                </Button>
                <Button
                  variant="text"
                  size="large"
                  onClick={() => navigate('/license-plates/new')}
                >
                  Create New
                </Button>
              </Box>
            )}
          {currentLoc === 'license-plates' &&
            location.pathname.includes('/new') && (
              <>
                <Box sx={{ display: 'flex', width: '10%' }}>
                  <Button
                    sx={{
                      background: '#F8F9FA',
                      width: '100%',
                      borderColor: '#cad0d3',
                    }}
                    variant="outlined"
                    size="medium"
                    onClick={print}
                  >
                    PRINT
                  </Button>
                </Box>
                <Box sx={{ display: 'flex', width: '10%' }}>
                  <Button
                    sx={{
                      background: '#3375E0',
                      width: '100%',
                    }}
                    variant="contained"
                    size="medium"
                    onClick={onPutAwayQueue}
                  >
                    Put Away Queue
                  </Button>
                </Box>
                <Box sx={{ display: 'flex', width: '10%' }}>
                  <Button
                    sx={{
                      background: '#3375E0',
                      width: '100%',
                    }}
                    variant="contained"
                    size="medium"
                    onClick={onSaveLicensePlate}
                  >
                    Save
                  </Button>
                </Box>
              </>
            )}
          {currentLoc === 'inventory' &&
            location.pathname.includes('/item') &&
            !location.pathname.includes('/item-details') && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: '8px',
                }}
              >
                <Button
                  variant="text"
                  size="large"
                  onClick={() => onOpenBinModal()}
                >
                  Add Bin & Qty
                </Button>
              </Box>
            )}
          {(currentLoc === 'inventory' || currentLoc === 'suppliers') &&
            location.pathname.includes('/suppliers') && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: '8px',
                }}
              >
                {handleUserPermissionAllow(
                  UserPermissionEnum.Suppliers_Add,
                ) && (
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={() => onOpenSupplierModal()}
                  >
                    Add New Supplier
                  </Button>
                )}
              </Box>
            )}
          {currentLoc === 'devices' &&
            !location.pathname.includes('devices/device') && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: '8px',
                }}
              >
                <Button
                  variant="text"
                  size="large"
                  onClick={() => onOpenDeviceModal()}
                >
                  Register Device
                </Button>
              </Box>
            )}
          {currentLoc === 'locations' &&
            !location.pathname.includes('/builder') &&
            !location.pathname.includes('/model') &&
            !location.pathname.includes('/transfer-queue') &&
            !location.pathname.includes('locations/location') && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: '8px',
                }}
              >
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={() => onClickPrintBarcode()}
                >
                  print barcode
                </Button>
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={() => onOpenFileInputModal()}
                >
                  Import
                </Button>

                <Button
                  variant="outlined"
                  size="medium"
                  onClick={() => onOpenFacilityZoneModal()}
                >
                  Add Area
                </Button>
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={() => onClickBulkDeleteBin()}
                >
                  Bulk Delete
                </Button>
                {handleUserPermissionAllow(
                  UserPermissionEnum.Locations_Add,
                ) && (
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={() => onOpenCreateBinModal()}
                  >
                    Add Bin Location
                  </Button>
                )}
              </Box>
            )}
          {(currentLoc === 'orders' ||
            currentLoc === 'warehouse-customer-orders') &&
            !location.pathname.includes(`/order-processing-rules`) &&
            !location.pathname.includes(`/order-rules`) &&
            !location.pathname.includes(`/sales-orders`) && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: '8px',
                }}
              >
                {!isWarehouseCustomerAccount && !isSupplierAccount && (
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={() => {
                      onHandleRefreshOrders();
                      setIsEnableToRefresh(false);
                    }}
                    disabled={!isEnableToRefresh}
                    sx={{
                      textTransform: 'none',
                    }}
                  >
                    Refresh Orders
                  </Button>
                )}

                {!isWarehouseCustomerAccount &&
                  handleUserPermissionAllow(
                    UserPermissionEnum.Orders_AddOrder,
                  ) && (
                    <>
                      <Button
                        variant="outlined"
                        size="large"
                        onClick={() => onOpenImportOrderModal()}
                        sx={{
                          textTransform: 'none',
                        }}
                      >
                        Import Order
                      </Button>
                      <Button
                        variant="contained"
                        size="large"
                        sx={{
                          display: handleUserPermissionAllow(
                            UserPermissionEnum.Orders_AddOrder,
                          )
                            ? 'block'
                            : 'none',
                        }}
                        onClick={() =>
                          navigate('/orders/sales-orders?form=new')
                        }
                      >
                        Add Order
                      </Button>
                    </>
                  )}
                {isWarehouseCustomerAccount &&
                  handleUserPermissionAllow(
                    UserPermissionEnum.Orders_AddOrder,
                  ) && (
                    <>
                      <Button
                        variant="outlined"
                        size="large"
                        onClick={() => onOpenImportOrderModal()}
                      >
                        Import Order
                      </Button>
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() =>
                          navigate(
                            '/warehouse-customer-orders/sales-orders?form=new',
                          )
                        }
                      >
                        Add Order
                      </Button>
                    </>
                  )}
              </Box>
            )}

          {currentLoc === 'order' &&
            location.pathname.includes(`/invoice-details`) && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: '8px',
                }}
              >
                {queryStringInvoiceDetailPage?.invoiceId !== 0 && (
                  <IconButton
                    size="small"
                    title="Print Invoice"
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                      onOpenPrintFormTemplateModal();
                      setFormTemplateType(FormTemplateTypeEnum.Invoice);
                    }}
                  >
                    <PackemInvoiceLogo
                      sx={{
                        width: '25px !important',
                        height: '25px !important',
                      }}
                    />
                  </IconButton>
                )}
                {!state.isBusy && (
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    sx={{
                      padding: 1,
                      backgroundColor: ' rgb(75, 126, 254) !important',
                    }}
                    onClick={(e) => {
                      OnClickCreateUpdateOrderInvoice(e);
                    }}
                  >
                    Save
                  </Button>
                )}
                {state.isBusy && (
                  <Button
                    disabled
                    type="submit"
                    variant="contained"
                    size="small"
                    sx={{
                      padding: 1,
                      backgroundColor: ' rgb(75, 126, 254) !important',
                      color: 'white !important',
                    }}
                  >
                    <CircularProgress
                      sx={{
                        color: 'white !important',
                        width: '25px !important',
                        height: '25px !important',
                      }}
                    />
                    <Box sx={{ paddingLeft: '5px' }}>Saving</Box>
                  </Button>
                )}
              </Box>
            )}

          {/* {currentLoc === 'orders' && location.pathname.includes('/order') && (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          width: '100%',
          gap: '8px',
        }}
      >
        <Button
          variant="text"
          size="large"
          onClick={() => onOpenManualReceiptModal()}
        >
          Archive
        </Button>
        <Button variant="text" size="large">
          Pick Ticket
        </Button>
        <Button
          variant="text"
          size="large"
          onClick={() => onOpenAddOrderLineItemModal()}
        >
          Add Items
        </Button>
      </Box>
    )} */}
          {location.pathname.includes('/sales-orders') && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              {(salesOrderFlagStatus.status === OrderStatus.Complete ||
                salesOrderFlagStatus.status === OrderStatus.ReadyForPickup ||
                salesOrderFlagStatus.status === OrderStatus.Shipment) &&
              pageViewer.isView() ? (
                <>
                  <Button
                    className="headerbuttonSecondary"
                    variant="outlined"
                    size="small"
                    onClick={(e) => {
                      printFormTemplateOnClick(
                        e,
                        FormTemplateTypeEnum.PackingList,
                      );
                    }}
                  >
                    Print Packing List
                  </Button>
                  <Button
                    className="headerbuttonSecondary"
                    onClick={(e) =>
                      printFormTemplateOnClick(
                        e,
                        FormTemplateTypeEnum.BillofLading,
                      )
                    }
                    variant="outlined"
                    size="small"
                  >
                    Print BOL
                  </Button>
                </>
              ) : (
                <span />
              )}
              {pageViewer.isView() && <OrderStatusDropdown />}
              {isFormDisable ? (
                <Button
                  onClick={() => {
                    setQueryStringItemOrders((prevState) => ({
                      ...prevState,
                      form: 'edit',
                    }));
                  }}
                  sx={{
                    ...(!handleUserPermissionAllow(
                      UserPermissionEnum.Orders_Edit,
                    ) && { display: 'none' }),
                    ...(isWarehouseCustomerAccount &&
                      salesOrderFlagStatus.status !== OrderStatus.Cancelled &&
                      salesOrderFlagStatus.status !== OrderStatus.New && {
                        display: 'none',
                      }),
                    backgroundColor: 'rgb(5, 47, 255)',
                  }}
                  variant="contained"
                  size="medium"
                >
                  Edit
                </Button>
              ) : (
                <>
                  {!pageViewer.isView() && !loading && (
                    <Button
                      variant="outlined"
                      size="medium"
                      sx={{
                        borderColor: 'rgb(5, 47, 255)',
                        color: 'rgb(5, 47, 255)',
                      }}
                      onClick={() => {
                        if (pageViewer.isNew()) {
                          if (!isWarehouseCustomerAccount) {
                            navigate('/orders');
                          } else {
                            navigate('/warehouse-customer-orders');
                          }
                        }
                        if (pageViewer.isEdit()) {
                          setQueryStringItemOrders((prevState) => ({
                            ...prevState,
                            form: 'view',
                          }));

                          if (!isWarehouseCustomerAccount) {
                            navigate(
                              `/orders/sales-orders?form=view&id=${queryStringItemOrders.id}`,
                            );
                          } else {
                            navigate(
                              `/warehouse-customer-orders/sales-orders?form=view&id=${queryStringItemOrders.id}`,
                            );
                          }
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  )}

                  {loading && (
                    <>
                      <CircularProgress sx={{ color: 'blue !important' }} />
                      <p> &nbsp; Please Wait</p>
                    </>
                  )}
                  {!loading && (
                    <Button
                      onClick={createSalesOrderOnClick}
                      variant="contained"
                      size="medium"
                      sx={{ backgroundColor: 'rgb(5, 47, 255)' }}
                    >
                      Save
                    </Button>
                  )}
                </>
              )}
            </Box>
          )}
          {location.pathname.includes('devices/device') && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
                gap: '8px',
              }}
            >
              <Button
                variant="text"
                size="large"
                onClick={() => onOpenDeviceTokenModal()}
              >
                Create Token
              </Button>
            </Box>
          )}
          {currentLoc === 'facilities' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
                gap: '8px',
              }}
            >
              {handleUserPermissionAllow(UserPermissionEnum.Locations_Add) && (
                <Button
                  variant="text"
                  size="large"
                  onClick={() => onOpenCustomerLocationModal()}
                >
                  Add Location
                </Button>
              )}
            </Box>
          )}
          {currentLoc === 'users' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
                gap: '8px',
              }}
            >
              <Button
                variant="contained"
                size="medium"
                onClick={() => onOpenUserModal()}
              >
                Add User
              </Button>
            </Box>
          )}
          {currentLoc === 'customers' &&
            !location.pathname.includes('customers/') &&
            handleUserPermissionAllow(UserPermissionEnum.Client_AddNew) && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: '8px',
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  onClick={() =>
                    navigate('/customers/warehouseCustomer?id=new')
                  }
                >
                  Add Client
                </Button>
              </Box>
            )}
          {currentLoc === 'customers' && location.pathname === '/customers/' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
                gap: '8px',
              }}
            >
              <Button
                variant="text"
                size="large"
                onClick={() => onOpenManualSalesOrderModal()}
              >
                Add Sale Order
              </Button>
              <Button
                variant="text"
                size="large"
                onClick={() => onOpenOrderCustomerAddressModal()}
              >
                Add Address
              </Button>
            </Box>
          )}
          {currentLoc === 'customers' &&
            location.pathname === '/customers/warehouseCustomer' &&
            handleUserPermissionAllow(UserPermissionEnum.Client_Edit) && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: '8px',
                }}
              >
                {isViewForm ? (
                  <>
                    {tabValue === '7' && (
                      <Button
                        variant="text"
                        size="large"
                        sx={{ textTransform: 'capitalize' }}
                        onClick={() => setIsCopyDefaultCustomerModalOpen(true)}
                      >
                        Copy From Defaults
                      </Button>
                    )}
                    {tabValue !== '4' && tabValue !== '6' && (
                      <Button
                        variant="contained"
                        size="medium"
                        onClick={handleEditViewOnlick}
                      >
                        Edit
                      </Button>
                    )}
                  </>
                ) : (
                  <Box sx={{ display: 'flex' }}>
                    {tabValue !== '4' && tabValue !== '6' && (
                      <>
                        <Button
                          variant="outlined"
                          size="medium"
                          sx={{
                            borderColor: 'rgb(5, 47, 255)',
                            color: 'rgb(5, 47, 255)',
                            marginRight: '10px',
                          }}
                          onClick={() => {
                            setIsViewForm(true);
                            setIsEditForm(false);
                            setIsManageClientDefault(false);

                            if (isEditForm) {
                              navigate(
                                `/customers/warehouseCustomer?form=view&id=${queryString.id}`,
                              );
                            } else {
                              navigate(`/customers`);
                            }
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          size="medium"
                          onClick={handleOnCreateWarehouseCustomer}
                        >
                          Save
                        </Button>
                      </>
                    )}
                  </Box>
                )}
              </Box>
            )}
          {currentLoc === 'integrations' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
                gap: '8px',
              }}
            >
              <Button
                variant="text"
                size="large"
                onClick={() => onOpenIntegrationModal()}
              >
                Add
              </Button>
            </Box>
          )}

          {currentLoc === 'pack-and-ship' &&
            location.pathname === '/pack-and-ship/pick-ticket' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100% !important',
                  '& button': {
                    marginLeft: '10px !important',
                    textTransform: 'none',
                  },
                }}
              >
                <PackAndShipButtons />
              </Box>
            )}

          {currentLoc === 'printoutforms' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
                '& button': {
                  padding: '10px !important',
                },
              }}
            >
              {location.pathname !== '/printoutforms/formdetails' && (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() =>
                    navigate('/printoutforms/formdetails?form=new')
                  }
                >
                  ADD NEW
                </Button>
              )}
              {location.pathname === '/printoutforms/formdetails' &&
                (pageViewerForms.isView() ? (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setQueryStringItemForm((prevState) => ({
                        ...prevState,
                        form: 'edit',
                      }));
                      navigate(
                        `/printoutforms/formdetails?form=edit&id=${queryStringItemForm.id}`,
                      );
                    }}
                  >
                    EDIT
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        color: 'rgb(5, 47, 255) !important',
                        marginRight: '10px',
                      }}
                      onClick={() => {
                        if (pageViewerForms.isNew()) {
                          navigate('/printoutforms');
                        }
                        if (pageViewerForms.isEdit()) {
                          setQueryStringItemForm((prevState) => ({
                            ...prevState,
                            form: 'view',
                          }));
                          navigate(
                            `/printoutforms/formdetails?form=view&id=${queryStringItemForm.id}`,
                          );
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={onClickCreateFormTemplate}
                    >
                      SAVE
                    </Button>
                  </>
                ))}
            </Box>
          )}
          {currentLoc === 'production' &&
            location.pathname !== '/production/job-details' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100% !important',
                  // '& button': {
                  //   marginLeft: '10px !important',
                  //   textTransform: 'none',
                  // },
                }}
              >
                {/* <Button
                  onClick={(e) => {
                    navigate('job-details?form=new');
                  }}
                  sx={{
                    marginLeft: '2px !important',
                    margin: '0px',
                    padding: 1,
                    background: '#3375E0',
                    fontWeight: 600,
                  }}
                  variant="contained"
                  size="small"
                >
                  Create Job
                </Button> */}
                {handleUserPermissionAllow(
                  UserPermissionEnum.Kitting_AddNew,
                ) && (
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={() => {
                      navigate('job-details?form=new');
                    }}
                  >
                    Create Job
                  </Button>
                )}
              </Box>
            )}

          {currentLoc === 'production' &&
            location.pathname === '/production/job-details' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100% !important',
                }}
              >
                {prodViewer.isView() ? (
                  <>
                    <ProductionJobStatusDropdown />
                    <Button
                      variant="contained"
                      size="medium"
                      sx={{
                        marginLeft: '10px',
                      }}
                      onClick={() => {
                        setQueryStringItemProduction((prevState) => ({
                          ...prevState,
                          form: 'edit',
                        }));
                        navigate(
                          `/production/job-details?form=edit&id=${queryStringItemProduction.id}`,
                        );
                      }}
                    >
                      Edit
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      size="medium"
                      sx={{
                        borderColor: 'rgb(5, 47, 255)',
                        color: 'rgb(5, 47, 255)',
                        marginRight: '10px',
                      }}
                      onClick={() => {
                        if (prodViewer.isNew()) {
                          navigate('/production');
                        }
                        if (prodViewer.isEdit()) {
                          setQueryStringItemProduction((prevState) => ({
                            ...prevState,
                            form: 'view',
                          }));
                          navigate(
                            `/production/job-details?form=view&id=${queryStringItemProduction.id}`,
                          );
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      size="medium"
                      onClick={(e) => createProductionJobOnClick(e)}
                    >
                      Save
                    </Button>
                  </>
                )}
              </Box>
            )}

          {currentLoc === 'cycle-count' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
                gap: '8px',
              }}
            >
              {location.pathname
                .toLocaleLowerCase()
                .includes('/cycle-count/detail') ? (
                <>
                  <Button
                    variant="text"
                    size="large"
                    disabled={
                      cycleCountDataDetailModal.statusName !== 'Counted'
                    }
                    onClick={(e) => onClickEndCycleCount(e)}
                  >
                    {cycleCountDataDetailModal.statusName !== 'Closed'
                      ? 'END CYCLE COUNT'
                      : 'Closed'}
                  </Button>

                  {cycleCountViewer.isView() &&
                    handleUserPermissionAllow(
                      UserPermissionEnum.CycleCount_Edit,
                    ) && (
                      <Button
                        variant="text"
                        size="large"
                        disabled={
                          cycleCountDataDetailModal.statusName !== 'Uncounted'
                        }
                        onClick={() => {
                          setQueryStringItemCycleCount((prevState) => ({
                            ...prevState,
                            form: 'edit',
                          }));
                          navigate(
                            `?type=${queryStringItemCycleCount.type}&form=edit&id=${queryStringItemCycleCount.id}`,
                          );
                        }}
                      >
                        EDIT
                      </Button>
                    )}
                  {cycleCountViewer.isEdit() && (
                    <Button
                      variant="text"
                      size="large"
                      onClick={(e) => onHandleCycleCountUpdate(e)}
                    >
                      SAVE
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {handleUserPermissionAllow(
                    UserPermissionEnum.CycleCount_Add,
                  ) && (
                    <Button
                      variant="contained"
                      size="medium"
                      ref={anchorRefCycleCount}
                      aria-controls={
                        openCycleCount ? 'composition-menu' : undefined
                      }
                      sx={{ textTransform: 'capitalize' }}
                      aria-expanded={openCycleCount ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={() => handleToggleCycleCount()}
                    >
                      Create Cycle Count
                    </Button>
                  )}
                  <Popper
                    sx={{ zIndex: '1' }}
                    open={openCycleCount}
                    anchorEl={anchorRefCycleCount.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === 'bottom-start'
                              ? 'left top'
                              : 'left bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener
                            onClickAway={handleCloseCycleCount}
                          >
                            <MenuList
                              autoFocusItem={openCycleCount}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                              onKeyDown={() => handleListKeyDownCycleCount}
                            >
                              <MenuItem
                                sx={{
                                  paddingRight: '120px',
                                  color: '#1C9DCC',
                                }}
                                onClick={() => {
                                  navigate(
                                    `?type=${cycleCountType[
                                      cycleCountType.Location
                                    ].toLocaleLowerCase()}&form=${
                                      FormQueryStringStatus.New
                                    }`,
                                  );
                                  onOpenCycleCountModal();
                                }}
                              >
                                LOCATIONS
                              </MenuItem>
                              <MenuItem
                                sx={{
                                  paddingRight: '120px',
                                  color: '#1C9DCC',
                                }}
                                onClick={() => {
                                  navigate(
                                    `?type=${cycleCountType[
                                      cycleCountType.Item
                                    ].toLocaleLowerCase()}&form=${
                                      FormQueryStringStatus.New
                                    }`,
                                  );
                                  onOpenCycleCountModal();
                                }}
                              >
                                ITEMS
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </>
              )}
            </Box>
          )}

          {currentLoc === 'returns' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
                gap: '8px',
              }}
            >
              <Button
                variant="contained"
                size="large"
                onClick={() =>
                  navigate('/receiving/receipts/subpage?form=new&isReturn=true')
                }
              >
                Create New Return
              </Button>
            </Box>
          )}

          {currentLoc === 'picking' && location.pathname !== '/picking/issue' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
                gap: '8px',
              }}
            >
              <Button
                variant="text"
                size="large"
                onClick={() => navigate('/picking/issue')}
              >
                Picking Issues
              </Button>
            </Box>
          )}
        </Box>
      </LowHeaderBox>
    </>
  );
}

export default React.memo(Header);
