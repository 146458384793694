/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';

import { UserPermissionEnum } from 'common/enums/enum-user-permissions';
import { IUserInfo } from 'common/interfaces';
import { getUsersByCustomerId } from 'services/api/user/users.api';
import { AuthContext } from 'store/contexts/AuthContext';

import { Item } from '../users-permission-modal';

export const userPermissionsTreeView: Item[] = [
  {
    value: UserPermissionEnum.PurchaseOrder,
    label: 'Purchase Order',
    parentId: 0,
  },
  {
    value: UserPermissionEnum.PurchaseOrder_View,
    label: 'View',
    parentId: UserPermissionEnum.PurchaseOrder,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.PurchaseOrder_Edit,
    label: 'Edit',
    parentId: UserPermissionEnum.PurchaseOrder,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.PurchaseOrder_DocumentUpload,
    label: 'Document Upload',
    parentId: UserPermissionEnum.PurchaseOrder,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.PurchaseOrder_Update_Closed_PO,
    label: 'Update Closed PO',
    parentId: UserPermissionEnum.PurchaseOrder,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Inventory,
    label: 'Inventory',
    parentId: 0,
  },
  {
    value: UserPermissionEnum.Inventory_View,
    label: 'View',
    parentId: UserPermissionEnum.Inventory,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Inventory_Edit,
    label: 'Edit',
    parentId: UserPermissionEnum.Inventory,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Inventory_AddNew,
    label: 'Add New',
    parentId: UserPermissionEnum.Inventory,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Inventory_AddPackage,
    label: 'Add Package',
    parentId: UserPermissionEnum.Inventory,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Inventory_AddKitOnDemand,
    label: 'Add Kit on Demand',
    parentId: UserPermissionEnum.Inventory,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Orders,
    label: 'Orders',
    parentId: 0,
  },
  {
    value: UserPermissionEnum.Orders_View,
    label: 'View',
    parentId: UserPermissionEnum.Orders,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Orders_Edit,
    label: 'Edit',
    parentId: UserPermissionEnum.Orders,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Orders_AddOrder,
    label: 'Add Order',
    parentId: UserPermissionEnum.Orders,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Orders_DocumentUpload,
    label: 'Document Upload',
    parentId: UserPermissionEnum.Orders,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Orders_EditNotes,
    label: 'Edit Notes',
    parentId: UserPermissionEnum.Orders,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Orders_EditChecklist,
    label: 'Edit Checklist',
    parentId: UserPermissionEnum.Orders,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Dashboard,
    label: 'Dashboard',
    parentId: 0,
  },
  {
    value: UserPermissionEnum.Dashboard_View,
    label: 'View',
    parentId: UserPermissionEnum.Dashboard,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Returns,
    label: 'Returns',
    parentId: 0,
  },
  {
    value: UserPermissionEnum.Returns_View,
    label: 'View',
    parentId: UserPermissionEnum.Returns,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Returns_AddReturns,
    label: 'Add Returns',
    parentId: UserPermissionEnum.Returns,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Kitting,
    label: 'Kitting',
    parentId: 0,
  },
  {
    value: UserPermissionEnum.Kitting_View,
    label: 'View',
    parentId: UserPermissionEnum.Kitting,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Kitting_Edit,
    label: 'Edit',
    parentId: UserPermissionEnum.Kitting,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Kitting_AddNew,
    label: 'Add New',
    parentId: UserPermissionEnum.Kitting,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Locations,
    label: 'Locations',
    parentId: 0,
  },
  {
    value: UserPermissionEnum.Locations_View,
    label: 'View',
    parentId: UserPermissionEnum.Locations,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Locations_Add,
    label: 'Add',
    parentId: UserPermissionEnum.Locations,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Locations_Edit,
    label: 'Edit',
    parentId: UserPermissionEnum.Locations,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Suppliers,
    label: 'Suppliers',
    parentId: 0,
  },
  {
    value: UserPermissionEnum.Suppliers_View,
    label: 'View',
    parentId: UserPermissionEnum.Suppliers,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Suppliers_Add,
    label: 'Add',
    parentId: UserPermissionEnum.Suppliers,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Suppliers_Edit,
    label: 'Edit',
    parentId: UserPermissionEnum.Suppliers,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.CycleCount,
    label: 'CycleCount',
    parentId: 0,
  },
  {
    value: UserPermissionEnum.CycleCount_View,
    label: 'View',
    parentId: UserPermissionEnum.CycleCount,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.CycleCount_Add,
    label: 'Add',
    parentId: UserPermissionEnum.CycleCount,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.CycleCount_Edit,
    label: 'Edit',
    parentId: UserPermissionEnum.CycleCount,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Receiving,
    label: 'Receiving',
    parentId: 0,
  },
  {
    value: UserPermissionEnum.Receiving_View,
    label: 'View',
    parentId: UserPermissionEnum.Receiving,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Receiving_AddNew,
    label: 'Add New',
    parentId: UserPermissionEnum.Receiving,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Receiving_Edit,
    label: 'Edit',
    parentId: UserPermissionEnum.Receiving,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Receiving_ConfirmQC,
    label: 'Confirm QC',
    parentId: UserPermissionEnum.Receiving,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Receiving_Putaway,
    label: 'Put away',
    parentId: UserPermissionEnum.Receiving,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Picking,
    label: 'Picking',
    parentId: 0,
  },
  {
    value: UserPermissionEnum.Picking_View,
    label: 'View',
    parentId: UserPermissionEnum.Picking,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Picking_Edit,
    label: 'Edit',
    parentId: UserPermissionEnum.Picking,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Picking_CompletePick,
    label: 'Complete Pick',
    parentId: UserPermissionEnum.Picking,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.PackAndShip,
    label: 'Pack and Ship',
    parentId: 0,
  },
  {
    value: UserPermissionEnum.PackAndShip_View,
    label: 'View',
    parentId: UserPermissionEnum.PackAndShip,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.PackAndShip_Edit,
    label: 'Edit',
    parentId: UserPermissionEnum.PackAndShip,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.PackAndShip_CompletePack,
    label: 'Complete Pack',
    parentId: UserPermissionEnum.PackAndShip,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Client,
    label: 'Client',
    parentId: 0,
  },
  {
    value: UserPermissionEnum.Client_View,
    label: 'View',
    parentId: UserPermissionEnum.Client,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Client_Edit,
    label: 'Edit',
    parentId: UserPermissionEnum.Client,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Client_AddNew,
    label: 'Add New',
    parentId: UserPermissionEnum.Client,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Client_Billing,
    label: 'Billing',
    parentId: UserPermissionEnum.Client,
  },
  {
    value: UserPermissionEnum.Client_Billing_View,
    label: 'View',
    parentId: UserPermissionEnum.Client_Billing,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Client_Billing_View_Charge_Rate,
    label: 'View Charge Rate',
    parentId: UserPermissionEnum.Client_Billing,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Client_Billing_Edit,
    label: 'Edit',
    parentId: UserPermissionEnum.Client_Billing,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Client_Integration,
    label: 'Integration',
    parentId: UserPermissionEnum.Client,
  },
  {
    value: UserPermissionEnum.Client_Integration_View,
    label: 'View',
    parentId: UserPermissionEnum.Client_Integration,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Client_Integration_Edit,
    label: 'Edit',
    parentId: UserPermissionEnum.Client_Integration,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Client_CustomerPortal,
    label: 'Customer Portal',
    parentId: UserPermissionEnum.Client,
  },
  {
    value: UserPermissionEnum.Client_CustomerPortal_View,
    label: 'View',
    parentId: UserPermissionEnum.Client_CustomerPortal,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Client_CustomerPortal_Edit,
    label: 'Edit',
    parentId: UserPermissionEnum.Client_CustomerPortal,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Client_ShippingAccounts,
    label: 'Shipping Accounts',
    parentId: UserPermissionEnum.Client,
  },
  {
    value: UserPermissionEnum.Client_ShippingAccounts_View,
    label: 'View',
    parentId: UserPermissionEnum.Client_ShippingAccounts,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Client_ShippingAccounts_Edit,
    label: 'Edit',
    parentId: UserPermissionEnum.Client_ShippingAccounts,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Client_Rules,
    label: 'Rules',
    parentId: UserPermissionEnum.Client,
  },
  {
    value: UserPermissionEnum.Client_Rules_View,
    label: 'View',
    parentId: UserPermissionEnum.Client_Rules,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Client_Rules_Edit,
    label: 'Edit',
    parentId: UserPermissionEnum.Client_Rules,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Reports,
    label: 'Reports',
    parentId: 0,
  },
  {
    value: UserPermissionEnum.Reports_View,
    label: 'View',
    parentId: UserPermissionEnum.Reports,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Administrative,
    label: 'Administrative',
    parentId: 0,
  },
  {
    value: UserPermissionEnum.Administrative_Settings,
    label: 'Settings',
    parentId: UserPermissionEnum.Administrative,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Administrative_UserManagement,
    label: 'User Management',
    parentId: UserPermissionEnum.Administrative,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Administrative_Devices,
    label: 'Devices',
    parentId: UserPermissionEnum.Administrative,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Administrative_Facilities,
    label: 'Facilities',
    parentId: UserPermissionEnum.Administrative,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Administrative_Forms,
    label: 'Forms',
    parentId: UserPermissionEnum.Administrative,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Invoices,
    label: 'Invoices',
    parentId: 0,
  },
  {
    value: UserPermissionEnum.Invoices_View,
    label: 'View',
    parentId: UserPermissionEnum.Invoices,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Inventory_DeleteItems,
    label: 'Delete Items',
    parentId: UserPermissionEnum.Inventory,
    lastNode: true,
  },
  {
    value: UserPermissionEnum.Orders_Assignment,
    label: 'Order Assignment',
    parentId: UserPermissionEnum.Picking,
    lastNode: true,
  },
];

export enum CheckboxState {
  UNCHECKED,
  CHECKED,
  INDETERMINATE,
}
export type ItemState = {
  value: number;
  state: CheckboxState;
  isUserPermissionChecked: boolean;
};

export const defaultItemStates: ItemState[] = userPermissionsTreeView.map(
  (i) => ({
    value: i.value,
    state: CheckboxState.UNCHECKED,
    isUserPermissionChecked: false,
  }),
);

interface IUsersPermission {
  setFilteredUsers: React.Dispatch<React.SetStateAction<IUserInfo[]>>;
  filteredUsers: IUserInfo[];
  setManagePermissionModal: React.Dispatch<React.SetStateAction<boolean>>;
  managePermissionModal: boolean;
  onLoadCustomerData: () => Promise<unknown>;
  setUsers: React.Dispatch<React.SetStateAction<IUserInfo[]>>;
  users: IUserInfo[];
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  checked: boolean;
}

type UsersPermissionProviderProps = {
  children: React.ReactNode;
};

const UsersContext = createContext<IUsersPermission>({} as IUsersPermission);
export const useUsersContext = () => useContext(UsersContext);

export const UsersContextProvider = ({
  children,
}: UsersPermissionProviderProps) => {
  const { currentUser } = useContext(AuthContext);

  const [users, setUsers] = useState<IUserInfo[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<IUserInfo[]>([]);
  const [managePermissionModal, setManagePermissionModal] = useState(false);

  const [checked, setChecked] = React.useState(true);

  const onLoadCustomerData = async () => {
    try {
      const customerUsersFromApi = await getUsersByCustomerId(
        currentUser.Claim_CustomerId,
        checked,
      );
      setUsers(customerUsersFromApi);
      setFilteredUsers(customerUsersFromApi);

      return true;
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    onLoadCustomerData();
  }, [currentUser.Claim_CustomerId]);

  const value = useMemo(
    () => ({
      users,
      checked,
      filteredUsers,
      managePermissionModal,
      setUsers,
      setChecked,
      setFilteredUsers,
      setManagePermissionModal,
      onLoadCustomerData,
    }),
    [users, checked, filteredUsers, managePermissionModal],
  );

  return (
    <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
  );
};
