import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const lookupOrderCustomer = async (customerId, searchText) => {
  try {
    const response = await httpClient.get(
      `/warehousecustomer/lookup/name/customer/${customerId}?searchText=${searchText}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const loadStatusOptions = async (searchText) => {
  try {
    const response = await httpClient.get(
      `/warehousecustomer/status/lookup?searchText=${searchText}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const loadShippingRatePreferenceOptions = async (searchText) => {
  try {
    const response = await httpClient.get(
      `/warehousecustomer/ShippingRatePreference/lookup?searchText=${searchText}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createWarehouseCustomer = async (data) => {
  try {
    const response = await httpClient.post(`/warehousecustomer/Create`, data);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateWarehouseCustomer = async (data) => {
  try {
    const response = await httpClient.post(`/warehousecustomer/edit`, data);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// GET /api/warehousecustomer/vm/{customerFacilityId}
export const GetWarehouseCustomerViewModel = async (
  customerFacilityId: number,
) => {
  try {
    const response = await httpClient.get(
      `/warehousecustomer/vm/${customerFacilityId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getWarehouseCustomerById = async (warehousecustomerid) => {
  try {
    const response = await httpClient.get(
      `/warehousecustomer/${warehousecustomerid}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getWarehouseCustomerList = async (customerid) => {
  try {
    const response = await httpClient.get(
      `/warehousecustomer/list/${customerid}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// ------------------------------------------ OTHER ACCOUNT
//  warehouse customer Other contact Update
export const updateWarehouseCustomerOtherContact = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomerothercontact/edit`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

//  warehouse customer Other contact Create
export const createWarehouseCustomerOtherContact = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomerothercontact/create`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

//  warehouse customer Other contact delete
export const deleteWarehouseCustomerOtherContact = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomerothercontact/delete`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// ------------------------------------------ SHIPPING ACCOUNT
// warehouse customer Shipping Account Create
export const createWarehouseCustomerShippingAccount = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomershippingaccount/create`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// warehouse customer Shipping Account Edit
export const updateWarehouseCustomerShippingAccount = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomershippingaccount/edit`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// warehouse customer Shipping Account Delete
export const deleteWarehouseCustomerShippingAccount = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomershippingaccount/delete`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

//   ------------------------------------------  Customer portal Account activate -------------------------------------
export const createCustomerPortalAccount = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomer/customerportalaccount/create`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const editCustomerPortalAccount = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomer/customerportalaccount/edit`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const generateActivationUrl = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomer/customerportalaccount/generateactivationurl`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const updateCustomerPortalAccountAccess = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomer/customerportalaccount/updateisactive`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const GetAccountDetailForActivation = async (key) => {
  try {
    const response = await httpClient.get(
      `/warehousecustomer/customerportalaccount/accountdetailforactivation?key=${key}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateCustomerPortalAccountActivation = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomer/customerportalaccount/updatecustomerPortalaccountactivation`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getCarrierLookup = async () => {
  try {
    const response = await httpClient.get(`/warehousecustomer/Lookup/Carriers`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// ----------------------------------------- BILLING API ----------------------

export const getCarrierPackagesForBilling = async (warehouseCustomerId) => {
  try {
    const response = await httpClient.get(
      `/warehousecustomer/billing/packages/${warehouseCustomerId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getBillingData = async (warehouseCustomerId) => {
  try {
    const response = await httpClient.get(
      `/warehousecustomer/billing/detail/${warehouseCustomerId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// billingChargeType = Storage / Handling / Adhoc
// chargeType = Inbound / Outbound / Sales Order
export const getBillingChargesDetailByModule = async (
  warehouseCustomerId,
  billingChargeType,
  chargeType,
) => {
  try {
    const response = await httpClient.get(
      `/warehousecustomer/billing/getbillingchargesdetailbymodule/${warehouseCustomerId}/${billingChargeType}/${chargeType}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createStorageChargeBilling = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomer/billing/storagecharge/create`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const editStorageChargeBilling = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomer/billing/storagecharge/edit`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deleteStorageChargeBilling = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomer/billing/storagecharge/delete`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createHandlingChargeBilling = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomer/billing/handlingcharge/create`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const editHandlingChargeBilling = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomer/billing/handlingcharge/edit`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deleteHandlingChargeBilling = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomer/billing/handlingcharge/delete`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createAdhocChargeBilling = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomer/billing/adhoccharge/create`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const editAdhocChargeBilling = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomer/billing/adhoccharge/edit`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deleteAdhocChargeBilling = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomer/billing/adhoccharge/delete`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const deleteConnectionIntegration = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomer/extensivIntegration/delete`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deleteBillingChargeSectionByModuleAsync = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomer/billing/DeleteBillingChargeSectionByModuleAsync/delete`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateCustomerPortalAccountEmailNotification = async (
  warehouseCustomerAccountId,
) => {
  try {
    const response = await httpClient.put(
      `/warehousecustomer/customerPortalAccount/${warehouseCustomerAccountId}/completeOrderEmailNotification`,
    );
  } catch (err) {
    throw errorCatch(err);
  }
};

// INVOICES ---------
export const getInvoiceByCustomer = async (invoiceId) => {
  try {
    const response = await httpClient.get(
      `/warehousecustomer/getInvoiceByCustomer/${invoiceId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// CUSTOMER SEND EMAIL ---------------------
export const sendInvoiceEmail = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomer/SendInvoiceEmail`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// delete warehouse customer account --------------- custoer page/ customer portal
export const DeleteCustomerPortalAccount = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomer/customerportalaccount/deletecustomerportalaccount`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const copyDefaultWarehouseCustomer = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomer/CopyDefaultWarehouseCustomer`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const deleteOrderSourceRule = async (data) => {
  try {
    const response = await httpClient.post(
      `/warehousecustomer/DeleteOrderSourceRule/delete`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
